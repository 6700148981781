import React from 'react';
import {useCookies} from 'react-cookie';
import {useQuery} from '@apollo/client';
import HomeSearchPosts from '../../queries/HomeSearchPosts';
import {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
} from 'react';
import PostDesgin from '../postDesing/PostDesgin';
import PollDisplay from '../postDesing/PollDisplay';
import PublicIcon from '@material-ui/icons/Public';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import ListAltIcon from '@mui/icons-material/ListAlt';
import SupervisedUserCircleOutlinedIcon from '@material-ui/icons/SupervisedUserCircleOutlined';
import {Button, Typography} from '@material-ui/core';
import {ThreeCircles} from 'react-loader-spinner';
import {useLocation} from 'react-router-dom';
const SearchPostSection = forwardRef((props, searchRef) => {
  const pathname = useLocation();

  const trackRef = useRef([]);
  const [data, setData] = useState([]);
  const [cookies, setCookie] = useCookies(['user']);
  const [getSubscriptionsData, setGetSubscriptionsData] = useState([]);
  const [filterselected, setfilterselected] = useState('all');
  const [loading, setLoading] = useState(true);

  useImperativeHandle(searchRef, () => ({
    search: searchPosts,
  }));

  const searchPosts = query => {
    refetch({
      inputFilter: {
        userId: cookies.user.userId ? cookies.user.userId : '',
        keyWord: query,
        type: 'post',
        filter: filterselected,
      },
      limit: 20,
      nextToken: null,
    });
  };

  const {refetch, fetchMore} = useQuery(
    HomeSearchPosts,
    {
      variables: {
        limit: 10,
        nextToken: null,
        inputFilter: {
          keyWord: 'a',
          filter: filterselected,
          userId: cookies.user.userId,
          type: 'post',
        },
      },
      onCompleted: res => {
        console.log('res : ', res);
        setData(res.homeSearchPost ? res.homeSearchPost.edges : []);
        setGetSubscriptionsData(res.homeSearchPost);
        setLoading(false);
      },
      onError: err => {
        console.log(err);
      },
    },
    [],
  );

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        nextToken: getSubscriptionsData.pageInfo.endCursor,
        limit: 10,
        inputFilter: {
          userId: cookies.user.userId ? cookies.user.userId : '',
          keyWord: props.query,
          type: 'post',
          filter: filterselected,
        },
      },
      updateQuery: (prev, {fetchMoreResult}) => {
        if (!fetchMoreResult) return prev;

        setGetSubscriptionsData(fetchMoreResult.homeSearchPost);
        return {
          ...prev,
          homeSearchPost: {
            ...prev.homeSearchPost,
            edges: [
              ...(prev.homeSearchPost ? prev.homeSearchPost.edges : []),
              ...(fetchMoreResult.homeSearchPost
                ? fetchMoreResult.homeSearchPost.edges
                : []),
            ],
            pageInfo: fetchMoreResult.homeSearchPost
              ? fetchMoreResult.homeSearchPost.pageInfo
              : [],
          },
        };
      },
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (getSubscriptionsData && getSubscriptionsData.pageInfo) {
        if (
          !trackRef.current.includes(
            getSubscriptionsData ? getSubscriptionsData.pageInfo.endCursor : '',
          )
        ) {
          handleLoadMore();
          trackRef.current = [
            ...trackRef.current,
            getSubscriptionsData ? getSubscriptionsData.pageInfo.endCursor : '',
          ];
        }
        console.log(trackRef.current);
      }
    };
    window.scrollTo(0, 0);

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [data, pathname]); // Ensure that this effect is executed whenever new data is fetched
  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '1rem',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Button
          variant="contained"
          startIcon={<PublicIcon />}
          color={filterselected === 'all' ? 'primary' : 'default'}
          style={{
            borderRadius: '2.5rem',
            color: 'white',
            textTransform: 'capitalize',
            width: '10rem',
          }}
          onClick={() => {
            setfilterselected('all');
            refetch({
              inputFilter: {
                userId: cookies.user.userId ? cookies.user.userId : '',
                keyWord: props.query,
                type: 'post',
                filter: 'all',
              },
              limit: 20,
              nextToken: null,
            });
            setLoading(true);
          }}>
          All
        </Button>
        <Button
          variant="contained"
          startIcon={<SupervisedUserCircleOutlinedIcon />}
          color={filterselected === 'network' ? 'primary' : 'default'}
          style={{
            borderRadius: '2.5rem',
            color: 'white',
            textTransform: 'capitalize',
            width: '10rem',
          }}
          onClick={() => {
            setfilterselected('network');
            refetch({
              inputFilter: {
                userId: cookies.user.userId ? cookies.user.userId : '',
                keyWord: props.query,
                type: 'post',
                filter: 'network',
              },
              limit: 20,
              nextToken: null,
            });
            setLoading(true);
          }}>
          My Network
        </Button>
        <Button
          variant="contained"
          startIcon={<ListAltIcon />}
          color={filterselected === 'mcq' ? 'primary' : 'default'}
          style={{
            borderRadius: '2.5rem',
            color: 'white',
            textTransform: 'capitalize',

            width: '10rem',
          }}
          onClick={() => {
            setLoading(true);
            setfilterselected('mcq');
            refetch({
              inputFilter: {
                userId: cookies.user.userId ? cookies.user.userId : '',
                keyWord: props.query,
                type: 'post',
                filter: 'mcq',
              },
              limit: 20,
              nextToken: null,
            });
          }}>
          MCQ
        </Button>
        <Button
          variant="contained"
          startIcon={<LocationCityIcon />}
          color={filterselected === 'city' ? 'primary' : 'default'}
          style={{
            borderRadius: '2.5rem',
            color: 'white',
            textTransform: 'capitalize',
            width: '10rem',
          }}
          onClick={() => {
            setfilterselected('city');
            refetch({
              inputFilter: {
                userId: cookies.user.userId ? cookies.user.userId : '',
                keyWord: props.query,
                type: 'post',
                filter: 'city',
              },
              limit: 20,
              nextToken: null,
            });
            setLoading(true);
          }}>
          My city{' '}
        </Button>
        <Button
          variant="contained"
          startIcon={<ViewModuleIcon />}
          color={filterselected === 'interest' ? 'primary' : 'default'}
          style={{
            borderRadius: '2.5rem',
            color: 'white',
            textTransform: 'capitalize',
            width: '10rem',
          }}
          onClick={() => {
            setfilterselected('interest');
            refetch({
              inputFilter: {
                userId: cookies.user.userId ? cookies.user.userId : '',
                keyWord: props.query,
                type: 'post',
                filter: 'interest',
              },
              limit: 5,
              nextToken: null,
            });
            setLoading(true);
            setData([]);
          }}>
          Similar Interests
        </Button>
      </div>
      {loading ? (
        <div>
          <ThreeCircles
            height="100"
            width="100"
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '1rem',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            color="#4fa94d"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="three-circles-rotating"
            outerCircleColor="#00a3ff"
            innerCircleColor="#ff9d00"
            middleCircleColor="#4088FF"
          />
        </div>
      ) : data.length > 0 ? (
        data.map((item, key) => (
          <div
            key={key}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            {item.node.poll ? (
              <PollDisplay data={item.node} />
            ) : (
              <PostDesgin data={item.node} />
            )}
          </div>
        ))
      ) : (
        <Typography
          variant="h1"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          No Posts To Show
        </Typography>
      )}
    </div>
  );
});

export default SearchPostSection;
