import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',

  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const CrispyPostDisplay = props => {
  const colorcodes = [
    '#FF7F50',
    '#DAA520',
    '#9ACD32',
    '#20B2AA',
    '#6A5ACD',
    '#7D6608',
  ];
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.root} style={{paddingBottom:"0.5rem"}}>
      
        <Accordion  style={{backgroundColor:colorcodes[props.index]}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            
            aria-controls="panel1a-content"
            id="panel1a-header"
           >
            <Typography className={classes.heading}>
              {props.data.heading}
            </Typography>
            <Divider />
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{props.data.content}</Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </React.Fragment>
  );
};

export default CrispyPostDisplay;
