import gql from 'graphql-tag';

export default gql`
  query iAmFollowing(
    $limit: Int
    $nextToken: String
    $userId: String
    $visitorUserId: String
  ) {
    iAmFollowing(
      limit: $limit
      nextToken: $nextToken
      userId: $userId
      visitorUserId: $visitorUserId
    ) @connection(key: "UserProfileDisplayModel") {
      edges {
        node {
          name
          description
          score
          userId
          profileImageUrl
          bannerImageUrl
          dateOfBirth
          state
          userType
          college
          degree
          company
          faceBookUrl
          gitHubUrl
          instagramUrl
          linkedInUrl
          followersCount
          followingCount
          interests 
          amIFollowing
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
