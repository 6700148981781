import gql from 'graphql-tag';

export default gql`
  query getNews($id: ID!) {
    getNews(id: $id) {
      id
      heading
      summary
      category
      location
      hostUrl
      mediaUrl
      createdBy
      createdAt
      updatedAt
    }
  }
`;
