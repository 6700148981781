import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
  IconButton,
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from '@material-ui/core';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(theme => ({}));
const TypePoll = props => {
  const [selectedValue, setSelectedValue] = React.useState('');

  const handleChangeForRight = event => {
    console.log(event.target.value);
    setSelectedValue(event.target.value);
    props.rightanswer(event.target.value);
  };

  const [inputs, setInputs] = useState([
    {key: '', value: '', checkbox: false, focus: false},
    {key: '', value: '', checkbox: false, focus: false},
  ]);

  const addHandler = () => {
    if (inputs.length > 4) {
      // showerrormessages("Max 4 options can be added");
    } else {
      const _inputs = [...inputs];
      _inputs.push({key: '', value: '', checkbox: false, focus: true});
      setInputs(_inputs);
    }
  };

  const deleteHandler = key => {
    const _inputs = inputs.filter((input, index) => index !== key);
    setInputs(_inputs);
  };

  const inputHandler = (text, key, checkstate, focus) => {
    const _inputs = [...inputs];
    _inputs[key].value = text;
    _inputs[key].key = key;
    _inputs[key].checkbox = checkstate;
    _inputs[key].focus = focus;
    setInputs(_inputs);
    props.options(_inputs);
  };

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="ans"
            name="ans1"
            value={selectedValue}
            onChange={handleChangeForRight}>
            {inputs.map((input, key) => {
              return (
                <div key={key} style={{display: 'flex'}}>
                  <div>
                    <TextField
                      variant="outlined"
                      multiline={true}
                      placeholder="Enter your option"
                      size="small"
                      style={{
                        width: '26rem',
                        margin: '1rem ',
                      }}
                      inputProps={{
                        maxLength: 200,
                      }}
                      onChange={e => {
                        inputHandler(e.target.value, key, input.checkbox, true);
                      }}
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'start',
                    }}>
                    <FormControlLabel
                      // style={{marginTop: '-1rem'}}
                      value={key + ''}
                      control={<Radio color='primary'/>}
                    />
                    {key > 1 ? (
                      <IconButton
                        // style={{left: '1rem',bottom:"2.5rem" }}
                        onClick={() => {
                          deleteHandler(key);
                          alert(key);
                        }}>
                        <CancelOutlinedIcon style={{color: 'ff0000'}} />
                      </IconButton>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              );
            })}
          </RadioGroup>
        </FormControl>
      </div>
      <div>
        {inputs.length >= 4 ? (
          <div />
        ) : (
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            color="primary"
            onClick={addHandler}
            style={{
              marginRight: '1.2rem',
              borderRadius: '1.2rem',
              border: 'none',
              outline: 'none',
              fontWeight: 'bold',
              fontSize: '1rem',
              textTransform: 'capitalize',
            }}>
            Add Option
          </Button>
        )}
       
      </div>
    </div>
  );
};

export default TypePoll;
