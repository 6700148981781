
import gql from 'graphql-tag';

export default gql`
  mutation likePost(
    $postId: ID!,
    $likedByUserId: String!,
  ) {
    likePost(
      postId: $postId
      likedByUserId: $likedByUserId
    )
  }
`;
