
import gql from 'graphql-tag';

export default gql`
query getAllDegrees($courseName: String!) {
  getAllDegrees(courseName: $courseName) {
    courseName
  }
}
`;
