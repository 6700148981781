import React, {useState,  useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Typography, Paper, Divider, CircularProgress, Button} from '@material-ui/core';
import Usersuggestions from '../../queries/Usersuggestions';
import UserCard from './UserCard';
import {useCookies} from 'react-cookie';
import {useQuery} from '@apollo/client';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  Paper: {
    width: '15rem',
    borderRadius: '1rem',
    margin: '0rem 2rem 3rem 0rem',
    paddingBottom: '0.5rem',
  },
  FriendRequestTextStyle: {
    fontWeight: 'bold',
    paddingTop: '0.5rem',
    marginLeft: '0.8rem',
    textAlign: 'center',
  },
}));

const SuggestionCard = (props) => {
  const [userSuggestionData, setuserSuggestionData] = useState([]);
  // const [getSubscriptionsData, setGetSubscriptionsData] = useState([]);
  const [cookies, setCookie] = useCookies(['user']);
  const [loading, setLoading] = useState(true);
  // const trackRef = useRef([]);
  const navigate=useNavigate();

  const {fetchMore} = useQuery(
    Usersuggestions,
    {
      variables: {
        userId: cookies.user?cookies.user.userId:"",
        limit: 10,
        nextToken: null,
      },
      onCompleted: res => {
        console.log('userSuggestion', res);

        setuserSuggestionData(
          res.userSuggestion ? res.userSuggestion.edges : [],
        );
        // setGetSubscriptionsData(res.userSuggestion);
        setLoading(false);
      },
      onError: err => {
        console.log(err);
      },
    },
    [],
  );
  // console.log('getsubscriptiondata : ', getSubscriptionsData);
  // const handleLoadMore = () => {
  //   fetchMore({
  //     variables: {
  //       nextToken: getSubscriptionsData.pageInfo.endCursor,
  //       limit: 10,
  //       userModelInputFilter: {
  //         userId: cookies.user.userId ? cookies.user.userId : '',
  //       },
  //     },

  //     updateQuery: (prev, {fetchMoreResult}) => {
  //       if (!fetchMoreResult) return prev;

  //       setGetSubscriptionsData(fetchMoreResult.userSuggestion);
  //       return {
  //         ...prev,
  //         userSuggestion: {
  //           ...prev.userSuggestion,
  //           edges: [
  //             ...prev.userSuggestion.edges,
  //             ...fetchMoreResult.userSuggestion.edges,
  //           ],
  //           pageInfo: fetchMoreResult.userSuggestion.pageInfo,
  //         },
  //       };
  //     },
  //   });
  // };

  // useEffect(() => {
  //   const handleScroll = () => {
  //     // You can define your own conditions to trigger loading more data based on scroll
  //     // if (
  //     //   window.innerHeight + window.scrollY >=
  //     //   document.body.offsetHeight - 500
  //     // ) {
  //     //   console.log('pagination if check');
  //     //   console.log(trackRef.current);
  //       if (getSubscriptionsData && getSubscriptionsData.pageInfo) {
  //         if (
  //           !trackRef.current.includes(
  //             getSubscriptionsData
  //               ? getSubscriptionsData.pageInfo.endCursor
  //               : '',
  //           )
  //         ) {
  //           handleLoadMore();
  //           trackRef.current = [
  //             ...trackRef.current,
  //             getSubscriptionsData
  //               ? getSubscriptionsData.pageInfo.endCursor
  //               : '',
  //           ];
  //         }
  //         console.log(trackRef.current);
  //       }
  //     }
  //   // };

  //   window.addEventListener('scroll', handleScroll);
  //   return () => window.removeEventListener('scroll', handleScroll);
  // }, [userSuggestionData]); // Ensure that this effect is executed whenever new data is fetched

  const classes = useStyles();
  return (
    <React.Fragment>
      <Paper elevation={1} className={classes.Paper}>
        <Typography
          variant="subtitle1"
          className={classes.FriendRequestTextStyle}>
          User Suggestions
        </Typography>
        <Divider />
        {loading ? (
          <CircularProgress style={{marginLeft: '6rem', marginTop: '5rem'}} />
        ) : (
          userSuggestionData.map((item, key) => (
            <div key={key}>
              <UserCard userSuggestionData={item.node} />
            </div>
          ))
        )}
        <Button style={{color:"gray",margin:"0rem 5rem",textTransform:"capitalize"}} onClick={()=>{
          navigate('/suggestion',);
        }}>See All...</Button>
      </Paper>
    </React.Fragment>
  );
};

export default SuggestionCard;
