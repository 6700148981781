import gql from 'graphql-tag';

export default gql`
  query topContentCreators(
    $userModelInputFilter: UserModelInputFilter
  ) {
    topContentCreators(
      userModelInputFilter: $userModelInputFilter
    ) {
          name
          description
          score
          userId
          profileImageUrl
          bannerImageUrl
          dateOfBirth
          state
          userType
          college
          degree
          company
          faceBookUrl
          gitHubUrl
          instagramUrl
          linkedInUrl
          followersCount
          followingCount
          interests 
          amIFollowing
       
    }
  }
`;
