import React, {useState, useEffect, useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Followers from './Followers';
import Following from './Following';
import {useLocation} from 'react-router-dom';

function TabPanel(props) {
  const {children, value, index, ...other} = props;
  return (
    <div
      style={{display: 'flex', justifyContent: 'center', alignItems: 'center', }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3} style={{marginTop:"3rem"}}>
          <Typography style={{textAlign:"center",}}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const useStyles = makeStyles(theme => ({
  Paper: {
    Paper: {
      backgroundColor: '#fff',
      minWidth: '100vh',
      minHeight: '100vw',
    },
  },
  CreateAPostTextStyle: {
    padding: '0.5rem',
  },
}));

const FolloweList = props => {
  const classes = useStyles();

  const [tabvalue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  useEffect(()=>{
    if (location.state.isfollower) {
      setTabValue(0);
    }
    else{
      setTabValue(1);
    }

  },[])

  const location = useLocation();

  return (
    <React.Fragment>
      <div>
        <AppBar 
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor:"white", }}>
          <Tabs
            value={tabvalue}
            onChange={handleChange}
            style={{backgroundColor: 'white' ,}}
            aria-label="simple tabs example">
            <Tab label="Followers" {...a11yProps(0)}  />
            <Tab label="Following" {...a11yProps(1)}  />
          </Tabs>
        </AppBar>

        <TabPanel value={tabvalue} index={0}  >
          <Followers
          
            user={location.state.userId}
            isFollowers={location.state.isfollower}
          />
        </TabPanel>
        <TabPanel value={tabvalue} index={1}>
          <Following
            user={location.state.userId}
            isFollowers={location.state.isfollower}
          />
        </TabPanel>
      </div>
    </React.Fragment>
  );
};
export default FolloweList;
