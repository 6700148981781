import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useNavigate} from 'react-router-dom';
import {
  Paper,
  TextField,
  Button,
  CircularProgress,
  Card,
} from '@material-ui/core';
import Typography from '@mui/material/Typography';
import {Link} from 'react-router-dom';
import Verifyuser from '../../queries/Verifyuser';
import scontilogo from '../../assets/login/scontilogo.svg';
import {useCookies} from 'react-cookie';
import {useLazyQuery} from '@apollo/client';
import './../../App.css';
import PhoneView from '../../components/phoneView/PhoneView';
import {auth, firebase, provider} from './firebase';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
// import {Google} from '@mui/icons-material';
import Google from '../../assets/Icon_png/google.png';

const useStyles = makeStyles(theme => ({
  loginSubHeading: {
    color: theme.palette.primary.main,
    paddingBottom: '1rem',
  },

  paper: {
    padding: '2rem',
    borderRadius: '1rem',
    width: '30rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0rem 3rem 3rem 0rem',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: '1rem',
      padding: '2rem',
      margin: '0rem 3rem 0rem 0rem',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      width: '100%',
      marginBottom: '2rem',
      padding: '3rem',
      alignItems: 'center',
      marginTop: '5rem',
    },
  },

  right: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '3rem',
  },

  divider: {
    color: theme.palette.color.black,
    height: '1rem',
  },
  textField: {
    margin: '0rem 0rem 2rem 0rem',
  },
  loginButton: {
    color: theme.palette.color.white,
    background: theme.palette.primary.main,
    textTransform: 'none',
    borderRadius: '2rem',
    margin: '1rem 0rem 1rem 0rem',
    '&:hover': {
      color: theme.palette.color.white,
      background: 'primary',
    },
  },
  icons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  TextColor: {
    color: theme.palette.color.black,
  },
  //
  scontilogo: {
    height: '7rem',
    width: '7rem',
  },
  TextFieldStyle: {
    borderColor: theme.palette.color.black,
    margin: '0.2rem 1rem',
  },
  resize: {
    padding: '0.5rem 0.5rem',
    border: `0.1rem solid ${theme.palette.color.grey2}`,
    borderRadius: '0.3rem',
    margin: theme.spacing(1),

    width: '20rem',
  },
  SubmitButtonBorder: {textAlign: 'center', margin: '2rem 0 1rem'},
  SubmitButton: {
    textTransform: 'capitalize',
    color: theme.palette.color.white,
    padding: '0.4rem 3rem',
    marginRight: '1rem',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  PhoneViewDisable: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  PhoneViewEnable: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  HelperTextStyle: {
    fontSize: 12,
    color: theme.palette.color.HelperTextColore,
    margin: '2rem 2rem 1rem',
    textAlign: 'center',
  },
  PhoneNumberTextStyle: {
    marginBottom: '0.3rem',
    color: theme.palette.color.grey1,
    textAlign: 'center',
  },
  TermTextStyle: {
    fontSize: 10,
    margin: '1rem 2rem',
    textAlign: 'center',
  },
  TermTextStyleTwo: {
    fontSize: 10,
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  RecaptchaStyle: {display: 'flex', justifyContent: 'center'},
}));

const Form = props => {
  const classes = useStyles();
  const navigate = useNavigate();
  // Inputs
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setotp] = useState('');
  const [show, setshow] = useState(false);
  const [final, setfinal] = useState('');
  // const [progress, setProgress] = useState(false);
  const [otpProgress, otpSetProgress] = useState(false);
  const [cookies, setCookie] = useCookies(['user']);
  const [boolean, setBoolean] = useState(false);
  const [verifyUser] = useLazyQuery(Verifyuser, {
    variables: {
      userId: auth.currentUser ? auth.currentUser.uid : '',
    },
  });

  const handleChange = e => {
    if (!isNaN(e.target.value)) {
      setPhoneNumber(e.target.value.slice(0, 10));
    }
    if (Number.isInteger(Number.parseInt(e.target.value))) {
      if (e.target.value.length <= 10) {
        setPhoneNumber(e.target.value);
      } else {
        alert('Number cannot exceed more than 10 digits');
      }
    }
  };

  // Sent OTP
  const signin = () => {
    setProgress(true);
    var phone = '+91' + phoneNumber;
    let verify = new firebase.auth.RecaptchaVerifier('recaptcha-container');
    auth
      .signInWithPhoneNumber(phone, verify)
      .then(result => {
        setfinal(result);
        setshow(true);
      })
      .catch(err => {
        alert(err);
        setProgress(false);
        window.location.reload();
      });
  };

  // Validate OTP
  const ValidateOtp = () => {
    otpSetProgress(true);
    if (otp === null || final === null) return;
    final
      .confirm(otp)
      .then(result => {
        console.log(result.user.uid);
        verifyUser({
          variables: {
            userId: result.user.uid,
          },
        }).then(res => {
          console.log(res);

          if (res.data && res.data.verifyUser) {
            console.log(res);
            setCookie('user', res.data.verifyUser, {path: '/'});
            otpSetProgress(false);
            navigate('/home');
          } else {
            otpSetProgress(false);
            props.setScreenTrack('nameform');
          }
        });
      })
      .catch(err => {
        alert('You have entered wrong otp!'); // put ssnacbar alert
        otpSetProgress(false);
      });
  };

  const [isRegister, setRegister] = useState(false);
  const [selectedUser, setSelectedUser] = useState('');
  const [username, setusername] = useState('');
  const [usernumber, setusernumber] = useState('');
  const [useremail, setuseremail] = useState('');
  const [userpassword, setuserpassword] = useState('');
  const [organisationname, setorganisationname] = useState('');
  const [progress, setProgress] = useState(false);
  const [resetpassword, setresetpassword] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isValid, setValid] = useState(true);

  const handleEmailChange = event => {
    const newEmail = event.target.value;
    // Perform email validation using a regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setuseremail(event.target.value);
    setIsEmailValid(emailRegex.test(newEmail));
  };

  const handleMobileNumberChange = event => {
    const inputMobileNumber = event.target.value;
    // Use a regular expression to check for 10 digits
    const isValidNumber = /^\d{10}$/.test(inputMobileNumber);
    setusernumber(event.target.value);
    setValid(isValidNumber);
  };

  const handleToggleForm = () => {
    if (resetpassword || isRegister) {
      setRegister(false);
      setresetpassword(false);
    } else {
      setRegister(!isRegister);
    }
  };

  const handleUserChange = event => {
    setSelectedUser(event.target.value);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setProgress(true);
    auth
      .createUserWithEmailAndPassword(useremail, userpassword)
      .then(res => {
        otpSetProgress(false);
        props.setPhoneNumber(usernumber);
        props.setScreenTrack('nameform');
      })
      .catch(error => {
        if (error.code === 'auth/email-already-in-use') {
          console.log('That email address is already in use!');
          alert('That email address is already in use!');
        }

        if (error.code === 'auth/invalid-email') {
          console.log('That email address is invalid!');
          alert('That email address is invalid!');
        }

        if (error.code === 'auth/weak-password') {
          alert('The password provided is weak');
        }
        setProgress(false);
      });
  };

  /** remember the catch while creating the account relation between firebase and graph ql */
  const handleLogin = e => {
    e.preventDefault();
    setProgress(true);
    auth
      .signInWithEmailAndPassword(useremail, userpassword)
      .then(result => {
        setProgress(false);
        setuseremail('');
        setuserpassword('');
        verifyUser({
          variables: {
            userId: result.user.uid,
          },
        }).then(res => {
          console.log(res);

          if (res.data && res.data.verifyUser) {
            console.log(res);
            setCookie('user', res.data.verifyUser, {path: '/'});
            otpSetProgress(false);
            navigate('/home');
          } else {
            otpSetProgress(false);
            props.setScreenTrack('nameform');
          }
        });
      })

      // onClose();

      .catch(err => {
        console.log(err);
        if (err.code === 'auth/wrong-password') {
          alert('Please check your password...');
        }

        if (err.code === 'auth/invalid-email') {
          alert('That email address is invalid!');
        }

        if (err.code === 'auth/user-not-found') {
          alert('Account not found. Create new account');
        }
        setProgress(false);
      });
  };
  const handleGoogleSignIn = () => {
    auth
      .signInWithPopup(provider)
      // verifyUser({
      //   variables: {
      //     userId: result.user.uid,
      //   },
      // })
      .then(result => {
        verifyUser({
          variables: {
            userId: result.user.uid,
          },
        }).then(res => {
          console.log(res);

          if (res.data && res.data.verifyUser) {
            console.log(res);
            setCookie('user', res.data.verifyUser, {path: '/'});
            otpSetProgress(false);
            navigate('/home');
          } else {
            otpSetProgress(false);
            props.setScreenTrack('nameform');
          }
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handlePasswordReset = e => {
    e.preventDefault();
    if (useremail && useremail !== '') {
      setProgress(true);
      auth
        .sendPasswordResetEmail(useremail)
        .then(res => {
          alert('Reset password link is sent to your mail...');
          setProgress(false);
        })
        .catch(err => {
          console.log(err);
          setProgress(false);
          alert('Please check ur email...');
        });
    } else {
      alert('Please enter email');
    }
  };
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };
  return (
    <React.Fragment>
      <div className={classes.right} id="register">
        <Paper elevation={3} className={classes.paper} id="paperForReg">
          <div
            onClick={() => {
              if (isRegister) {
                setRegister(false);
              } else if (resetpassword) {
                setresetpassword(false);
              } else {
              }
            }}
            style={{cursor: 'pointer'}}>
            <ArrowBackIcon style={{marginRight: '25rem'}} />
          </div>
          <img
            decode="async"
            alt="Login"
            id="logo"
            longdesc="https://studentcontinent.com/"
            loading="eager"
            referrerPolicy="no-referrer"
            src={scontilogo}
            className={classes.scontilogo}
          />

          <div style={{display: !show ? 'block' : 'none'}} id="RegForm">
            <div className={classes.TextFieldStyle} id="mainForm">
              <div>
                <Typography
                  variant="h4"
                  textAlign="center"
                  fontWeight="400"
                  color="#00a3ff">
                  {isRegister
                    ? 'Create Account'
                    : resetpassword
                    ? 'Restore Password'
                    : 'Welcome back.'}
                </Typography>
              </div>
              <div style={{height: 15}} />
              <form
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '0.5rem',
                }}>
                {isRegister && (
                  <TextField
                    type="tel"
                    // margin="normal"
                    size="small"
                    required
                    autoFocus
                    fullWidth
                    multiline={true}
                    id="login_register_mobilenumber"
                    label="Number"
                    name="mobile_number"
                    variant="outlined"
                    value={usernumber}
                    onChange={handleMobileNumberChange}
                  />
                )}

                {/* <div> */}
                <TextField
                  label="Email"
                  fullWidth
                  type="email"
                  size="small"
                  id="email"
                  name="email"
                  autoFocus
                  required
                  variant="outlined"
                  multiline={true}
                  value={useremail}
                  onChange={handleEmailChange}
                  error={!isEmailValid}
                  helperText={!isEmailValid ? 'Invalid email address' : ''}
                />
                {resetpassword ? (
                  <></>
                ) : (
                  <TextField
                    label="Password"
                    size="small"
                    fullWidth
                    id="password"
                    required
                    variant="outlined"
                    // autoFocus
                    name="password"
                    maxLength={8}
                    value={userpassword}
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end">
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onChange={e => {
                      setuserpassword(e.target.value);
                    }}
                  />
                )}
              </form>

              {isRegister || resetpassword ? (
                <></>
              ) : (
                <div
                  style={{height: 20, margin: 'auto'}}
                  onClick={() => {
                    setresetpassword(true);
                  }}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                    sx={{m: 1}}>
                    <Link
                      variant="subtitle2"
                      underline="hover"
                      style={{
                        textDecoration: 'none',
                        color: '#FF9D00',
                        fontWeight: 'bold',
                      }}>
                      Forgot password?
                    </Link>
                  </Stack>
                </div>
              )}
              <div
                style={{
                  height: 20,
                  margin: 10,
                  display: 'flex',
                  justifyContent: 'center',
                  alignContent: 'center',
                }}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleToggleForm}
                  style={{cursor: 'pointer', margin: 'auto'}}>
                  {isRegister || resetpassword
                    ? 'Already have an account? Login'
                    : "Don't have an account? Register"}
                </Button>
              </div>

              <div style={{height: 20, margin: 5}} />
            </div>
            <div
              id="policy"
              style={{cursor: 'pointer'}}
              onClick={() => {
                window.open('https://scontiprivicy.web.app/');
              }}>
              <Typography variant="subtitle2" className={classes.TermTextStyle}>
                By clicking Agree & Join, you agree to the Sconti Terms of
                Service
                <div id="cookie-policy" className={classes.TermTextStyleTwo}>
                  User Agreement, Privacy Policy, and Cookie Policy.
                </div>
              </Typography>
            </div>

            <div
              id="recaptcha-container"
              className={classes.SubmitButtonBorder}>
              {progress === false ? (
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  style={{
                    textTransform: 'capitalize',
                    color: '#fff',
                    borderRadius: '5rem',
                  }}
                  onClick={
                    isRegister
                      ? handleSubmit
                      : resetpassword
                      ? handlePasswordReset
                      : handleLogin
                  }>
                  {isRegister
                    ? 'Register'
                    : resetpassword
                    ? 'Reset Password'
                    : 'Login'}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  id="loading"
                  size="small">
                  <CircularProgress
                    color="secondary"
                    size={20}
                    style={{margin: '0 2rem'}}
                  />
                </Button>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Card elevation={5} style={{borderRadius: 16}}>
                <IconButton onClick={handleGoogleSignIn}>
                  <img
                    src={Google}
                    style={{width: 40, height: 40}}
                    alt="google_Icon"
                  />
                </IconButton>
              </Card>
            </div>
          </div>
        </Paper>
      </div>
      <div className={classes.PhoneViewEnable}>
        <PhoneView />
      </div>
    </React.Fragment>
  );
};

export default Form;
