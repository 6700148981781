import gql from 'graphql-tag';

export default gql`
  query verifyUser($userId: String!) {
    verifyUser(userId: $userId) {
      name
      description
      score
      userId
      profileImageUrl
      bannerImageUrl
      dateOfBirth
      state
      userType
      college
      degree
      company
      faceBookUrl
      gitHubUrl
      instagramUrl
      linkedInUrl
      followersCount
      followingCount
      interests
      profileCompleteness
      hasUpdatedInterests
    }
  }
`;
