import React, {useEffect, useState} from 'react';
import {DialogTitle, makeStyles} from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import FlagIcon from '@material-ui/icons/Flag';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import {useCookies} from 'react-cookie';
import {useMutation} from '@apollo/client';
import FollowUser from '../../queries/FollowUser';
import UnFollowUser from '../../queries/UnFollowUser';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ShowPost from '../../queries/ShowPost';
import EditPost from '../addPost/EditPost';
const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: 300,
    height: 150,
    backgroundColor: theme.palette.background.paper,
    border: '0.5rem',
    borderRadius: '0.5rem',
    margin: theme.spacing(40, 70, 10),
    paddingTop: '0.75rem',
  },
  dividerStyle: {
    marginTop: '0.5rem',
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));
const PostOption = props => {
  const classes = useStyles();
  const [followChange, setfollowChange] = useState(true);
  const [cookies, setCookie] = useCookies(['user']);
  const [followMutation] = useMutation(FollowUser);
  const [unfollowMutation] = useMutation(UnFollowUser);
  const [open, setOpen] = React.useState(false);
  const [openForReport, setOpenForReport] = useState(false);
  const [editOpen, setEditOpen] = useState(false);

  const handleOpenForEdit = () => {
    setEditOpen(true);
  };

  const handleCloseForEdit = () => {
    setEditOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenForReport = () => {
    setOpenForReport(true);
  };
  const handleCloseForReport = () => {
    setOpenForReport(false);
  };
  const updateCacheforfollow = (client, {data: {followUser}}) => {
    console.log('entered');
    const data = client.readQuery({
      query: ShowPost,
      variables: {
        limit: 10,
        nextToken: null,
        userModelInputFilteruser: {userId: cookies.user.userId},
      },
    });

    //  const newArray = data.getSubscriptions.edges.filter(
    //   item => item.node.postId !== props.data.postId,
    // );
    var newArray = [];
    data.getSubscriptions.edges.map((item, index) => {
      if (item.node.postedBy.userId === props.data.postedBy.userId) {
        newArray.push({
          node: {
            ...item.node,
            postedBy: {...item.node.postedBy, amIFollowing: true},
          },
        });
      } else {
        newArray.push(item);
      }
    });
    const newData = {
      getSubscriptions: {...data.getSubscriptions, edges: newArray},
    };
    // const newData = {getSubscriptions: newArray};
    client.writeQuery({
      query: ShowPost,
      variables: {
        limit: 10,
        nextToken: null,
        userModelInputFilteruser: {userId: cookies.user.userId},
      },
      data: newData,
    });
  };
  const followUser = () => {
    followMutation({
      variables: {
        userId: props.data.postedBy.userId,
        followedUserId: cookies.user.userId,
      },
      update: updateCacheforfollow,
    })
      .then(res => {
        console.log(res);
      })
      .catch(err => {
        console.log(err);
      });
  };
  const unfollowUser = () => {
    unfollowMutation({
      variables: {
        userId: props.data.postedBy.userId,
        followedUserId: cookies.user.userId,
      },
    })
      .then(res => {
        console.log(res);
      })
      .catch(err => {
        console.log(err);
      });
  };
  useEffect(() => {
    setfollowChange(props.data.postedBy.amIFollowing);
  }, [props.data]);
  return (
    <div className={classes.paper}>
      {props.ismyPost ? (
        <center>
          <div>
            {' '}
            <Button onClick={handleOpenForEdit} startIcon={<EditIcon />}>
              Edit Post
            </Button>
            <Modal
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              open={editOpen}
              onClose={handleCloseForEdit}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description">
              <EditPost
                data={props.data}
                close={handleCloseForEdit}
                closed={props.closed}
              />
            </Modal>
            <Divider className={classes.dividerStyle} />
            {/**   <Button
              onClick={() => {
                props.handleDelete();
              }}
              startIcon={<DeleteIcon />}>
              Delete
            </Button> */}
            <Button onClick={handleClickOpen} startIcon={<DeleteIcon />}>
              Delete
            </Button>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
              <DialogContent>
                <DialogTitle> Delete the Post</DialogTitle>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to delete the post?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel </Button>
                <Button
                  onClick={() => {
                    props.handleDelete();
                    // alert("deleted");
                    if (props.closed) {
                      props.closed();
                    }
                  }}>
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
            <Divider className={classes.dividerStyle} />
          </div>
          <Button
            startIcon={<CloseIcon />}
            onClick={() => {
              if (props.closed) {
                props.closed();
              }
              if (props.pollClose) {
                props.pollClose();
              }
            }}>
            cancel
          </Button>
        </center>
      ) : (
        <div>
          <center>
            <div
              onClick={() => {
                if (!followChange === true) {
                  followUser();
                  // alert('you followed ');
                } else {
                  unfollowUser();
                  // alert('you unFollowed ');
                }
                setfollowChange(!followChange);
              }}>
              {!followChange ? (
                <Button startIcon={<PersonAddIcon />}> Follow</Button>
              ) : (
                <Button startIcon={<HowToRegIcon />} color="primary">
                  Following
                </Button>
              )}
            </div>
            {/**  <Divider className={classes.dividerStyle} />
            <Button startIcon={<EditIcon />}> Edit Post</Button> */}
            <Divider className={classes.dividerStyle} />
            <Button startIcon={<FlagIcon />} onClick={handleClickOpenForReport}>
              Report
            </Button>
            <Dialog
              onClose={handleCloseForReport}
              aria-labelledby="customized-dialog-title"
              open={openForReport}>
              <MuiDialogTitle
                style={{marginBottom: '-1rem'}}
                id="customized-dialog-title">
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    justifyContent: 'space-around',
                    gap: '3rem',
                  }}>
                  <Typography variant="h6">
                    Select a reporting reason
                  </Typography>
                  <IconButton
                    aria-label="close"
                    style={{bottom: '0.5rem'}}
                    onClick={handleCloseForReport}>
                    <CloseIcon />
                  </IconButton>
                </div>
              </MuiDialogTitle>
              <DialogContent
                dividers
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                }}>
                <Button
                  style={{textTransform: 'capitalize'}}
                  onClick={() => {
                    alert('Thank you for your feed back');
                  }}>
                  Abusive Content
                </Button>
                <Button
                  style={{textTransform: 'capitalize'}}
                  onClick={() => {
                    alert('Thank you for your feed back');
                  }}>
                  Content not meeting the set of standards
                </Button>
                <Button
                  style={{textTransform: 'capitalize'}}
                  onClick={() => {
                    alert('Thank you for your feed back');
                  }}>
                  Content Creating unstability
                </Button>
                <Button
                  style={{textTransform: 'capitalize'}}
                  onClick={() => {
                    alert('Thank you for your feed back');
                  }}>
                  Irrelevant post identity
                </Button>
                <Button
                  style={{textTransform: 'capitalize'}}
                  onClick={() => {
                    alert('Thank you for your feed back');
                  }}>
                  Post Copied from other source
                </Button>
              </DialogContent>
            </Dialog>
            <Divider className={classes.dividerStyle} />
            <Button
              startIcon={<CloseIcon />}
              onClick={() => {
                if (props.closed) {
                  props.closed();
                }
                if (props.pollClose) {
                  props.pollClose();
                }
              }}>
              cancel
            </Button>
          </center>
        </div>
      )}
    </div>
  );
};
export default PostOption;
