import React, { useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Typography, Paper, Divider} from '@material-ui/core';
import NewCard from './NewsCard';
import ListAllNews from '../../queries/ListAllNews';
import { useQuery} from '@apollo/client';
import { auth } from '../Auth/firebase';

const useStyles = makeStyles(theme => ({
  UserName: {
    fontWeight: 'bold',
    fontSize: 12,
  },
  CollageName: {
    fontSize: 10,
  },
  Paper: {
    width: '18rem',
    borderRadius: '1rem',
    margin: '1rem 2rem',
    paddingBottom: '0.5rem',
  },
  heading:{
    fontWeight: 'bold',
            paddingTop: '0.5rem',
            marginLeft: '0.8rem',
            textAlign:"center"
  }
}));

const LatestNews = () => {
  const classes = useStyles();
  const [newsinfo, setNewsinfo] = useState([]);

  useQuery(ListAllNews, {
    variables: {
      limit: 10,
      nextToken: null,
      userModelInputFilter: {
        userId: auth.currentUser ? auth.currentUser.uid : '',
      },
    },
    onCompleted: res => {
      console.log('news', res);
      setNewsinfo(res.listAllNews ? res.listAllNews.edges : []);
    },
    onError: err => {
      console.log(err);
    },
  });
  return (
    <React.Fragment>
      <Paper elevation={1} className={classes.Paper}>
        <Typography
          variant="subtitle1"
          className={classes.heading}>
          Sconti News
        </Typography>
        <Divider/>
        <div>
          <NewCard News={newsinfo} />
        </div>
      </Paper>
    </React.Fragment>
  );
};

export default LatestNews;
