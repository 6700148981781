import React, {useState, useEffect, useRef} from 'react';
import {Typography, CircularProgress, Paper} from '@material-ui/core';
import FolloweUserCard from './FolloweUserCard';
import FollowingList from '../../queries/FollowingList';
import {useLazyQuery} from '@apollo/client';
import {useCookies} from 'react-cookie';
import { ThreeCircles } from 'react-loader-spinner';
const Following = props => {
  const [following, setFollowing] = useState([]);
  const [loading, setLoading] = useState(true);
  const [cookies, setCookie] = useCookies(['user']);
  const trackRef = useRef([]);

  const [getSubscriptionsDataFollowing, setGetSubscriptionsDataFollowing] =
    useState([]);
  const [getFollowing, {fetchMore: fetchMoreFollowing}] = useLazyQuery(
    FollowingList,
    {
      variables: {
        limit: 10,
        nextToken: null,
        userId: props.user,
        visitorUserId: cookies.user.userId,
      },
      fetchPolicy: 'network-only',
      onCompleted: res => {
        console.log('iAmFollowing', res);
        setFollowing(res.iAmFollowing ? res.iAmFollowing.edges : []);
        setGetSubscriptionsDataFollowing(res.iAmFollowing);
        setLoading(false);
      },
      onError: err => {
        console.log(err);
      },
    },
  );
  const handleLoadMoreFollowing = () => {
    fetchMoreFollowing({
      variables:{
        limit: 10,
        nextToken: getSubscriptionsDataFollowing.pageInfo.endCursor,
        userId: props.user,
        visitorUserId: cookies.user.userId,
      },

      updateQuery: (prev, {fetchMoreResult}) => {
        if (!fetchMoreResult) return prev;

        setGetSubscriptionsDataFollowing(fetchMoreResult.iAmFollowing);
        return {
          ...prev,
          iAmFollowing: {
            ...prev.iAmFollowing,
            edges: [
              ...prev.iAmFollowing.edges,
              ...fetchMoreResult.iAmFollowing.edges,
            ],
            pageInfo: fetchMoreResult.iAmFollowing.pageInfo,
          },
        };
      },
    });
  };

  useEffect(() => {
    // console.log('lol')
    const handleScroll = () => {
      //   You can define your own conditions to trigger loading more data based on scroll
      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - 500
      ) {
        console.log('pagination if check');
        console.log(trackRef.current);
        if (
          getSubscriptionsDataFollowing &&
          getSubscriptionsDataFollowing.pageInfo
        ) {
          if (
            !trackRef.current.includes(
              getSubscriptionsDataFollowing
                ? getSubscriptionsDataFollowing.pageInfo.endCursor
                : '',
            )
          ) {
            handleLoadMoreFollowing();
            trackRef.current = [
              ...trackRef.current,
              getSubscriptionsDataFollowing
                ? getSubscriptionsDataFollowing.pageInfo.endCursor
                : '',
            ];
          }
          console.log(trackRef.current);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [following]);
  useEffect(() => {
    getFollowing();
  }, []); // Ensure that this effect is executed whenever new data is fetched
  return (
    <Paper elevation={2} >
      {loading ? (
        <ThreeCircles
          style={{margin: '5rem'}}
          height="100"
          width="100"
          color="#4fa94d"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="three-circles-rotating"
          outerCircleColor="#00a3ff"
          innerCircleColor="#ff9d00"
          middleCircleColor="#4088FF"
        />
      ) : following.length > 0 ? (
        following.map((item, key) => (
          <div key={key}>
            <FolloweUserCard followers={item.node} />
          </div>
        ))
      ) : (
        <Typography
          variant="body2"
          style={{margin:"5rem"}}>
          {props.user === cookies.user.userId
            
            ? 'You are not following'
            : 'No following to show'}
        </Typography>
      )}
    </Paper>
  );
};

export default Following;
