import gql from 'graphql-tag';

export default gql`
  query homeSearchPost(
    $limit: Int
    $nextToken: String
    $inputFilter: SearchInputFilter!
  ) {
    homeSearchPost(
      limit: $limit
      nextToken: $nextToken
      inputFilter: $inputFilter
    ) @connection(key: "PostDisplayModel") {
      edges {
        node {
          postId
          description
          media {
            name
            url
            type
            thumbnailUrl
            audioPlayCount
          }
          poll {
            description
            options
            optionSelectionPercentage
            correctAnsIndex
            myAnsIndex
            amIAnswered
            totalAnswerCount
          }
          postedBy {
            name
            userId
            profileImageUrl
            collegeName
            amIFollowing
            userType
            companyName
          }
          interests {
            name
          }
          sharedByUserId
          sharedByUserName
          sharedByUserProfileImageUrl
          likedByMe
          commentCount
          likeCount
          shareCount
          createdOn
          updatedOn
          viewCount
          markedByCount
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
