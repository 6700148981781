import React, { useEffect } from 'react';
import {useState} from 'react';
import {Chip} from '@material-ui/core';

const ChipChange = (props) =>{
  const [selected, setselected] = useState(false);
    const handleSelect = () => {
      setselected(true);
      props.setChipData([...props.chipData, props.item.name]);
      console.log(props.chipData);
   }
   const handleDelete = () => {
      props.setChipData(chips => props.chipData.filter(chips => chips !== props.item.name));
      setselected(false)
      console.log(props.chipData);
   }
   useEffect(()=>{
    for(var i=0; i<props.chipData.length;i++){
      if(props.chipData[i] ===props.item.name){
        setselected(true);
        break;
      }
    }
   },[]);
  return (
    <div id='chip'>
      <Chip

        label={props.item.name}
        onClick={() => {
          if(selected){
            handleDelete();
          }else{
          handleSelect();
          }
        }}
        color={selected ? 'primary' : 'default'}
      />
    </div>
    
  );
};

export default ChipChange;
