
import gql from 'graphql-tag';

export default gql`
query getAllColleges($collegeName: String!) {
  getAllColleges(collegeName: $collegeName) {
    collegeName
  }
}
`;
