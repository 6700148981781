import gql from 'graphql-tag';

export default gql`
  query listAllNews(
    $limit: Int
    $nextToken: String
    $userModelInputFilter: UserModelInputFilter
  ) {
    listAllNews(
      limit: $limit
      nextToken: $nextToken
      userModelInputFilter: $userModelInputFilter
    ) @connection(key: "NewsDisplayModel") {
      edges {
        node { 
          id
          heading
          summary
          category
          location
          hostUrl
          mediaUrl
          createdBy
          createdAt
          updatedAt
         }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      
    }
  }
`;
