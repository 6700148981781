import React, {useState, useEffect} from 'react';
import {Typography, Avatar} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import IconButton from '@material-ui/core/IconButton';
import FollowUser from '../../queries/FollowUser';
import UnFollowUser from '../../queries/UnFollowUser';
import {useCookies} from 'react-cookie';
import {useNavigate, useLocation} from 'react-router-dom';
import {useMutation} from '@apollo/client';
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.5rem',
    textAlign: 'center',
    width: '15rem',
  },
  UserName: {
    fontWeight: 'bold',
    bottom: '0.5rem',
    fontSize: 12,
    width: '10rem',
  },
  CollageName: {
    fontSize: 10,
  },
  UserProfileStyle: {width: '2rem', height: '2rem', marginBottom: '1rem'},
  marginLeft05: {
    marginLeft: '1rem',
    cursor: 'pointer',
  },
}));

const SearchUserCard = props => {
  console.log('props : ', props);
  const navigate = useNavigate();
  const pathname = useLocation();
  const [iconChange, seticonChange] = useState(false);
  const classes = useStyles();
  const [cookies, setCookie] = useCookies(['user']);
  const [followMutation] = useMutation(FollowUser);
  const [unfollowMutation] = useMutation(UnFollowUser);
  const followUser = () => {
    followMutation({
      variables: {
        userId: props.data.userId,
        followedUserId: cookies.user.userId,
      },
    })
      .then(res => {
        console.log(res);
      })
      .catch(err => {
        console.log(err);
      });
  };
  const unfollowUser = () => {
    unfollowMutation({
      variables: {
        userId: props.data.userId,
        followedUserId: cookies.user.userId,
      },
    })
      .then(res => {
        console.log(res);
      })
      .catch(err => {
        console.log(err);
      });
  };
  useEffect(() => {
    seticonChange(props.data.amIFollowing);
  
  }, [pathname]);

  return (
    <div className={classes.root}>
      <div
        style={{cursor: 'pointer'}}
        onClick={() => {
          navigate('/profile', {state: props.data.userId});
        }}>
        <Avatar
          alt="Remy Sharp"
          src={props.data.profileImageUrl}
          className={classes.UserProfileStyle}
        />
      </div>
      <div
        className={classes.marginLeft05}
        onClick={() => {
          navigate('/profile', {state: props.data.userId});
        }}>
        <Typography variant="subtitle1" className={classes.UserName}>
          {props.data.name}
        </Typography>

        <Typography variant="subtitle2" className={classes.CollageName}>
          {props.data.college}
        </Typography>
      </div>

      <div>
        {props.data.userId ===cookies.user.userId?<div  style={{
          bottom: '0.6rem',
          left: '0.5rem',
          width: '2.5rem',
        }}/>:
        <IconButton
          aria-label="person-add"
          onClick={() => {
            if (!iconChange === true) {
              followUser();
              alert('you followed ');
            } else {
              unfollowUser();
              alert('you unFollowed ');
            }
            seticonChange(!iconChange);
          }}
          style={{
            bottom: '0.6rem',
            left: '0.5rem',
            width: '2.5rem',
          }}>
          {iconChange ? (
            <HowToRegIcon
              style={{
                color: '#00B5E2',
              }}
            />
          ) : (
            <PersonAddIcon />
          )}
        </IconButton>
}
      </div>
    </div>
  );
};

export default SearchUserCard;
