
import gql from 'graphql-tag';

export default gql`
  mutation createPost(
    $description: String!,
    $postedById: String!,
    $postedByName: String!,
    $mediaData: [CreateMediaInputModel],
    $interests: [String]
    $poll: CreatePollInputModel
    $mentioned: [String]
  ) {
    createPost(
      createPostInputModel: {
        description: $description
        postedById: $postedById
        postedByName: $postedByName
        mediaData: $mediaData
        interests: $interests
        poll: $poll
        mentioned: $mentioned
      }
    )
    {
      postId
      description
      media {
        name
        url
        type
        thumbnailUrl
        audioPlayCount
      }
      poll{
        description
        options
        optionSelectionPercentage
        correctAnsIndex
        amIAnswered
        totalAnswerCount
      }
      postedBy{
        name
        userId
        profileImageUrl
        collegeName
        amIFollowing
        userType
        companyName
      }      
      interests {
        name
      }
      commentCount
      likeCount
      shareCount
      createdOn
      updatedOn
      viewCount
      markedByCount
    }
  }
`;
