import gql from 'graphql-tag';

export default gql`
  query pollsOfUser(
    $limit: Int
    $nextToken: String
    $userModelInputFilter: UserModelInputFilter
    $visitingUserId: String
  ) {
    pollsOfUser(
      limit: $limit
      nextToken: $nextToken
      userModelInputFilter: $userModelInputFilter
      visitingUserId: $visitingUserId
    ) @connection(key: "PostDisplayModel") {
      edges {
        node { 
          postId
      description
      media {
        name
        url
        type
        thumbnailUrl
      }
      poll {
        description
        options
        optionSelectionPercentage
        correctAnsIndex
        myAnsIndex
        amIAnswered
        totalAnswerCount
      }
      postedBy {
        name
        userId
        profileImageUrl
        collegeName
        amIFollowing
        userType
        companyName
      }
      interests {
        name
      }
      sharedByUserId
      sharedByUserName
      sharedByUserProfileImageUrl
      likedByMe
      commentCount
      likeCount
      shareCount
      createdOn
      updatedOn
      viewCount
      markedByCount
         }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      
    }
  }
`;
