import React, {useState} from 'react';
import scontimedia_bannerImage from '../../assets/Icon_png/scontimedia_bannerImage.jpeg';
import {makeStyles} from '@material-ui/core/styles';
import {
  Paper,
  Typography,
  Divider,
  Button,
  Avatar,
  CircularProgress,
  Badge,
} from '@material-ui/core';
import UserDetails from '../../queries/UserDetails';
import EditIcon from '@material-ui/icons/Edit';
import {useQuery} from '@apollo/client';
import {useCookies} from 'react-cookie';
import {Link,useNavigate} from 'react-router-dom';
import GraduationSymbol1 from '../../assets/Icon_svg/GraduationSymbol1.svg'

const useStyles = makeStyles(theme => ({
  scontilogo: {
    height: '4rem',
    width: '4rem',
    borderRadius: '2rem',
    position: 'sticky',
    margin: '0.5rem 0rem 0rem 5.5rem',
  },
  Paper: {
    width: '15rem',
    borderRadius: '1rem',
    margin: '1rem 0rem',
  },
  banner: {
    height: '5rem',
    width: '15rem',
    borderTopRightRadius: '1rem',
    borderTopLeftRadius: '1rem',
  },
  UserName: {
    // margin: '2rem 0rem 0rem 0rem',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  CollageName: {
    fontSize: 12,
    textAlign: 'center',
  },
  Divider: {
    margin: '1rem 0rem 0rem 0rem',
  },
  PostsNumber: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
  Posts: {
    textAlign: 'center',
  },
  ProfilRoot: {
    flexDirection: 'row',
    display: 'flex',
    margin: '0.5rem 0rem 1rem 0rem',
  },
  flex1: {
    flex: 1,
    cursor:"pointer"
  },
  button: {
    textTransform: 'capitalize',
    alignSelf: 'center',
    borderRadius: 15,
    margin: '-1rem 0rem 0.5rem 0rem',
  },
  ButtonBordered: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const ProfileCard = () => {
  const [userDetailsData, setuserDetailsData] = useState({});
  const [interests, setinterests] = useState('');
  const [cookies, setCookie] = useCookies(['user']);
  const [loading, setLoading] = useState(true);
  const navigate=useNavigate();

  useQuery(
    UserDetails,
    {
      variables: {
        id: cookies.user?cookies.user.userId:"",
        visitType: 'self',
        visitingUserId: cookies.user?cookies.user.userId:"",
      },
      onCompleted: res => {
        var str = '';
        console.log('UserDetails', res);

        setuserDetailsData(res.getUserDetails ? res.getUserDetails : {});
        if (res.getUserDetails && res.getUserDetails.interests) {
          res.getUserDetails.interests.map(
            (item, index) => (str = str + item + ' , '),
          );
          setinterests(str.substring(0, str.length - 2));
        }
        setLoading(false);
      },
      onError: err => {
        console.log(err);
      },
    },
    [],
  );
  const classes = useStyles();
  return (
    <React.Fragment>
      <div>
        <Paper elevation={1} className={classes.Paper}>
          {loading ? (
            <CircularProgress 
              style={{marginLeft: '6rem', marginTop: '5rem'}}
            />
          ) : (
            <div>
              <img
                decode="async"
                alt="Login"
                longdesc="https://studentcontinent.com/"
                loading="eager"
                referrerPolicy="no-referrer"
                src={
                  userDetailsData.bannerImageUrl
                    ? userDetailsData.bannerImageUrl
                    : scontimedia_bannerImage
                }
                className={classes.banner}
              />
              <Link to={`/profile?${userDetailsData.userId}`}>
              {userDetailsData.userType === 'Professional' ? (
                <Badge
                overlap="circular"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  badgeContent={
                    <img 
                    src={GraduationSymbol1}
                    alt='cap'
                    style={{
                      width:25,height:25,
                      marginLeft:"1.5rem"
                    }}
                     
                    />
                  }>
                <Avatar
                  variant="rounded"
                  alt="userImage"
                  src={userDetailsData.profileImageUrl}
                  className={classes.scontilogo}
                />
                </Badge>
                ) : (<Avatar
                  variant="rounded"
                  alt="userImage"
                  src={userDetailsData.profileImageUrl}
                  className={classes.scontilogo}
                />
                )}
              </Link>
              <div>
                <Link
                  style={{textDecoration: 'none', color: 'black'}}
                  to={`/profile?${userDetailsData.userId}`}>
                  <Typography variant="subtitle1" className={classes.UserName}>
                    {userDetailsData.name}
                  </Typography>
                </Link>
                <Typography variant="subtitle2" className={classes.CollageName}>
                  {userDetailsData.description}
                </Typography>
              </div>
              <Divider className={classes.Divider} />
              <div className={classes.ProfilRoot}>
                <div className={classes.flex1} onClick={()=>{
                  navigate('/followlist', {
                    state: { userId: userDetailsData.userId, isfollower: true },
                  });
                }}>
                  <Typography
                    variant="subtitle1"
                    className={classes.PostsNumber}>
                    {userDetailsData.followersCount}
                  </Typography>
                  <Typography variant="body2" className={classes.Posts}>
                    Followers
                  </Typography>
                </div>
                <div className={classes.flex1}
                onClick={()=>{
                  navigate('/followlist', {
                    state: { userId: userDetailsData.userId, isfollower: false },
                  });
                }}>

                {/** */}
                
                  <Typography
                    variant="subtitle1"
                    className={classes.PostsNumber}>
                    {userDetailsData.followingCount}
                  </Typography>
                  <Typography variant="body2" className={classes.Posts}>
                    Following
                  </Typography>
                </div>
              </div>
              <div className={classes.ButtonBordered}>
                <Link style={{textDecoration: 'none'}} to="/setting">
                  <Button
                    color="primary"
                    className={classes.button}
                    startIcon={<EditIcon />}>
                    Edit Profile
                  </Button>
                </Link>
              </div>
            </div>
          )}
        </Paper>
      </div>
    </React.Fragment>
  );
};

export default ProfileCard;
