
import gql from 'graphql-tag';

export default gql`
  mutation editPost(
    $postId: ID!,
    $description: String,
    $mediaData: [CreateMediaInputModel],
    $updateMedia: Boolean
  ) {
    editPost(
      editPostInputModel: {
        postId: $postId
        description: $description
        mediaData: $mediaData
        updateMedia: $updateMedia
      }
    )
    {
      postId
      description
      media {
        name
        url
        type
        thumbnailUrl
      }
      poll{
        description
        options
        optionSelectionPercentage
        correctAnsIndex
        amIAnswered
        totalAnswerCount
      }
      postedBy{
        name
        userId
        profileImageUrl
        collegeName
        amIFollowing
        userType
        companyName
      }      
      interests {
        name
      }
      commentCount
      likeCount
      shareCount
      markedByCount
      createdOn
      viewCount
      updatedOn
    }
  }
`;
