import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
  Typography,
  Button,
  TextField,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LanguageIcon from '@material-ui/icons/Language';
import PinDropOutlinedIcon from '@material-ui/icons/PinDropOutlined';

const useStyles = makeStyles(theme => ({
  UserProfileStyle: {
    width: '2.5rem',
    height: '2.5rem',
    marginTop: '0.4rem',
  },
  resize: {
    padding: '0.3rem 0.5rem',
    border: `0.1rem solid ${theme.palette.color.grey2}`,
    borderRadius: '0.3rem',
    width: '20rem',
  },
  AppInfo: {
    border: `0.1rem solid ${theme.palette.color.AppInfoColore}`,
    padding: '1rem',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },

  SubmitButton: {
    textTransform: 'capitalize',
    color: theme.palette.color.white,
    padding: '0 1.5rem',
    marginRight: '1rem',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  displayFlex: {display: 'flex'},
  width75: {width: '7.5rem'},
  ChangeProfileButtonStyle: {
    fontWeight: 'bold',
    fontSize: 12,
    color: theme.palette.primary.main,
    textTransform: 'capitalize',
    padding: '0',
  },
  DataEnterBorder: {display: 'flex', marginTop: '1.5rem'},
  width10: {width: '10rem'},
  CheckBoxIconstyle: {padding: '0 0.5rem'},
  HelperTextStyle: {
    fontSize: '0.70rem',
    color: theme.palette.color.HelperTextColore,
    margin: '1rem 1.7rem 0 0',
  },
  DropDownBorderStyle: {marginRight: '1.7rem'},
  SubmitButtonBorder: {textAlign: 'center', marginTop: ' 2rem'},
  fontsize12: {fontSize: 12},
  ChangeprofilephotoButton: {
    [theme.breakpoints.down('md')]: {
      marginLeft: '2.5rem',
    },
  },
}));

const EditProfile = () => {
  const classes = useStyles();
  const [msg, setMsg] = useState('');
  const [email, setEmail] = useState('');
  return (
    <React.Fragment>
      <div className={classes.displayFlex}>
        <div className={classes.marginRight}>
          <Typography variant="subtitle1" className={classes.TcStyle}>
            Send us some feedback! Found a bug? Have a suggestion? Fill out the
            form below and will take a look into it
          </Typography>

          <div className={classes.marginBottom}>
            <TextField
              required
              placeholder="Your Email "
              value={email}
              onChange={e => {
                setEmail(e.target.value);
              }}
              InputProps={{
                disableUnderline: true,
                classes: {
                  input: classes.resize,
                },
              }}
            />
          </div>
          <div>
            <TextField
              style={{marginTop: '1rem'}}
              id="outlined-multiline-static"
              required
              multiline
              onChange={e => {
                setMsg(e.target.value);
              }}
              value={msg}
              minRows={5}
              label="Give Your Feedback"
              fullWidth
              variant="outlined"
            />

            <div>
              <span>
                {' '}
                <Button
                  variant="contained"
                  size="small"
                  style={{marginTop: '1rem'}}
                  onClick={() => {
                   if (msg.length>=300 ||email.length>=50) {
                    alert("limit exceed")
                   } else {
                    if (email.length > 0 && msg.length > 0) {
                      alert('Thank you for your feedback');
                      setEmail('');
                      setMsg('');
                    } else {
                      alert('please enter valid details');
                      setEmail('');
                      setMsg('');
                    }
                   }
                  }}>
                  submit
                </Button>{' '}
              </span>
            </div>

            <div>
              <Typography variant="body1" style={{marginTop:"1rem"}}>
                Contact us
              </Typography>

              <span>
                <Tooltip title="mail">
                  <IconButton
                    sx={{
                      '&:hover': {
                        backgroundColor: '#dff4ff',
                        cursor: 'default',
                      },
                    }}
                    onClick={() => {
                      window.location =
                        'mailto:studentcontinentofficial@gmail.com';
                    }}
                    size="medium"
                    color="inherit">
                    <MailOutlineIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title=" website">
                  <IconButton
                    sx={{
                      '&:hover': {
                        backgroundColor: '#dff4ff',
                        cursor: 'default',
                      },
                    }}
                    size="medium"
                    color="inherit"
                    onClick={() => window.open('http://studentcontinent.in/')}>
                    <LanguageIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="location">
                  <IconButton
                    sx={{
                      '&:hover': {
                        backgroundColor: '#dff4ff',
                        cursor: 'default',
                      },
                    }}
                    size="medium"
                    color="inherit"
                    onClick={() =>
                      window.open(
                        'https://www.google.com/maps/dir/12.974148,77.5046096/meru+tp+solutions+pvt+ltd/@12.9774895,77.5024636,16z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3bae3d92782e769d:0xda0e94bf73c126e0!2m2!1d77.5108406!2d12.9828819?entry=ttu',
                      )
                    }>
                    <PinDropOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </span>
            </div>
          </div>
        </div>
        <div className={classes.AppInfo}>
          <Typography variant="subtitle1" className={classes.fontsize12}>
            You are connected to the SCONTIVERSE.
          </Typography>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditProfile;
