import {Divider, Paper, Typography} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {makeStyles} from '@material-ui/core/styles';
import {Event} from '@material-ui/icons';
import ViewResults from './ViewResults';
import {Link} from 'react-router-dom';
import {db} from '../Auth/firebase';
// import { collection, getDocs } from "firebase/firestore";

// import {collection,getDocs} from "firebase-firestore"
import SubQuizSection from './SubQuizSection';
const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '4rem',
    marginLeft: '1rem',
    marginRight: '1rem',
    gap: '1rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  left: {width: '30vw'},
  right: {width: '70vw'},
  image: {borderRadius: '50%'},
  main: {
    margin: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '0.5rem',
  },
  submain: {
    margin: '0.5rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '0.5rem',
    cursor: 'pointer',
  },
  rightMain: {
    margin: '1rem',
  },
  event: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 100,
    height: 100,
    position: 'fixed',
    bottom: '2rem',
    right: '2rem',
    backgroundColor: '#ff9d00',
    borderRadius: '50%',
    cursor: 'pointer',
    boxShadow: '0.2rem 0.2rem 0.2rem 0.2rem #888888',
  },
}));

const QuizMainComponent = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [lastDocument, setLastDocument] = useState();
  const [subData, setSubData] = useState([]);
  const [subLastDocument, setSubLastDocument] = useState();

  const LoadData = () => {
    console.log('LOAD');
    let query = db.collection('QuizMainCategory').orderBy('ranking', 'asc'); // sort the data
    if (lastDocument !== undefined) {
      query = query.startAfter(lastDocument); // fetch data following the last document accessed
    }
    query

      .limit(20) // limit to your page size, 3 is just an example
      .get()
      .then(querySnapshot => {
        setLastDocument(querySnapshot.docs[querySnapshot.docs.length - 1]);
        var userlist = [];
        console.log(querySnapshot);
        // setdataloaded(true);
        querySnapshot.forEach(snaps => {
          userlist.push(snaps.data());
          const idItem = snaps.data().id;
        });
        setData([...data, ...userlist]);
        // MakeUserData(querySnapshot.docs);
      });

  };
  useEffect(() => {
    LoadData();
  }, []);

  const SubCategory = (id) => {
    console.log('SubData');
    let query = db
      // .collection('QuizMainCategory')
      // .doc('id')
      .collection('QuizSubCategory')
      .doc(id)
      .collection('subcategories')
      .orderBy('ranking', 'asc'); // sort the data

    if (subLastDocument !== undefined) {
      query = query.startAfter(subLastDocument); // fetch data following the last document accessed
    }
    query

      .limit(12) // limit to your page size, 3 is just an example
      .get()
      .then(querySnapshot => {
        setSubLastDocument(querySnapshot.docs[querySnapshot.docs.length - 1]);
        var userlist = [];
        console.log(querySnapshot);
        // setdataloaded(true);
        querySnapshot.forEach(snaps => {
          userlist.push(snaps.data());
        });
        setSubData([...userlist]);
        // MakeUserData(querySnapshot.docs);
      });
  };
  useEffect(() => {
    SubCategory();
    console.log(SubCategory);
  }, []);

  return (
    <div className={classes.root}>
      <Paper elevation={2} className={classes.left}>
        <Typography style={{margin: '1rem'}} variant="h6">
          Main Category
        </Typography>
        {data.map((item, index) => {
          return (
            <div className={classes.main}>
              <Accordion style={{width: '25rem'}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  onClick={()=>{
                    SubCategory(item.id)
                  }}
                  aria-controls="panel1a-content"
                  id="panel1a-header">
                  <img
                    src={item.imgurl}
                    alt="quiz -logo"
                    width={50}
                    height={50}
                    className={classes.image}
                  />
                  <Typography variant="h6">{item.name}</Typography>
                </AccordionSummary>
                <Divider />
                <AccordionDetails
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                  }}>
                  {item.ishavingsubcategories ? (
                    subData.map((items, index) => {
                      return (
                        <div className={classes.submain}>
                          <img
                            src={items.imgurl}
                            alt="quiz-logo"
                            width={30}
                            height={30}
                            className={classes.image}
                          />
                          <Typography variant="body1">{items.title}</Typography>
                          <Divider />
                        </div>
                      );
                    })
                  ) : (
                    <Typography variant="body1">
                      No SubCategory to show
                    </Typography>
                  )}
                </AccordionDetails>
              </Accordion>
            </div>
          );
        })}
        <Divider />
      </Paper>
      <Paper elevation={2} className={classes.right}>
        <div className={classes.rightMain}>
          <SubQuizSection />
        </div>
        <Link style={{textDecoration: 'none'}} to="/quizreg">
          <div className={classes.event}>
            <Event style={{color: 'white'}} />
            <Typography variant="body2" style={{color: 'white'}}>
              Register Quiz
            </Typography>
          </div>
        </Link>
      </Paper>
    </div>
  );
};

export default QuizMainComponent;
