import { Typography } from '@material-ui/core'
import React from 'react'
import error from "../../src/assets/Icon_png/error-404.png"

const PageNotFound = () => {
  return (
    <div style={{display:"flex",flexDirection:"column", justifyContent:"center",alignItems:"center",gap:"2rem",margin:"2rem"}}>   
    <img src={error} alt='page not found'width={100} height={100}/>
     <Typography variant='h4' style={{textAlign:"center"}}>Page not Found</Typography>
     <Typography variant='h6'color='disabled'  style={{textAlign:"center", color:"#767676"}}>The Requested URL was not found in this server.That's all we know</Typography>

    
    </div>
  )
}

export default PageNotFound