import Navbar from '../navbar/Navbar';
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {useState, useRef} from 'react';
import SearchUserSection from './SearchUserSection';
import SearchPostSection from './SearchPostSection';
import SearchNewsSection from './SearchNewsSection';
import {Container, Paper, TextField} from '@material-ui/core';
import PhoneView from '../phoneView/PhoneView';
function TabPanel(props) {
  const {children, value, index, ...other} = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tabpanel-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  mainDev: {
    // width: '100vw',
    height:"100%",
    padding: '6rem',
  },
  PaperStyle: {
    flexGrow: 1,
    display: 'flex',
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    borderColor: theme.palette.color.grey2,
    borderRadius: '0.5rem',
  },
  AppLogo: {
    width: '2rem',
    height: '2rem',
  },
  activeTab: {fontWeight: 'bold'},
  TabIndicatorPropsStyle: {
    backgroundColor: 'black',
    left: 0,
  },
  textTransform: {textTransform: 'capitalize'},
  AppInfoBoder: {textAlign: 'center', margin: '1rem 0'},
  AppTextBorder: {margin: '0.5rem 0rem 0'},
  AppNameStyle: {fontWeight: 'bold'},
  AppDesStyle: {
    color: theme.palette.color.grey1,
    fontSize: '0.5rem',
    left: 0,
  },
  PhoneViewDisable: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  TabBorderstyle: {
    borderRight: `0.1rem solid ${theme.palette.color.grey2}`,
  },
  TabPanelStyle: {
    display:"flex",
    flexDirection:"row",

  },

  searchBox:{top: '5rem',
  color:"white",
  margin:"0rem 30rem",
  width: '30rem',
  },
  PhoneViewEnable: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  input:{color:"black",cursor:"pointer" },

  AppInfo: {
    border: `0.1rem solid ${theme.palette.color.AppInfoColore}`,
    padding: '1rem',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
 
}));

function SearchDisplay() {
  const classes = useStyles();

  const [value, setValue] = useState(0);
  const [inputText, setInputText] = useState('');
  const searchRef = useRef();
  const postsRef = useRef();
  const newsRef = useRef();

  const handleUserSearch = text => {
    setInputText(text);
    if (value === 0) {
      searchRef.current.search(text);
    }
    if (value === 1) {
      postsRef.current.search(text);
    }
    if (value === 2) {
      newsRef.current.search(text);
    }
  };

  const handleChange = (event, newValue) => {
    console.log(newValue);
    setValue(newValue);
  };

  const handleChangeIndex = index => {
    setValue(index);
    searchRef.current.search(inputText);
  };
  return (
    <React.Fragment>
      <div className={classes.PhoneViewDisable}>
        <Navbar />
        <div>
          <TextField
          className={classes.searchBox}
            // placeholder="Search"
            value={inputText}
            type='search'
            id="standard-search"
            label="Search "
            variant="outlined"
            // size="large"
            autoFocus
            
            onChange={e => {
              setInputText(e.target.value);
              handleUserSearch(e.target.value);
            }}
            InputProps={{disableUnderline:true,className:classes.input}}
          />
        </div>
        <div>
          <Container maxWidth="xl">
            <div className={classes.mainDev}>
              <Paper className={classes.PaperStyle}>
                <div className={classes.TabBorderstyle}>
                  <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    TabIndicatorProps={{
                      className: classes.TabIndicatorPropsStyle,
                    }}
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example">
                    <Tab
                      label={
                        <span className={value === 0 ? classes.activeTab : ''}>
                          Users
                        </span>
                      }
                      {...a11yProps(0)}
                      className={classes.textTransform}
                    />
                    <Tab
                      label={
                        <span className={value === 1 ? classes.activeTab : ''}>
                          Posts
                        </span>
                      }
                      {...a11yProps(3)}
                      className={classes.textTransform}
                    />
                    <Tab
                      label={
                        <span className={value === 2 ? classes.activeTab : ''}>
                          News
                        </span>
                      }
                      {...a11yProps(3)}
                      className={classes.textTransform}
                    />
                  </Tabs>
                </div>

                <TabPanel
                  value={value}
                  index={0}
                  className={classes.TabPanelStyle}>
                  {<SearchUserSection ref={searchRef} query={inputText} />}
                </TabPanel>
                <TabPanel value={value} index={1}>
                  {<SearchPostSection ref={postsRef} query={inputText} />}
                </TabPanel>
                <TabPanel value={value} index={2}>
                  {<SearchNewsSection ref={newsRef} query={inputText} />}
                </TabPanel>
              </Paper>
            </div>
          </Container>
        </div>
      </div>
      <div className={classes.PhoneViewEnable}>
        <PhoneView />
      </div>
    </React.Fragment>
  );
}

export default SearchDisplay;
