import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Paper, Typography, Button} from '@material-ui/core';
import {ArrowBack} from '@material-ui/icons';
import ShowInterests from '../../queries/ShowInterests';
import ChipChange from './ChipChange';
import CreateProfile from '../../queries/CreateProfile';
import {useQuery, useMutation} from '@apollo/client';
import {useCookies} from 'react-cookie';
import {useNavigate} from 'react-router-dom';
import {auth, firebase, db} from './firebase';
import '../../App.css';
import PhoneView from '../../components/phoneView/PhoneView';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: '2rem',
    borderRadius: '1rem',
    width: '30rem',
    height: '20rem',
    display: 'flex',
    flexDirection: 'column',
    margin: '0rem 5rem 3rem 0rem',
    [theme.breakpoints.down('md')]: {
      width: '30rem',
      marginBottom: '1rem',
      padding: '2rem',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  PhoneViewDisable: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  PhoneViewEnable: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

  right: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  Box: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginTop: '1rem',
    height: '25rem',
    overflowY: 'scroll',
    // overflowY: 'hidden',
    '& > *': {
      margin: theme.spacing(0.7),
    },
  },
  SubmitButtonBorder: {textAlign: 'center', marginTop: ' 2rem'},
  SubmitButton: {
    textTransform: 'capitalize',
    color: theme.palette.color.white,
    padding: '0.5rem 8rem',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  HeadingStyle: {
    marginBottom: '0.3rem',
    color: theme.palette.color.black,
    fontWeight: 'bold',
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
const Interest = props => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [interestData, setinterestData] = useState([]);
  const [selectedinterestData, setselectedinterestData] = useState([]);
  const [cookies, setCookie] = useCookies(['user']);

  console.log(interestData.map(item => item));
  useQuery(
    ShowInterests,

    {
      onCompleted: res => {
        console.log('fetchPrimaryInterests', res);

        setinterestData(
          res.fetchPrimaryInterests ? res.fetchPrimaryInterests : [],
        );
      },
      onError: err => {
        console.log(err);
      },
    },
    [],
  );
  const [profileCreation] = useMutation(CreateProfile);
  const handleProfileCreate = () => {
    profileCreation({
      variables: {
        name: props.userData.name,
        userId: props.userData.userId,
        mobileNumber: props.userData.mobileNumber
          ? props.userData.mobileNumber
          : '',
        state: props.userData.state,
        company: props.userData.company,
        college: props.userData.college,
        userType: props.userData.userType,
        email:auth.currentUser.email,
        interests: selectedinterestData,
        fcmToken: '',
      },
    })
      .then(res => {
        if (res.data && res.data.createUser) {
          setCookie('user', res.data.createUser, {path: '/'});
          navigate('/home');
        } else {
          alert('Something went wrong...');
        }
      })
      .catch(err => {
        alert('Something went wrong...');
        console.log(err);
      });
  };
  return (
    <React.Fragment>
      <div className={classes.right} id="ChooesIntrestsPage">
        <Paper elevation={3} className={classes.paper} id="paperForChoose">
          <div className={classes.root} id="rootIntrests">
            <div
              id="navigate"
              onClick={() => {
                props.setScreenTrack('nameform');
              }}>
              <ArrowBack />
            </div>
            <Typography variant="h6" className={classes.HeadingStyle}>
              Choose your interest
            </Typography>
            <Typography variant="body2" className={classes.HeadingStyle}>
              You have to choose minimum of 2 interest
            </Typography>
          </div>
          <div className={classes.Box} id="intrestsBox">
            {interestData.map((item, index) => {
              return (
                <div key={index} id="index">
                  <ChipChange
                    className="item"
                    item={item}
                    setChipData={setselectedinterestData}
                    chipData={selectedinterestData}
                  />
                </div>
              );
            })}
          </div>
          <div className={classes.SubmitButtonBorder} id="submitChip">
            <Button
              variant="contained"
              color="primary"
              size="small"
              id="select"
              className={classes.SubmitButton}
              onClick={() => {
                if (selectedinterestData.length >= 2) {
                  handleProfileCreate();
                } else {
                  alert('Minimum 2 interest need to select');
                }
              }}>
              Submit
            </Button>
          </div>
        </Paper>
      </div>
      <div className={classes.PhoneViewEnable} id="chipResponsive">
        <PhoneView />
      </div>
    </React.Fragment>
  );
};

export default Interest;
