import React, {useEffect, useState} from 'react';
import PostDesign from '../postDesing/PostDesgin';
import {useCookies} from 'react-cookie';
import {useLocation} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import ViewPost from "../../queries/ViewPost"
import { Typography } from '@material-ui/core';

const ViewPosts = () => {
  const [viewPost, setViewPost] = useState();
  const [cookies, setCookie] = useCookies(['user']);
  const location = useLocation();

  useQuery(ViewPost, {
    variables: {
      userId: cookies.user.userId,
      postId: location.state,
    },
    onCompleted: res => {
      console.log('viewPost', res);
      setViewPost(res.viewPost ? res.viewPost : null);
    },
    onError: err => {
      console.log(err);
    },
  },[]);
  return (
    <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
      {viewPost  ?(<PostDesign data={viewPost} />):(<Typography gutterBottom variant='h6'>Nothing to show</Typography>)}
    </div>
  );
};

export default ViewPosts;
