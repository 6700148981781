import React, {useState,  useLayoutEffect, useRef} from 'react';
import Navbar from '../navbar/Navbar';
import {makeStyles} from '@material-ui/core/styles';
import { Container, Paper} from '@material-ui/core';
import ProfileCard from './../profile/ProfileCard';
import SuggestionCard from './../suggestion_Card/SuggestionCard';
import LatestNews from './../news/LatestNews';
import NotificationCard from './NotificationCard';
import ShowNotifications from '../../queries/ShowNotifications';
import {ThreeCircles} from 'react-loader-spinner';
import {useQuery} from '@apollo/client';
import {useCookies} from 'react-cookie';
import { useEffect } from 'react';
import { useLocation,useNavigate } from 'react-router-dom';
import PhoneView from '../phoneView/PhoneView';

const useStyles = makeStyles(theme => ({
  mainDiv: {
    display: 'flex',
    paddingTop: '4rem',
    height: window.innerHeight,
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
    },
  },
  ProfilCard: {
    position: 'sticky',
    top: 0,
    marginLeft: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  News: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  Serach: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  PhoneViewDisable: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  PhoneViewEnable: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  Paper: {
    width: '35rem',
    marginTop: '1rem',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  flex: {
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
}));

const Notification = () => {
  const classes = useStyles();
  const pathname=useLocation();
  const navigate=useNavigate()
  const [cookies, setCookie] = useCookies(['user']);
  const [userNotificationData, setuserNotificationData] = useState([]);
  const [getSubscriptionsData, setGetSubscriptionsData] = useState([]);
  
  // const [showButton, setShowButton] = useState(false);
  const handleScrollToTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  const [loading, setLoading] = useState(true);
  const trackRef = useRef([]);

  const {fetchMore} = useQuery(ShowNotifications, {
    variables: {
      userId: cookies.user?cookies.user.userId:"",
      limit: 10,
      nextToken: null,
    },
    nextFetchPolicy:'cache-and-network',
    onCompleted: res => {
      console.log('userNotification', res);
      setuserNotificationData(
        res.fetchNotifications ? res.fetchNotifications.edges : [],
      );
      setGetSubscriptionsData(res.fetchNotifications);
      setLoading(false);
    },
    onError: err => {
      console.log(err);
    },
  });
  console.log('get sub data : ', getSubscriptionsData);

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        nextToken: getSubscriptionsData.pageInfo.endCursor,
        limit: 10,
        userModelInputFilter: {
          userId: cookies.user ? cookies.user.userId : '',
        },
      },

      updateQuery: (prev, {fetchMoreResult}) => {
        if (!fetchMoreResult) return prev;

        setGetSubscriptionsData(fetchMoreResult.fetchNotifications);
        return {
          ...prev,
          fetchNotifications: {
            ...prev.fetchNotifications,
            edges: [
              ...prev.fetchNotifications.edges,
              ...fetchMoreResult.fetchNotifications.edges,
            ],
            pageInfo: fetchMoreResult.fetchNotifications.pageInfo,
          },
        };
      },
    });
  };

  useLayoutEffect(() => {
    const handleScroll = () => {
     
      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - 500
      ) {
        console.log('pagination if check');
        console.log(trackRef.current);
        if (getSubscriptionsData && getSubscriptionsData.pageInfo) {
          if (
            !trackRef.current.includes(
              getSubscriptionsData
                ? getSubscriptionsData.pageInfo.endCursor
                : '',
            )
          ) {
            handleLoadMore();
            trackRef.current = [
              ...trackRef.current,
              getSubscriptionsData
                ? getSubscriptionsData.pageInfo.endCursor
                : '',
            ];
          }
          console.log(trackRef.current);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [userNotificationData]); // Ensure that this effect is executed whenever new data is fetched
useEffect(()=>{
  window.scrollTo(0,0)
},[pathname])
useEffect(() => {
  if (!cookies.user) {
    navigate('/auth')
  }
  // console.log('cookie user', cookies);
}, []);
  return (
    <React.Fragment>
      <div className={classes.PhoneViewDisable}>
        <Navbar />
        <div>
          <Container maxWidth="xl">
            <div className={classes.mainDiv}>
              <div className={classes.ProfilCard}>
                <ProfileCard />
                <SuggestionCard />
              </div>
              <div>
                <Paper elevation={1} className={classes.Paper}>
                  <div className={classes.flex}>
                    
                    {loading ? (
                      <div style={{marginLeft: '13.5rem', marginTop: '5rem'}}>
                        <ThreeCircles
                          height="100"
                          width="100"
                          color="#4fa94d"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                          ariaLabel="three-circles-rotating"
                          outerCircleColor="#00a3ff"
                          innerCircleColor="#ff9d00"
                          middleCircleColor="#4088FF"
                        />
                      </div>
                    ) : (
                      <div>
                        {userNotificationData.map((item, key) => (
                          <div key={key}>
                            <NotificationCard
                              userNotificationData={item.node}
                            />
                          </div>
                        ))}
                        {getSubscriptionsData.pageInfo.hasNextPage ? (
                          <div
                            style={{marginLeft: '13.5rem', marginTop: '5rem'}}>
                            <ThreeCircles
                              height="100"
                              width="100"
                              color="#4fa94d"
                              wrapperStyle={{}}
                              wrapperClass=""
                              visible={true}
                              ariaLabel="three-circles-rotating"
                              outerCircleColor="#00a3ff"
                              innerCircleColor="#ff9d00"
                              middleCircleColor="#4088FF"
                            />
                          </div>
                        ) : (
                          <div />
                        )}
                      </div>
                    )}
                  </div>
                </Paper>
              </div>
              <div className={classes.News}>
                <LatestNews />
              </div>
            </div>
          </Container>
        </div>
      </div>
      <div className={classes.PhoneViewEnable}>
      <PhoneView/>
      </div>
    </React.Fragment>
  );
};

export default Notification;
