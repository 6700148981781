import gql from 'graphql-tag';

export default gql`
  mutation createComment(
    $description: String!
    $postId: Int
    $commentId: Int
    $createdById: String!
    $createdOn: String
    $mediaData: [CreateMediaInputModel]
    $isSubComment: Boolean!
    $mentioned: [String]
  ) {
    createComment(
      createCommentInputModel: {
        description: $description
        postId: $postId
        commentId: $commentId
        createdById: $createdById
        createdOn: $createdOn
        mediaData: $mediaData
        isSubComment: $isSubComment
        mentioned: $mentioned
      }
    ) {
      id
      description
      commentedById
      commentedByName
      likeCount
      replyCount
      media {
        name
        url
        type
        thumbnailUrl
      }
      createdOn
      commentedByProfileImageUrl
    }
  }
`;
