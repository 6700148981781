import {Paper, Typography, TextField, Button} from '@material-ui/core';
import React from 'react';

const QuizRegister = () => {
  return (
    <Paper elevation={2}>
      <div>
        <div>
          <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTw_HeSzHfBorKS4muw4IIeVvvRgnhyO8Gn8w&usqp=CAU' alt='Quiz-Logo'/>
        </div>
        <div>
          <Typography> Please Enter The Details</Typography>
        </div>
        <Typography> Enter your college name</Typography>

        <TextField variant="outlined" required placeholder="Enter your college">
          Enter your college
        </TextField>
        <Typography>Enter your course/branch</Typography>

        <TextField
          variant="outlined"
          required
          placeholder="Enter your course/branch">
          Enter your course/branch
        </TextField>
        <Typography>Which year you currently studying in?</Typography>

        <TextField
          variant="outlined"
          required
          placeholder="Which year you currently studying in?">
          Which year you currently studying in?
        </TextField>
        <Button size="small" color="primary" variant='contained' style={{color:'whitesmoke', textTransform:"capitalize"}}>
        Register Now
      </Button>
      </div>
    </Paper>
  );
};

export default QuizRegister;
