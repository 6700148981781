import React, {Component} from 'react';
import {useCookies} from 'react-cookie';
import {useQuery} from '@apollo/client';
import HomeSearchNews from '../../queries/HomeSearchNews';
import {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
} from 'react';
import {ThreeCircles} from 'react-loader-spinner';
import NewsDisplay from './NewsDisplay';

const SearchNewsSection = forwardRef((props, searchRef) => {
  const trackRef = useRef([]);
  const [data, setData] = useState([]);
  const [cookies, setCookie] = useCookies(['user']);
  const [getSubscriptionsData, setGetSubscriptionsData] = useState([]);
  const [loading, setLoading] = useState(true);

  useImperativeHandle(searchRef, () => ({
    search: searchNews,
  }));

  const searchNews = query => {
    refetch({
      inputFilter: {
        userId: cookies.user.userId ? cookies.user.userId : '',
        keyWord: query,
        filter: '',
        type: 'news',
      },
      limit: 20,
      nextToken: null,
    });
  };

  const {refetch, fetchMore} = useQuery(
    HomeSearchNews,
    {
      variables: {
        limit: 10,
        nextToken: null,
        inputFilter: {
          keyWord: '',
          filter: '',
          userId: cookies.user.userId,
          type: 'news',
        },
      },
      onCompleted: res => {
        console.log('res : ', res);
        setData(res.homeSearchNews ? res.homeSearchNews.edges : []);
        setGetSubscriptionsData(res.homeSearchNews);
        setLoading(false);
      },
      onError: err => {
        console.log(err);
      },
    },
    [],
  );
  // console.log('get sub data : ', getSubscriptionsData);

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        nextToken: getSubscriptionsData.pageInfo.endCursor,
        limit: 10,
        inputFilter: {
          userId: cookies.user.userId ? cookies.user.userId : '',
          keyWord: '',
          filter: '',
          type: 'news',
        },
      },

      updateQuery: (prev, {fetchMoreResult}) => {
        if (!fetchMoreResult) return prev;

        setGetSubscriptionsData(fetchMoreResult.homeSearchNews);
        return {
          ...prev,
          homeSearchNews: {
            ...prev.homeSearchNews,
            edges: [
              ...prev.homeSearchNews.edges,
              ...fetchMoreResult.homeSearchNews.edges,
            ],
            pageInfo: fetchMoreResult.homeSearchNews.pageInfo,
          },
        };
      },
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      // You can define your own conditions to trigger loading more data based on scroll
      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - 500
      ) {
        console.log('pagination if check');
        console.log(trackRef.current);
        if (getSubscriptionsData && getSubscriptionsData.pageInfo) {
          if (
            !trackRef.current.includes(
              getSubscriptionsData
                ? getSubscriptionsData.pageInfo.endCursor
                : '',
            )
          ) {
            handleLoadMore();
            trackRef.current = [
              ...trackRef.current,
              getSubscriptionsData
                ? getSubscriptionsData.pageInfo.endCursor
                : '',
            ];
          }
          console.log(trackRef.current);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [data]); // Ensure that this effect is executed whenever new data is fetched

  return (
    <div>
      {loading ? (
        <div style={{ display: 'flex',
        flexDirection: 'row',
        gap: '1rem',
        justifyContent: 'space-between',
        alignItems: 'center',}}>
          <ThreeCircles
            height="100"
            width="100"
            color="#4fa94d"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="three-circles-rotating"
            outerCircleColor="#00a3ff"
            innerCircleColor="#ff9d00"
            middleCircleColor="#4088FF"
          />
        </div>
      ) : (
        <NewsDisplay News={data} />
      )}
    </div>
  );
});

export default SearchNewsSection;
