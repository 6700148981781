import gql from 'graphql-tag';

export default gql`
  mutation updateUser(
    $id: String
    $userId: String!
    $name: String
    $aboutMe: String
    $state: String
    $company: String
    $profileImageUrl: String
    $bannerImageUrl: String
    $degree: String
    $gitHubUrl: String
    $linkedInUrl: String
    $instagramUrl: String
    $faceBookUrl: String
    $dateOfBirth: String
    $updatedOn: String
    $college: String
    $interests: [String]
    $hasUpdatedInterests: Boolean
    $userType: String
  ) {
    updateUser(
      userInfo: {
        id: $id
        userId: $userId
        name: $name
        aboutMe: $aboutMe
        state: $state
        company: $company
        profileImageUrl: $profileImageUrl
        bannerImageUrl: $bannerImageUrl
        degree: $degree
        gitHubUrl: $gitHubUrl
        linkedInUrl: $linkedInUrl
        instagramUrl: $instagramUrl
        faceBookUrl: $faceBookUrl
        dateOfBirth: $dateOfBirth
        updatedOn: $updatedOn
        college: $college
        interests: $interests
        hasUpdatedInterests: $hasUpdatedInterests
        userType: $userType
      }
    ) {
      name
      description
      score
      userId
      profileImageUrl
      bannerImageUrl
      dateOfBirth
      state
      userType
      college
      degree
      company
      faceBookUrl
      gitHubUrl
      instagramUrl
      linkedInUrl
      followersCount
      followingCount
      interests 
      amIFollowing
      profileCompleteness
      hasUpdatedInterests
    }
  }
`;
