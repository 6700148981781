import React, {useState, useEffect, useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
  Paper,
  Typography,
  Button,
  Divider,
  TextField,
  Avatar,
} from '@material-ui/core/';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CancelIcon from '@material-ui/icons/Cancel';
import ClearIcon from '@material-ui/icons/Clear';
import ImageIcon from '@material-ui/icons/Image';
import QueueIcon from '@material-ui/icons/Queue';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import ListAltIcon from '@mui/icons-material/ListAlt';
import TypePoll from './TypePoll';
import CrispyPost from './CrispyPost';
import AddPost from '../../queries/AddPost';
import Topics from './Topics';
import {uploadFile} from 'react-s3';
import {useCookies} from 'react-cookie';
import {useMutation} from '@apollo/client';
import ShowPost from '../../queries/ShowPost';
import {db} from '../Auth/firebase';
import Cancel from '@material-ui/icons/Cancel';

const useStyles = makeStyles(theme => ({
  Paper: {
    backgroundColor: '#fff',
    width: '36rem',
    height: '28rem',
    overflow: 'auto',
  },

  CreateAPostTextStyle: {
    padding: '0.5rem',
  },
  avatar: {
    display: 'flex',
    padding: '0.7rem',
  },
  username: {
    display: 'flex',
    alignItems: 'center',

    fontWeight: 'bold',
  },
  textfield: {
    marginLeft: '1rem',
    marginBottom: '1rem',
  },
  btnicon: {
    display: 'flex',
    position: 'relative',
    padding: '0.5rem 0',
    marginLeft: '0.7rem',
  },

  button: {
    textTransform: 'capitalize',
    padding: 0,
    marginRight: '1rem',
    backgroundColor: '#00A3FF',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#00A3FF',
    },
    input: {
      display: 'none',
    },
  },

  RemovePollbutton: {
    textTransform: 'capitalize',
    marginRight: '1rem',
    padding: '0 0.5rem',
    backgroundColor: '#ff0000',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#ff0000',
    },
    input: {
      display: 'none',
    },
  },
  AfterColore: {color: '#8B8B8B', marginRight: '1rem', cursor: 'pointer'},
  BeforeColore: {color: '#00A3FF', marginRight: '1rem', cursor: 'pointer'},
}));

const CreatePost = props => {
  const classes = useStyles();
  const [cookies, setCookie] = useCookies(['user']);
  const [mediaType, setMediaType] = useState();
  const [mediaName, setMediaName] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImagePoll, setSelectedImagePoll] = useState('');
  const [selectedDoc, setSelectedDoc] = useState();
  const [source, setSource] = React.useState();
  const [disablebtn, setDisablebtn] = useState(false);
  const [poll, setPoll] = React.useState(
    props.PollType === true ? true : false,
  );
  const [crispy, setCrispy] = React.useState(
    props.CrispyType === true ? true : false,
  );
  const [postCapture, setPostCapture] = useState('');
  const [polloptions, setpolloptions] = useState([]);
  const [rightanswer, setrightanswer] = useState(5);
  const [mediaData, setmediaData] = useState([]);
  const [inputs, setinputs] = useState([
    {key: '', heading: '', content: '', focus: false},
  ]);
  const [awsdata, setawsdata] = useState({});
  const [config, setconfig] = useState();
  // aws confing
  const [selectedFile, setSelectedFile] = useState(null);
  useEffect(() => {
    db.collection('details')
      .doc('AWSconstants')
      .get()
      .then(snap => {
        setawsdata(snap.data());
        setconfig({
          bucketName: snap.data().bucket,
          region: snap.data().region,
          accessKeyId: snap.data().accessKey,
          secretAccessKey: snap.data().secretKey,
        });
      })
      .catch(err => {
        console.log(err);
      });
  }, []);
  const handleFileInput = e => {
    setSelectedFile(e.target.files[0]);
  };
  const inputHandler = (text, key, checkstate, focus) => {
    const _inputs = [...inputs];
    _inputs[key].heading = text;
    _inputs[key].key = key;
    _inputs[key].content = checkstate;
    _inputs[key].focus = focus;
    setinputs(_inputs);
    // props.options(_inputs);
  };
  const handleUpload = async file => {
    uploadFile(file, config)
      .then(data => {
        var mediaarr = [];
        var obj = {
          url: data.location,
          type: mediaType,
          name: mediaName ? mediaName : '',
        };
        mediaarr = [...mediaData, obj];
        setmediaData([...mediaData, obj]);
        if (crispy) {
          crispyPostData(mediaarr);
        } else if(poll){
          addpollData(mediaarr);
        }else {
          handleAddPost(mediaarr);
        }
        
      })
      .catch(err => console.error(err));
  };

  // aws config ends
  console.log(mediaType);
  const [addPostMutation] = useMutation(AddPost);
  const updateCache = (client, {data: {createPost}}) => {
    const data = client.readQuery({
      query: ShowPost,
      variables: {
        limit: 20,
        nextToken: null,
        userModelInputFilter: {userId: cookies.user.userId},
      },
    });

    // console.log('wholenew', createPost);
    console.log(data);
    if (createPost) {
      var obj = {node: createPost};
      const newArray = [obj, ...data.getSubscriptions.edges];
      const newData = {
        getSubscriptions: {...data.getSubscriptions, edges: newArray},
      };
      client.writeQuery({
        query: ShowPost,
        variables: {
          limit: 20,
          nextToken: null,
          userModelInputFilter: {userId: cookies.user.userId},
        },
        data: newData,
      });
    }
  };
  const handleAddPost = mediaarr => {
    addPostMutation({
      variables: {
        description: postCapture,
        postedById: cookies.user.userId,
        postedByName: cookies.user.name,
        interests: childCompRef.current.sendData(),
        mediaData: mediaarr,
      },
      update: updateCache,
    })
      .then(res => {
        setDisablebtn(false);

        // alert('Posted Succuessfully');
        props.changeModalVisibility();
      })
      .catch(err => {
        console.log(err, 'first');
      });
  };

  useEffect(() => {
    if (props.mediaLink !== undefined && props.mediaType === '0') {
      //  const url = URL.createObjectURL(props.mediaLink);
      setMediaType('image');
      // 0 indicates image
      setSelectedImage(props.mediaLink);
    }
    if (props.mediaLink !== undefined && props.mediaType === '1') {
      setMediaType('video');
      // const url = URL.createObjectURL(props.mediaLink);
      setSource(props.mediaLink);
      // 1 indicates Video
    }
    if (props.mediaLink !== undefined && props.mediaType === '2') {
      const doctype = props.mediaLink.type;
      const filefordoc = props.mediaLink;
      if (filefordoc) {
        const fileExtension = filefordoc.type.split('.').pop();
        console.log(`File extension: ${fileExtension}`);
        setMediaType(filefordoc);
        console.log(filefordoc);
      }
      const urlfordoc = `https://drive.google.com/viewerng/viewer?embedded=true&url='+${filefordoc}`;
      setSelectedDoc(urlfordoc);
      if (doctype === 'application/pdf') {
        setMediaType('pdf');
        setMediaName(filefordoc.name);
        setSelectedDoc(filefordoc);
      } else if (
        doctype === 'application/ppt' ||
        doctype === 'application/pptx'
      ) {
        setMediaType('ppt');
        setSelectedDoc(filefordoc);
        setMediaName(filefordoc.name);
      } else if (
        doctype === 'application/xlsx' ||
        doctype === 'application/xls'
      ) {
        setMediaType('excel');
        setSelectedDoc(filefordoc);
        setMediaName(filefordoc.name);
      } else if (
        doctype === 'application/doc' ||
        doctype === 'application/docx' ||
        'application/word'
      ) {
        setMediaType('word');
        setSelectedDoc(filefordoc);
        setMediaName(filefordoc.name);
      }

      // 2 indicates doc
    }
    if (props.mediaType === '3') {
      setMediaType('poll');

      
      // 3 indicates poll
    }
  }, []);

  const handleVideoChange = event => {
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);
    if (event.target.files[0].size > 10e6 * 3) {
      alert('Video limit is 30mb');
    } else {
      setSource(file);
      setMediaType('video');
    }
    event.target.value = '';
  };

  const handleDocChange = event => {
    setMediaType('2');
    event.preventDefault();
    event.target.value = '';

    const doctype = event.target.files[0].type;
    const filefordoc = event.target.files[0];
    if (filefordoc) {
      const fileExtension = filefordoc.type.split('.').pop();
      console.log(`File extension: ${fileExtension}`);
      setMediaType(filefordoc);
      console.log(filefordoc);
    }
    const urlfordoc = `https://drive.google.com/viewerng/viewer?embedded=true&url='+${filefordoc}`;
    setSelectedDoc(urlfordoc);
    if (doctype === 'application/pdf') {
      setMediaType('pdf');
      setSelectedDoc(filefordoc);

      setMediaName(filefordoc.name);
    } else if (
      doctype === 'application/ppt' ||
      doctype === 'application/pptx'
    ) {
      setMediaType('ppt');
      setSelectedDoc(filefordoc);
      setMediaName(filefordoc.name);
    } else if (
      doctype === 'application/xlsx' ||
      doctype === 'application/xls'
    ) {
      setMediaType('excel');
      setSelectedDoc(filefordoc);
      setMediaName(filefordoc.name);
    } else if (
      doctype === 'application/doc' ||
      doctype === 'application/docx' ||
      'application/word'
    ) {
      setMediaType('word');
      setSelectedDoc(filefordoc);
      setMediaName(filefordoc.name);
    }
  };
  const childCompRef = useRef();

  const addpollData = (media) => {
    var pollopt = [];
    polloptions.map((item, index) => {
      pollopt.push(item.value.trim());
    });
    console.log('pollopt', pollopt);
    addPostMutation({
      variables: {
        description: postCapture.trim(),
        postedById: cookies.user.userId,
        postedByName: cookies.user.name,
        interests: childCompRef.current.sendData(),
        mediaData: media,
        poll: {
          options: pollopt,
          correctAnsIndex: rightanswer.toString(),
        },
      },
      update: updateCache,
    })
      .then(res => {
        // alert('Posted Succuessfully');
        props.changeModalVisibility();
        console.log(res);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const crispyPostData = media => {
    addPostMutation({
      variables: {
        description: postCapture.trim() + 'sp@%12' + JSON.stringify(inputs),
        postedById: cookies.user.userId,
        postedByName: cookies.user.name,
        interests: childCompRef.current.sendData(),
        mediaData: media,
      },
      update: updateCache,
    })
      .then(res => {
        props.changeModalVisibility();
        console.log(res);
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <div style={{overflowY: 'hidden'}}>
      <Paper elevation={5} className={classes.Paper}>
        <div style={{minHeight: '25rem'}}>
          <div style={{display: 'flex'}}>
            <Typography
              variant="body1"
              gutterBottom
              className={classes.CreateAPostTextStyle}>
              {poll === true
                ? 'Create a MCQ'
                : crispy === true
                ? 'Create a crispy post'
                : 'Create a post'}
            </Typography>
            <div
              style={{
                flex: 1,
                alignSelf: 'center',
                textAlign: 'right',
                marginRight: '1rem',
              }}>
              <ClearIcon
                style={{cursor: 'pointer'}}
                onClick={() => {
                  setSelectedImage(null);
                  setMediaType(undefined);
                  setSource(undefined);
                  setSelectedDoc(undefined);
                  setPoll(false);
                  props.changeModalVisibility(false);
                  props.changePollType(false);
                  setCrispy(false);
                  props.changeCrispyType(false);
                }}
              />
            </div>
          </div>
          <Divider />
          <div className={classes.avatar}>
            <Avatar alt="UserImage" src={cookies.user.profileImageUrl} />
            <div style={{marginLeft: '0.6rem', flex: 2}}>
              <Typography variant="subtitle2" className={classes.username}>
                {cookies.user.name}
              </Typography>
              <Typography variant="subtitle2" style={{fontSize: '0.7rem'}}>
                {cookies.user.college}
              </Typography>
            </div>
            <div>
              {poll === true ? (
                <Button
                  onClick={() => {
                    setMediaType(undefined);
                    setPoll(false);
                  }}
                  className={classes.RemovePollbutton}>
                  remove MCQ
                </Button>
              ) : (
                ''
              )}
            </div>
            <div>
              {crispy === true ? (
                <Button
                  onClick={() => {
                    setMediaType(undefined);
                    setCrispy(false);
                  }}
                  className={classes.RemovePollbutton}>
                  Remove Crispy
                </Button>
              ) : (
                ''
              )}
            </div>
            <div>
              {source !== undefined ? (
                <Button
                  onClick={() => {
                    setMediaType(undefined);
                    setSource(undefined);
                  }}
                  className={classes.RemovePollbutton}>
                  remove Video
                </Button>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className={classes.textfield}>
            <TextField
              noValidate
              autoComplete="off"
              multiline
              placeholder={
                poll === true
                  ? 'Your Question*'
                  : crispy === true
                  ? 'Enter Topic'
                  : 'Type Something to post..'
              }
              variant="standard"
              style={{width: '100%', paddingRight: '1rem'}}
              InputProps={{
                disableUnderline: true,
                paddingeft: '2rem',
                maxLength: 1000,
              }}
              onChange={e => {
                setPostCapture(e.target.value);
              }}
              // value={postCapture}
            />
          </div>

          <div>
            {selectedImage && (
              <div style={{margin: '0 1rem'}}>
                <div style={{position: 'relative', margin: '1rem'}}>
                  <CancelIcon
                    style={{color: '#8B8B8B'}}
                    onClick={() => {
                      setMediaType(undefined);
                      setSelectedImage(null);
                    }}
                  />
                </div>
                <img
                  alt="not found"
                  width={'100%'}
                  height={'100%'}
                  style={{borderRadius: '0.5rem'}}
                  src={URL.createObjectURL(selectedImage)}></img>
              </div>
            )}
            <input
              accept="image/*"
              id="ImageAddCreate"
              type="file"
              style={{display: 'none'}}
              onChange={event => {
                // if (event.target.files[0]) {

                // }
                setSelectedImage(event.target.files[0]);
                setMediaType('image');
                event.target.value = '';
              }}
            />
          </div>

          <div className="VideoInput">
            {source && (
              <div
                style={{
                  margin: '0 1rem',
                  maxWidth: '33rem',
                }}>
                <video
                  className="VideoInput_video"
                  width={'100%'}
                  height={'100%'}
                  controls
                  src={URL.createObjectURL(source)}
                />
              </div>
            )}
            <input
              type="file"
              onChange={handleVideoChange}
              accept=".mov,.mp4"
              style={{display: 'none'}}
              id="VideoAdd"
            />
          </div>
          <div>
            <div>
              {selectedDoc && (
                <div>
                  <div style={{position: 'relative', margin: '1rem'}}>
                    <CancelIcon
                      style={{color: '#8B8B8B'}}
                      onClick={() => {
                        setMediaType(undefined);
                        setSelectedDoc(null);
                      }}
                    />
                  </div>
                  <object
                    title="doctype"
                    height="100%"
                    width="100%"
                    // controls

                    data={selectedDoc}
                  />
                </div>
              )}
            </div>
            <input
              style={{display: 'none'}}
              type="file"
              onChange={handleDocChange}
              accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
               text/plain, application/pdf,"
              id="DocumentAdd"
            />
          </div>

          <div>
            {poll === true ? (
              <div>
                <TypePoll
                  options={setpolloptions}
                  rightanswer={setrightanswer}
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <input
                    accept="image/*"
                    style={{display: 'none'}}
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={event => {
                      setSelectedImagePoll(event.target.files[0]);
                      setMediaType('imagepoll');

                      event.target.value = '';
                    }}
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        textTransform: 'capitalize',
                        color: 'white',
                        borderRadius: '0.5rem',
                      }}
                      component="span">
                      Add Image
                    </Button>
                  </label>
                  {selectedImagePoll !== '' ? (
                    <Cancel
                      fontSize="small"
                      style={{cursor: 'pointer', color: '#8B8B8B'}}
                      onClick={() => {
                        setSelectedImagePoll('');
                        // file can't be empty error means check the use state weather it is " " or null
                      }}
                    />
                  ) : (
                    <div />
                  )}

                  {selectedImagePoll && (
                    <div style={{margin: '0 1rem'}}>
                      <img
                        alt="not found"
                        width={'100%'}
                        height={'100%'}
                        style={{borderRadius: '0.5rem'}}
                        src={URL.createObjectURL(selectedImagePoll)}
                      />
                    </div>
                  )}
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
          <div>
            {crispy === true ? (
              <CrispyPost
                adddata={inputHandler}
                inputs={inputs}
                setInputs={setinputs}
                setSelectedImage={setSelectedImage}
                setMediaType={setMediaType}
                mediaType={mediaType}
              />
            ) : (
              ''
            )}
          </div>
          <div>
            <Topics ref={childCompRef} />
          </div>
        </div>
        <div className={classes.btnicon}>
          {mediaType === undefined && !crispy && !poll ? (
            <label htmlFor="ImageAddCreate">
              <ImageIcon className={classes.BeforeColore} />
            </label>
          ) : (
            <ImageIcon className={classes.AfterColore} />
          )}

          {mediaType === undefined && !crispy && !poll ? (
            <label htmlFor="VideoAdd">
              <VideoLibraryIcon className={classes.BeforeColore} />
            </label>
          ) : (
            <VideoLibraryIcon className={classes.AfterColore} />
          )}

          {mediaType === undefined && !crispy && !poll ? (
            <label htmlFor="DocumentAdd">
              <AttachFileIcon className={classes.BeforeColore} />
            </label>
          ) : (
            <AttachFileIcon className={classes.AfterColore} />
          )}

          {mediaType === undefined && !crispy ? (
            <ListAltIcon
              onClick={() => {
                setMediaType(3);
                setPoll(true);
              }}
              className={classes.BeforeColore}
            />
          ) : (
            <ListAltIcon className={classes.AfterColore} />
          )}
          {mediaType === undefined && !crispy ? (
            <label htmlFor="CrispyPostAdd">
              <QueueIcon
                className={classes.BeforeColore}
                onClick={() => {
                  setCrispy(true);
                }}
              />
            </label>
          ) : (
            <QueueIcon className={classes.AfterColore} />
          )}

          <div style={{flex: 1, textAlign: 'right'}}>
            <Button
              variant="contained"
              className={classes.button}
              disabled={disablebtn}
              // onClick={() => handleUpload(selectedImage)}
              onClick={() => {
                if (childCompRef.current.sendData().length > 0) {
                  if (
                    (postCapture && postCapture !== '') ||
                    selectedImage ||
                    source ||
                    selectedDoc
                  ) {
                    if (poll) {
                      var isoptionemty = false;
                      polloptions.map((item, index) => {
                        if (item.value === '') {
                          isoptionemty = true;
                        }
                      });
                      if (isoptionemty) {
                        alert('Option is empty...');
                      } else if (rightanswer === 5) {
                        alert('Select the answer...');
                      } else {
                        if (selectedImagePoll === '') {
                          addpollData();
                        } else {
                          handleUpload(selectedImagePoll);
                        }
                      }
                    } else if (crispy) {
                      var isempty = false;
                      inputs.map((item, index) => {
                        if (item.heading === '') {
                          isempty = true;
                        }
                      });
                      if (isempty) {
                        alert('Heading is required...');
                      } else {
                        if (mediaType === 'image') {
                          handleUpload(selectedImage);
                        } else {
                          crispyPostData([]);
                        }
                      }
                    } else {
                      if (mediaType === 'image') {
                        handleUpload(selectedImage);
                      } else if (mediaType === 'video') {
                        handleUpload(source);
                      } else if (
                        mediaType === 'word' ||
                        mediaType === 'text' ||
                        mediaType === 'excel' ||
                        mediaType === 'pdf' ||
                        mediaType === 'ppt'
                      ) {
                        // alert('entered');
                        handleUpload(selectedDoc);
                      } else {
                        setDisablebtn(true);
                        handleAddPost([]);
                        // alert('Posted successfully');
                        props.changeModalVisibility();
                      }
                    }
                    // alert('Posted Succuessfully');
                    // props.changeModalVisibility();
                  } else {
                    alert('Empty post is not allowed');
                    props.changeModalVisibility();
                  }
                } else {
                  alert('Topics must be selected');
                }
              }}>
              Post
            </Button>
          </div>
        </div>

        {/* <Dialog
          open={exitPost}
          //onClose={() => changeModalVisibility(false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description">
          <div>
        
            <CreatePosts
              changeModalVisibility={changeModalVisibility}
              changePollType={changePollType}
              mediaType={mediaType}
              mediaLink={mediaLink}
              PollType={isPoll}
            /> 
          </div>
        </Dialog> */}
      </Paper>
    </div>
  );
};
export default CreatePost;
