import gql from 'graphql-tag';

export default gql`
  query docsOfUser(
    $limit: Int
    $nextToken: String
    $userModelInputFilter: UserModelInputFilter
    $visitingUserId: String
  ) {
    docsOfUser(
      limit: $limit
      nextToken: $nextToken
      userModelInputFilter: $userModelInputFilter
      visitingUserId: $visitingUserId
    ) @connection(key: "PostDisplayModel") {
      edges {
        node {
          name
          url
          type
          thumbnailUrl
        }
      }
      pageInfo {
        endCursor 
        hasNextPage
      }
    }
  }
`;
