import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Avatar, Typography, Button, Badge} from '@material-ui/core';
import ScontiLogoNavbar from '../../assets/Icon_svg/ScontiLogoNavbar.svg';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SettingsIcon from '@material-ui/icons/Settings';
import WebIcon from '@material-ui/icons/Web';
import HomeIcon from '@material-ui/icons/Home';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import seenNotification from '../../queries/seenNotification';
import {Link} from 'react-router-dom';
import {useMutation} from '@apollo/client';
import {useCookies} from 'react-cookie';
import {Search} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.color.white,
    position: 'fixed',
    width: '100%',
    zIndex: '2',
    [theme.breakpoints.down('sm')]: {
      bottom: 0,
    },
  },
  ScontiLogoNavbar: {
    height: '1.8rem',
    width: '1.8rem',
    margin: '0.3rem 1rem 0rem 0rem',
  },
  InputTextBackground: {
    backgroundColor: theme.palette.color.InputTextBackground,
    borderRadius: '0.5rem',
    paddingInlineStart: '1rem',
    paddingBottom: '0.2rem',
    paddingTop: '0.2rem',
    borderColor: 'secondary',
  },

  SearchIconStyle: {
    margin: '0.3rem 0.5rem 0rem 0rem',
  },

  Avatar: {
    margin: '0rem 0rem 0rem 1rem',
    width: '1.5rem',
    height: '1.5rem',
  },

  HomeBox: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0rem 1rem 0rem 25rem',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      margin: '0rem 1rem 0rem 25rem',
    },
    [theme.breakpoints.down('md')]: {
      margin: '0rem 1rem 0rem 10rem',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0rem 0.7rem 0rem 0rem',
    },
  },
  IconName: {
    color: theme.palette.color.black,
    fontSize: 10,
    margin: '0rem 0rem 0rem 0rem',
    alignSelf: 'center',
  },

  navButtonActive: {
    borderBottomStyle: 'solid',
    borderWidth: '0rem 0rem 0.1rem 0rem',
    borderImage: theme.palette.color.grey,
    borderRadius: 0,
    fontWeight: '500',
    fontSize: '1rem',
    lineHeight: '1rem',
    letterSpacing: '0.047rem',
    textTransform: 'capitalize',
  },
  navButton: {
    color: theme.palette.color.grey,
    borderBottom: theme.palette.color.white,
    borderRadius: 0,
    fontWeight: '400',
    fontSize: '1rem',
    lineHeight: '1rem',
    letterSpacing: '0.047rem',
    textTransform: 'capitalize',
  },
  IconNameBorder: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  SearchIconBorder: {
    margin: '0.5rem 0rem 0.5rem 0rem',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    marginLeft: '1rem',
  },
  AvatarStyle: {
    width: '1.5rem',
    height: '1.5rem',
    color: theme.palette.color.grey,
    alignSelf: 'center',
    margin: '0rem 0rem 0rem 0rem',
  },
  IconNameBorderTow: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0rem 1rem 0rem 0rem',
    [theme.breakpoints.down('sm')]: {
      margin: '0rem 0.3rem 0rem 0rem',
    },
  },
  // SearchIconStyleIcon: {width: 20, height: 20, cursor: 'pointer'},
}));

const Navbar = props => {
  const classes = useStyles();
  const [notificationcount, setnotificationcount] = useState(0);
  const [cookies, setCookie] = useCookies(['user']);
  const [seennotif] = useMutation(seenNotification);

  return (
    <React.Fragment>
      <div className={classes.root}>
        <div className={classes.SearchIconBorder}>
          <Link
            style={{textDecoration: 'none'}}
            to="/home"
            onClick={() => {
              props.click();
              window.location.reload(true)
            }}>
            <img
              decode="async"
              alt="Login"
              longdesc="https://studentcontinent.com/"
              loading="eager"
              referrerPolicy="no-referrer"
              src={ScontiLogoNavbar}
              className={classes.ScontiLogoNavbar}
            />
          </Link>
          {/**<TextField
            className={classes.InputTextBackground}
            placeholder="search"
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <div className={classes.SearchIconStyle}>
                  <SearchOutlinedIcon className={classes.SearchIconStyleIcon}/>
                  
                                 
                </div>
              ),
            }}
          /> */}
        </div>

        <Link style={{textDecoration: 'none'}} to="/home">
          <div className={classes.HomeBox}>
            <Button
              className={
                window.location.pathname === '/home'
                  ? classes.navButtonActive
                  : classes.navButton
              }>
              <div className={classes.IconNameBorder}>
                <HomeIcon />
                <Typography className={classes.IconName} variant="subtitle2">
                  Home
                </Typography>
              </div>
            </Button>
          </div>
        </Link>
        <Link style={{textDecoration: 'none'}} to="/SearchDisplay">
          <div className={classes.IconNameBorderTow}>
            <Button
              className={
                window.location.pathname === '/SearchDisplay'
                  ? classes.navButtonActive
                  : classes.navButton
              }>
              <div className={classes.IconNameBorder}>
                <Search />
                <Typography className={classes.IconName} variant="subtitle2">
                  Search
                </Typography>
              </div>
            </Button>
          </div>
        </Link>

        <Link style={{textDecoration: 'none'}} to="/news">
          <div className={classes.IconNameBorderTow}>
            <Button
              className={
                window.location.pathname === '/news'
                  ? classes.navButtonActive
                  : classes.navButton
              }>
              <div className={classes.IconNameBorder}>
                <WebIcon />
                <Typography className={classes.IconName} variant="subtitle2">
                  News
                </Typography>
              </div>
            </Button>
          </div>
        </Link>

        <Link style={{textDecoration: 'none'}} to="/notification">
          <div className={classes.IconNameBorderTow}>
            <Button
              onClick={() => {
                seennotif({
                  variables: {
                    userId: cookies ? cookies.user.userId : '',
                  },
                })
                  .then(res => {
                    console.log('success', res);
                    setnotificationcount(0);
                  })
                  .catch(err => {
                    console.log('seen', err);
                  });
              }}
              className={
                window.location.pathname === '/notification'
                  ? classes.navButtonActive
                  : classes.navButton
              }>
              <div className={classes.IconNameBorder}>
                <Badge
                  badgeContent={notificationcount}
                  color="primary"
                  overlap="rectangular">
                  <NotificationsIcon />
                </Badge>
                <Typography className={classes.IconName} variant="subtitle2">
                  Notification
                </Typography>
              </div>
            </Button>
          </div>
        </Link>

{/**        <Link style={{textDecoration: 'none'}} to="/mainquiz">
          <div className={classes.IconNameBorderTow}>
            <Button
              className={
                window.location.pathname === '/mainquiz'
                  ? classes.navButtonActive
                  : classes.navButton
              }>
              <div className={classes.IconNameBorder}>
                <FactCheckIcon />
                <Typography className={classes.IconName} variant="subtitle2">
                  Quiz
                </Typography>
              </div>
            </Button>
          </div>
        </Link> */}
        <Link style={{textDecoration: 'none'}} to="/setting">
          <div className={classes.IconNameBorderTow}>
            <Button
              className={
                window.location.pathname === '/setting'
                  ? classes.navButtonActive
                  : classes.navButton
              }>
              <div className={classes.IconNameBorder}>
                <SettingsIcon />
                <Typography className={classes.IconName} variant="subtitle2">
                  Settings
                </Typography>
              </div>
            </Button>
          </div>
        </Link>

        <Link style={{textDecoration: 'none'}} to="/profile">
          <div className={classes.IconNameBorderTow}>
            <Button
              className={
                window.location.pathname === '/profile'
                  ? classes.navButtonActive
                  : classes.navButton
              }>
              <div className={classes.IconNameBorder}>
                <Avatar
                  src={cookies.user?cookies.user.profileImageUrl:""} //point to note
                  className={classes.AvatarStyle}
                />
                <Typography className={classes.IconName} variant="subtitle2">
                  Profile
                </Typography>
              </div>
            </Button>
          </div>
        </Link>
      </div>
    </React.Fragment>
  );
};
export default Navbar;
