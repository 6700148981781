import React, {useState, useEffect} from 'react';
import {db} from '../Auth/firebase';
import {Button, Typography} from '@material-ui/core';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import Modal from '@material-ui/core/Modal';
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {useCookies} from 'react-cookie';

const useStyles = makeStyles(theme => ({
  paper: {
    width: '32rem',
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const ScontiEarnings = () => {
  const [data, setData] = useState();
  const [upi, setUpi] = useState('');
  const [validUpiId, setValidUpiId] = useState('');
  const [cookies, setCookie] = useCookies(['user']);

  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);
  const LoadData = () => {
    let query = db.collection('details').doc('Content'); // sort the
    query.get().then(info => {
      setData(info.data());
    });
  };
  useEffect(() => {
    LoadData();
  }, []);

  function rand() {
    return Math.round(Math.random() * 20) - 10;
  }
  function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleStore = () => {
    db.collection('AccountDetails')
      .doc(cookies.user.userId)
      .set({upiid: upi})
      .then(res => {})
      .catch(err => {}); // sort the
  };
  useEffect(() => {
    let query = db.collection('AccountDetails').doc(cookies.user.userId); // sort the
    query.get().then(info => {
      setUpi(info.data() ? info.data().upiid : '');
    });
  }, []);

  const handleUpiIdValidate = event => {
    const newUpiId = event.target.value;
    const upiRegex = /[\.\-a-z0-9]+@[a-z]+/gm;
    setUpi(event.target.value);
    setValidUpiId(upiRegex.test(newUpiId));
  };
  const body = (
    <div style={modalStyle} className={classes.paper}>
      <Typography variant="h6" color="primary" id="simple-modal-title">
        Enter your details for payment
      </Typography>
      <p id="simple-modal-description">
        (please enter your UPI Id if you have one otherwise add account number)
      </p>

      <Typography variant="body1">Enter your UPI-Id</Typography>

      <TextField
        variant="outlined"
        margin="normal"
        required
        label="Upi Id"
        id="upiId"
        fullWidth
        autoComplete="upi"
        size="small"
        autoFocus
        placeholder="Enter your UPI-ID (e.g,xxxxxxxxxx@Easebuzz)"
        value={upi}
        onChange={handleUpiIdValidate}
        error={!validUpiId}
        helperText={!validUpiId ? 'invalid upi id' : ''}
      />

      <Button
        variant="contained"
        style={{
          backgroundColor: '#00A3FF',
          color: 'white',
          textTransform: 'capitalize',
        }}
        onClick={() => {
          handleStore();
          setUpi('');
          alert('stored');
        }}>
        Submit details
      </Button>
    </div>
  );

  return (
    <React.Fragment>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '5rem',
        }}>
        <Typography variant="h6">{data ? data.schemeheading : ''}</Typography>
        <Button
          startIcon={<AccountBalanceIcon />}
          style={{textTransform: 'capitalize'}}
          type="button"
          onClick={handleOpen}>
          Bank details
        </Button>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        {body}
      </Modal>
      <Typography variant="h6">Terms and Conditions:</Typography>{' '}
      {(data ? data.terms : []).map((item, index) => {
        return (
          <ul>
            <li>{item}</li>
          </ul>
        );
      })}
      <Typography variant="h6">Note:</Typography>
      {(data ? data.notes : []).map((item, index) => {
        return (
          <ul>
            <li>{item}</li>
          </ul>
        );
      })}
    </React.Fragment>
  );
};

export default ScontiEarnings;
