export const numberinputvalidation = text => {
  var regex = /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi;
  if (text.length > 10) {
  }
  return text.replace(regex, '').trim();
};

export const nameinputvalidation = text => {
  var regexforspecialcharacters = /^[^a-zA-Z]+$/;
  var specialcharacters = regexforspecialcharacters.test(text);
  if (specialcharacters) {
    return false;
  } else {
    return true;
  }
};

export const nameinputvalidationforbio = text => {
  if (text.length < 3) {
    return false;
  } else {
    return true;
  }
};

export const servererrors = code => {
  if (code.includes('4')) {
  } else if (code.includes('5')) {
  } else {
  }
};

export const addposttextvalidation = text => {
  if (text.length > 1000) {
    return true;
  } else {
    return false;
  }
};

export const addcommenttextvalidation = text => {
  if (text.length > 200) {
    return true;
  } else {
    return false;
  }
};

export const urlvalidation = text => {
  const regex = new RegExp(
    '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?',
  );
  return regex.test(text);
};

export const numberformatter = (num, digits) => {
  const lookup = [
    {value: 1, symbol: ''},
    {value: 1e3, symbol: 'k'},
    {value: 1e6, symbol: 'M'},
    {value: 1e9, symbol: 'G'},
    {value: 1e12, symbol: 'T'},
    {value: 1e15, symbol: 'P'},
    {value: 1e18, symbol: 'E'},
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : '0';
};

export const changecolor = () => {};

export const extractURLFromDescription = description => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const urls = description.match(urlRegex);

  if (urls && urls.length > 0) {
    return urls[0]; // Return the first URL found
  } else {
    return null; // No URL found
  }
};
