import React, {useState, useEffect, useRef} from 'react';
import FollowersList from '../../queries/FollowersList';
import {useLazyQuery} from '@apollo/client';
import {useCookies} from 'react-cookie';
import {Typography,  Paper} from '@material-ui/core';
import FolloweUserCard from './FolloweUserCard';
import { ThreeCircles } from 'react-loader-spinner';

const Followers = props => {
  const [followers, setFollowers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [cookies, setCookie] = useCookies(['user']);
  const trackRef = useRef([]);

  const [getSubscriptionsDataFollowers, setGetSubscriptionsDataFollowers] =
    useState([]);
  const [getFollowers, {fetchMore: fetchMoreFollowers}] = useLazyQuery(
    FollowersList,
    {
      variables: {
        limit: 10,
        nextToken: null,
        userId: props.user,
        visitorUserId: cookies.user.userId,
      },
      fetchPolicy: 'network-only',
      onCompleted: res => {
        console.log('myFollowers', res);
        setFollowers(res.myFollowers ? res.myFollowers.edges : []);
        setGetSubscriptionsDataFollowers(res.myFollowers);
        setLoading(false);
      },
      onError: err => {
        console.log(err);
      },
    },
  );
  const handleLoadMoreFollowers = () => {
    fetchMoreFollowers({
      variables:{
        limit: 10,
        nextToken: getSubscriptionsDataFollowers.pageInfo.endCursor,
        userId: props.user,
        visitorUserId: cookies.user.userId,
  
      },
      updateQuery: (prev, {fetchMoreResult}) => {
        if (!fetchMoreResult) return prev;

        setGetSubscriptionsDataFollowers(fetchMoreResult.myFollowers);
        return {
          ...prev,
          myFollowers: {
            ...prev.myFollowers,
            edges: [
              ...prev.myFollowers.edges,
              ...fetchMoreResult.myFollowers.edges,
            ],
            pageInfo: fetchMoreResult.myFollowers.pageInfo,
          },
        };
      },
    }).catch(err => {
      console.log(err);
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      console.log('working...');
      // You can define your own conditions to trigger loading more data based on scroll
      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - 600
      ) {
        console.log('pagination if check');
        //handleLoadMoreFollowers();

        // console.log(trackRef.current);
        if (
          getSubscriptionsDataFollowers &&
          getSubscriptionsDataFollowers.pageInfo
        ) {
          if (
            !trackRef.current.includes(
              getSubscriptionsDataFollowers
                ? getSubscriptionsDataFollowers.pageInfo.endCursor
                : '',
            )
          ) {
            console.log('tfggg');
            handleLoadMoreFollowers();
            trackRef.current = [
              ...trackRef.current,
              getSubscriptionsDataFollowers
                ? getSubscriptionsDataFollowers.pageInfo.endCursor
                : '',
            ];
          }
          console.log(trackRef.current);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [followers, getSubscriptionsDataFollowers]);
  useEffect(() => {
    getFollowers();
  }, []);

  return (
    <Paper elevation={2}>
      {loading ? (
        <ThreeCircles
          style={{margin: '5rem'}}
          height="100"
          width="100"
          color="#4fa94d"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="three-circles-rotating"
          outerCircleColor="#00a3ff"
          innerCircleColor="#ff9d00"
          middleCircleColor="#4088FF"
        />
      ) : followers.length > 0 ? (
        followers.map((item, key) => (
          <div key={key}>
            <FolloweUserCard followers={item.node} />
          </div>
        ))
      ) : (
        <Typography variant="body2" style={{margin: '5rem'}}>
          {props.user === cookies.user.userId
            ? 'You have no followers'
            : 'No followers to show'}
        </Typography>
      )}
    </Paper>
  );
};

export default Followers;
