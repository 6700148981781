import {createTheme} from '@material-ui/core/styles';

const theme = createTheme({
  overrides: {
    MuiDropzoneSnackbar: {
      infoAlert: {
        backgroundColor: '#f44336',
      },
    },
    MuiAccordion: {
      root: {
        '&:before': {
          opacity: 0,
        },
      },
    },

    MuiAccordionSummary: {
      content: {
        margin: '0.3rem 0 0.3rem 0',
      },
    },

    MuiRating: {
      root: {
        color: '#CC0000',
      },
    },
  },
  typography: {
    fontFamily: '"Poppins", "Roboto", "Helvetica", "Arial", sans-serif',
  },
  palette: {
    primary: {
      main: '#00A3FF',
      light: '#FFFFFF',
      dark: '#CCCCCC',
    },
    secondary: {
      main: '#C2E9FF',
      light: '#FFFFFF',
      dark: '#CCCCCC',
    },
    error: {
      main: '#BDBDBD',
      light: '#FF4F30',
      dark: '#930000',
    },
    background: {
      default: '#f3f2ef',
    },
    color: {
      grey: '#818181',
      black: '#000000',
      white: '#FFFFFF',
      InputTextBackground: '#f5f5f5',
      grey1: '#8B8B8B',
      grey2: '#DBDBDB',
      grey3: '#9E9E9F',
      HelperTextColore: '#757575',
      AppInfoColore: '#e0e0e0',
    },
  },
  breakpoints: {
    values: {
      xs: 280.05 /* phone */,
      sm: 600 /* I Pad */,
      md: 960 /* I Pad pro */,
      lg: 1280 /* laptop */,
      xl: 1920 /* 24inc monitor */,
      /* xxl: 2048  4K monitor */
    },
  },
});

export default theme;
