import React, {useEffect, useState, useRef} from 'react';
import Navbar from '../navbar/Navbar';
import {makeStyles} from '@material-ui/core/styles';
import {Container,  Paper, Typography} from '@material-ui/core';
import ProfileCard from './../profile/ProfileCard';
import SuggestionCard from './../suggestion_Card/SuggestionCard';
import LatestNews from './LatestNews';
import PhoneView from '../phoneView/PhoneView';
import ListAllNews from '../../queries/ListAllNews';
import {Link} from 'react-router-dom';
import {useLazyQuery} from '@apollo/client';
import {auth} from '../Auth/firebase';
import {useCookies} from 'react-cookie';
import ListSingleNews from '../../queries/ListSingleNews';
import {useLocation,useNavigate} from 'react-router-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import {Carousel} from 'react-responsive-carousel';
const useStyles = makeStyles(theme => ({
  mainDiv: {
    display: 'flex',
    paddingTop: '4rem',
    height: window.innerHeight,
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
    },
  },
  ProfilCard: {
    position: 'sticky',
    top: 0,
    marginLeft: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  News: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  Serach: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  PhoneViewDisable: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  PhoneViewEnable: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  Paper: {
    width: '30rem',
    marginTop: '1rem',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  flex: {
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
}));

const News = props => {
  const classes = useStyles();
  const location = useLocation();
  const navigate=useNavigate()
  const [newsinfo, setNewsinfo] = useState([]);
  const [cookies, setCookie] = useCookies(['user']);
  const [getSubscriptionsData, setGetSubscriptionsData] = useState([]);

  const trackRef = useRef([]);

  const [getNews, {fetchMore}] = useLazyQuery(ListAllNews, {
    variables: {
      limit: 10,
      nextToken: null,
      userModelInputFilter: {
        userId: auth.currentUser ? auth.currentUser.uid : '',
      },
    },
    onCompleted: res => {
      console.log('news', res);
      // setNewsinfo(res.listAllNews ? res.listAllNews.edges : []);
      if (location.state) {
        idForNews().then(res1 => {
          var data = res1.data.getNews;
          var node = {node: data};
          // console.log('singe', res.listAllNews ? [node,...res.listAllNews.edges] : [])
          setNewsinfo(res.listAllNews ? [node, ...res.listAllNews.edges] : []);
        });
      } else {
        setNewsinfo(res.listAllNews ? res.listAllNews.edges : []);
      }
      setGetSubscriptionsData(res.listAllNews);
    },
    onError: err => {
      console.log(err);
    },
  });
  console.log('getsubdata : ', getSubscriptionsData);

  const [idForNews] = useLazyQuery(
    ListSingleNews,
    {
      variables: {id: location.state},
    },
    [],
  );
  useEffect(() => {
    getNews();
  }, []);
  const handleLoadMore = () => {
    fetchMore({
      variables: {
        nextToken: getSubscriptionsData.pageInfo.endCursor,
        limit: 10,
        userModelInputFilter: {
          userId: cookies.user ? cookies.user.userId : '',
        },
      },

      updateQuery: (prev, {fetchMoreResult}) => {
        if (!fetchMoreResult) return prev;

        setGetSubscriptionsData(fetchMoreResult.listAllNews);
        return {
          ...prev,
          listAllNews: {
            ...prev.listAllNews,
            edges: [
              ...prev.listAllNews.edges,
              ...fetchMoreResult.listAllNews.edges,
            ],
            pageInfo: fetchMoreResult.listAllNews.pageInfo,
          },
        };
      },
    });
  };
  const paginate = () => {
    console.log('paginating');
    handleLoadMore();
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    if (!cookies.user) {
      navigate('/auth')
    }
  }, []);
  return (
    <React.Fragment>
      <div className={classes.PhoneViewDisable}>
        <Navbar />
        <div>
          <Container maxWidth="xl">
            <div className={classes.mainDiv}>
              <div className={classes.ProfilCard}>
                <ProfileCard />
                <SuggestionCard />
              </div>
              <div>
                <Paper elevation={1} className={classes.Paper}>
                  <div className={classes.flex}>
                    <Carousel
                      axis={'horizontal'}
                      showIndicators={false}
                      useKeyboardArrows={true}
                      onChange={(pageIndex, direction) => {
                        console.log(pageIndex);
                        var percent = pageIndex / newsinfo.length;
                        if (percent === 0.8) {
                          paginate();
                        }
                      }}
                      emulateTouch>
                      {newsinfo.map((item, index) => {
                        return (
                          <article key={index}>
                            <Typography
                              variant="h5"
                              component="h1"
                              gutterBottom
                              style={{
                                lineHeight: 1,
                                margin: '1rem',
                                fontWeight: 'bold',
                              }}>
                              {item.node.heading}
                            </Typography>
                            <div
                              style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                              }}>
                              <img
                                src={item.node.mediaUrl}
                                loading="lazy"
                                alt="mediaURL"
                                style={{
                                  width: '75%',
                                  height: '13rem',
                                  margin: '0.5rem 0',
                                }}
                              />
                            </div>
                            <Typography
                              variant="subtitle2"
                              style={{
                                lineHeight: 2,
                                textAlign: 'center',
                                margin: '2rem',
                                maxLines: 2,
                                overflow: 'break-word',
                                wordBreak: 'break-word',
                                wordWrap: 'break-word',
                                width: '26rem',
                              }}>
                              {item.node.summary.substring(0, 400)}...
                            </Typography>
                            <Link 
                              to={item.node.hostUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{margin: '1rem',}}>
                              Read more....
                            </Link>
                          </article>
                        );
                      })}
                    </Carousel>
                  </div>
                </Paper>
              </div>
              <div className={classes.News}>
                <LatestNews condition={handleLoadMore} />
              </div>
            </div>
          </Container>
        </div>
      </div>
      <div className={classes.PhoneViewEnable}>
        <PhoneView />
      </div>
    </React.Fragment>
  );
};
export default News;
