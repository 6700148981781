import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Typography, Avatar, Paper, TextField, Dialog} from '@material-ui/core';
import PhotoIcon from '@material-ui/icons/Photo';
import VideocamIcon from '@material-ui/icons/Videocam';
import AttachFileIcon from '@material-ui/icons/AttachFile';

import ListAltIcon from '@mui/icons-material/ListAlt';
import QueueIcon from '@material-ui/icons/Queue';
import CreatePost from './../addPost/CreatePost';
import {useCookies} from 'react-cookie';
import {useNavigate} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {margin: '1rem 0rem'},
  Paper: {
    width: '34rem',
    borderRadius: '0.8rem',
    [theme.breakpoints.down('sm')]: {
      width: '15rem',
    },
  },
  InputTextBackground: {
    width: '15rem',
    padding: '1rem',
  },
  button: {
    textTransform: 'capitalize',
    alignSelf: 'center',
    borderRadius: 15,
    margin: '0.5rem 1rem',
    color: theme.palette.primary.main,
  },
  PostDesBorderStyle: {flexDirection: 'row', display: 'flex', padding: '1rem'},
  userProfileStyle: {width: '2rem', height: '2rem', marginTop: '0.2rem'},
  TextFieldStyle: {
    borderWidth: '1rem',
    borderColor: theme.palette.color.black,
    width: '15rem',
    margin: '0.2rem 1rem',
  },
  IconTextColor: {
    color: theme.palette.color.black,
    margin: '0.15rem 0rem 0 0.5rem ',
  },
  ModalDivStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
}));

const AddPost = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [mediaType, setMediaType] = useState();
  const [mediaLink, setMediaLink] = useState();
  const [isPoll, setPoll] = useState(false);
  const [open, setOpen] = useState(false);
  const [isCrispy, setIsCrispy] = useState(false);
  const [cookies, setCookie] = useCookies(['user']);

  const changeModalVisibility = bool => {
    setOpen(bool);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const changePollType = bool => {
    setPoll(bool);
  };

  const changeCrispyType = bool => {
    setIsCrispy(bool);
  };

  useEffect(() => {
    if (mediaLink !== undefined && mediaType !== undefined) {
      setMediaType(undefined);
      setMediaLink(undefined);
    }
  }, [mediaLink, mediaType]);

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Paper elevation={1} className={classes.Paper}>
          <div
            onClick={() => changeModalVisibility(true)}
            className={classes.PostDesBorderStyle}>
            <div
              style={{cursor: 'pointer'}}
              onClick={() => {
                navigate('/profile', {state: cookies.user.userId});
              }}>
              <Avatar
                alt="Remy Sharp"
                src={cookies.user ? cookies.user.profileImageUrl : ''}
                className={classes.userProfileStyle}
              />
            </div>
            <div className={classes.TextFieldStyle}>
              <TextField
                autoFocus={false}
                placeholder="Type something to post.."
                InputProps={{disableUnderline: true}}
              />
            </div>
          </div>
          <div>
            <input
              accept="image/*"
              id="ImageAdd"
              type="file"
              style={{display: 'none'}}
              onChange={event => {
                changeModalVisibility(true);
                setMediaType('0');
                setMediaLink(event.target.files[0]);
                event.target.value = '';
              }}
            />
          </div>
          <div>
            <input
              type="file"
              accept=".mov,.mp4"
              id="VideoAddHome"
              style={{display: 'none'}}
              onChange={event => {
                changeModalVisibility(true);
                if (event.target.files[0].size > 10e6 * 3) {
                  alert('Video limit is 30mb');
                } else {
                  setMediaType('1');
                  setMediaLink(event.target.files[0]);
                }
                // console.log(event.target.files[0].size)
                event.target.value = '';
              }}
            />
          </div>
          <div>
            <input
              style={{display: 'none'}}
              type="file"
              accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
               text/plain, application/pdf,"
              id="DocumentAddHome"
              onChange={event => {
                const selectedFile = event.target.files[0];
                if (selectedFile) {
                  const fileExtension = selectedFile.type.split('.').pop();
                  console.log(`File extension: ${fileExtension}`);
                  setMediaType(selectedFile);
                  console.log(selectedFile);

                  changeModalVisibility(true);
                  setMediaType('2');
                  setMediaLink(event.target.files[0]);
                  event.target.value = '';
                }
              }}
            />
          </div>

          <div style={{textAlign: 'center', display: 'flex'}}>
            <label htmlFor="ImageAdd">
              <div
                style={{display: 'flex', margin: '0 1rem', cursor: 'pointer'}}>
                <PhotoIcon color="primary" />
                <Typography variant="body2" className={classes.IconTextColor}>
                  Photo
                </Typography>
              </div>
            </label>

            <label htmlFor="VideoAddHome">
              <div
                style={{display: 'flex', margin: '0 1rem', cursor: 'pointer'}}>
                <VideocamIcon color="primary" />
                <Typography variant="body2" className={classes.IconTextColor}>
                  Video
                </Typography>
              </div>
            </label>

            <label htmlFor="DocumentAddHome">
              <div
                style={{display: 'flex', margin: '0 1rem', cursor: 'pointer'}}>
                <AttachFileIcon color="primary" />
                <Typography variant="body2" className={classes.IconTextColor}>
                  Document
                </Typography>
              </div>
            </label>

            <label>
              <div
                onClick={() => {
                  changePollType(true);
                  changeModalVisibility(true);
                  setMediaType('3');
                }}
                style={{
                  display: 'flex',
                  margin: '0 1rem 0.2rem',
                  cursor: 'pointer',
                }}>
                <ListAltIcon style={{color: '00A3FF'}} />
                <Typography variant="body2" className={classes.IconTextColor}>
                  MCQ
                </Typography>
              </div>
            </label>
            <label>
              <div
                onClick={() => {
                  changeCrispyType(true);
                  changeModalVisibility(true);
                  setMediaType('4');
                }}
                style={{
                  display: 'flex',
                  margin: '0 1rem 0.2rem',
                  cursor: 'pointer',
                }}>
                <QueueIcon color="primary" />
                <Typography variant="body2" className={classes.IconTextColor}>
                  CrispyPost
                </Typography>
              </div>
            </label>
          </div>
        </Paper>

        <Dialog
          open={open}
          onClose={handleClose}
          // onClose={() => changeModalVisibility(false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description">
          <div className={classes.ModalDivStyle}>
            <CreatePost
              changeModalVisibility={changeModalVisibility}
              changePollType={changePollType}
              changeCrispyType={changeCrispyType}
              mediaType={mediaType}
              mediaLink={mediaLink}
              PollType={isPoll}
              CrispyType={isCrispy}
              close={handleClose}
            />
          </div>
        </Dialog>
      </div>
    </React.Fragment>
  );
};

export default AddPost;
