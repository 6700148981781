import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';
import IMAGE from '../../assets/login/IMAGE.svg';
import PhoneView from  "../../components/phoneView/PhoneView"
const useStyles = makeStyles(theme => ({
  left: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0rem 0rem 0rem 1.5rem',
    [theme.breakpoints.down('md')]: {
      margin: '0rem 0rem 0rem -1rem',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },  PhoneViewDisable: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  PhoneViewEnable: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

  logoHeading: {
    color: theme.palette.primary.main,
    margin: '1rem',
    marginTop: '10rem',
    fontWeight: 'bold',
  },
  logoSubHeading: {
    color: theme.palette.color.grey,
    textAlign:"center"
  },
  swipe: {
    paddingTop: '2rem',
    paddingBottom: '2rem',
    height: '10rem',
    width: '18rem',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '1rem',
      height: '15rem',
      width: '15rem',
    },
  },
}));

const Appinfo = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.left} id='first-auth-screen' >
        <Typography variant="h4" component="h4" className={classes.logoHeading} id='welcome'>
          Welcome To Sconti
        </Typography>
        <Typography
          variant="h6"
          component="h6"
          id='description'
          className={classes.logoSubHeading}>
          “To the Students, By the Students, <br></br>
           For the Students & Network of young minds & Professionals”
        </Typography>

        <img
        id='image'
          decode="async"
          alt="Login"
          longdesc="https://studentcontinent.com/"
          loading="eager"
          referrerPolicy="no-referrer"
          className={classes.swipe}
          src={IMAGE}
        />
      </div>
      <div className={classes.PhoneViewEnable} id='responsive'>
      <PhoneView />
    </div>
    </React.Fragment>
  );
};

export default Appinfo;
