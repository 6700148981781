import gql from 'graphql-tag';

export default gql`
  query mediasOfUser(
    $limit: Int
    $nextToken: String
    $userModelInputFilter: UserModelInputFilter
    $visitingUserId: String
  ) {
    mediasOfUser(
      limit: $limit
      nextToken: $nextToken
      userModelInputFilter: $userModelInputFilter
      visitingUserId: $visitingUserId
    ) @connection(key: "MediaDisplayModel") {
      edges {
        node {
          name
          url
          type
          thumbnailUrl
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
