import gql from 'graphql-tag';

export default gql`
  query userSuggestionsFromCollege(
    $limit: Int
    $nextToken: String
    $userId: String!
    ) {
      userSuggestionsFromCollege(
      limit: $limit
      nextToken: $nextToken
      userId: $userId
      )
      @connection(key: "UserProfileDisplayModel") {
      edges {
        node {
          name
          userId
          profileImageUrl

          userType
          college
          company

          amIFollowing
          interests 
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
