import gql from 'graphql-tag';

export default gql`
  mutation markPost(
    $postId: ID!,
    $userModelInputFilter: UserModelInputFilter!,
    $markAs: String!
  ) {
    markPost(
      postId: $postId
      userModelInputFilter: $userModelInputFilter
      markAs: $markAs
    )
  }
`;
