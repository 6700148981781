import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Container} from '@material-ui/core';
import Appinfo from './Appinfo';
import Form from './Form';
import UserData from './UserData';
import Interest from './Interest';
import {useCookies} from 'react-cookie';
import {useNavigate} from 'react-router-dom';
import PhoneView from  "../../components/phoneView/PhoneView"
// import Login from './Login';


const useStyles = makeStyles(theme => ({
  topImage: {
    width: '100%',
    height: window.innerHeight * 0.25,
    background: theme.palette.secondary.main,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  sectionContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginTop: '-6rem',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  PhoneViewDisable: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  PhoneViewEnable: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  Appinfo: {
    flex: '1',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  LoginDataEnterStyle: {
    flex: '1',
    [theme.breakpoints.down('md')]: {
      marginLeft: '10rem',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

const Auth = () => {
  const classes = useStyles();
  const [cookies, setCookie] = useCookies(['user']);
  const [screenTrack, setScreenTrack] = useState('login');
  const [screenUserData, setScreenUserData] = useState({});
  const [mobile, setMobile] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (cookies.user) {
      console.log('cookie user', cookies);
      navigate('/home');
    } else {
      console.log('cookie user', cookies);

      console.log('no');
    }
  }, []);
  return (
    <React.Fragment>
      <div className={classes.topImage} id='auth-page'/>
      <Container maxWidth="lg" id="container">
        <div className={classes.sectionContainer} id='section-container'>
          <div className={classes.Appinfo} id='scont-appInfo'>
            <Appinfo />
          </div>

          {screenTrack === 'login' ? (
            <div className={classes.LoginDataEnterStyle} id='loginData'>
              <Form setScreenTrack={setScreenTrack} setuserData={setScreenUserData} setPhoneNumber={setMobile} />
            </div>
          ) : screenTrack === 'nameform' ? (
            <div className={classes.UserData} id='userData'>
              <UserData
                setScreenTrack={setScreenTrack}
                setuserData={setScreenUserData}
                phoneNumber={mobile}
              />
            </div>
          ) : (
            <div id='intrests'>
              <Interest
                setScreenTrack={setScreenTrack}
                userData={screenUserData}
                setuserData={setScreenUserData}
              />
            </div>
          )}
          {/* <Login/> */}
        </div>
      </Container>
      <div className={classes.PhoneViewEnable} id='response'>
      <PhoneView />
    </div>
    </React.Fragment>
  );
};

export default Auth;
