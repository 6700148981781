import React from 'react';
import {Typography, Button, Paper, Grid, Avatar} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import RuleIcon from '@mui/icons-material/Rule';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import {PieChart} from '@mui/x-charts/PieChart';

const useStyles = makeStyles(theme => ({
  left: {
    width: '40vw',
    height: '100vh',
  },
  right: {
    width: '60vw',
    height: '100vh',
  },

  paper: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  main: {
    marginTop: '5rem',
    display: 'flex',
    flexDirection: 'row',
    gap: '2rem',
  },
  cardLeft: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
    justifyContent: 'flex-start',
    margin: '1rem',
  },
  next: {
    width: '10rem',
    color: 'white',
    left: '30rem',
    textTransform: 'capitalize',
    borderRadius: '1rem',
  },
  radio: {
    left: '35rem',
  },
  formControl: {
    border: '0.1rem solid #00a3ff',
    borderRadius: '1rem',
  },
  mainGrid: {
    width: '20vw',
    display: 'grid',
    gridTemplateColumns: 'auto auto auto auto auto auto',
    gridGap: '2rem',
    margin: '1rem',
  },
  results: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: '0.5rem',
  },
  avg: {
    border: '0.1rem solid #00a3ff',
    borderRadius: '1rem',
    marginLeft: '1rem',
    marginRight: '1rem',
    top: '1rem',
    color: 'white',
    textAlign: 'center',
    height: '2rem',
    backgroundColor: '#00a3ff',
    padding: '0.3rem',
    fontSize: '1rem',
  },
  yours: {
    border: '0.1rem solid #ff9d00',
    borderRadius: '1rem',
    marginLeft: '1rem',
    marginRight: '1rem',
    top: '1rem',
    color: 'white',
    textAlign: 'center',
    backgroundColor: '#ff9d00',
    height: '2rem',
    padding: '0.3rem',
    fontSize: '1rem',
  },
  done: {
    borderRadius: '1rem',
    marginLeft: '1rem',
    marginRight: '1rem',
    top: '1rem',
    color: 'white',
    width: '30rem',
  },
  response: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: '0.5rem',
  },
}));

const palette = ['#ff9d00', '#00a3ff', 'black'];

const ViewResults = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.main}>
        <Paper className={classes.left}>
          <div className={classes.root}>
            <Grid container spacing={2}>
              <Grid item className={classes.mainGrid}>
                <Avatar>1</Avatar>
                <Avatar>2</Avatar>
                <Avatar>3</Avatar>
                <Avatar>4</Avatar>
                <Avatar>5</Avatar>
                <Avatar>6</Avatar>
                <Avatar>7</Avatar>
                <Avatar>8</Avatar>
                <Avatar>9</Avatar>
                <Avatar>10</Avatar>
                <Avatar>11</Avatar>
                <Avatar>12</Avatar>
                <Avatar>13</Avatar>
                <Avatar>14</Avatar>
                <Avatar>15</Avatar>
              </Grid>
            </Grid>
          </div>
          <Typography style={{marginLeft: '5rem'}}>
            <span role="img">🟠-Skipped</span>
            <span role="img">🔵-Attend</span>
            <span role="img">⚫-Attended</span>
          </Typography>
          <Typography style={{marginLeft: '5rem'}}>
            Result will be updated soon after the quiz ends...
          </Typography>
          <div className={classes.results}>
            <RuleIcon />
            <Button>Click here to see response with answers</Button>
          </div>
          <div className={classes.response}>
            <AnalyticsIcon />
            <Button>Check your statistical results</Button>
          </div>
          <div>
            <Typography style={{marginLeft: '5rem', top: '1rem'}}>
              Average Score
            </Typography>
            <div className={classes.avg}>40%</div>
            <Typography style={{marginLeft: '5rem', top: '1rem'}}>
              Your Score
            </Typography>
            <div className={classes.yours}>40%</div>
          </div>
          <div>
            <Button
              variant="contained"
              color="primary"
              className={classes.done}>
              Done
            </Button>
          </div>
        </Paper>
        <Paper elevation={2} className={classes.right}>
          <Typography
            variant="h6"
            style={{
              margin: '2rem',
            }}>
            Your Quiz report will be
          </Typography>
          <PieChart
            colors={palette}
            series={[
              {
                data: [
                  {id: 0, value: 10, label: 'Skipped '},
                  {id: 1, value: 15, label: ' Attended '},
                  {id: 2, value: 20, label: 'Not Attended'},
                ],
              },
            ]}
            width={400}
            height={200}
          />
          <Typography
            variant="h6"
            style={{
              margin: '2rem',
            }}>
            No report to show
          </Typography>
          <Typography variant="h6">Quiz response</Typography>
          <div>
            <Typography variant="body1">Question Statment</Typography>
            <div>
              <ol>
                <li>song</li>
                <li>dance</li>
                <li>atrs</li>
                <li>nodi swamy </li>
              </ol>
            </div>
            <Typography variant="body1">Correct answer:2</Typography>
          </div>
        </Paper>
      </div>
    </React.Fragment>
  );
};

export default ViewResults;
