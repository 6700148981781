import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
  Typography,
  Avatar,
  Button,
  TextField,
  MenuItem,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import ShowColleges from '../../queries/ShowColleges';
import ShowDegree from '../../queries/ShowDegree';
import ShowStates from '../../queries/ShowStates';
import ChipChange from '../Auth/ChipChange';
import ShowInterests from '../../queries/ShowInterests';
import UpdateProfile from '../../queries/UpdateProfile';
import ShowDistricts from '../../queries/ShowDistricts';
import UserDetails from '../../queries/UserDetails';
import {useCookies} from 'react-cookie';
import {uploadFile} from 'react-s3';
import {db} from '../Auth/firebase';
import {useLocation, useNavigate} from 'react-router-dom';

import {
  useQuery,
  useMutation,
  useLazyQuery,
  useApolloClient,
} from '@apollo/client';
import {urlvalidation} from '../../Regex';

const useStyles = makeStyles(theme => ({
  UserProfileStyle: {
    width: '3rem',
    height: '3rem',
  },
  resize: {
    padding: '0.3rem 0.5rem',
    border: `0.1rem solid ${theme.palette.color.grey2}`,
    borderRadius: '0.3rem',
    width: '20rem',
  },
  AppInfo: {
    border: `0.1rem solid ${theme.palette.color.AppInfoColore}`,
    padding: '1rem',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  SubmitButton: {
    height: '2rem',
    borderRadius: '1rem',
    textTransform: 'capitalize',
    color: theme.palette.color.white,
    padding: '0 1.5rem',
    marginRight: '1rem',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  displayFlex: {display: 'flex'},
  width75: {width: '8rem'},
  ChangeProfileButtonStyle: {
    fontWeight: 'bold',
    fontSize: 12,
    color: theme.palette.primary.main,
    textTransform: 'capitalize',
    padding: '0',
  },
  DataEnterBorder: {display: 'flex', marginTop: '1rem'},
  width10: {width: '10rem', marginTop: '0.6rem'},
  CheckBoxIconstyle: {padding: '0 0.5rem'},
  HelperTextStyle: {
    fontSize: '0.70rem',
    color: theme.palette.color.HelperTextColore,
    margin: '0.3rem 1rem 0 0',
  },
  DropDownBorderStyle: {marginRight: '1.5rem'},
  SubmitButtonBorder: {textAlign: 'center', marginTop: ' 2rem'},
  fontsize12: {fontSize: 12},
  ChangeprofilephotoButton: {
    [theme.breakpoints.down('md')]: {
      marginLeft: '2.5rem',
    },
  },
  input: {
    display: 'none',
  },
  avatarBadge: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  typeIcon: {
    color: 'black',
  },
  changeChip: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',

    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  SelectIcon: {marginRight: '0.5rem'},
  Selectresize: {
    padding: '0.5rem',
    border: `0.1rem solid ${theme.palette.color.grey2}`,
    borderRadius: '0.3rem',
    //   width: '25rem',
  },
  paperChip: {
    width: '25rem',
    height: '15rem',
    overflowY: 'auto',
    borderRadius: '1rem',
    margin: '1rem 0rem',
    display: 'flex',

    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

const EditProfile = () => {
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(['user']);
  const [userDetailsData, setuserDetailsData] = useState({});
  const [interests, setinterests] = useState('');
  const [name, setName] = useState([]);
  const [bio, setBio] = useState([]);
  const [userType, setUserType] = useState('Student');
  const [degreedata, setDegreedata] = useState([]);
  const [selectedDegree, setSelectedDegree] = useState([]);
  const [collegedata, setcollegedata] = useState([]);
  const [selectedCollege, setSelectedCollege] = useState([]);
  const [statedata, setstatedata] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const [districtData, setDisTrictData] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState([]);
  const [interestData, setinterestData] = useState([]);
  const [selectedInterestData, setSelectedIntrestData] = useState([]);
  const [company, setCompanyName] = useState([]);
  const [linkedIn, setLinkedIn] = useState([]);
  const [gitHub, setGitHub] = useState([]);
  const [instagram, setInstagram] = useState([]);
  const [youTube, setYouTube] = useState([]);
  const [islinkedIn, setisLinkedIn] = useState([]);
  const [isgitHub, setisGitHub] = useState([]);
  const [isinstagram, setisInstagram] = useState([]);
  const [isyouTube, setisYouTube] = useState([]);
  const [faceBook, setFaceBook] = useState([]);
  const [mediaType, setMediaType] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedBannerImage, setSelectedBannerImage] = useState(null);
  const pathname = useLocation();
  // aws confing
  const [awsdata, setawsdata] = useState({});
  const [config, setconfig] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [filteredstate, setfilteredstate] = useState([]);
  const [statehideresults, setstatehideresults] = useState(true);
  const [districthideresults, setdistricthideresults] = useState(true);
  const [collegehideresults, setcollegehideresults] = useState(true);

  const [filtereddistrict, setfiltereddistrict] = useState([]);

  const [filtereddegree, setfiltereddegree] = useState([]);
  const [degreehideresults, setdegreehideresults] = useState(true);
  useEffect(() => {
    db.collection('details')
      .doc('AWSconstants')
      .get()
      .then(snap => {
        setawsdata(snap.data());
        setconfig({
          bucketName: snap.data().bucket,
          region: snap.data().region,
          accessKeyId: snap.data().accessKey,
          secretAccessKey: snap.data().secretKey,
        });
      })
      .catch(err => {
        console.log(err);
      });
  }, []);
  const filterdata = query => {
    if (query !== '') {
      setstatehideresults(false);
    } else if (query.length > 0) {
      setstatehideresults(true);
    }
    setSelectedState(query);
    setfilteredstate(
      statedata.filter(item => {
        return item.stateName.toLowerCase().includes(query.toLowerCase());
      }),
    );
  };
  const filterdegreedata = query => {
    if (query !== '') {
      setdegreehideresults(false);
    } else if (query.length > 0) {
      setdegreehideresults(true);
    }
    setSelectedDegree(query);
    setfiltereddegree(
      degreedata.filter(item => {
        return item.courseName.toLowerCase().includes(query.toLowerCase());
      }),
    );
  };
  const handleFileInput = e => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUpload = async (file, type) => {
    uploadFile(file, config)
      .then(data => {
        console.log('uploaded');
        var mediaarr = [];
        var obj = {
          url: data.location,
          type: mediaType,
          name: '',
        };
        if (type === 'profile') {
          setSelectedImage(data.location);
        } else {
          setSelectedBannerImage(data.location);
        }
      })
      .catch(err => console.error(err));
  };

  // aws config ends
  console.log(mediaType);

  const defaultProps1 = {
    options: degreedata,
    getOptionLabel: option => option.courseName,
  };
  const defaultProps2 = {
    options: collegedata,
    getOptionLabel: option => option.collegeName,
  };
  const defaultProps3 = {
    options: statedata,
    getOptionLabel: option => option.stateName,
  };
  const defaultProps4 = {
    options: districtData,
    getOptionLabel: option => option.districtName,
  };
  const userTypes = [
    {
      value: 'Student',
    },
    {
      value: 'Professional',
    },
  ];
  const handleChangeUserType = event => {
    setUserType(event.target.value);
  };

  const clients = useApolloClient();

  useQuery(
    UserDetails,
    {
      variables: {
        id: cookies.user ? cookies.user.userId : '',
        visitType: 'self',
        visitingUserId: cookies.user ? cookies.user.userId : '',
      },
      onCompleted: res => {
        var str = '';
        console.log('UserDetails', res);
        var locationdata = res.getUserDetails.state.split(',');
        console.log('splitt', locationdata + ',' + locationdata.length);
        if (locationdata.length > 1) {
          setSelectedDistrict(locationdata[0]);
          setSelectedState(locationdata[1]);
          // setSelectedState(locationdata[1]);
        } else {
          setSelectedState(res.getUserDetails.state);
          // setSelectedState(props.data.state);
        }
        setuserDetailsData(res.getUserDetails ? res.getUserDetails : {});
        setName(res.getUserDetails ? res.getUserDetails.name : '');
        setBio(res.getUserDetails ? res.getUserDetails.description : '');
        // setSelectedState(res.getUserDetails ? res.getUserDetails.state : '');
        setCompanyName(res.getUserDetails ? res.getUserDetails.company : '');
        setSelectedCollege(
          res.getUserDetails ? res.getUserDetails.college : '',
        );
        setSelectedDegree(res.getUserDetails ? res.getUserDetails.degree : '');
        // setSelectedDistrict(res.getUserDetails?res.getUserDetails.:""),
        setSelectedIntrestData(
          res.getUserDetails ? res.getUserDetails.interests : '',
        );
        setUserType(res.getUserDetails ? res.getUserDetails.userType : '');
        setGitHub(res.getUserDetails ? res.getUserDetails.gitHubUrl : '');
        setLinkedIn(res.getUserDetails ? res.getUserDetails.linkedInUrl : '');
        setInstagram(res.getUserDetails ? res.getUserDetails.instagramUrl : '');
        setYouTube(res.getUserDetails ? res.getUserDetails.faceBookUrl : '');
        setSelectedImage(
          res.getUserDetails ? res.getUserDetails.profileImageUrl : '',
        );
        setSelectedBannerImage(
          res.getUserDetails ? res.getUserDetails.bannerImageUrl : '',
        );

        if (res.getUserDetails && res.getUserDetails.interests) {
          var objarr = [];
          res.getUserDetails.interests.map((item, index) => {
            objarr.push(item);
            str = str + item + ' , ';
          });
          setinterests(str.substring(0, str.length - 2));
          setSelectedIntrestData(objarr);
        }
      },
      onError: err => {
        console.log(err);
      },
    },
    [],
  );
  const nameinputvalidation = text => {
    var regexforspecialcharacters = /^[^a-zA-Z]+$/;
    var specialcharacters = regexforspecialcharacters.test(text);
    if (specialcharacters) {
      alert('Invalid input. Alphanumeric characters expected');
      return false;
    } else {
      return true;
    }
  };
  const searchCollegeData = text => {
    if (text.length >= 3) {
      // sethideresults(false);
      clients
        .query({
          query: ShowColleges,
          variables: {collegeName: text},
        })
        .then(res => {
          console.log(res);
          setcollegedata(res.data.getAllColleges);
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      setcollegedata([]);
      // sethideresults(true);
    }
  };

  useQuery(
    ShowDegree,

    {
      variables: {
        courseName: '',
      },
      onCompleted: res => {
        console.log('getAllStates', res);
        setDegreedata(res.getAllDegrees ? res.getAllDegrees : []);
      },
      onError: err => {
        console.log(err);
      },
    },
    [],
  );

  useQuery(
    ShowStates,
    {
      onCompleted: res => {
        console.log('getAllStates', res);
        setstatedata(res.getAllStates ? res.getAllStates : []);
      },
      onError: err => {
        console.log(err);
      },
    },
    [],
  );
  const [getDistrictsByState] = useLazyQuery(
    ShowDistricts,
    {
      variables: {stateName: selectedState},
      onCompleted: res => {
        console.log('getDistrictsByState', res);
        setDisTrictData(res.getDistrictsByState ? res.getDistrictsByState : []);
      },
      onError: err => {
        console.log(err);
      },
    },
    [],
  );
  useQuery(
    ShowInterests,
    {
      onCompleted: res => {
        console.log('getAllInterests', res);

        setinterestData(
          res.fetchPrimaryInterests ? res.fetchPrimaryInterests : [],
        );
      },
      onError: err => {
        console.log(err);
      },
    },
    [],
  );

  const [updateProfileMutation] = useMutation(UpdateProfile);
  const handleUpdateProfile = () => {
    updateProfileMutation({
      variables: {
        userId: cookies.user.userId,
        name: name,
        profileImageUrl: selectedImage,
        bannerImageUrl: selectedBannerImage,
        aboutMe: bio,
        state: selectedState,
        degree: selectedDegree,
        college: selectedCollege,
        interests: selectedInterestData,
        userType: userType,
        company: company,
        gitHubUrl: gitHub,
        linkedInUrl: linkedIn,
        instagramUrl: instagram,
        faceBookUrl: youTube,
      },
    })
      .then(res => {
        console.log(res);
        setCookie('user', res.data.updateUser, {path: '/'});
        alert('updated successfully');
      })
      .catch(err => {
        console.log(err);
        alert('what went wrong');
      });
    nameinputvalidation();
  };
  useEffect(() => {
    if (mediaType === '0') {
      setMediaType('image');
    }
    if (mediaType === '1') {
      setMediaType('bannerImage');
    }
  }, [mediaType]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  useEffect(() => {
    !cookies.user ? navigate('/auth') : navigate('/setting');
    // console.log('cookie user', cookies);
  }, []);
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.displayFlex}>
        <div>
          <div className={classes.displayFlex}>
            <div className={classes.width75}>
              <Avatar
                alt="UserImage"
                src={cookies.user ? cookies.user.profileImageUrl : ''}
                className={classes.UserProfileStyle}
              />
            </div>

            <div className={classes.ChangeprofilephotoButton}>
              <Typography variant="subtitle1" style={{fontWeight: 'bold',marginLeft:"2rem"}}>
                {cookies.user ? cookies.user.name : ''}
              </Typography>

              {/**profile image starts here */}
              <div className={classes.root}>
                {selectedImage && (
                  <div style={{margin: '0 1rem'}}>
                    <Avatar
                      alt="not found"
                      width="100px"
                      height="100px"
                      style={{borderRadius: '50%'}}
                      src={selectedImage}
                    />
                  </div>
                )}
                <input
                  accept="image/*"
                  className={classes.input}
                  id="contained-button-file"
                  type="file"
                  onChange={event => {
                    setSelectedImage(
                      URL.createObjectURL(event.target.files[0]),
                    );
                    setMediaType('0');
                    handleUpload(event.target.files[0], 'profile');
                  }}
                />
                <label htmlFor="contained-button-file">
                  <Button
                    component="span"
                    style={{textTransform: 'capitalize'}}>
                    Change profile photo
                  </Button>
                </label>
              </div>
              {/**profile image end */}
              {/**banner image starts here */}
              <div className={classes.root}>
                {selectedBannerImage && (
                  <div style={{margin: '0 1rem'}}>
                    <img
                      alt="not found"
                      width={200}
                      src={selectedBannerImage}></img>
                  </div>
                )}
                <input
                  accept="image/*"
                  className={classes.input}
                  id="contained-button-file-banner"
                  key="banner"
                  type="file"
                  onChange={event => {
                    setSelectedBannerImage(
                      URL.createObjectURL(event.target.files[0]),
                    );
                    // setMediaType('1');
                    handleUpload(event.target.files[0], 'banner');
                  }}
                />
                <label htmlFor="contained-button-file-banner">
                  <Button
                    component="span"
                    style={{textTransform: 'capitalize'}}>
                    Change Banner Photo
                  </Button>
                </label>
              </div>
              {/**banner image ends here */}
            </div>
          </div>

          <div className={classes.DataEnterBorder}>
            <div className={classes.width10}>
              <Typography variant="subtitle1">Name</Typography>
            </div>

            <div>
              <TextField
                onChange={e => {
                  setName(e.target.value);
                }}
                variant="outlined"
                size="small"
                style={{width: '30rem'}}
                placeholder="User Name"
                inputprops={{
                  disableUnderline: true,
                  classes: {
                    input: classes.resize,
                  },
                }}
                value={name}
              />
              <Typography
                variant="subtitle1"
                className={classes.HelperTextStyle}>
                Help people discover your account by using the name that you're
                known by: either your full name
              </Typography>
            </div>
          </div>

          <div className={classes.DataEnterBorder}>
            <div className={classes.width10}>
              <Typography variant="subtitle1">Account</Typography>
            </div>

            <div className={classes.DropDownBorderStyle}>
              <TextField
                variant="outlined"
                size="small"
                select
                style={{width: '30rem'}}
                SelectProps={{
                  classes: {icon: classes.SelectIcon},
                }}
                value={userType}
                onChange={handleChangeUserType}
                inputprops={{
                  disableUnderline: true,
                  classes: {
                    input: classes.Selectresize,
                  },
                }}>
                {userTypes.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>

              <Typography
                variant="subtitle1"
                className={classes.HelperTextStyle}>
                Help people discover your account by the account type
              </Typography>
            </div>
          </div>

          <div className={classes.DataEnterBorder}>
            <div className={classes.width10}>
              <Typography variant="subtitle1">Degree</Typography>
            </div>

            <div className={classes.DropDownBorderStyle}>
              {/* <Autocomplete
                {...defaultProps1}
                disableClearable={false}
                id="auto-completeForDegree"
                autoComplete
                getOptionSelected={(option, value) =>
                  option.courseName === value.courseName
                }
                inputValue={selectedDegree}
                fullWidth
                onChange={(e, v) => {
                  setSelectedDegree(v.courseName.toLowerCase());
                }}
                includeInputInList
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    label="degree"
                    fullWidth
                    value={degreedata}
                    margin="normal"
                    onChange={event => {
                      // setDegreedata(event.target.value);
                    }}
                  />
                )}
              /> */}
              <TextField
                variant="outlined"
                size="small"
                label="Degree"
                // fullWidth
                style={{width: '30rem'}}
                value={selectedDegree}
                margin="normal"
                onChange={event => {
                  filterdegreedata(event.target.value);
                  // setDegreedata(event.target.value);
                }}
              />

              {degreehideresults ? (
                <div />
              ) : (
                <div
                  style={{
                    cursor: 'pointer',
                    height: '10rem',
                    overflowY: 'scroll',
                  }}>
                  {(filtereddegree && filtereddegree.length > 0
                    ? filtereddegree
                    : degreedata
                  ).map((item, index) => {
                    return (
                      <div
                        onClick={() => {
                          setdegreehideresults(true);
                          setSelectedDegree(item.courseName);
                        }}>
                        {item.courseName}
                      </div>
                    );
                  })}
                </div>
              )}

              <Typography
                variant="subtitle1"
                className={classes.HelperTextStyle}>
                Help people discover your account by using the name that you're
                known by: either your degree
              </Typography>
            </div>
          </div>

          <div className={classes.DataEnterBorder}>
            <div className={classes.width10}>
              <Typography variant="subtitle1">State</Typography>
            </div>

            <div className={classes.DropDownBorderStyle}>
              <TextField
                variant="outlined"
                size="small"
                label="state"
                // fullWidth
                style={{width: '30rem'}}
                value={selectedState}
                margin="normal"
                onChange={event => {
                  filterdata(event.target.value);
                  // setDegreedata(event.target.value);
                }}
              />

              {statehideresults ? (
                <div />
              ) : (
                <div
                  style={{
                    cursor: 'pointer',
                    height: '10rem',
                    overflowY: 'scroll',
                  }}>
                  {(filteredstate && filteredstate.length > 0
                    ? filteredstate
                    : statedata
                  ).map((item, index) => {
                    return (
                      <div
                        onClick={() => {
                          setstatehideresults(true);
                          setSelectedState(item.stateName);
                          getDistrictsByState({
                            variables: {
                              stateName: item.stateName,
                            },
                          });
                        }}>
                        {item.stateName}
                      </div>
                    );
                  })}
                </div>
              )}

              {/* <Autocomplete
                {...defaultProps3}
                id="auto-completeForState"
                autoComplete
                size="small"
                inputValue={selectedState}
                value={selectedState}
                getOptionSelected={(option, value) =>
                  option.stateName === value.stateName
                }
                onChange={(e, v) => {
                  setSelectedState(v.stateName);
                  getDistrictsByState({
                    variables: {
                      stateName: v.stateName,
                    },
                  });
                }}
                fullWidth
                // includeInputInList
                renderInput={params => (
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    {...params}
                    label="state"
                    margin="normal"
                    // onChange={event => {
                    //   setstatedata(event.target.value);
                    // }}
                  />
                )}
              /> */}

              <Typography
                variant="subtitle1"
                className={classes.HelperTextStyle}>
                Help people discover your account by using the name that you're
                known by: either your State name
              </Typography>
            </div>
          </div>
          <div className={classes.DataEnterBorder}>
            <div className={classes.width10}>
              <Typography variant="subtitle1">City</Typography>
            </div>

            <div className={classes.DropDownBorderStyle}>
              {/* <Autocomplete
                {...defaultProps4}
                id="auto-complete"
                autoComplete
                fullWidth
                size="small"
                inputValue={selectedDistrict}
                onChange={(e, v) => {
                  setSelectedDistrict(v.districtName.toLowerCase());
                }}
                getOptionSelected={(option, value) =>
                  option.districtName === value.districtName
                }
                includeinputinlistinputprops={{
                  disableUnderline: true,
                  classes: {
                    input: classes.resize,
                  },
                }}
                renderInput={params => (
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    {...params}
                    label="city"
                    value={districtData}
                    margin="normal"
                    onChange={event => {
                      // setDisTrictData(event.target.value);
                    }}
                  />
                )}
              /> */}
              <TextField
                variant="outlined"
                size="small"
                label="City"
                // fullWidth
                style={{width: '30rem'}}
                value={selectedDistrict}
                // margin="normal"
                onChange={event => {
                  setSelectedDistrict(event.target.value);
                  if (event.target.value && event.target.value !== '') {
                    setdistricthideresults(false);
                  } else {
                    setdistricthideresults(true);
                  }

                  setfiltereddistrict(
                    districtData.filter(item => {
                      return item.districtName
                        .toLowerCase()
                        .includes(event.target.value.toLowerCase());
                    }),
                  );
                }}
              />

              {districthideresults ? (
                <div />
              ) : (
                <div
                  style={{
                    cursor: 'pointer',
                    height: '10rem',
                    overflowY: 'scroll',
                  }}>
                  {(filtereddistrict && filtereddistrict.length > 0
                    ? filtereddistrict
                    : districtData
                  ).map((item, index) => {
                    return (
                      <div
                        onClick={() => {
                          setSelectedDistrict(item.districtName);
                          setdistricthideresults(true);
                        }}>
                        {item.districtName}
                      </div>
                    );
                  })}
                </div>
              )}
              <Typography
                variant="subtitle1"
                className={classes.HelperTextStyle}>
                Help people discover your account by using the name that you're
                known by: either your City name
              </Typography>
            </div>
          </div>

          <div className={classes.DataEnterBorder}>
            <div className={classes.width10}>
              <Typography variant="subtitle1">Bio</Typography>
            </div>

            <div>
              <TextField
                placeholder="Enter About Yourself"
                variant="outlined"
                size="small"
                // fullWidth
                style={{width: '30rem'}}
                multiline
                value={bio}
                maxRows={5}
                onChange={e => {
                  setBio(e.target.value);
                }}
                inputprops={{
                  disableUnderline: true,
                  classes: {
                    input: classes.resize,
                  },
                }}
              />
              <Typography
                variant="subtitle1"
                className={classes.HelperTextStyle}>
                Help people discover your account by using the name that you're
                known by: either your Bio
              </Typography>
            </div>
          </div>

          <div className={classes.DataEnterBorder}>
            <div className={classes.width10}>
              <Typography variant="subtitle1">College</Typography>
            </div>

            <div>
              <TextField
                variant="outlined"
                size="small"
                label="College"
                multiline
                style={{width: '30rem'}}
                value={selectedCollege}
                margin="normal"
                onChange={event => {
                  setSelectedCollege(event.target.value);
                  if (event.target.value && event.target.value !== '') {
                    searchCollegeData(event.target.value);
                    setcollegehideresults(false);
                  } else {
                    setcollegehideresults(true);
                  }

                  // setfiltereddistrict(
                  //   districtData.filter(item => {
                  //     return item.districtName
                  //       .toLowerCase()
                  //       .includes(event.target.value.toLowerCase());
                  //   }),
                  // );
                }}
              />

              {collegehideresults ? (
                <div />
              ) : (
                <div
                  style={{
                    cursor: 'pointer',
                    height: '10rem',
                    overflowY: 'scroll',
                  }}>
                  {collegedata.map((item, index) => {
                    return (
                      <div
                        onClick={() => {
                          setSelectedCollege(item.collegeName);
                          setcollegehideresults(true);
                        }}>
                        {item.collegeName}
                      </div>
                    );
                  })}
                </div>
              )}
              {/* <Autocomplete
                {...defaultProps2}
                id="auto-completeForcollege"
                autoComplete
                fullWidth
                disableClearable={false}
                getOptionSelected={(option, value) =>
                  option.collegeName === value.collegeName
                }
                size="small"
                includeInputInList
                onChange={(e, v) => {
                  setSelectedCollege(v.collegeName);
                }}
                inputValue={selectedCollege}
                inputprops={{
                  disableUnderline: true,
                  classes: {
                    input: classes.resize,
                  },
                }}
                renderInput={params => (
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    {...params}
                    label="college"
                    margin="normal"
                    in={collegedata}
                    onChange={event => {
                      searchCollegeData(event.target.value);
                    }}
                  />
                )}
              /> */}

              <Typography
                variant="subtitle1"
                className={classes.HelperTextStyle}>
                Help people discover your account by using the name that you're
                known by: either your College
              </Typography>
            </div>
          </div>

          {userType === 'Student' ? (
            <div />
          ) : (
            <div className={classes.DataEnterBorder}>
              <div className={classes.width10}>
                <Typography variant="subtitle1">Organization</Typography>
              </div>

              <div>
                <TextField
                  placeholder="Enter your Organization name"
                  variant="outlined"
                  size="small"
                  style={{width: '30rem'}}
                  onChange={e => {
                    setCompanyName(e.target.value);
                  }}
                  value={company}
                  inputprops={{
                    disableUnderline: true,
                    classes: {
                      input: classes.resize,
                    },
                  }}
                />
                <Typography
                  variant="subtitle1"
                  className={classes.HelperTextStyle}>
                  Help people discover your account by using the name that
                  you're known by: either your Organization name
                </Typography>
              </div>
            </div>
          )}
          <div className={classes.DataEnterBorder}>
            <div className={classes.width10}>
              <Typography variant="subtitle1">linkedIn</Typography>
            </div>

            <div>
              <TextField
                placeholder="Enter your linkedIn Url"
                onChange={e => {
                  setLinkedIn(e.target.value);
                  setisLinkedIn(urlvalidation(e.target.value));
                }}
                style={{width: '30rem'}}
                variant="outlined"
                size="small"
                value={linkedIn}
                inputprops={{
                  disableUnderline: true,
                  classes: {
                    input: classes.resize,
                  },
                }}
              />
              {!islinkedIn && (
                <Typography
                  variant="subtitle1"
                  style={{color: '#ff0000'}}
                  className={classes.HelperTextStyle}>
                  Invalid Url
                </Typography>
              )}
              <Typography
                variant="subtitle1"
                className={classes.HelperTextStyle}>
                Help people discover your account by using the name that you're
                known by: either your linkedIn Profile
              </Typography>
            </div>
          </div>
          <div className={classes.DataEnterBorder}>
            <div className={classes.width10}>
              <Typography variant="subtitle1">GitHub</Typography>
            </div>

            <div>
              <TextField
                placeholder="Enter your GitHub Url"
                onChange={e => {
                  setGitHub(e.target.value);
                  setisGitHub(urlvalidation(e.target.value));
                }}
                style={{width: '30rem'}}
                variant="outlined"
                size="small"
                value={gitHub}
                inputprops={{
                  disableUnderline: true,
                  classes: {
                    input: classes.resize,
                  },
                }}
              />
              {!isgitHub && (
                <Typography
                  variant="subtitle1"
                  style={{color: '#ff0000'}}
                  className={classes.HelperTextStyle}>
                  Invalid Url
                </Typography>
              )}
              <Typography
                variant="subtitle1"
                className={classes.HelperTextStyle}>
                Help people discover your account by using the name that you're
                known by: either your GitHub Profile
              </Typography>
            </div>
          </div>
          <div className={classes.DataEnterBorder}>
            <div className={classes.width10}>
              <Typography variant="subtitle1">Instagram</Typography>
            </div>

            <div>
              <TextField
                placeholder="Enter your Instagram Url"
                onChange={e => {
                  setInstagram(e.target.value);
                  setisInstagram(urlvalidation(e.target.value));
                }}
                variant="outlined"
                size="small"
                style={{width: '30rem'}}
                value={instagram}
                inputprops={{
                  disableUnderline: true,
                  classes: {
                    input: classes.resize,
                  },
                }}
              />
              {!isinstagram && (
                <Typography
                  variant="subtitle1"
                  style={{color: '#ff0000'}}
                  className={classes.HelperTextStyle}>
                  Invalid Url
                </Typography>
              )}
              <Typography
                variant="subtitle1"
                className={classes.HelperTextStyle}>
                Help people discover your account by using the name that you're
                known by: either your Instagram Profile
              </Typography>
            </div>
          </div>
          <div className={classes.DataEnterBorder}>
            <div className={classes.width10}>
              <Typography variant="subtitle1">FaceBook</Typography>
            </div>

            <div>
              <TextField
                placeholder="Enter your FaceBook Url"
                onChange={e => {
                  setFaceBook(e.target.value);
                  setisYouTube(urlvalidation(e.target.value));
                }}
                variant="outlined"
                size="small"
                style={{width: '30rem'}}
                value={faceBook}
                inputprops={{
                  disableUnderline: true,
                  classes: {
                    input: classes.resize,
                  },
                }}
              />{' '}
              {!isyouTube && (
                <Typography
                  variant="subtitle1"
                  style={{color: '#ff0000'}}
                  className={classes.HelperTextStyle}>
                  Invalid Url
                </Typography>
              )}
              <Typography
                variant="subtitle1"
                className={classes.HelperTextStyle}>
                Help people discover your account by using the name that you're
                known by: either your youtube Profile
              </Typography>
            </div>
          </div>
          <div className={classes.DataEnterBorder}>
            <div className={classes.width10}>
              <Typography variant="subtitle1">Interest</Typography>
            </div>

            <div className={classes.changeChip}>
              <Paper elevation={0} className={classes.paperChip}>
                {interestData.map((item, key) => {
                  return (
                    <div key={key}>
                      <ChipChange
                        item={item}
                        setChipData={setSelectedIntrestData}
                        chipData={selectedInterestData}
                      />
                    </div>
                  );
                })}
              </Paper>
            </div>
          </div>

          <div className={classes.SubmitButtonBorder}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                if (!isgitHub || !isinstagram || !isyouTube || !islinkedIn) {
                  alert('enter valid url');
                } else if (name.length === 0) {
                  alert('name should not be empty');
                } else if (name.length >= 40) {
                  alert('name should not exceed more than 40 letters');
                } else if (interestData.length < 2) {
                  alert('Minimum of 2 intrests needs to be selected');
                } else {
                  handleUpdateProfile();
                }
              }}
              className={classes.SubmitButton}>
              Update Profile
            </Button>
          </div>
        </div>

        <div className={classes.AppInfo}>
          <Typography variant="subtitle1" className={classes.fontsize12}>
            You are connected to the SCONTIVERSE.
          </Typography>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditProfile;
