import React from 'react';
import IconButton from '@mui/material/IconButton';
import YouTubeIcon from '@mui/icons-material/YouTube';
import PublicIcon from '@mui/icons-material/Public';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import AndroidOutlinedIcon from '@material-ui/icons/AndroidOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import TelegramIcon from '@mui/icons-material/Telegram';
import {makeStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';
import {Twitter} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  IconSize: {
    width: '10rem',
    height: '10rem',
    margin: '2rem ',
  },
  AppInfo: {
    border: `0.1rem solid ${theme.palette.color.AppInfoColore}`,
    padding: '1rem',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  fontsize12: {fontSize: 12},
  displayFlex: {display: 'flex'},
}));
const Socialmedia = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.displayFlex}>
        <div>
          <div className={classes.displayFlex}>
            <IconButton
              aria-label="facebook"
              className={classes.IconSize}
              onClick={() => {
                window.open(
                  'https://www.facebook.com/people/Scontinent-Technologies-Pvt-Ltd/100075986688577/',
                );
              }}>
              <FacebookIcon style={{fontSize: '5rem'}} color="primary" />
            </IconButton>
            <IconButton
              aria-label="youtube"
              className={classes.IconSize}
              onClick={() => {
                window.open(
                  'https://youtube.com/@studentcontinent8119?si=JHajXVYa_c2p39Fy',
                );
              }}>
              <YouTubeIcon style={{fontSize: '5rem', color: 'red'}} />
            </IconButton>
          </div>
          <div className={classes.displayFlex}>
            <IconButton
              aria-label="Instagram"
              className={classes.IconSize}
              onClick={() => {
                window.open('https://www.instagram.com/sconti__app/');
              }}>
              <InstagramIcon style={{fontSize: '5rem', color: 'black'}} />
            </IconButton>
            <IconButton
              aria-label="LinkedIn"
              className={classes.IconSize}
              onClick={() => {
                window.open(
                  'https://www.linkedin.com/company/scontinent-technologies-pvt-ltd/about/',
                );
              }}>
              <LinkedInIcon style={{fontSize: '5rem'}} color="primary" />
            </IconButton>
          </div>
          <div className={classes.displayFlex}>
            <IconButton
              aria-label="App"
              className={classes.IconSize}
              onClick={() => {
                window.open(
                  'https://play.google.com/store/apps/details?id=com.scontinent.sconti&pcampaignid=web_share',
                );
              }}>
              <AndroidOutlinedIcon
                style={{fontSize: '5rem', color: '#61B33B'}}
              />
            </IconButton>
            <IconButton
              aria-label="Website"
              className={classes.IconSize}
              onClick={() => {
                window.open('https://studentcontinent.in/');
              }}>
              <PublicIcon style={{fontSize: '5rem', color: 'black'}} />
            </IconButton>
          </div>
          <div className={classes.displayFlex}>
            <IconButton
              aria-label="Telegram"
              className={classes.IconSize}
              onClick={() => {
                window.open('https://t.me/networkoyoungminds');
              }}>
              <TelegramIcon style={{fontSize: '5rem', color: '#37c6ff'}} />
            </IconButton>
            <IconButton
              aria-label="twitter"
              className={classes.IconSize}
              onClick={() => {
                window.open('https://twitter.com/AppSconti');
              }}>
              <Twitter style={{fontSize: '5rem', color: 'black'}} />
            </IconButton>
          </div>
        </div>
        <div className={classes.AppInfo}>
          <Typography variant="subtitle1" className={classes.fontsize12}>
            it is a dynamic ecosystem of opportunity education and networking.
            By leveraging the power of Facebook, LinkedIn, Twitter, Instagram and
            YouTube.Sconti has created a platform that empowers users to explore job and internship
            opportunities access educational resources, engage with crispy and
            informative posts, participate in quizzes and connect with young
            minds across different states. In doing so, it is shaping the future
            of education and career opportunities, fostering a community of
            lifelong learners and paving the way for a brighter and more
            interconnected world. Perhaps the most profound aspect of Sconti is
            its ability to connect young minds across different states and
            regions. In a world that is becoming increasingly globalized, the
            ability to collaborate and learn from peers across geographical
            boundaries is invaluable. Through its various platforms, Sconti
            encourages knowledge exchange and collaboration among young minds.
            It provides a platform where students and professionals can share
            experiences, ideas and insights, enriching each other's
            perspectives and contributing to personal and professional growth.
            In today's digital age, social media has become an indispensable
            part of our lives, shaping how we interact, share information and
            connect with people from all corners of the world. Among the
            multitude of platforms available, Sconti Social Media stands out as
            a beacon of opportunity, education and networking. This dynamic
            platform leverages the power of Facebook, LinkedIn, Twitter,
            Instagram, YouTube, a dedicated website and Telegram to create
            awareness about jobs, internships, engaging posts, educational
            resources and quizzes. It serves as a hub for connecting with young
            minds across different states, fostering knowledge exchange and
            shaping the future of education and career opportunities.
          </Typography>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Socialmedia;
