import React from 'react';
import Navbar from '../navbar/Navbar';
import {Button} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded';
import QuizMainComponent from './QuizMainComponent';

const useStyles = makeStyles(theme => ({
  event: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 100,
    height: 100,
    position: 'fixed',
    bottom: '2rem',
    right: '2rem',
    backgroundColor: '#ff9d00',
    borderRadius: '50%',
    cursor: 'pointer',
    boxShadow: '0.2rem 0.2rem 0.2rem 0.2rem #888888',
  },
}));

const MainQuizSection = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div>
        <Navbar />
        <div>
          <div>
            <Button
              style={{top: '3.5rem', color: 'white'}}
              color="primary"
              variant="contained"
              fullWidth
              startIcon={<CampaignRoundedIcon style={{color: 'red'}} />}>
              Announcement
            </Button>
          </div>
          {/** <Quiz />
            <ViewResults />
            <MainCards />
            <div className={classes.event}>
              <Event style={{color: 'white'}} />
              <Typography variant="body2" style={{color: 'white'}}>
                Register Quiz
              </Typography>
            </div>
            <SubQuizSection />
            <QuizRegister />
            <QuizRegCard />
            <Instruction /> */}
          <QuizMainComponent />
        </div>
      </div>
    </React.Fragment>
  );
};

export default MainQuizSection;
