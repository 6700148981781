import React from 'react';
import {Paper, Typography} from '@material-ui/core';
import {useNavigate} from 'react-router-dom';

const NewsDisplay = props => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      {props.News ? (
        props.News.map((item, index) => {
          return (
            <Paper elevation={3}>
              <div
                onClick={() => {
                  navigate('/news', {state: item.node.id});
                }}
                key={index}
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  margin: '1rem',
                  padding: '0.5rem',
                  cursor: 'pointer',
                }}>
                <img
                  src={item.node.mediaUrl}
                  alt="mediaUrl"
                  width={75}
                  height={75}
                  style={{borderRadius: '5px'}}
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '0.2rem',
                    marginLeft: '1rem',
                  }}>
                  <Typography variant="body1" style={{maxLines: 2}}>
                    {item.node.heading.substring(0, 50)}...
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{maxLines: 2, color: 'gray'}}>
                    {item.node.summary.substring(0, 150)}...
                  </Typography>
                </div>
              </div>
            </Paper>
          );
        })
      ) : (
        <Typography
          variant="h1"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          {' '}
          No Posts To Show
        </Typography>
      )}
    </React.Fragment>
  );
};

export default NewsDisplay;
