import React from 'react';
import {useCookies} from 'react-cookie';
import {useQuery} from '@apollo/client';
import HomeSearchPosts from '../../queries/HomeSearchPosts';
import {useState, useEffect, useRef} from 'react';
import PostDesgin from '../postDesing/PostDesgin';
import PollDisplay from '../postDesing/PollDisplay';
import {useLocation} from 'react-router-dom';
import {Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Navbar from '../navbar/Navbar';
import {ThreeCircles} from 'react-loader-spinner';

const useStyles = makeStyles(theme => ({
  mainDiv: {
    paddingTop: '4rem',
    height: window.innerHeight,
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
    },
  },
}));

const HashtagDisplay = props => {
  const classes = useStyles();
  const pathname = useLocation();

  const trackRef = useRef([]);
  const [data, setData] = useState([]);
  const [cookies, setCookie] = useCookies(['user']);
  const [loading, setloading] = useState(true);
  const [getSubscriptionsData, setGetSubscriptionsData] = useState([]);
  const [filterselected, setfilterselected] = useState('all');
  const location = useLocation();

  const {fetchMore} = useQuery(
    HomeSearchPosts,
    {
      variables: {
        limit: 10,
        nextToken: null,
        inputFilter: {
          keyWord: location.state,
          filter: filterselected,
          userId: cookies.user.userId,
          type: 'post',
        },
      },
      fetchPolicy: 'network-only',
      onCompleted: res => {
        console.log('res : ', res);
        setData(res.homeSearchPost ? res.homeSearchPost.edges : []);
        setGetSubscriptionsData(res.homeSearchPost);
        setloading(false);
      },
      onError: err => {
        console.log(err);
      },
    },
    [],
  );

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        nextToken: getSubscriptionsData.pageInfo.endCursor,
        limit: 10,
        inputFilter: {
          userId: cookies.user.userId ? cookies.user.userId : '',
          keyWord: location.state,
          type: 'post',
          filter: filterselected,
        },
      },
      updateQuery: (prev, {fetchMoreResult}) => {
        if (!fetchMoreResult) return prev;

        setGetSubscriptionsData(fetchMoreResult.homeSearchPost);
        return {
          ...prev,
          homeSearchPost: {
            ...prev.homeSearchPost,
            edges: [
              ...prev.homeSearchPost.edges,
              ...fetchMoreResult.homeSearchPost.edges,
            ],
            pageInfo: fetchMoreResult.homeSearchPost.pageInfo,
          },
        };
      },
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      // You can define your own conditions to trigger loading more data based on scroll
      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - 500
      ) {
        console.log('pagination if check');
        console.log(trackRef.current);
        if (getSubscriptionsData && getSubscriptionsData.pageInfo) {
          if (
            !trackRef.current.includes(
              getSubscriptionsData
                ? getSubscriptionsData.pageInfo.endCursor
                : '',
            )
          ) {
            handleLoadMore();
            trackRef.current = [
              ...trackRef.current,
              getSubscriptionsData
                ? getSubscriptionsData.pageInfo.endCursor
                : '',
            ];
          }
          console.log(trackRef.current);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [data]); // Ensure that this effect is executed whenever new data is fetched
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <Navbar />
      <div className={classes.mainDiv}>
        <Typography variant="h6" style={{textAlign: 'center', color: 'orange'}}>
          {location.state}
        </Typography>
        {loading ? (
          <div style={{marginLeft: '45rem', marginTop: '5rem'}}>
            <ThreeCircles
              height="100"
              width="100"
              color="#4fa94d"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="three-circles-rotating"
              outerCircleColor="#00a3ff"
              innerCircleColor="#ff9d00"
              middleCircleColor="#4088FF"
            />
          </div>
        ) : (
          data.map((item, key) => (
            <div key={key} style={{marginLeft: '33rem'}}>
              <Typography variant="body1">{item.node.key}</Typography>
              {item.node.poll ? (
                <PollDisplay data={item.node} />
              ) : (
                <PostDesgin data={item.node} />
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default HashtagDisplay;
