import {Avatar, Typography, Paper, Button, Divider} from '@material-ui/core';
import {Clear} from '@material-ui/icons';
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import UsersViewed from '../../queries/UsersViewed';
import {useQuery} from '@apollo/client';
import {useState, useEffect, useRef} from 'react';
import {useNavigate} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  Paper: {
    width: '35rem',
    maxHeight: '95%',
    marginLeft: '30rem',
    borderRadius: '1rem',
    color: 'cream',
    overflow: 'auto',
    marginTop: '1rem',
  },
  main: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '1rem',
    margin: '1rem',
    cursor: 'pointer',
  },
  heading: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    margin: '0.5rem',
  },
  inline: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
}));

const ViewCount = props => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [viewCount, setViewCount] = useState([]);
  const [getviewCount, setGetViewCount] = useState([]);

  const trackRef = useRef([]);
  const {fetchMore} = useQuery(UsersViewed, {
    variables: {
      limit: 10,
      nextToken: null,
      postId: props.postId,
    },
    fetchPolicy:'network-only',
    onCompleted: res => {
      console.log('UsersViewed', res);
      setViewCount(res.getAllPostViewers ? res.getAllPostViewers.edges : []);
      setGetViewCount(res.getAllPostViewers);
    },
    onError: err => {
      console.log(err);
    },
  });
  console.log('get sub data : ', getviewCount);

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        nextToken: getviewCount.pageInfo.endCursor,
        limit: 20,
        postId: props.postId,
      },

      updateQuery: (prev, {fetchMoreResult}) => {
        if (!fetchMoreResult) return prev;

        setGetViewCount(fetchMoreResult.getAllPostViewers);
        return {
          ...prev,
          getAllPostViewers: {
            ...prev.getAllPostViewers,
            edges: [
              ...prev.getAllPostViewers.edges,
              ...fetchMoreResult.getAllPostViewers.edges,
            ],
            pageInfo: fetchMoreResult.getAllPostViewers.pageInfo,
          },
        };
      },
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      // You can define your own conditions to trigger loading more data based on scroll
      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - 500
      ) {
        console.log('pagination if check');
        console.log(trackRef.current);
        if (getviewCount && getviewCount.pageInfo) {
          if (
            !trackRef.current.includes(
              getviewCount ? getviewCount.pageInfo.endCursor : '',
            )
          ) {
            // setGetSubscriptionsData(getSubscriptionsData.concat(handleLoadMore))

            handleLoadMore();
            trackRef.current = [
              ...trackRef.current,
              getviewCount ? getviewCount.pageInfo.endCursor : '',
            ];
          }
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [viewCount]);
  const handleScroll = () => {
    // You can define your own conditions to trigger loading more data based on scroll
    if (
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight - 500
    ) {
      console.log('pagination if check');
      console.log(trackRef.current);
      if (getviewCount && getviewCount.pageInfo) {
        if (
          !trackRef.current.includes(
            getviewCount ? getviewCount.pageInfo.endCursor : '',
          )
        ) {
          // setGetSubscriptionsData(getSubscriptionsData.concat(handleLoadMore))

          handleLoadMore();
          trackRef.current = [
            ...trackRef.current,
            getviewCount ? getviewCount.pageInfo.endCursor : '',
          ];
        }
      }
    }
  };
  return (
    <React.Fragment>
      <Paper className={classes.Paper} onScroll={handleScroll}>
        <div className={classes.heading} >
          <Typography variant="h6">View Count</Typography>
          <Button
            onClick={() => {
              if (props.closeViewForPost) {
                props.closeViewForPost();
              }
              if (props.closeViewForPoll) {
                props.closeViewForPoll();
              }
            }}>
            <Clear style={{color: 'gray'}} />
          </Button>
        </div>
        <Divider style={{bottom: '2rem'}} />
        <div style={{overflow: 'auto'}} >
          {viewCount
            ? viewCount.map((item,) => {
                return (
                  <div
                    className={classes.main}
                    key={item.node}
                    onClick={() => {
                      navigate('/profile', {state: item.node.userId});
                      if (props.closeViewForPost) {
                        props.closeViewForPost();
                      }
                      // console.log(props.postId)
                    }}>
                    <Avatar
                    variant="circular"
                      src={item.node.profileImageUrl}
                      style={{marginTop: '0.3rem'}}
                    />
                    <div className={classes.inline}>
                      <Typography>{item.node.name}</Typography>
                      <Typography style={{opacity: '0.5'}} variant="body2">
                        {item.node.college}
                      </Typography>
                    </div>
                  </div>
                );
              })
            : 'There No views to show'}
        </div>
      </Paper>
    </React.Fragment>
  );
};

export default ViewCount;
