
import gql from 'graphql-tag';

export default gql`
query fetchPrimaryInterests {
  fetchPrimaryInterests {
    name
  }
}
`;
