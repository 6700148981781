
import gql from 'graphql-tag';

export default gql`
query getDistrictsByState($stateName: String!) {
  getDistrictsByState(stateName: $stateName) {
    id
    districtName
    districtState
  }
}
`;
