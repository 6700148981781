import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import {useCookies} from 'react-cookie';
import {useQuery} from '@apollo/client';
import Users from '../suggestion_Card/Users';
import Box from '@material-ui/core/Box';
import TopContentCreators from '../../queries/TopContentCreatorsFile';
import UserSuggestionsCollege from '../../queries/Usersuggestionscollege';
import UserSuggestionsProfessionals from '../../queries/Usersuggestionsprofessional';
import UserSuggestionsStudents from '../../queries/Usersuggestionsstudent';
import {ThreeCircles} from 'react-loader-spinner';

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
}));

const Suggestion = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [content, setcontent] = useState([]);
  const [college, setcollege] = useState([]);
  const [getCollegeData, setGetCollegeData] = useState([]);
  const [student, setstudent] = useState([]);
  const [getStudentData, setGetStudentData] = useState([]);
  const [prof, setprof] = useState([]);
  const [getProfData, setGetProfData] = useState([]);
  const [cookies, setCookie] = useCookies(['user']);
  const [loading, setLoading] = useState(true);
  const trackRef = useRef([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = index => {
    setValue(index);
  };

  useQuery(
    TopContentCreators,
    {
      variables: {
        userModelInputFilter: {
          userId: cookies.user.userId ? cookies.user.userId : '',
        },
      },
      onCompleted: res => {
        console.log('userSuggestion', res);

        setcontent(res.topContentCreators ? res.topContentCreators : []);
        setLoading(false);
      },
      onError: err => {
        console.log(err);
      },
    },
    [],
  );
  {
    /** ----------------------------End of Top Content Creators---------------- */
  }
  const {fetchMore: fetchMoreCollege} = useQuery(
    UserSuggestionsCollege,
    {
      variables: {
        limit: 10,
        nextToken: null,
        userId: cookies.user.userId,
      },

      onCompleted: res => {
        console.log('userSuggestion', res);

        setcollege(
          res.userSuggestionsFromCollege
            ? res.userSuggestionsFromCollege.edges
            : [],
        );
        setGetCollegeData(res.userSuggestionsFromCollege);
        setLoading(false);
      },
      onError: err => {
        console.log(err);
      },
    },
    [],
  );
  console.log('getsubscriptiondata : ', getCollegeData);
  const handleLoadMoreCollege = () => {
    fetchMoreCollege({
      variables: {
        limit: 10,
        nextToken: getCollegeData.pageInfo.endCursor,
        userId: cookies.user.userId ? cookies.user.userId : '',
      },

      updateQuery: (prev, {fetchMoreResult}) => {
        if (!fetchMoreResult) return prev;

        setGetCollegeData(fetchMoreResult.userSuggestionsFromCollege);
        return {
          ...prev,
          userSuggestionsFromCollege: {
            ...prev.userSuggestionsFromCollege,
            edges: [
              ...prev.userSuggestionsFromCollege.edges,
              ...fetchMoreResult.userSuggestionsFromCollege.edges,
            ],
            pageInfo: fetchMoreResult.userSuggestionsFromCollege.pageInfo,
          },
        };
      },
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (getCollegeData && getCollegeData.pageInfo) {
        if (
          !trackRef.current.includes(
            getCollegeData ? getCollegeData.pageInfo.endCursor : '',
          )
        ) {
          handleLoadMoreCollege();
          trackRef.current = [
            ...trackRef.current,
            getCollegeData ? getCollegeData.pageInfo.endCursor : '',
          ];
        }
      }
    };
    // };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [college]); // Ensure that this effect is executed whenever new data is fetched
  {
    /**COllege over */
  }
  const {fetchMore: fetchMoreProf} = useQuery(
    UserSuggestionsProfessionals,
    {
      variables: {
        userId: cookies.user.userId,
        limit: 10,
        nextToken: null,
      },
      fetchPolicy: 'network-only',

      onCompleted: res => {
        console.log('professionalUserSuggestions', res);

        setprof(
          res.professionalUserSuggestions
            ? res.professionalUserSuggestions.edges
            : [],
        );
        setGetProfData(res.professionalUserSuggestions);
        setLoading(false);
      },
      onError: err => {
        console.log(err);
      },
    },
    [],
  );
  console.log('getsubscriptiondata : ', getProfData);
  const handleLoadMoreProf = () => {
    fetchMoreProf({
      variables: {
        nextToken: getProfData.pageInfo.endCursor,
        limit: 10,
        userId: cookies.user.userId ? cookies.user.userId : '',
      },

      updateQuery: (prev, {fetchMoreResult}) => {
        if (!fetchMoreResult) return prev;

        setGetProfData(fetchMoreResult.professionalUserSuggestions);
        return {
          ...prev,
          professionalUserSuggestions: {
            ...prev.professionalUserSuggestions,
            edges: [
              ...prev.professionalUserSuggestions.edges,
              ...fetchMoreResult.professionalUserSuggestions.edges,
            ],
            pageInfo: fetchMoreResult.professionalUserSuggestions.pageInfo,
          },
        };
      },
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (getProfData && getProfData.pageInfo) {
        if (
          !trackRef.current.includes(
            getProfData ? getProfData.pageInfo.endCursor : '',
          )
        ) {
          handleLoadMoreProf();
          trackRef.current = [
            ...trackRef.current,
            getProfData ? getProfData.pageInfo.endCursor : '',
          ];
        }
      }
    };
    // };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prof]); // Ensure that this effect is executed whenever new data is fetched

  /**-------------------Prof end */

  const {fetchMore: fetchMoreStudents} = useQuery(
    UserSuggestionsStudents,
    {
      variables: {
        userId: cookies.user.userId,
        limit: 10,
        nextToken: null,
      },
      // fetchPolicy: 'network-only',

      onCompleted: res => {
        console.log('studentUserSuggestions', res);

        setstudent(
          res.studentUserSuggestions ? res.studentUserSuggestions.edges : [],
        );
        setGetStudentData(res.studentUserSuggestions);
        setLoading(false);
      },
      onError: err => {
        console.log(err);
      },
    },
    [],
  );
  console.log('getStudentData : ', getStudentData);
  const handleLoadMoreStudent = () => {
    fetchMoreStudents({
      variables: {
        nextToken: getStudentData.pageInfo.endCursor,
        limit: 10,
        userId: cookies.user.userId ? cookies.user.userId : '',
      },

      updateQuery: (prev, {fetchMoreResult}) => {
        if (!fetchMoreResult) return prev;

        setGetStudentData(fetchMoreResult.studentUserSuggestions);
        return {
          ...prev,
          studentUserSuggestions: {
            ...prev.studentUserSuggestions,
            edges: [
              ...prev.studentUserSuggestions.edges,
              ...fetchMoreResult.studentUserSuggestions.edges,
            ],
            pageInfo: fetchMoreResult.studentUserSuggestions.pageInfo,
          },
        };
      },
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (getStudentData && getStudentData.pageInfo) {
        if (
          !trackRef.current.includes(
            getStudentData ? getStudentData.pageInfo.endCursor : '',
          )
        ) {
          handleLoadMoreStudent();
          trackRef.current = [
            ...trackRef.current,
            getStudentData ? getStudentData.pageInfo.endCursor : '',
          ];
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [student]); // Ensure that this effect is executed whenever new data is fetched

  return (
    <div className={classes.root}>
    <Typography variant='h6' style={{textAlign:"center",}}>People you might know</Typography>
      <AppBar position="sticky" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example">
          <Tab label="Top Content Creators" {...a11yProps(0)} />
          <Tab label=" My College" {...a11yProps(1)} />
          <Tab label="Professionals" {...a11yProps(2)} />
          <Tab label="Students" {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      {/* <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}> */}
        <TabPanel value={value} index={0} dir={theme.direction}>
          {loading ? (
            <div style={{marginLeft: '13.5rem', marginTop: '5rem'}}>
              <ThreeCircles
                height="100"
                width="100"
                color="#4fa94d"
                // wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="three-circles-rotating"
                outerCircleColor="#00a3ff"
                innerCircleColor="#ff9d00"
                middleCircleColor="#4088FF"
              />
            </div>
          ) : content.length > 0 ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'flex-start',
                gap: '1rem',
              }}>
              {content.map((item, key) => (
                <div key={key}>
                  <Users data={item} />
                </div>
              ))}
            </div>
          ) : (
            <Typography>No Users to show </Typography>
          )}
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          {loading ? (
            <div style={{marginLeft: '13.5rem', marginTop: '5rem'}}>
              <ThreeCircles
                height="100"
                width="100"
                color="#4fa94d"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="three-circles-rotating"
                outerCircleColor="#00a3ff"
                innerCircleColor="#ff9d00"
                middleCircleColor="#4088FF"
              />
            </div>
          ) : college.length > 0 ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'flex-start',
                gap: '1rem',
              }}>
              {college.map((item, key) => (
                <div key={key}>
                  <Users data={item.node} />
                </div>
              ))}
            </div>
          ) : (
            <Typography>No Users to show </Typography>
          )}
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          {loading ? (
            <div style={{marginLeft: '13.5rem', marginTop: '5rem'}}>
              <ThreeCircles
                height="100"
                width="100"
                color="#4fa94d"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="three-circles-rotating"
                outerCircleColor="#00a3ff"
                innerCircleColor="#ff9d00"
                middleCircleColor="#4088FF"
              />
            </div>
          ) : prof.length > 0 ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'flex-start',
                gap: '1rem',
              }}>
              {prof.map((item, key) => (
                <div key={key}>
                  <Users data={item.node} />
                </div>
              ))}
            </div>
          ) : (
            <Typography>No Users to show </Typography>
          )}
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          {loading ? (
            <div style={{marginLeft: '13.5rem', marginTop: '5rem'}}>
              <ThreeCircles
                height="100"
                width="100"
                color="#4fa94d"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="three-circles-rotating"
                outerCircleColor="#00a3ff"
                innerCircleColor="#ff9d00"
                middleCircleColor="#4088FF"
              />
            </div>
          ) : student.length > 0 ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'flex-start',
                gap: '1rem',
              }}>
              {student.map((item, key) => (
                <div key={key}>
                  <Users data={item.node} />
                </div>
              ))}
            </div>
          ) : (
            <Typography>No Users to show </Typography>
          )}
        </TabPanel>
      {/* </SwipeableViews> */}
    </div>
  );
};

export default Suggestion;
