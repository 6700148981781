import gql from 'graphql-tag';

export default gql`
  query getRatingwiseMarkCount($postId: ID) {
    getRatingwiseMarkCount(postId: $postId) {
      relevant
      notRelevant
      seen
      trending
    }
  }
`;
