import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import GetAppIcon from '@material-ui/icons/GetApp';
import pdf from '../../assets/Icon_png/pdf.png';
import xls from '../../assets/Icon_png/xls.png';
import ppt from '../../assets/Icon_png/ppt.png';
import doc from '../../assets/Icon_png/doc.png';
import {Button, Typography} from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '500px',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    // backgroundColor:"f0f0f0",
    height: '50px',
    width: '450px',
    borderRadius: '25px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  fileName: {
    marginRight: '1rem',
  },
}));

const DocumentShow = props => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            {props.data.type === 'pdf' ? (
              <img
                src={pdf}
                alt="Doc-type"
                className="icon"
                height={30}
                width={30}
              />
            ) : props.data.type === 'word' ? (
              <img
                src={doc}
                alt="Doc-type"
                className="icon"
                height={30}
                width={30}
              />
            ) : props.data.type === 'ppt' ? (
              <img
                src={ppt}
                alt="Doc-type"
                className="icon"
                height={30}
                width={30}
              />
            ) : (
              <img
                src={xls}
                alt="Doc-type"
                className="icon"
                height={30}
                width={30}
              />
            )}
            <Typography variant='body2' className="fileName">{props.data.name}</Typography>

            <Button>
              <a
              style={{color:"black"}}
                href={props.data.url}
                target="_blank"
                color="transparent"
                rel="noopener noreferrer"
                download>
                <GetAppIcon height={30} width={30} />
              </a>
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default DocumentShow;
