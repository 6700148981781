import gql from 'graphql-tag';

export default gql`
  query homeSearchUser(
    $limit: Int
    $nextToken: String
    $inputFilter: SearchInputFilter!
  ) {
    homeSearchUser(
      limit: $limit
      nextToken: $nextToken
      inputFilter: $inputFilter
    ) @connection(key: "UserProfileDisplayModel") {
      edges {
        node {
          name
          description
          userId
          profileImageUrl
          state
          userType
          college
          company
          
          amIFollowing
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
