import gql from 'graphql-tag';

export default gql`
  query getCommentsForPost(
    $postId: ID!
    $userId: String
    $limit: Int
    $nextToken: String
  ) {
    getCommentsForPost(
      postId: $postId
      userId: $userId
      limit: $limit
      nextToken: $nextToken
    ) @connection(key: "CommentsDisplayModelConnection") {
      edges {
        node {
          id
          description
          commentedById
          commentedByName
          likeCount
          replyCount
          createdOn
          commentedByProfileImageUrl
          media {
            name
            url
            type
            thumbnailUrl
          }
          likedByMe
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
