import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {IconButton, Paper, Typography} from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { Cancel } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '500px',
  },
  paper: {
    height: 150,
    width: 150,
    borderRadius: '15px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'end',
    justifyContent: 'space-between',
    padding: '0.5rem',
  },
  control: {
    padding: theme.spacing(2),
  },
}));

const Media = props => {
  const [spacing, setSpacing] = React.useState(2);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [fullimage, setFullimage] = useState('');

  const handleOpen = url => {
    setOpen(true);
    setFullimage(url);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={spacing}>
          {props.data.length > 0 ? (
            props.data.map(value => (
              <Grid key={value.node.url} item>
                {value.node.type === 'video' ? (
                  <video
                    controls
                    style={{borderRadius: '10px', height: 150, width: 150}}>
                    <source
                      src={value.node.url}
                      poster={value.node.thumbnailUrl}
                      type="video/mp4"
                    />
                  </video>
                ) : (
                  <div
                    onClick={() => {
                      handleOpen(value.node.url);
                    }}>
                    <img
                      style={{
                        borderRadius: '10px',
                        top: '2rem',
                        width: 150,
                        height: 150,
                      }}
                      src={value.node.url}
                      alt="media"
                    />
                  </div>
                )}
              </Grid>
            ))
          ) : (
            <Typography>No Media to show</Typography>
          )}
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description">
           <div style={{display:"flex",flexDirection:"row", justifyContent:"space-around", alignItems:"flex-start",margin:"2rem"}}>
          
            <img
              style={{
                borderRadius: '10px',
                width: '60%',
                height: '60%',
              }}
              src={fullimage}
              alt="media"
            />
            <IconButton onClick={handleClose} style={{width:"1rem ", height:"1rem",backgroundColor:"white",right:"10rem"}} >
            <Cancel/>
            </IconButton>
           </div>
          </Modal>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Media;
