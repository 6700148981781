import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
  Paper,
  Typography,
  TextField,
  Button,
  MenuItem,

} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ShowColleges from '../../queries/ShowColleges';
import ShowStates from '../../queries/ShowStates';
import ShowDistricts from '../../queries/ShowDistricts';
import {useApolloClient, useQuery, useLazyQuery} from '@apollo/client';
import {auth} from './firebase';
import PhoneView from '../../components/phoneView/PhoneView';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: '2rem',
    borderRadius: '1rem',
    width: '25rem',
    display: 'flex',
    flexDirection: 'column',
    margin: '0rem 5rem 3rem 0rem',
    [theme.breakpoints.down('md')]: {
      width: '25rem',
      marginBottom: '1rem',
      padding: '2rem',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      width: '100%',
      marginBottom: '2rem',
      padding: '3rem',
      alignItems: 'center',
      marginTop: '5rem',
    },
  },
  PhoneViewDisable: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  PhoneViewEnable: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  right: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  resize: {
    padding: '0.5rem 0.5rem',
    border: `0.1rem solid ${theme.palette.color.grey2}`,
    borderRadius: '0.3rem',
    width: '20rem',
  },
  Selectresize: {
    padding: '0.5rem',
    border: `0.1rem solid ${theme.palette.color.grey2}`,
    borderRadius: '0.3rem',
    width: '19rem',
  },
  SubmitButtonBorder: {textAlign: 'center', marginTop: ' 2rem'},
  SubmitButton: {
    textTransform: 'capitalize',
    color: theme.palette.color.white,
    padding: '0.5rem 8rem',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  HeadingStyle: {
    marginBottom: '0.3rem',
    color: theme.palette.color.black,
    fontWeight: 'bold',
  },
  TypographyTextStyle: {
    marginBottom: '0.3rem',
    color: '#5b5b5b',
  },
  marginTop1: {marginTop: '0.5rem'},
  AutocompleteStyle: {
    border: `0.05rem solid ${theme.palette.color.grey1}`,
    borderRadius: '0.3rem',
    padding: '0.1rem 0.5rem',
  },
  SelectIcon: {marginRight: '0.5rem'},
}));

const UserData = props => {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [userType, setUserType] = useState('');
  const [collegedata, setcollegedata] = useState([]);
  const [statedata, setstatedata] = useState();
  const [districtData, setDisTrictData] = useState();
  const [company, setCompanyName] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedDistrict, setSelectedDistrict] = useState('');
  const [selectedCollege, setSelectedCollege] = useState('');

  const userTypes = [
    {
      value: 'Student',
    },
    {
      value: 'Professional',
    },
  ];
  const defaultProps2 = {
    options: collegedata,
    getOptionLabel: option => option.collegeName,
  };
  const defaultProps3 = {
    options: statedata,
    getOptionLabel: option => option.stateName,
  };
  const defaultProps4 = {
    options: districtData,
    getOptionLabel: option => option.districtName,
  };
  const handleChangeUserType = event => {
    setUserType(event.target.value);
  };
  const clients = useApolloClient();
  const searchCollegeData = text => {
    if (text.length >= 3) {
      clients
        .query({
          query: ShowColleges,
          variables: {collegeName: text},
        })
        .then(res => {
          console.log(res);
          setcollegedata(res.data.getAllColleges);
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      setcollegedata([]);
      // sethideresults(true);
    }
  };

  useQuery(
    ShowStates,
    {
      onCompleted: res => {
        console.log('getAllStates', res);
        setstatedata(res.getAllStates ? res.getAllStates : []);
      },
      onError: err => {
        console.log(err);
      },
    },
    [],
  );
  const [getDistrictsByState] = useLazyQuery(
    ShowDistricts,
    {
      variables: {stateName: selectedState},
      onCompleted: res => {
        console.log('getDistrictsByState', res);
        setDisTrictData(res.getDistrictsByState ? res.getDistrictsByState : []);
      },
      onError: err => {
        console.log(err);
      },
    },
    [],
  );

  return (
    <React.Fragment>
      <div className={classes.right} id='userInfoMain'>
        <Paper elevation={3} className={classes.paper} id='paperForUserInfo'>
          <div id='detail-info'>
            <Typography variant="h6" className={classes.HeadingStyle}>
              Enter your Name and Details
            </Typography>
          </div>
          <div>
            <div className={classes.TextFieldStyle} id='userRoot'>
              <div style={{display: 'flex', flexDirection: 'row'}} id='username-Data'>
                <Typography
                  variant="subtitle2"
                  className={classes.TypographyTextStyle}>
                  Name
                </Typography>
                <Typography gutterBottom style={{color: 'red'}}>
                  *
                </Typography>
              </div>
              <TextField
                placeholder="Enter Your Name"
                required
                id='userName'
                value={name}
                multiline={true}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    input: classes.resize,
                  },
                }}
                onChange={e => {
                  if (e.target.value.length < 50) {
                    setName(e.target.value);
                  } else {
                    setName(e.target.value.trim(0,50));
                    alert('Name Limit Exceed');
                  }
                }}
              />
            </div>
          </div>
          <div className={classes.marginTop1} id='userState-margin'>
            <div className={classes.TextFieldStyle} id='userState-textfield'>
              <div style={{display: 'flex', flexDirection: 'row'}} id='userState-data'>
                <Typography
                  variant="subtitle2"
                  className={classes.TypographyTextStyle}>
                  State
                </Typography>{' '}
                <Typography gutterBottom style={{color: 'red'}}>
                  *
                </Typography>
              </div>
              <Autocomplete
                {...defaultProps3}
                id="auto-completeForState"
                autoComplete
                r
                noOptionsText={
                  statedata
                    ? 'start typing to search...'
                    : 'Please select your state'
                }
                size="small"
                fullWidth
                onChange={(e, v) => {
                  console.log(v);
                  setSelectedDistrict('');
                  setSelectedState(v ? v.stateName : '');
                  setSelectedDistrict('');

                  getDistrictsByState({
                    variables: {
                      stateName: v ? v.stateName : '',
                    },
                  });
                }}
                includeInputInListInputProps={{
                  disableUnderline: true,
                  classes: {
                    input: classes.resize,
                  },
                }}
                renderInput={params => (
                  <TextField
                    variant="outlined"
                    size="small"
                    {...params}
                    id='selectedState'
                    required
                    value={selectedState}
                    label="Enter your state name"
                    // margin="normal"
                    onChange={event => {
                      // setstatedata(event.target.value);
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className={classes.marginTop1} id='userCity-margin'>
            <div className={classes.TextFieldStyle} id='userCity-textfield'>
              <div style={{display: 'flex', flexDirection: 'row'}} id='userCity-data'>
                <Typography
                  variant="subtitle2"
                  className={classes.TypographyTextStyle}>
                  City
                </Typography>{' '}
                <Typography gutterBottom style={{color: 'red'}}>
                  *
                </Typography>
              </div>
              <Autocomplete
                {...defaultProps4}
                id="auto-completeForDistrict"
                autoComplete
                disabled={districtData === undefined}
                noOptionsText={
                  districtData
                    ? 'start typing to search...'
                    : 'Please select your city'
                }
                fullWidth
                inputValue={selectedDistrict}
                size="small"
                onChange={(e, v) => {
                  setSelectedDistrict(v ? v.districtName : '');
                }}
                onInputCapture={(e, v) => {
                  setSelectedDistrict(e.target.value);
                }}
                includeInputInListInputProps={{
                  disableUnderline: true,
                  classes: {
                    input: classes.resize,
                  },
                }}
                renderInput={params => (
                  <TextField
                  id='selectedCity'
                    required
                    value={selectedDistrict}
                    variant="outlined"
                    size="small"
                    {...params}
                    label="Enter your city name"
                    // margin="normal"
                    onChange={event => {
                      // setDisTrictData(event.target.value);
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className={classes.marginTop1}  id='userType-margin'>
            <div className={classes.TextFieldStyle}  id='userType-textfiled'>
              <div style={{display: 'flex', flexDirection: 'row'}} id='userType-Data'>
                <Typography
                  variant="subtitle2"
                  className={classes.TypographyTextStyle}>
                  Type
                </Typography>{' '}
                <Typography gutterBottom style={{color: 'red'}}>
                  *
                </Typography>
              </div>
              <TextField
                required
                select
                id='dropDown'
                SelectProps={{
                  classes: {icon: classes.SelectIcon},
                  placeholder: 'Choose your type',
                }}
                value={userType}
                onChange={handleChangeUserType}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    input: classes.Selectresize,
                  },
                }}>
                {userTypes.map(option => (
                  <MenuItem key={option.value} autoFocus  value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>
          <div className={classes.marginTop1}id="userCollege-margin" >
            <div className={classes.TextFieldStyle}id="userCollege-textfield" >
              <div style={{display: 'flex', flexDirection: 'row'}} id="userCollege-data">
                <Typography
                  variant="subtitle2"
                  className={classes.TypographyTextStyle}>
                  College
                </Typography>{' '}
                <Typography gutterBottom style={{color: 'red'}}>
                  *
                </Typography>
              </div>
              <Autocomplete
                {...defaultProps2}
                id="auto-completeForcollege"
                autoComplete
                fullWidth
                noOptionsText={
                  collegedata ? 'start typing to search...' : 'No Results Found'
                }
                includeInputInList
                onChange={(e, v) => {
                  setSelectedCollege(v ? v.collegeName : '');
                }}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    input: classes.resize,
                  },
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    required
                    id='selectedCollege'
                    value={selectedCollege}
                    label="Enter your college Name"
                    onChange={event => {
                      searchCollegeData(event.target.value);
                    }}
                  />
                )}
              />
            </div>
          </div>

          {userType === 'Professional' ? (
            <div className={classes.marginTop1} id='userCompany-margin'>
              <div className={classes.TextFieldStyle} id='userCompany-textField'>
                <div style={{display: 'flex', flexDirection: 'row'}} id='userCompany-data'>
                  <Typography
                    variant="subtitle2"
                    className={classes.TypographyTextStyle}>
                    Company
                  </Typography>{' '}
                  <Typography gutterBottom style={{color: 'red'}}>
                    *
                  </Typography>
                </div>
                <TextField
                  placeholder="Enter your Company name"
                  required
                  id='CompanyName'
                  value={company}
                  multiline={true}                 
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: classes.resize,
                    },
                  }}
                  onChange={e => {
                    if (e.target.value.length < 150) {
                      setCompanyName(e.target.value);
                    } else {
                      setCompanyName(e.target.value.trim(0,50));

                      alert('Company name Limit Exceed');
                    }
                  }}
                />
              </div>
            </div>
          ) : (
            <div />
          )}
          <div className={classes.SubmitButtonBorder}>
            <Button
              variant="contained"
              color="primary"
              id='userDetails'
              size="small"
              className={classes.SubmitButton}
              onClick={() => {
                var obj = {
                  mobileNumber: props.phoneNumber,
                  userId: auth.currentUser ? auth.currentUser.uid : '',
                  name: name,
                  company: company && company !== '' ? company.trim() : '',
                  college: selectedCollege !== '' ? selectedCollege.trim() : '',
                  state: selectedDistrict + ',' + selectedState,
                  userType: userType,
                };
                if (name.length > 50 || company.length > 100) {
                  alert(
                    name.length > 50
                      ? 'Please enter less than 50 characters'
                      : 'Please enter less than 100 characters',
                  );
                } else {
                  // if (name.length > 0 && selectedCollege.length > 0 && selectedDistrict.length > 0 && userType.length > 0) {
                  // props.setuserData(obj);
                  // props.setScreenTrack('interests');

                  // }
                  // else {
                  //   alert('Insufficient details to create account')
                  // }
                  if (
                    name === '' ||
                    userType === '' ||
                    name.length < 3 &
                    selectedState === '' ||
                    (userType === 'Student' && selectedCollege === '') ||
                    selectedDistrict === '' ||
                    (userType === 'Professional' && company === '')
                  ) {
                    alert('Please enter all the mandatory fields');
                  } else {
                    props.setuserData(obj);
                    props.setScreenTrack('interests');
                  }
                }
              }}>
              Submit
            </Button>
          </div>
        </Paper>
      </div>
      <div className={classes.PhoneViewEnable}>
        <PhoneView />
      </div>
    </React.Fragment>
  );
};
export default UserData;
