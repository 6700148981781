
import gql from 'graphql-tag';

export default gql`
  mutation unfollowUser(
    $userId: String!,
    $followedUserId: String!,
  ) {
    unfollowUser(
      userId: $userId
      followedUserId: $followedUserId
    )
  }
`;
