import React, {useEffect, useState, useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ChatIcon from '@material-ui/icons/Chat';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import {PieChart} from '@mui/x-charts/PieChart';

import moment from 'moment';
import {
  Typography,
  Avatar,
  Card,
  Modal,
  Badge,
  Button,
  Dialog,
  IconButton,
  DialogContent,
  Divider,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import GraduationSymbol1 from '../../assets/Icon_svg/GraduationSymbol1.svg';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import RepeatIcon from '@material-ui/icons/Repeat';
import CopyrightIcon from '@material-ui/icons/Copyright';
import Popover from '@material-ui/core/Popover';
import CommentScreen from './../Comment/CommentScreen';
import CrispyPostDisplay from './CrispyPostDisplay';
import CloseIcon from '@material-ui/icons/Close';
import PostOption from './PostOption';
import DeletePost from '../../queries/DeletePost';
import LikePost from '../../queries/LikePost';
import UnLikePost from '../../queries/UnLikePost';
import ViewCount from './ViewCount';
import {useLazyQuery, useMutation} from '@apollo/client';
import {useCookies} from 'react-cookie';
import {useNavigate} from 'react-router-dom';
import ShowPost from '../../queries/ShowPost';
import RatePost from '../../queries/RatePost';
import VerifiedIcon from '@mui/icons-material/Verified';
import {FirebaseDynamicLinks} from 'firebase-dynamic-links';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from 'react-share';
import GetRatings from '../../queries/GetRatings';
import {MoreVertOutlined} from '@material-ui/icons';

const DialogTitle = props => {
  const {children, classes, onClose, ...other} = props;
  return (
    <MuiDialogTitle
      disableTypography
      {...other}
      style={{
        display: 'flex',
        flexDirection: 'row',
        borderRadius: '1rem',
        gap: '22rem',
        marginBottom: '-1.5rem',
      }}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          style={{bottom: '0.4rem'}}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const useStyles = makeStyles(theme => ({
  Paper: {
    width: '34rem',
    borderRadius: '1rem',
    margin: '1rem 0rem',
    paddingBottom: '0.5rem',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.only('md')]: {
      marginRight: '2rem',
    },
  },
  userDetailsBorderStyle: {
    display: 'flex',
    justifyContent: 'spaceBetween',
    alignItems: 'flex-start',
    margin: '0rem 1rem',
    cursor: 'pointer',
  },
  UserProfileStyle: {
    width: '2.5rem',
    height: '2.5rem',
    marginTop: '1rem',
  },
  UserNameAndLocatoinBorderStyle: {
    margin: '1rem 1rem ',

    width: '22rem',
    cursor: 'pointer',
  },
  LocationIconStyle: {
    width: '1rem',
    height: '1rem',
  },
  LocationTextStyle: {
    fontSize: 10,
    margin: '0rem 0.3rem',
    color: theme.palette.primary.main,
  },
  PostAddTimeTextStyle: {fontSize: 10, width: '10rem'},
  MoreIconStyle: {
    width: '1.5rem',
    height: '1.5rem',
    marginTop: '0.7rem',
    left: '2rem',
  },
  PostDesStyle: {
    margin: '0.5rem 0.5rem',
    width: '32rem',
    overflow: 'break-word',
    wordBreak: 'break-word',
    wordWrap: 'break-word',
  },
  BookMarkIconBorderStyle: {display: 'flex', marginLeft: '7rem'},
  LikeCommentShareBorderStyle: {
    margin: '0.5rem 1rem 0rem 1rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  DisplayFlex: {display: 'flex'},
  fontWeightBold: {fontWeight: 'bold'},
  PostImageStyle: {width: '100%'},
  IconSelect: {
    color: theme.palette.primary.main,
  },
  IcontNotSelect: {
    color: theme.palette.color.grey1,
  },
  CommentIconBorderStyle: {display: 'flex', margin: '0rem 1rem'},
  ButtonStyle: {
    padding: 0,
    textTransform: 'capitalize',
    backgroundColor: theme.palette.color.white,
    '&:hover': {
      background: theme.palette.color.white,
    },
  },
  ModalDivStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },

  liked: {color: '#FF0000'},
  group: {display: 'flex', margin: '1.5rem'},
}));

const palette = ['#ff9d00', '#00a3ff', 'black', '#FF3131'];
const PostDesgin = props => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [cookies, setCookie] = useCookies(['user']);
  const [openModalForOption, setopenModalForOption] = useState(false);
  const [iconChangeForLike, seticonChangeForLike] = useState(true);
  const [linkstoshare, setlinkstoshare] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [isReadMore, setIsReadMore] = useState(true);
  const [openForPieChart, setOpenForPieChart] = useState(false);
  const [getRating, setGetRating] = useState([]);
  const [openView, setOpenView] = useState(false);
  const [fullimage, setFullimage] = useState('');
  const [open, setOpen] = useState(false);
  const [openForShare, setOpenForShare] = useState(false);



  const handleOpenView = url => {
    setOpenView(true);
    setFullimage(url);
  };

  const handleCloseView = () => {
    setOpenView(false);
    // alert("hello")
  };

  const handleClickOpenForPieChart = () => {
    getViewers();
    setOpenForPieChart(true);
  };
  const handleClosForPieChart = () => {
    setOpenForPieChart(false);
  };

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  const [ratePost] = useMutation(RatePost);

  const handleClickPopUp = event => {
    setAnchorEl(event.currentTarget);
  };

  const openForPopUp = Boolean(anchorEl);
  const id = openForPopUp ? 'simple-popover' : undefined;

  const handleCloseForPopUp = () => {
    setAnchorEl(null);
  };

  const handleOpenForOption = () => {
    setopenModalForOption(true);
  };

  const handleCloseForOption = () => {
    setopenModalForOption(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [likemutation] = useMutation(LikePost);
  const [unlikemutation] = useMutation(UnLikePost);
  const [deleteMyPostMutation] = useMutation(DeletePost);
  const extractVideoId = link => {
    // Regular expression to match YouTube video URLs
    const regex =
      /(?:https:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]+)/;
    if (link && link !== '') {
      const match = link.match(regex);

      if (match && match[1]) {
        return match[1];
      } else {
        return '';
      }
    }
  };
  const extractLinks = text => {
    // Regular expression to match URLs
    if (text && text !== '') {
      const regex = /(https?:\/\/[^\s]+)/g;
      const matches = text.match(regex);

      if (matches) {
        return matches ? matches[0] : '';
      } else {
        return '';
      }
    }
  };
  const updateCachefordelete = (client, {data: {deletePost}}) => {
    console.log('entered');
    const data = client.readQuery({
      query: ShowPost,
      variables: {
        limit: 10,
        nextToken: null,
        userModelInputFilteruser: {userId: cookies.user.userId},
      },
    });

    const newArray = data.getSubscriptions.edges.filter(
      item => item.node.postId !== props.data.postId,
    );
    const newData = {
      getSubscriptions: {...data.getSubscriptions, edges: newArray},
    };
    // const newData = {getSubscriptions: newArray};
    client.writeQuery({
      query: ShowPost,
      variables: {
        limit: 10,
        nextToken: null,
        userModelInputFilteruser: {userId: cookies.user.userId},
      },
      data: newData,
    });
  };

  const updateCacheforlike = (client, {data: {likePost}}) => {
    console.log('entered');
    const data = client.readQuery({
      query: ShowPost,
      variables: {
        limit: 10,
        nextToken: null,
        userModelInputFilteruser: {userId: cookies.user.userId},
      },
    });

    // const newArray = data.getSubscriptions.edges.filter(
    //   item => item.node.postId !== props.data.postId,
    // );
    var newArray = [];
    data.getSubscriptions.edges.map((item, index) => {
      if (item.node.postId === props.data.postId) {
        newArray.push({
          node: {
            ...item.node,
            likedByMe: true,
            likeCount: likePost,
          },
        });
      } else {
        newArray.push(item);
      }
    });
    // console.log('likechanges', newArray[0].node.postedBy.likedByMe);
    const newData = {
      getSubscriptions: {...data.getSubscriptions, edges: newArray},
    };
    // const newData = {getSubscriptions: newArray};
    client.writeQuery({
      query: ShowPost,
      variables: {
        limit: 10,
        nextToken: null,
        userModelInputFilteruser: {userId: cookies.user.userId},
      },
      data: newData,
    });
  };
  const updateCacheforunlike = (client, {data: {unlikePost}}) => {
    console.log('entered');
    const data = client.readQuery({
      query: ShowPost,
      variables: {
        limit: 10,
        nextToken: null,
        userModelInputFilteruser: {userId: cookies.user.userId},
      },
    });

    // const newArray = data.getSubscriptions.edges.filter(
    //   item => item.node.postId !== props.data.postId,
    // );
    var newArray = [];
    data.getSubscriptions.edges.map((item, index) => {
      if (item.node.postId === props.data.postId) {
        newArray.push({
          node: {
            ...item.node,
            likedByMe: false,
            likeCount: unlikePost,
          },
        });
      } else {
        newArray.push(item);
      }
    });
    // console.log('followchanges', newArray[0].node.postedBy.amIFollowing);
    const newData = {
      getSubscriptions: {...data.getSubscriptions, edges: newArray},
    };
    // const newData = {getSubscriptions: newArray};
    client.writeQuery({
      query: ShowPost,
      variables: {
        limit: 10,
        nextToken: null,
        userModelInputFilteruser: {userId: cookies.user.userId},
      },
      data: newData,
    });
  };
  const like = () => {
    likemutation({
      variables: {
        postId: props.data.postId,
        likedByUserId: cookies.user.userId,
      },
      update: updateCacheforlike,
    })
      .then(res => {
        console.log(res);
        // alert('liked successfully');
      })
      .catch(err => {
        console.log(err);
      });
  };

  const unlike = () => {
    unlikemutation({
      variables: {
        postId: props.data.postId,
        likedByUserId: cookies.user.userId,
      },
      update: updateCacheforunlike,
    })
      .then(res => {
        console.log(res);
        // alert('Unliked successfully');
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    seticonChangeForLike(!props.data.likedByMe);
  }, []);
  const deleteMyPost = () => {
    deleteMyPostMutation({
      variables: {
        postId: props.data.postId,
      },
      update: updateCachefordelete,
    })
      .then(res => {
        console.log(res);
        // alert('deleted successfully');
      })
      .catch(err => {
        console.log(err);
      });
  };

  //hashtag concept-----------------
  const clickableHashtagGenerator = (str, baseUrl) => {
    let elem = str;
    elem = elem
      .replace(
        /(https?:\/\/[^\s]+)/g,
        `<a class='link' href="$1" target='_blank'>$1</a>`,
      )
      .replace(/(@[^\s]+)/g, `<a class='username' title='$1'>$1</a>`)
      .replace(/(#[^\s]+)/g, `(<a class='hashtag' >$1</a>)`);

    return elem;
  };

  // Ensure that this effect is executed whenever new data is fetched

  const buildmentionstring = string => {
    const splitReg = /(#[^\s]+)/g; // Regex used to split the string
    let userNames = []; // array to store all names
    let str = [];
    const regex =
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    // const matches = text.match(regex);
    const replaceStrong = string.split(' ').map(v => {
      if (v[0] === '#') {
        str.push({key: v, ishash: true});
      } else if (v.match(regex)) {
        str.push({key: v, ishash: false, islink: true});
      } else {
        str.push({key: v, ishash: false});
      }
    });

    return str;
  };
  const body = (
    <PostOption
      ismyPost={props.data.postedBy.userId === cookies.user.userId}
      handleDelete={deleteMyPost}
      data={props.data}
      closed={handleCloseForOption}
    />
  );
  const [openForView, setOpenForView] = useState(false);
  const handleOpenForView = () => {
    setOpenForView(true);
  };

  const handleCloseForView = () => {
    setOpenForView(false);
  };
  const view = (
    <ViewCount
      closeViewForPost={handleCloseForView}
      ismyPost={props.data.postedBy.userId === cookies.user.userId}
      postId={props.data.postId}
    />
  );
  const renderhashtags = text => {
    return (
      <div style={{flexWrap: 'wrap', flexDirection: 'row', display: 'flex'}}>
        {buildmentionstring(text).map((item, index) => {
          if (item.ishash === true) {
            return (
              <div
                onClick={() => {
                  navigate('/hashtagDisplay', {state: item.key});
                }}>
                <Typography
                  style={{color: '#ff9d00', marginLeft: 5, cursor: 'pointer'}}>
                  {item.key}
                </Typography>
              </div>
            );
          } else if (item.islink) {
            return (
              <div
                onClick={() => {
                  window.open(item.key);
                }}>
                <Typography
                  style={{color: '#00a3ff', marginLeft: 5, cursor: 'pointer'}}>
                  {item.key}
                </Typography>
              </div>
            );
          } else {
            return <Typography style={{marginLeft: 5}}>{item.key}</Typography>;
          }
        })}
      </div>
    );
  };

  const firebaseDynamicLinks = new FirebaseDynamicLinks(
    'AIzaSyAa3zif99kE5V0qgP_dYoLpwWOfwv12XbQ',
  );

  const generateDynamicLink = () => {
    firebaseDynamicLinks
      .createLink({
        dynamicLinkInfo: {
          domainUriPrefix: 'https://studentcontinent.page.link',
          link:
            'https://play.google.com/store/apps/details?id=com.scontinent.sconti&postid=' +
            props.data.postId,
          androidInfo: {
            androidPackageName: 'com.scontinent.sconti',
          },
        },
      })
      .then(res => {
        setlinkstoshare(res.shortLink);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleClickOpenForShare = () => {
    generateDynamicLink();
    setOpenForShare(true);
  };
  const handleCloseForShare = () => {
    setOpenForShare(false);
  };
  const videoRef = useRef(null);
  const [shouldAutoplay, setShouldAutoplay] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      const {innerWidth, innerHeight} = window;
      // Define your screen size criteria here
      const minWidth = 50;
      const minHeight = 50;
      console.log(window);

      // Check if screen size meets the criteria
      if (
        innerHeight >= innerHeight * 0.2 &&
        innerHeight <= innerHeight * 0.9
      ) {
        setShouldAutoplay(true);
        console.log(innerWidth, innerHeight);
      } else {
        setShouldAutoplay(false);
      }
    };

    // Initial check on component mount
    if (props.data.media && props.data.media.length > 0) {
      if (props.data.media[0].type === 'video') {
        handleResize();
      }
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [getViewers, {fetchMore}] = useLazyQuery(
    GetRatings,
    {
      variables: {
        postId: props.data.postId,
      },
      fetchPolicy: 'network-only',
      onCompleted: res => {
        console.log('ratings', res);
        if (res.getRatingwiseMarkCount) {
          try {
            var data = res.getRatingwiseMarkCount;
            // var datas = delete data.__typename;
            var total =
              data.notRelevant + data.relevant + data.seen + data.trending;
            var arrmark = Object.values(data);

            var finalarr = arrmark.slice(1);
            var percentarr = [];
            var labels = ['Relevant', 'Irrelevant', 'Repeated', 'Copied'];
            if (total > 0) {
              for (var i = 0; i < finalarr.length; i++) {
                percentarr.push({
                  value:
                    (Number.parseInt(finalarr[i]) / Number.parseInt(total)) *
                    100,
                  label: labels[i],
                  id: i + 'key',
                });
              }
            } else {
              percentarr = [];
            }
            setGetRating(percentarr);
          } catch (error) {}
        }
      },
      onError: err => {
        if (err.networkError) {
        } else {
          // setinterneterr(false);
        }
      },
    },
    [],
  );
  return (
    <React.Fragment>
      <Card elevation={1} className={classes.Paper}>
        <div className={classes.userDetailsBorderStyle}>
          <div
            onClick={() => {
              navigate('/profile', {state: props.data.postedBy.userId});
            }}>
            {props.data.postedBy.userType === 'Professional' ? (
              <Badge
                overlap="circular" // variant="dot"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                badgeContent={
                  <img
                    src={GraduationSymbol1}
                    alt="cap"
                    style={{
                      width: 20,
                      height: 20,
                    }}
                  />
                }>
                <Avatar
                  src={
                    props.data.postedBy
                      ? props.data.postedBy.profileImageUrl
                      : 'UserImage'
                  }
                  className={classes.UserProfileStyle}
                />
              </Badge>
            ) : (
              <Avatar
                // alt={userDetailsData.name.substring(1)}
                alt="UserImage"
                src={
                  props.data.postedBy
                    ? props.data.postedBy.profileImageUrl
                    : 'Remy-shep'
                }
                className={classes.UserProfileStyle}
              />
            )}
          </div>
          <div
            className={classes.UserNameAndLocatoinBorderStyle}
            onClick={() => {
              navigate('/profile', {state: props.data.postedBy.userId});
            }}>
            <Typography variant="subtitle2" className={classes.fontWeightBold}>
              {props.data.postedBy ? props.data.postedBy.name : 'user'} @
              {props.data.postedBy ? props.data.postedBy.collegeName : 'user'}
            </Typography>
            <div className={classes.DisplayFlex}>
              <Typography className={classes.PostAddTimeTextStyle}>
                {moment
                  .utc(props.data.createdOn)
                  .local()
                  .startOf('seconds')
                  .fromNow()}
              </Typography>
            </div>

          </div>
          <div>
            <IconButton
              className={classes.MoreIconStyle}
              onClick={handleOpenForOption}>
              <MoreVertOutlined />
            </IconButton>
            <Modal open={openModalForOption} onClose={handleCloseForOption}>
              {body}
            </Modal>
          </div>
        </div>
        <Divider style={{marginBottom: '0.5rem'}} />
        <div>
          <Typography className={classes.PostDesStyle}>
            {props.data.description.includes('sp@%12')
              ? renderhashtags(props.data.description.split('sp@%12')[0])
              : renderhashtags(
                  isReadMore
                    ? props.data.description.slice(0, 400)
                    : props.data.description,
                )}

            {props.data.description.length > 400 &&
              !props.data.description.includes('sp@%12') && (
                <span
                  onClick={toggleReadMore}
                  className="read-or-hide"
                  style={{color: '#00A3FF', cursor: 'pointer'}}>
                  {isReadMore ? '...read more' : ' read less'}
                </span>
              )}
          </Typography>

          {props.data.media &&
            props.data.media.map((item, index, key) => {
              if (item.type === 'image') {
                return (
                  <div>
                    <div
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        handleOpenView(item.url);
                      }}>
                      <img
                        // onClick={handleOpenImage}
                        key={index}
                        src={item.url}
                        alt="user"
                        width="300"
                        height="300"
                        className={classes.PostImageStyle}
                      />
                    </div>
                    <Modal
                      open={openView}
                      onClose={handleCloseView}
                      aria-labelledby="simple-modal-title"
                      aria-describedby="simple-modal-description">
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'start',
                          margin: '2rem',
                        }}>
                        <img
                          style={{
                            width: 300,
                            height: '100%',
                          }}
                          src={fullimage}
                          alt="media"
                        />
                        <IconButton
                          onClick={handleCloseView}
                          style={{
                            width: '1rem ',
                            height: '1rem',
                            backgroundColor: 'white',
                          }}>
                          <CloseIcon />
                        </IconButton>
                      </div>
                    </Modal>
                  </div>
                );
              } else if (item.type === 'video') {
                return (
                  <video
                    id="index"
                    className="VideoInput_video"
                    width={'100%'}
                    height={'100%'}
                    controls={true}
                    preload="none"
                    loop="false"
                    ref={videoRef}
                    autoPlay={shouldAutoplay}
                    src={item.url}
                  />
                );
              } else if (item.type === item.url) {
                return (
                  <iframe
                    id="index"
                    title="linkType"
                    height="100%"
                    width="100%"
                    controls
                    src={item.url}
                  />
                );
              } else {
                return (
                  <object
                    id="index"
                    title="doctype"
                    height="100%"
                    width="100%"
                    type="application/pdf"
                    controls
                    data={
                      'https://drive.google.com/viewerng/viewer?embedded=true&url=' +
                      item.url
                    }
                  />
                );
              }
            })}
          {extractLinks(props.data.description) &&
          extractLinks(props.data.description) !== '' ? (
            extractVideoId(extractLinks(props.data.description)) &&
            extractVideoId(extractLinks(props.data.description)) !== '' && (
              <iframe
                width="100%"
                height="100%"
                src={
                  'https://www.youtube.com/embed/' +
                  extractVideoId(extractLinks(props.data.description))
                }
                frameborder="0"
                allowfullscreen></iframe>
            )
          ) : (
            <div />
          )}
          {props.data.description.includes('sp@%12') &&
            JSON.parse(props.data.description.split('sp@%12')[1]).map(
              (item, index) => {
                return <CrispyPostDisplay data={item} index={index} />;
              },
            )}
        </div>

        <div className={classes.LikeCommentShareBorderStyle}>
          <div
            className={classes.DisplayFlex}
            onClick={() => {
              seticonChangeForLike(!iconChangeForLike);
            }}>
            {iconChangeForLike ? (
              <Button
                className={classes.ButtonStyle}
                disableRipple
                onClick={like}
                startIcon={
                  <FavoriteBorderIcon className={classes.IcontNotSelect} />
                }>
                {props.data.likeCount || props.data.likeCount !== 0
                  ? props.data.likeCount
                  : ''}
              </Button>
            ) : (
              <Button
                className={classes.ButtonStyle}
                disableRipple
                onClick={unlike}
                startIcon={<FavoriteIcon className={classes.liked} />}>
                {props.data.likeCount || props.data.likeCount !== 0
                  ? props.data.likeCount
                  : ''}
              </Button>
            )}
          </div>

          <div className={classes.CommentIconBorderStyle}>
            <Button
              onClick={handleOpen}
              type="submit"
              disableRipple
              className={classes.ButtonStyle}
              startIcon={<ChatIcon className={classes.IcontNotSelect} />}>
              {props.data.commentCount || props.data.commentCount !== 0
                ? props.data.commentCount
                : ''}
            </Button>
          </div>

          <div className={classes.CommentIconBorderStyle}>
            <Button
              className={classes.ButtonStyle}
              disableRipple
              onClick={() => {
                if (props.data.postedBy.userId === cookies.user.userId) {
                  handleOpenForView();
                }
              }}
              startIcon={
                <RemoveRedEyeOutlinedIcon className={classes.IcontNotSelect} />
              }>
              {props.data.viewCount || props.data.viewCount !== 0
                ? props.data.viewCount
                : ''}
            </Button>
            <Modal open={openForView} onClose={handleCloseForView}>
              {view}
            </Modal>
          </div>
          <div className={classes.CommentIconBorderStyle}>
            <Button
              aria-describedby={id}
              className={classes.ButtonStyle}
              disableRipple
              onClick={
                props.data.postedBy.userId !== cookies.user.userId
                  ? handleClickPopUp
                  : handleClickOpenForPieChart
              }
              startIcon={
                <RateReviewOutlinedIcon className={classes.IcontNotSelect} />
              }>
              {props.data.markedByCount || props.data.markedByCount !== 0
                ? props.data.markedByCount
                : ''}
            </Button>
            {props.data.postedBy.userId !== cookies.user.userId ? (
              <Popover
                id={id}
                open={openForPopUp}
                anchorEl={anchorEl}
                onClose={handleCloseForPopUp}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    flexWrap: 'wrap',
                    gap: '1rem',
                    top: '1rem',
                    backgroundColor: '#E2F9FF',
                  }}>
                  <div
                    style={{display: 'flex', flexDirection: 'column'}}
                    onClick={() => {
                      // alert('Thank you for your feedback');
                      handleCloseForPopUp();
                      ratePost({
                        variables: {
                          postId: Number.parseInt(props.data.postId),
                          userModelInputFilter: {
                            userId: cookies.user.userId,
                          },
                          markAs: 'relevant',
                        },
                      })
                        .then(res => {})
                        .catch(err => {
                          console.log(err);
                        });
                    }}>
                    <IconButton>
                      <ThumbUpIcon style={{color: '#3399FF'}} />{' '}
                    </IconButton>
                    <Typography variant="body2">Relevant</Typography>
                  </div>
                  <div
                    style={{display: 'flex', flexDirection: 'column'}}
                    onClick={() => {
                      // alert('Thank you for your feedback');
                      handleCloseForPopUp();
                      ratePost({
                        variables: {
                          postId: Number.parseInt(props.data.postId),
                          userModelInputFilter: {
                            userId: cookies.user.userId,
                          },
                          markAs: 'notRelevant',
                        },
                      })
                        .then(res => {})
                        .catch(err => {
                          console.log(err);
                        });
                    }}>
                    <IconButton>
                      <ThumbDownIcon style={{color: '#FF5733'}} />{' '}
                    </IconButton>
                    <Typography variant="body2">Irrelevant</Typography>
                  </div>
                  <div
                    style={{display: 'flex', flexDirection: 'column'}}
                    onClick={() => {
                      // alert('Thank you for your feedback');
                      handleCloseForPopUp();
                      ratePost({
                        variables: {
                          postId: Number.parseInt(props.data.postId),
                          userModelInputFilter: {
                            userId: cookies.user.userId,
                          },
                          markAs: 'seen',
                        },
                      })
                        .then(res => {})
                        .catch(err => {
                          console.log(err);
                        });
                    }}>
                    <IconButton>
                      <RepeatIcon style={{color: '#1C4FA2'}} />{' '}
                    </IconButton>
                    <Typography variant="body2">Repeated</Typography>
                  </div>
                  <div
                    style={{display: 'flex', flexDirection: 'column'}}
                    onClick={() => {
                      // alert('Thank you for your feedback');
                      handleCloseForPopUp();
                      ratePost({
                        variables: {
                          postId: Number.parseInt(props.data.postId),
                          userModelInputFilter: {
                            userId: cookies.user.userId,
                          },
                          markAs: 'trending',
                        },
                      })
                        .then(res => {})
                        .catch(err => {
                          console.log(err);
                        });
                    }}>
                    <IconButton>
                      <CopyrightIcon style={{color: '#FF5733'}} />{' '}
                    </IconButton>
                    <Typography variant="body2">Copied</Typography>
                  </div>
                </div>
              </Popover>
            ) : (
              <Dialog
                onClose={handleClosForPieChart}
                aria-labelledby="customized-dialog-title"
                open={openForPieChart}>
                <MuiDialogTitle
                  style={{marginBottom: '-1rem'}}
                  id="customized-dialog-title">
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'flex-start',
                      justifyContent: 'space-around',
                      gap: '3rem',
                    }}>
                    <Typography variant="h6">Your Post Relevency</Typography>
                    <IconButton
                      aria-label="close"
                      style={{bottom: '0.5rem'}}
                      onClick={handleClosForPieChart}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                </MuiDialogTitle>
                <DialogContent
                  dividers
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                  }}>
                  {getRating && getRating.length > 1 ? (
                    <PieChart
                      colors={palette}
                      series={[
                        {
                          data: getRating,
                        },
                      ]}
                      width={400}
                      height={200}
                    />
                  ) : (
                    <Typography variant="body1">
                      There is no post relevency data
                    </Typography>
                  )}
                </DialogContent>
              </Dialog>
            )}
          </div>

          <div className={classes.CommentIconBorderStyle}>
            <Button
              className={classes.ButtonStyle}
              disableRipple
              // onClick={generateDynamicLink}
              onClick={handleClickOpenForShare}
              startIcon={
                <ShareOutlinedIcon className={classes.IcontNotSelect} />
              }>
              {props.data.shareCount || props.data.shareCount !== 0
                ? props.data.shareCount
                : ''}
            </Button>
          </div>

          <Dialog
            onClose={handleCloseForShare}
            aria-labelledby="customized-dialog-title"
            open={openForShare}>
            <DialogTitle
              id="customized-dialog-title"
              onClose={handleCloseForShare}>
              Share To
            </DialogTitle>
            <MuiDialogContent dividers>
              <div
                style={{
                  width: '30rem',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                  borderRadius: '2rem',
                }}>
                <div
                  style={{
                    gap: '1rem',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                  }}>
                  <LinkedinShareButton url={linkstoshare}>
                    <LinkedinIcon
                      size={32}
                      round={true}
                      onClick={handleCloseForShare}
                    />
                    <Typography variant="body2">LinkedIn</Typography>
                  </LinkedinShareButton>

                  <WhatsappShareButton url={linkstoshare}>
                    <WhatsappIcon
                      size={32}
                      round={true}
                      onClick={handleCloseForShare}
                    />
                    <Typography variant="body2">Whatsapp</Typography>
                  </WhatsappShareButton>

                  <TelegramShareButton url={linkstoshare}>
                    <TelegramIcon
                      size={32}
                      round={true}
                      onClick={handleCloseForShare}
                    />
                    <Typography variant="body2">Telegram</Typography>
                  </TelegramShareButton>
                  <FacebookShareButton url={linkstoshare}>
                    <FacebookIcon
                      size={32}
                      round={true}
                      onClick={handleCloseForShare}
                    />
                    <Typography variant="body2">Facebook</Typography>
                  </FacebookShareButton>
                  <TwitterShareButton url={linkstoshare}>
                    <TwitterIcon
                      size={32}
                      round={true}
                      onClick={handleCloseForShare}
                    />
                    <Typography variant="body2">Twitter</Typography>
                  </TwitterShareButton>
                  <EmailShareButton url={linkstoshare}>
                    <EmailIcon
                      size={32}
                      round={true}
                      onClick={handleCloseForShare}
                    />
                    <Typography variant="body2">Email</Typography>
                  </EmailShareButton>
                </div>
              </div>
            </MuiDialogContent>
          </Dialog>

          <Modal open={open}>
            <div className={classes.ModalDivStyle}>
              <Button
                disableRipple
                onClick={handleClose}
                style={{left: '60rem', bottom: '14.5rem'}}
                startIcon={<CloseIcon color="default" />}></Button>

              <div>
                <CommentScreen data={props.data} />
              </div>
            </div>
          </Modal>
        </div>
      </Card>
    </React.Fragment>
  );
};

export default PostDesgin;
