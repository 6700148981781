import React, {useState, useEffect, useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import CommentCard from './CommentCard';
import FavoriteIcon from '@material-ui/icons/Favorite';
import {Cancel, FavoriteBorder} from '@material-ui/icons';
import ChatIcon from '@material-ui/icons/Chat';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import {
  Typography,
  Avatar,
  Divider,
  Modal,
  DialogContent,
  Button,
  TextField,
  Dialog,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import {PieChart} from '@mui/x-charts/PieChart';
import {db} from '../Auth/firebase';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import ShowComments from '../../queries/ShowComments';
import CreateComment from '../../queries/CreateComment';
import {
  useQuery,
  useMutation,
  useApolloClient,
  useLazyQuery,
} from '@apollo/client';
import RatePost from '../../queries/RatePost';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import RepeatIcon from '@material-ui/icons/Repeat';
import CopyrightIcon from '@material-ui/icons/Copyright';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import Popover from '@material-ui/core/Popover';
import {useCookies} from 'react-cookie';
import LikePost from '../../queries/LikePost';
import UnLikePost from '../../queries/UnLikePost';
import ShowPost from '../../queries/ShowPost';
import {useNavigate} from 'react-router-dom';
import CrispyPostDisplay from '../postDesing/CrispyPostDisplay';
import {Comment} from 'react-loader-spinner';
import {uploadFile} from 'react-s3';
import ViewCount from '../postDesing/ViewCount';
import {FirebaseDynamicLinks} from 'firebase-dynamic-links';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from 'react-share';
import PollDisplayForComment from '../postDesing/PollDisplayForComment';
import GetRatings from '../../queries/GetRatings';
const DialogTitle = props => {
  const {children, classes, onClose, ...other} = props;
  return (
    <MuiDialogTitle
      disableTypography
      {...other}
      style={{
        display: 'flex',
        flexDirection: 'row',
        borderRadius: '1rem',
        gap: '22rem',
        marginBottom: '-1.5rem',
      }}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          style={{bottom: '0.4rem'}}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const useStyles = makeStyles(theme => ({
  PostImageStyle: {flex: 1},
  UserProfileStyle: {
    width: '2rem',
    height: '2rem',
  },

  MoreIconStyle: {
    width: '1.5rem',
    height: '1.5rem',
  },
  LikeCommentShareBorderStyle: {
    margin: '0.5rem 1rem 0rem 1rem',
    display: 'flex',
  },
  ButtonStyle: {
    padding: 0,
    // fontSize:"10",
    textTransform: 'capitalize',
    backgroundColor: theme.palette.color.white,
    '&:hover': {
      background: theme.palette.color.white,
    },
  },
  animation: {display: 'flex', justifyContent: 'center', alignItems: 'center'},
  right: {
    width: '100%',
    height: '22rem',
    overflowY: 'scroll',
    margin: '0.5rem 0',
  },
  DisplayFlex: {display: 'flex', fontSize: 18},
  IcontNotSelect: {
    color: theme.palette.color.grey1,
  },
  CommentIconBorderStyle: {display: 'flex', margin: '0rem 1rem'},
  TextFieldStyle: {
    margin: '0.2rem 1rem',
    width: '100%',
    height: '3rem',
    overflowY: 'auto',
  },
  liked: {color: '#FF0000'},

  SubmitButtonBorder: {textAlign: 'center', marginTop: '0.5rem'},
  SubmitButton: {
    textTransform: 'capitalize',
    color: theme.palette.color.white,
    padding: '0',
    marginRight: '1rem',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  root: {
    backgroundColor: '#000',
    display: 'flex',
    height: '32rem',
    width: '60rem',
  },
  main: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  content: {
    width: '30rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    borderRadius: '2rem',
  },
  share: {
    gap: '1rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  input: {
    display: 'none',
  },
}));
const palette = ['#ff9d00', '#00a3ff', 'black', '#FF3131'];

const CommentScreen = props => {
  const [commentScreeData, setcommentScreeData] = useState([]);
  const [commentCapture, setCommentCapture] = useState('');
  const [cookies, setCookie] = useCookies(['user']);
  const [getSubscriptionsData, setGetSubscriptionsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [iconChangeForLike, seticonChangeForLike] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [linkstoshare, setlinkstoshare] = useState('');
  const [mediaType, setMediaType] = useState();
  const [mediaName, setMediaName] = useState();
  const [mediaData, setmediaData] = useState([]);
  const [awsdata, setawsdata] = useState({});
  const [config, setconfig] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openForPieChart, setOpenForPieChart] = useState(false);
  const [getRating, setGetRating] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [openForShare, setOpenForShare] = useState(false);
  const [openForView, setOpenForView] = useState(false);

  useEffect(() => {
    db.collection('details')
      .doc('AWSconstants')
      .get()
      .then(snap => {
        setawsdata(snap.data());
        setconfig({
          bucketName: snap.data().bucket,
          region: snap.data().region,
          accessKeyId: snap.data().accessKey,
          secretAccessKey: snap.data().secretKey,
        });
      })
      .catch(err => {
        console.log(err);
      });
  }, []);
  const handleFileInput = e => {
    setSelectedFile(e.target.files[0]);
  };
  const handleUpload = async (file, type) => {
    uploadFile(file, config)
      .then(data => {
        console.log('uploaded');
        var mediaarr = [];
        var obj = {
          url: data.location,
          type: mediaType,
          name: mediaName ? mediaName : '',
        };
        mediaarr = [...mediaData, obj];
        setmediaData([...mediaData, obj]);
        handleAddComment(mediaarr);
      })
      .catch(err => console.error(err));
  };
  console.log(mediaType);

  const trackRef = useRef([]);
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  const clientsupd = useApolloClient();
  const firebaseDynamicLinks = new FirebaseDynamicLinks(
    'AIzaSyAa3zif99kE5V0qgP_dYoLpwWOfwv12XbQ',
  );

  const generateDynamicLink = () => {
    firebaseDynamicLinks
      .createLink({
        dynamicLinkInfo: {
          domainUriPrefix: 'https://studentcontinent.page.link',
          link:
            'https://play.google.com/store/apps/details?id=com.scontinent.sconti&postid=' +
            props.data.postId,
          androidInfo: {
            androidPackageName: 'com.scontinent.sconti',
          },
        },
      })
      .then(res => {
        setlinkstoshare(res.shortLink);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleClickOpenForShare = () => {
    generateDynamicLink();
    setOpenForShare(true);
  };
  const handleCloseForShare = () => {
    setOpenForShare(false);
  };

  const {fetchMore} = useQuery(
    ShowComments,
    {
      // getCommentsForPost
      variables: {
        postId: props.data.postId,
        userId: cookies.user.userId,
        limit: 10,
        nextToken: null,
      },
      fetchPolicy: 'network-only',
      onCompleted: res => {
        console.log('getCommentsForPost', res);

        setcommentScreeData(
          res.getCommentsForPost ? res.getCommentsForPost.edges : [],
        );
        setLoading(false);
        setGetSubscriptionsData(res.getCommentsForPost);
        clientsupd.writeQuery({
          query: ShowComments,
          variables: {
            postId: props.data.postId,
            userId: cookies.user.userId,
            limit: 10,
            nextToken: null,
          },
          data: res,
        });
      },
      onError: err => {
        console.log(err);
      },
    },
    [],
  );
  console.log('get subscriptiondata : ', getSubscriptionsData);

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        nextToken: getSubscriptionsData.pageInfo.endCursor,
        postId: props.data.postId,
        userId: cookies.user.userId,
        limit: 10,
      },

      updateQuery: (prev, {fetchMoreResult}) => {
        if (!fetchMoreResult) return prev;

        setGetSubscriptionsData(fetchMoreResult.getCommentsForPost);
        return {
          ...prev,
          getCommentsForPost: {
            ...prev.getCommentsForPost,
            edges: [
              ...(prev.getCommentsForPost ? prev.getCommentsForPost.edges : []),
              ...(fetchMoreResult.getCommentsForPost
                ? fetchMoreResult.getCommentsForPost.edges
                : []),
            ],
            pageInfo: fetchMoreResult.getCommentsForPost?fetchMoreResult.getCommentsForPost.pageInfo:[],
          },
        };
      },
    });
  };

  useEffect(() => {
   
      const handleScroll = () => {
        if (getSubscriptionsData && getSubscriptionsData.pageInfo) {
          if (
            !trackRef.current.includes(
              getSubscriptionsData
                ? getSubscriptionsData.pageInfo.endCursor
                : '',
            )
          ) {
            handleLoadMore();
            trackRef.current = [
              ...trackRef.current,
              getSubscriptionsData
                ? getSubscriptionsData.pageInfo.endCursor
                : '',
            ];
          }
          console.log(trackRef.current);
        }
      // };
    }
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [commentScreeData]); // Ensure that this effect is executed whenever new data is fetched

  const [addCommentMutation] = useMutation(CreateComment);
  const updateCache = (client, {data: {createComment}}) => {
    const data = client.readQuery({
      query: ShowComments,
      variables: {
        postId: props.data.postId,
        userId: cookies.user.userId,
        limit: 10,
        nextToken: null,
      },
    });
    const data1 = client.readQuery({
      query: ShowPost,
      variables: {
        limit: 10,
        nextToken: null,
        userModelInputFilteruser: {
          userId: cookies.user.userId,
        },
      },
    });
    if (createComment) {
      var obj = {node: createComment};
      const newArrays = [obj, ...data.getCommentsForPost.edges];
      const newData = {
        getCommentsForPost: {...data.getCommentsForPost, edges: newArrays},
      };

      client.writeQuery({
        query: ShowComments,
        variables: {
          postId: props.data.postId,
          userId: cookies.user.userId,
          limit: 10,
          nextToken: null,
        },
        data: newData,
      });

      var newArray = [];
      data1.getSubscriptions.edges.map((item, index) => {
        if (item.node.postId === props.data.postId) {
          newArray.push({
            node: {
              ...item.node,
              commentCount: newData.getCommentsForPost.length,
            },
          });
        } else {
          newArray.push(item);
        }
      });
      // console.log('likechanges', newArray[0].node.postedBy.likedByMe);
      const newData1 = {
        getSubscriptions: {...data1.getSubscriptions, edges: newArray},
      };
      // const newData = {getSubscriptions: newArray};
      client.writeQuery({
        query: ShowPost,
        variables: {
          limit: 10,
          nextToken: null,
          userModelInputFilteruser: {
            userId: cookies.user.userId,
          },
        },
        data: newData1,
      });
    }
  };
  const handleScroll = (event) => {
    // console.log('commscroll');
    if (
      event.currentTarget.scrollLeft + event.currentTarget.offsetTop >=
      document.body.offsetHeight - 500
    ) {
      console.log('pagination if check');
      console.log(trackRef.current);

    if (getSubscriptionsData && getSubscriptionsData.pageInfo) {
      if (
        !trackRef.current.includes(
          getSubscriptionsData ? getSubscriptionsData.pageInfo.endCursor : '',
        )
      ) {
        handleLoadMore();
        trackRef.current = [
          ...trackRef.current,
          getSubscriptionsData ? getSubscriptionsData.pageInfo.endCursor : '',
        ];
      }
      console.log(trackRef.current);
    }
    }
  };
  const handleAddComment = mediaData => {
    addCommentMutation({
      variables: {
        description: commentCapture,
        postId: props.data.postId,
        createdById: cookies.user.userId,
        isSubComment: false,
        mediaData: mediaData,
      },
      update: updateCache,
    }).then(res => {
      setCommentCapture('');
      setSelectedImage(null); // updates and get cleared
    });
  };
  //hashtag concept-----------------
  const clickableHashtagGenerator = (str, baseUrl) => {
    let elem = str;
    elem = elem
      .replace(
        /(https?:\/\/[^\s]+)/g,
        `<a class='link' href="$1" target='_blank'>$1</a>`,
      )
      .replace(/(@[^\s]+)/g, `<a class='username' title='$1'>$1</a>`)
      .replace(/(#[^\s]+)/g, `(<a class='hashtag' >$1</a>)`);

    return elem;
  };

  // Ensure that this effect is executed whenever new data is fetched

  const buildmentionstring = string => {
    const splitReg = /(#[^\s]+)/g; // Regex used to split the string
    let userNames = []; // array to store all names
    let str = [];

    const replaceStrong = string.split(' ').map(v => {
      if (v[0] === '#') {
        str.push({key: v, ishash: true});
      } else {
        str.push({key: v, ishash: false});
      }
    });

    return str;
  };
  const renderhashtags = text => {
    return (
      <div style={{flexWrap: 'wrap', flexDirection: 'row', display: 'flex'}}>
        {buildmentionstring(text).map((item, index) => {
          if (item.ishash === true) {
            return (
              <div
                onClick={() => {
                  navigate('/hashtagDisplay', {state: item.key});
                }}>
                <Typography
                  style={{color: '#ff9d00', marginLeft: 5, cursor: 'pointer'}}>
                  {item.key}
                </Typography>
              </div>
            );
          } else {
            return <Typography style={{marginLeft: 5}}>{item.key}</Typography>;
          }
        })}
      </div>
    );
  };
  const navigate = useNavigate();
  const classes = useStyles();
  const [likemutation] = useMutation(LikePost);
  const [unlikemutation] = useMutation(UnLikePost);
  const updateCacheforlike = (client, {data: {likePost}}) => {
    console.log('entered');
    const data = client.readQuery({
      query: ShowPost,
      variables: {
        limit: 10,
        nextToken: null,
        userModelInputFilteruser: {userId: cookies.user.userId},
      },
    });

    // const newArray = data.getSubscriptions.edges.filter(
    //   item => item.node.postId !== props.data.postId,
    // );
    var newArray = [];
    data.getSubscriptions.edges.map((item, index) => {
      if (item.node.postId === props.data.postId) {
        newArray.push({
          node: {
            ...item.node,
            likedByMe: true,
            likeCount: likePost,
          },
        });
      } else {
        newArray.push(item);
      }
    });
    // console.log('likechanges', newArray[0].node.postedBy.likedByMe);
    const newData = {
      getSubscriptions: {...data.getSubscriptions, edges: newArray},
    };
    // const newData = {getSubscriptions: newArray};
    client.writeQuery({
      query: ShowPost,
      variables: {
        limit: 10,
        nextToken: null,
        userModelInputFilteruser: {userId: cookies.user.userId},
      },
      data: newData,
    });
  };
  const updateCacheforunlike = (client, {data: {unlikePost}}) => {
    console.log('entered');
    const data = client.readQuery({
      query: ShowPost,
      variables: {
        limit: 10,
        nextToken: null,
        userModelInputFilteruser: {userId: cookies.user.userId},
      },
    });

    // const newArray = data.getSubscriptions.edges.filter(
    //   item => item.node.postId !== props.data.postId,
    // );
    var newArray = [];
    data.getSubscriptions.edges.map((item, index) => {
      if (item.node.postId === props.data.postId) {
        newArray.push({
          node: {
            ...item.node,
            likedByMe: false,
            likeCount: unlikePost,
          },
        });
      } else {
        newArray.push(item);
      }
    });
    // console.log('followchanges', newArray[0].node.postedBy.amIFollowing);
    const newData = {
      getSubscriptions: {...data.getSubscriptions, edges: newArray},
    };
    // const newData = {getSubscriptions: newArray};
    client.writeQuery({
      query: ShowPost,
      variables: {
        limit: 10,
        nextToken: null,
        userModelInputFilteruser: {userId: cookies.user.userId},
      },
      data: newData,
    });
  };
  const like = () => {
    likemutation({
      variables: {
        postId: props.data.postId,
        likedByUserId: cookies.user.userId,
      },
      update: updateCacheforlike,
    })
      .then(res => {
        console.log(res);
        alert('liked successfully');
      })
      .catch(err => {
        console.log(err);
      });
  };

  const unlike = () => {
    unlikemutation({
      variables: {
        postId: props.data.postId,
        likedByUserId: cookies.user.userId,
      },
      update: updateCacheforunlike,
    })
      .then(res => {
        console.log(res);
        alert('Unliked successfully');
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    seticonChangeForLike(!props.data.likedByMe);
  }, []);

  const handleClickOpenForPieChart = () => {
    getViewers();
    setOpenForPieChart(true);
  };
  const handleClosForPieChart = () => {
    setOpenForPieChart(false);
  };

  const [ratePost] = useMutation(RatePost);

  const handleClickPopUp = event => {
    setAnchorEl(event.currentTarget);
  };

  const openForPopUp = Boolean(anchorEl);
  const id = openForPopUp ? 'simple-popover' : undefined;

  const handleCloseForPopUp = () => {
    setAnchorEl(null);
  };
  const handleOpenForView = () => {
    setOpenForView(true);
  };

  const handleCloseForView = () => {
    setOpenForView(false);
  };
  const view = (
    <ViewCount
      closeViewForPost={handleCloseForView}
      ismyPost={props.data.postedBy.userId === cookies.user.userId}
      postId={props.data.postId}
    />
  );
  const [getViewers, {fetchMore: fetchMoreRatings}] = useLazyQuery(
    GetRatings,
    {
      variables: {
        postId: props.data.postId,
      },
      fetchPolicy: 'network-only',
      onCompleted: res => {
        console.log('ratings', res);
        if (res.getRatingwiseMarkCount) {
          try {
            var data = res.getRatingwiseMarkCount;
            // var datas = delete data.__typename;
            var total =
              data.notRelevant + data.relevant + data.seen + data.trending;
            var arrmark = Object.values(data);

            var finalarr = arrmark.slice(1);
            var percentarr = [];
            var labels = ['Relevant', 'Irrelevant', 'Repeated', 'Copied'];
            if (total > 0) {
              for (var i = 0; i < finalarr.length; i++) {
                percentarr.push({
                  value:
                    (Number.parseInt(finalarr[i]) / Number.parseInt(total)) *
                    100,
                  label: labels[i],
                  id: i + 'key',
                });
              }
            } else {
              percentarr = [];
            }
            setGetRating(percentarr);
          } catch (error) {}
        }
      },
      onError: err => {
        if (err.networkError) {
        } else {
          // setinterneterr(false);
        }
      },
    },
    [],
  );

  return (
    <div className={classes.root} style={{}}>
      <div className={classes.main}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '30rem',
            width: '30rem',
            overflowY: 'scroll',
          }}>
          {props.data.poll ? (
            <div style={{height: '30rem', width: '30rem'}}>
              <PollDisplayForComment data={props.data} />
            </div>
          ) : (
            <div />
          )}
          {props.data.description.includes('sp@%12') &&
            JSON.parse(props.data.description.split('sp@%12')[1]).map(
              (item, index) => {
                return <CrispyPostDisplay data={item} index={index} />;
              },
            )}
          {props.data.media &&
            props.data.media.map((item, index) => {
              if (item.type === 'image') {
                return props.data.description.includes('sp@%12') ? (
                  <img
                    src={item.url}
                    style={{
                      width: '300px',
                      height: '400px',
                      objectFit: 'scale-down',
                    }}
                    alt="hanuman"
                    // className={classes.PostImageStyle}
                  />
                ) : (
                  <img
                    src={item.url}
                    height={'100%'}
                    width="100%"
                    alt="hanuman"
                    className={classes.PostImageStyle}
                  />
                );
              } else if (item.type === 'video') {
                return (
                  <video
                    className="VideoInput_video"
                    width={'100%'}
                    height={'100%'}
                    controls
                    src={item.url}
                  />
                );
              } else {
                return (
                  <iframe
                    title="doctype"
                    height="100%"
                    width="100%"
                    controls
                    src={
                      'https://drive.google.com/viewerng/viewer?embedded=true&url=' +
                      item.url
                    }
                  />
                );
              }
            })}
        </div>
      </div>
      <div style={{flex: 1, backgroundColor: '#fff'}}>
        <div>
          <div style={{display: 'flex', margin: '1rem 1rem 0'}}>
            <Avatar
              style={{cursor: 'pointer'}}
              onClick={() => {
                navigate('/profile', {state: props.data.postedBy.userId});
              }}
              alt="Remy Sharp"
              src={props.data.postedBy.profileImageUrl}
              className={classes.UserProfileStyle}
            />
            <div
              style={{
                margin: '-0.27rem 0.5rem 0rem',
                flex: 1,
                cursor: 'pointer',
              }}
              onClick={() => {
                navigate('/profile', {state: props.data.postedBy.userId});
              }}>
              <Typography
                variant="subtitle2"
                style={{
                  fontWeight: 'bold',
                }}>
                {props.data.postedBy.name}
              </Typography>
              <Typography variant="subtitle2" style={{fontSize: 10}}>
                {props.data.postedBy.collegeName}
              </Typography>
            </div>
          </div>

          <div className={classes.right} onScroll={handleScroll}>
            <Typography variant="subtitle2" style={{margin: '0 1.2rem 0.5rem'}}>
              {props.data.description.includes('sp@%12')
                ? renderhashtags(props.data.description.split('sp@%12')[0])
                : renderhashtags(
                    isReadMore
                      ? props.data.description.slice(0, 400)
                      : props.data.description,
                  )}
              {(props.data.description.includes('sp@%12')
                ? props.data.description.split('sp@%12')[0]
                : props.data.description
              ).length > 400 && (
                <span
                  onClick={toggleReadMore}
                  className="read-or-hide"
                  style={{color: '#00A3FF', cursor: 'pointer'}}>
                  {isReadMore ? '...read more' : ' read less'}
                </span>
              )}{' '}
            </Typography>

            <Divider />
            <div style={{margin: '1rem'}} onScroll={handleScroll}>
              {loading ? (
                <CircularProgress
                  style={{marginLeft: '15rem', marginTop: '5rem'}}
                />
              ) : commentScreeData && commentScreeData.length > 0 ? (
                commentScreeData.map((item, key) => (
                  <div key={key}>
                    <CommentCard
                      commentScreeData={item.node}
                      postId={props.data.postId}
                    />
                  </div>
                ))
              ) : (
                <div className={classes.animation}>
                  <Comment
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="comment-loading"
                    wrapperStyle={{}}
                    wrapperClass="comment-wrapper"
                    color="#fff"
                    backgroundColor="#D3D3D3"
                  />
                  <Typography variant="body1" align="center" color="error">
                    Be the first one to comment{' '}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className={classes.LikeCommentShareBorderStyle}>
          <div
            className={classes.DisplayFlex}
            onClick={() => {
              seticonChangeForLike(!iconChangeForLike);
            }}>
            {iconChangeForLike ? (
              <Button
                className={classes.ButtonStyle}
                disableRipple
                onClick={like}
                startIcon={
                  <FavoriteBorder className={classes.IcontNotSelect} />
                }>
                {props.data.likeCount || props.data.likeCount !== 0
                  ? props.data.likeCount
                  : ''}
              </Button>
            ) : (
              <Button
                className={classes.ButtonStyle}
                style={{fontSize: 10}}
                disableRipple
                onClick={unlike}
                startIcon={<FavoriteIcon className={classes.liked} />}>
                {props.data.likeCount || props.data.likeCount !== 0
                  ? props.data.likeCount
                  : ''}
              </Button>
            )}
          </div>

          <div className={classes.CommentIconBorderStyle}>
            <Button
              type="submit"
              disableRipple
              className={classes.ButtonStyle}
              startIcon={<ChatIcon className={classes.IcontNotSelect} />}>
              {props.data.commentCount || props.data.commentCount !== 0
                ? props.data.commentCount
                : ''}
            </Button>
          </div>
          <div className={classes.CommentIconBorderStyle}>
            <Button
              className={classes.ButtonStyle}
              disableRipple
              onClick={() => {
                if (props.data.postedBy.userId === cookies.user.userId) {
                  handleOpenForView();
                }
              }}
              startIcon={
                <RemoveRedEyeOutlinedIcon className={classes.IcontNotSelect} />
              }>
              {props.data.viewCount || props.data.viewCount !== 0
                ? props.data.viewCount
                : ''}
            </Button>
            <Modal open={openForView} onClose={handleCloseForView}>
              {view}
            </Modal>
          </div>
          <div className={classes.CommentIconBorderStyle}>
            <Button
              aria-describedby={id}
              className={classes.ButtonStyle}
              disableRipple
              onClick={
                props.data.postedBy.userId !== cookies.user.userId
                  ? handleClickPopUp
                  : handleClickOpenForPieChart
              }
              startIcon={
                <RateReviewOutlinedIcon className={classes.IcontNotSelect} />
              }>
              {props.data.markedByCount || props.data.markedByCount !== 0
                ? props.data.markedByCount
                : ''}
            </Button>
            {props.data.postedBy.userId !== cookies.user.userId ? (
              <Popover
                id={id}
                open={openForPopUp}
                anchorEl={anchorEl}
                onClose={handleCloseForPopUp}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    flexWrap: 'wrap',
                    gap: '1rem',
                    top: '1rem',
                    backgroundColor: '#E2F9FF',
                  }}>
                  <div
                    style={{display: 'flex', flexDirection: 'column'}}
                    onClick={() => {
                      alert('Thank you for your feedback');
                      handleCloseForPopUp();
                      ratePost({
                        variables: {
                          postId: Number.parseInt(props.data.postId),
                          userModelInputFilter: {
                            userId: cookies.user.userId,
                          },
                          markAs: 'relevant',
                        },
                      })
                        .then(res => {})
                        .catch(err => {
                          console.log(err);
                        });
                    }}>
                    <IconButton>
                      <ThumbUpIcon style={{color: '#3399FF'}} />{' '}
                    </IconButton>
                    <Typography variant="body2">Relevant</Typography>
                  </div>
                  <div
                    style={{display: 'flex', flexDirection: 'column'}}
                    onClick={() => {
                      alert('Thank you for your feedback');
                      handleCloseForPopUp();
                      ratePost({
                        variables: {
                          postId: Number.parseInt(props.data.postId),
                          userModelInputFilter: {
                            userId: cookies.user.userId,
                          },
                          markAs: 'notRelevant',
                        },
                      })
                        .then(res => {})
                        .catch(err => {
                          console.log(err);
                        });
                    }}>
                    <IconButton>
                      <ThumbDownIcon style={{color: '#FF5733'}} />{' '}
                    </IconButton>
                    <Typography variant="body2">Irrelevant</Typography>
                  </div>
                  <div
                    style={{display: 'flex', flexDirection: 'column'}}
                    onClick={() => {
                      alert('Thank you for your feedback');
                      handleCloseForPopUp();
                      ratePost({
                        variables: {
                          postId: Number.parseInt(props.data.postId),
                          userModelInputFilter: {
                            userId: cookies.user.userId,
                          },
                          markAs: 'seen',
                        },
                      })
                        .then(res => {})
                        .catch(err => {
                          console.log(err);
                        });
                    }}>
                    <IconButton>
                      <RepeatIcon style={{color: '#1C4FA2'}} />{' '}
                    </IconButton>
                    <Typography variant="body2">Repeated</Typography>
                  </div>
                  <div
                    style={{display: 'flex', flexDirection: 'column'}}
                    onClick={() => {
                      alert('Thank you for your feedback');
                      handleCloseForPopUp();
                      ratePost({
                        variables: {
                          postId: Number.parseInt(props.data.postId),
                          userModelInputFilter: {
                            userId: cookies.user.userId,
                          },
                          markAs: 'trending',
                        },
                      })
                        .then(res => {})
                        .catch(err => {
                          console.log(err);
                        });
                    }}>
                    <IconButton>
                      <CopyrightIcon style={{color: '#FF5733'}} />{' '}
                    </IconButton>
                    <Typography variant="body2">Copied</Typography>
                  </div>
                </div>
              </Popover>
            ) : (
              <Dialog
                onClose={handleClosForPieChart}
                aria-labelledby="customized-dialog-title"
                open={openForPieChart}>
                <MuiDialogTitle
                  style={{marginBottom: '-1rem'}}
                  id="customized-dialog-title">
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'flex-start',
                      justifyContent: 'space-around',
                      gap: '3rem',
                    }}>
                    <Typography variant="h6">Your Post Relevency</Typography>
                    <IconButton
                      aria-label="close"
                      style={{bottom: '0.5rem'}}
                      onClick={handleClosForPieChart}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                </MuiDialogTitle>
                <DialogContent
                  dividers
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                  }}>
                  {getRating && getRating.length > 1 ? (
                    <PieChart
                      colors={palette}
                      series={[
                        {
                          data: getRating,
                        },
                      ]}
                      width={400}
                      height={200}
                    />
                  ) : (
                    <Typography variant="body1">
                      There is no post relevency data
                    </Typography>
                  )}
                </DialogContent>
              </Dialog>
            )}
          </div>

          <div>
            <Button
              className={classes.ButtonStyle}
              disableRipple
              onClick={handleClickOpenForShare}
              startIcon={
                <ShareOutlinedIcon className={classes.IcontNotSelect} />
              }>
              {props.data.shareCount || props.data.shareCount !== 0
                ? props.data.shareCount
                : ''}
            </Button>
            <Dialog
              onClose={handleCloseForShare}
              aria-labelledby="customized-dialog-title"
              open={openForShare}>
              <DialogTitle
                id="customized-dialog-title"
                onClose={handleCloseForShare}>
                Share To
              </DialogTitle>
              <MuiDialogContent dividers>
                <div className={classes.content}>
                  <div className={classes.share}>
                    <LinkedinShareButton url={linkstoshare}>
                      <LinkedinIcon size={32} round={true} />
                      <Typography variant="body2">LinkedIn</Typography>
                    </LinkedinShareButton>
                    <WhatsappShareButton url={linkstoshare}>
                      <WhatsappIcon size={32} round={true} />
                      <Typography variant="body2">Whatsapp</Typography>
                    </WhatsappShareButton>
                    <TelegramShareButton url={linkstoshare}>
                      <TelegramIcon size={32} round={true} />
                      <Typography variant="body2">Telegram</Typography>
                    </TelegramShareButton>
                    <FacebookShareButton url={linkstoshare}>
                      <FacebookIcon size={32} round={true} />
                      <Typography variant="body2">Facebook</Typography>
                    </FacebookShareButton>
                    <TwitterShareButton url={linkstoshare}>
                      <TwitterIcon size={32} round={true} />
                      <Typography variant="body2">Twitter</Typography>
                    </TwitterShareButton>
                    <EmailShareButton url={linkstoshare}>
                      <EmailIcon size={32} round={true} />
                      <Typography variant="body2">Email</Typography>
                    </EmailShareButton>
                  </div>
                </div>
              </MuiDialogContent>
            </Dialog>
          </div>
        </div>
        <Divider style={{margin: '0.5rem 0'}} />

        <div style={{display: 'flex'}}>
          <input
            accept="image/*"
            className={classes.input}
            id="icon-button-file"
            type="file"
            onChange={event => {
              setSelectedImage(event.target.files[0]);
              event.target.value = '';
            }}
          />
          <label htmlFor="icon-button-file">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span">
              <PhotoCamera style={{color: '#8B8B8B'}} />
            </IconButton>
          </label>
          {selectedImage !== null ? (
            <Cancel
              fontSize="small"
              style={{cursor: 'pointer', color: '#8B8B8B'}}
              onClick={() => {
                setSelectedImage(null);
              }}
            />
          ) : (
            <div />
          )}

          {selectedImage && (
            <div style={{margin: '0 1rem'}}>
              <img
                alt="not found"
                width="100px"
                height="50px"
                src={URL.createObjectURL(selectedImage)}
              />
            </div>
          )}
          <TextField
            multiline={true}
            autoFocus
            placeholder="Add a comment..."
            className={classes.TextFieldStyle}
            InputProps={{disableUnderline: true}}
            value={commentCapture}
            onChange={e => {
              setCommentCapture(e.target.value);
            }}
          />

          <div className={classes.SubmitButtonBorder}>
            <Button
              onClick={() => {
                if (commentCapture.trim().length > 0 || selectedImage) {
                  if (selectedImage) {
                    handleUpload(selectedImage);
                  } else {
                    if (commentCapture > 1000) {
                      alert(
                        'Comment length should not exceeds more than 1000 characters',
                      );
                    } else {
                      handleAddComment([]);
                    }
                  }
                } else {
                  alert(' empty comment is not valid');
                }
              }}
              variant="contained"
              color="primary"
              size="small"
              className={classes.SubmitButton}>
              Send
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommentScreen;
