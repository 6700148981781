import gql from 'graphql-tag';

export default gql`
  mutation answerPoll(
    $postId: ID!
    $answeredByUserId: ID!
    $selectedOption: Int!
  ) {
    answerPoll(
      postId: $postId
      answeredByUserId: $answeredByUserId
      selectedOption: $selectedOption
    ) {
      postId
      description
      media {
        name
        url
        type
        thumbnailUrl
      }
      poll {
        description
        options
        optionSelectionPercentage
        correctAnsIndex
        myAnsIndex
        amIAnswered
        totalAnswerCount
      }
      postedBy {
        name
        userId
        profileImageUrl
        collegeName
        amIFollowing
        userType
        companyName
      }
      interests {
        name
      }
      commentCount
      likeCount
      shareCount
      createdOn
      updatedOn
    }
  }
`;
