import gql from 'graphql-tag';

export default gql`
  mutation createUser(
    $name: String!
    $userId: String!
    $mobileNumber: String!
    $state: String!
    $company: String
    $college: String
    $userType: String
    $interests: [String]
    $fcmToken: String
    $businessAddress: String
    $businessLocation: String
    $designation: String
    $businessContactNumber: String
    $email: String
  ) {
    createUser(
      createUserInputModel: {
        name: $name
        userId: $userId
        mobileNumber: $mobileNumber
        state: $state
        company: $company
        college: $college
        userType: $userType
        interests: $interests
        fcmToken: $fcmToken
        businessAddress: $businessAddress
        businessLocation: $businessLocation
        designation: $designation
        businessContactNumber: $businessContactNumber
        email: $email
      }
    ) {
      name
      description
      score
      userId
      profileImageUrl
      bannerImageUrl
      dateOfBirth
      state
      userType
      college
      degree
      company
      faceBookUrl
      gitHubUrl
      instagramUrl
      linkedInUrl
      followersCount
      followingCount
      interests
      amIFollowing
      businessAddress
      businessLocation
      designation
      webUrl
      youtubeUrl
      businessContactNumber
      twitterUrl
      numberOfPosts
      email
    }
  }
`;