import React, {useState, useEffect, useRef} from 'react';
import UserDetails from '../../queries/UserDetails';
import Navbar from '../navbar/Navbar';
import LatestNews from './../news/LatestNews';
import PropTypes from 'prop-types';
import {Container, Paper} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Typography, Tabs, Tab, Box} from '@material-ui/core';
import ImageAspectRatioIcon from '@material-ui/icons/ImageAspectRatio';
import TheatersOutlinedIcon from '@material-ui/icons/TheatersOutlined';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import PostDesgin from '../postDesing/PostDesgin';
import ShowPostOfUser from '../../queries/ShowPostOfUser';
import SuggestionCard from './../suggestion_Card/SuggestionCard';
import CardProfile from './CardProfile';
import Media from './Media';
import DocumentShow from './DocumentShow';
import ShowPollOfUser from '../../queries/ShowPollOfUser';
import ShowDocsOfUser from '../../queries/ShowDocsOfUser';
import ShowMediaOfUser from '../../queries/ShowMediaOfUser';
import PollDisplay from '../postDesing/PollDisplay';
import {useLocation, useNavigate} from 'react-router-dom';
import {useCookies} from 'react-cookie';
import {useQuery} from '@apollo/client';
import {ThreeCircles} from 'react-loader-spinner';
import PhoneView from '../phoneView/PhoneView';
import { ListAlt } from '@material-ui/icons';

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  mainDiv: {
    display: 'flex',
    paddingTop: '4rem',
    height: window.innerHeight,
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
    },
  },

  News: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  Serach: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  PhoneViewDisable: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  PhoneViewEnable: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

  button: {
    flex: '1',
    display: 'flex',
    margin: '0rem 1rem 0.5rem',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  PostPeper: {
    width: '45rem',
    marginTop: '1rem',
    marginLeft: '2rem',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.down('md')]: {
      width: '50rem',
      marginLeft: 0,
    },
  },

  ProfilePostBorderStyle: {marginLeft: '2rem', display: 'flex'},
  marginTop1rem: {marginTop: '1.5rem', marginRight: '-1.5rem'},
  textTransform: {textTransform: 'capitalize'},
  activeTab: {fontWeight: 'bold', margin: '0.5rem 0rem 0 0.5rem'},
  TabPanelStyle: {
    width: '100%',
  },
  TabIndicatorPropsStyle: {
    height: '0',
  },
  inActiveTab: {
    margin: '0.5rem 0rem 0 0.5rem',
  },
}));

const Profile = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [userDetailsData, setuserDetailsData] = useState({});
  const [interests, setinterests] = useState('');
  const [cookies, setCookie] = useCookies(['user']);
  const [post, setPost] = useState([]);
  const [poll, setPoll] = useState([]);
  const [doc, setDoc] = useState([]);
  const [media, setMedia] = useState([]);
  const [postPaginateData, setPostPaginateData] = useState([]);
  const [pollPaginateData, setPollPaginateData] = useState([]);
  const [docPaginateData, setDocPaginateData] = useState([]);
  const [mediaPaginateData, setMediaPaginateData] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
 const navigate=useNavigate()
  const trackRef = useRef([]);
 

  useQuery(
    UserDetails,
    {
      variables: {
        id: location.state ? location.state : cookies.user?cookies.user.userId:"",
        visitType: location.state ? 'other' : 'self',
        visitingUserId: cookies.user?cookies.user.userId:"",
      },
      fetchPolicy: 'network-only',
      onCompleted: res => {
        var str = '';
        console.log('UserDetails', res);

        setuserDetailsData(res.getUserDetails ? res.getUserDetails : {});

        if (res.getUserDetails && res.getUserDetails.interests) {
          res.getUserDetails.interests.map(item => (str = str + item + ' , '));
          setinterests(str.substring(0, str.length - 2));
        }
        setLoading(false);
      },

      onError: err => {
        console.log(err);
      },
    },
    [],
  );
  const {fetchMore: fetchMorePosts} = useQuery(ShowPostOfUser, {
    variables: {
      limit: 10,
      nextToken: null,
      userModelInputFilter: {
        userId: location.state ? location.state : cookies.user?cookies.user.userId:"",
        

      },
      visitingUserId: cookies.user?cookies.user.userId:"",
    },
    fetchPolicy: 'network-only',

    onCompleted: res => {
      console.log('posts', res);
      setPost(res.postsOfUser ? res.postsOfUser.edges : []);
      setPostPaginateData(res.postsOfUser);
    },
    onError: err => {
      console.log(err);
    },
  });
  const handleLoadMorePosts = () => {
    fetchMorePosts({
      variables: {
        nextToken: postPaginateData.pageInfo.endCursor,
        limit: 10,
        userModelInputFilter: {
          userId: location.state ? location.state : cookies.user?cookies.user.userId:"",
        },
      },

      updateQuery: (prev, {fetchMoreResult}) => {
        if (!fetchMoreResult) return prev;

        setPostPaginateData(fetchMoreResult.postsOfUser);
        return {
          ...prev,
          postsOfUser: {
            ...prev.postsOfUser,
            edges: [
              ...(prev.postsOfUser ? prev.postsOfUser.edges : []),
              ...fetchMoreResult.postsOfUser?fetchMoreResult.postsOfUser.edges:[],
            ],
            pageInfo: fetchMoreResult.postsOfUser?fetchMoreResult.postsOfUser.pageInfo:[],
          },
        };
      },
    });
  };
  useEffect(() => {
    console.log('usersid', location.state);
  }, []);
  useEffect(()=>{
    window.scrollTo(0,0)
  },[location])

  useEffect(() => {
    const handleScroll = () => {
      // You can define your own conditions to trigger loading more data based on scroll
      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - 500
      ) {
        console.log('pagination if check');
        console.log(trackRef.current);
        if (postPaginateData && postPaginateData.pageInfo) {
          if (
            !trackRef.current.includes(
              postPaginateData ? postPaginateData.pageInfo.endCursor : '',
            )
          ) {
            handleLoadMorePosts();
            trackRef.current = [
              ...trackRef.current,
              postPaginateData ? postPaginateData.pageInfo.endCursor : '',
            ];
          }
          console.log(trackRef.current);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [post]); // Ensure that this effect is executed whenever new data is fetched
  // ------------------------end of Post user data-----------------------------
  const {fetchMore: fetchMorePolls} = useQuery(ShowPollOfUser, {
    variables: {
      limit: 10,
      nextToken: null,
      userModelInputFilter: {
        userId: location.state ? location.state : cookies.user?cookies.user.userId:"",
      },
      visitingUserId: cookies.user?cookies.user.userId:"",
    },
    fetchPolicy: 'network-only',

    onCompleted: res => {
      console.log('polls', res);
      setPoll(res.pollsOfUser ? res.pollsOfUser.edges : []);
      setPollPaginateData(res.pollsOfUser);
      setLoading(false);
    },
    onError: err => {
      console.log(err);
    },
  });
  const handleLoadMorePolls = () => {
    fetchMorePolls({
      variables: {
        nextToken: pollPaginateData.pageInfo.endCursor,
        limit: 10,
        userModelInputFilter: {
          userId: location.state ? location.state : cookies.user?cookies.user.userId:"",
        },
      },

      updateQuery: (prev, {fetchMoreResult}) => {
        if (!fetchMoreResult) return prev;

        setPollPaginateData(fetchMoreResult.pollsOfUser);
        return {
          ...prev,
          pollsOfUser: {
            ...prev.pollsOfUser,
            edges: [
              ...(prev.pollsOfUser ? prev.pollsOfUser.edges : []),
              ...(fetchMoreResult.pollsOfUser
                ? fetchMoreResult.pollsOfUser.edges
                : []),
            ],
            pageInfo: fetchMoreResult.pollsOfUser
              ? fetchMoreResult.pollsOfUser.pageInfo
              : [],
          },
        };
      },
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      // You can define your own conditions to trigger loading more data based on scroll
      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - 500
      ) {
        console.log('pagination if check');
        console.log(trackRef.current);
        if (pollPaginateData && pollPaginateData.pageInfo) {
          if (
            !trackRef.current.includes(
              pollPaginateData ? pollPaginateData.pageInfo.endCursor : '',
            )
          ) {
            handleLoadMorePolls();
            trackRef.current = [
              ...trackRef.current,
              pollPaginateData ? pollPaginateData.pageInfo.endCursor : '',
            ];
          }
          console.log(trackRef.current);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [poll]); // Ensure that this effect is executed whenever new data is fetched
  // ------------------------end of Polls user data-----------------------------
  const {fetchMore: fetchMoredocs} = useQuery(ShowDocsOfUser, {
    variables: {
      limit: 10,
      nextToken: null,
      userModelInputFilter: {
        userId: location.state ? location.state : cookies.user?cookies.user.userId:"",
      },
      visitingUserId: cookies.user?cookies.user.userId:"",
    },
    fetchPolicy: 'network-only',

    onCompleted: res => {
      console.log('docs', res);
      setDoc(res.docsOfUser ? res.docsOfUser.edges : []);
      setDocPaginateData(res.docsOfUser);
      setLoading(false);
    },
    onError: err => {
      console.log(err);
    },
  });
  const handleLoadMoreDocs = () => {
    fetchMoredocs({
      variables: {
        nextToken: docPaginateData.pageInfo.endCursor,
        limit: 10,
        userModelInputFilter: {
          userId: location.state ? location.state : cookies.user?cookies.user.userId:"",
        },
      },

      updateQuery: (prev, {fetchMoreResult}) => {
        if (!fetchMoreResult) return prev;

        setDocPaginateData(fetchMoreResult.docsOfUser);
        return {
          ...prev,
          docsOfUser: {
            ...prev.docsOfUser,
            edges: [
              ...(prev.docsOfUser ? prev.docsOfUser.edges : []),
              ...(fetchMoreResult.docsOfUser
                ? fetchMoreResult.docsOfUser.edges
                : []),
            ],
            pageInfo: fetchMoreResult.docsOfUser
              ? fetchMoreResult.docsOfUser.pageInfo
              : [],
          },
        };
      },
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      // You can define your own conditions to trigger loading more data based on scroll
      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - 500
      ) {
        console.log('pagination if check');
        console.log(trackRef.current);
        if (docPaginateData && docPaginateData.pageInfo) {
          if (
            !trackRef.current.includes(
              docPaginateData ? docPaginateData.pageInfo.endCursor : '',
            )
          ) {
            handleLoadMoreDocs();
            trackRef.current = [
              ...trackRef.current,
              docPaginateData ? docPaginateData.pageInfo.endCursor : '',
            ];
          }
          console.log(trackRef.current);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [doc]); // Ensure that this effect is executed whenever new data is fetched
  // ------------------------end of Docs user data-----------------------------
  const {fetchMore: fetchMoreMedia} = useQuery(ShowMediaOfUser, {
    variables: {
      limit: 10,
      nextToken: null,
      userModelInputFilter: {
        userId: location.state ? location.state : cookies.user?cookies.user.userId:"",
      },
      visitingUserId: cookies.user?cookies.user.userId:"",
    },
    fetchPolicy: 'network-only',

    onCompleted: res => {
      console.log('media', res);
      setMedia(res.mediasOfUser ? res.mediasOfUser.edges : []);
      setMediaPaginateData(res.mediasOfUser);
      setLoading(false);
    },
    onError: err => {
      console.log(err);
    },
  });
  const handleLoadMoreMedia = () => {
    fetchMoreMedia({
      variables: {
        nextToken: mediaPaginateData.pageInfo.endCursor,
        limit: 10,
        userModelInputFilter: {
          userId: location.state ? location.state : cookies.user?cookies.user.userId:"",
        },
      },

      updateQuery: (prev, {fetchMoreResult}) => {
        if (!fetchMoreResult) return prev;

        setMediaPaginateData(fetchMoreResult.mediasOfUser);
        return {
          ...prev,
          mediasOfUser: {
            ...prev.mediasOfUser,
            edges: [
              ...(prev.mediasOfUser ? prev.mediasOfUser.edges : []),
              ...(fetchMoreResult.mediasOfUser
                ? fetchMoreResult.mediasOfUser.edges
                : []),
            ],
            pageInfo: fetchMoreResult.mediasOfUser
              ? fetchMoreResult.mediasOfUser.pageInfo
              : [],
          },
        };
      },
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      // You can define your own conditions to trigger loading more data based on scroll
      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - 500
      ) {
        console.log('pagination if check');
        console.log(trackRef.current);
        if (mediaPaginateData && mediaPaginateData.pageInfo) {
          if (
            !trackRef.current.includes(
              mediaPaginateData ? mediaPaginateData.pageInfo.endCursor : '',
            )
          ) {
            handleLoadMoreMedia();
            trackRef.current = [
              ...trackRef.current,
              mediaPaginateData ? mediaPaginateData.pageInfo.endCursor : '',
            ];
          }
          console.log(trackRef.current);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [media]); // Ensure that this effect is executed whenever new data is fetched

  // ------------------------end of Media user data-----------------------------
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (!cookies.user) {
      navigate('/auth')
    }
  }, [])
  return (
    <React.Fragment>
      <div className={classes.PhoneViewDisable}>
        <Navbar />
        <div>
          <Container maxWidth="xl">
            <div className={classes.mainDiv}>
              <div>
                <CardProfile userDetailsData={userDetailsData} />
                <Paper elevation={1} className={classes.PostPeper}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    TabIndicatorProps={{
                      className: classes.TabIndicatorPropsStyle,
                    }}
                    aria-label="simple tabs example">
                    <Tab
                      style={{margin: ' 0 1.5rem'}}
                      label={
                        <div>
                          <div className={classes.button}>
                            <ImageAspectRatioIcon
                              style={{margin: '0.4rem 0 0'}}
                            />
                            <Typography
                              variant="body2"
                              className={
                                value === 0
                                  ? classes.activeTab
                                  : classes.inActiveTab
                              }>
                              Posts
                            </Typography>
                          </div>
                        </div>
                      }
                      {...a11yProps(0)}
                      className={classes.textTransform}
                    />
                    <Tab
                      label={
                        <div>
                          <div className={classes.button}>
                            <TheatersOutlinedIcon
                              style={{margin: '0.4rem 0 0'}}
                            />
                            <Typography
                              variant="body2"
                              className={
                                value === 1
                                  ? classes.activeTab
                                  : classes.inActiveTab
                              }>
                              Media
                            </Typography>
                          </div>
                        </div>
                      }
                      {...a11yProps(1)}
                      className={classes.textTransform}
                    />
                    <Tab
                      label={
                        <div>
                          <div className={classes.button}>
                            <InsertDriveFileOutlinedIcon
                              style={{margin: '0.4rem 0 0'}}
                            />
                            <Typography
                              variant="body2"
                              className={
                                value === 2
                                  ? classes.activeTab
                                  : classes.inActiveTab
                              }>
                              Docs
                            </Typography>
                          </div>
                        </div>
                      }
                      {...a11yProps(2)}
                      className={classes.textTransform}
                    />
                    <Tab
                      label={
                        <div>
                          <div className={classes.button}>
                            <ListAlt style={{margin: '0.4rem 0 0'}} />
                            <Typography
                              variant="body2"
                              className={
                                value === 3
                                  ? classes.activeTab
                                  : classes.inActiveTab
                              }>
                              MCQ
                            </Typography>
                          </div>
                        </div>
                      }
                      {...a11yProps(3)}
                      className={classes.textTransform}
                    />
                  </Tabs>
                </Paper>
                <div className={classes.ProfilePostBorderStyle}>
                  <div className={classes.marginTop1rem}>
                    <SuggestionCard />
                  </div>

                  <div>
                    <TabPanel
                      className={classes.TabPanelStyle}
                      value={value}
                      index={0}>
                      {loading ? (
                        <div style={{marginLeft: '13.5rem', marginTop: '5rem'}}>
                          <ThreeCircles
                            height="100"
                            width="100"
                            color="#4fa94d"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel="three-circles-rotating"
                            outerCircleColor="#00a3ff"
                            innerCircleColor="#ff9d00"
                            middleCircleColor="#4088FF"
                          />
                        </div>
                      ) : post.length > 0 ? (
                        post.map((item, key) => (
                          <div key={key}>
                            <PostDesgin data={item.node} />
                          </div>
                        ))
                      ) : (
                        <Typography>No Post to show </Typography>
                      )}
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      {loading ? (
                        <div style={{marginLeft: '13.5rem', marginTop: '5rem'}}>
                          <ThreeCircles
                            height="100"
                            width="100"
                            color="#4fa94d"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel="three-circles-rotating"
                            outerCircleColor="#00a3ff"
                            innerCircleColor="#ff9d00"
                            middleCircleColor="#4088FF"
                          />
                        </div>
                      ) : (
                        <Media data={media} />
                      )}
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                      {loading ? (
                        <div style={{marginLeft: '13.5rem', marginTop: '5rem'}}>
                          <ThreeCircles
                            height="100"
                            width="100"
                            color="#4fa94d"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel="three-circles-rotating"
                            outerCircleColor="#00a3ff"
                            innerCircleColor="#ff9d00"
                            middleCircleColor="#4088FF"
                          />
                        </div>
                      ) : doc.length > 0 ? (
                        doc.map((item, key) => (
                          <div key={key}>
                            <DocumentShow data={item.node} />
                          </div>
                        ))
                      ) : (
                        <Typography>No Documents to Show </Typography>
                      )}
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                      {loading ? (
                        <div style={{marginLeft: '13.5rem', marginTop: '5rem'}}>
                          <ThreeCircles
                            height="100"
                            width="100"
                            color="#4fa94d"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel="three-circles-rotating"
                            outerCircleColor="#00a3ff"
                            innerCircleColor="#ff9d00"
                            middleCircleColor="#4088FF"
                          />
                        </div>
                      ) : poll.length > 0 ? (
                        poll.map((item, key) => (
                          <div key={key}>
                            <PollDisplay data={item.node} />
                          </div>
                        ))
                      ) : (
                        <Typography>No MCQ to show </Typography>
                      )}
                    </TabPanel>
                  </div>
                </div>
              </div>
              <div className={classes.News}>
                <LatestNews />
              </div>
            </div>
          </Container>
        </div>
      </div>
      <div className={classes.PhoneViewEnable}>
        <PhoneView/>
      </div>
    </React.Fragment>
  );
};

export default Profile;
