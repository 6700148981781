import React, {useState, useEffect} from 'react';
import Navbar from '../navbar/Navbar';
import {makeStyles} from '@material-ui/core/styles';
import {
  Container,
  Tabs,
  Tab,
  Typography,
  Paper,
  Divider,
  Box,
  Button,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import AppLogo from '../../assets/Icon_svg/Scontilogo1.svg';
import EditProfile from './EditProfile';
import Socialmedia from './Socialmedia';
import HelpAndFeedback from './HelpAndFeedback';
import ScontiEarnings from './ScontiEarnings';
import PhoneView from '../phoneView/PhoneView';
import {useCookies} from 'react-cookie';
import {useNavigate} from 'react-router-dom';

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  mainDev: {
    width: '100%',
    padding: '5rem',
  },
  PaperStyle: {
    flexGrow: 1,
    display: 'flex',
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    borderColor: theme.palette.color.grey2,
    borderRadius: '0.5rem',
  },
  AppLogo: {
    width: '2rem',
    height: '2rem',
  },
  PhoneViewDisable: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  PhoneViewEnable: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  activeTab: {fontWeight: 'bold'},
  TabIndicatorPropsStyle: {
    backgroundColor: 'black',
    left: 0,
  },
  textTransform: {textTransform: 'capitalize'},
  AppInfoBoder: {textAlign: 'center', margin: '1rem 0'},
  AppTextBorder: {margin: '0.5rem 0rem 0'},
  AppNameStyle: {fontWeight: 'bold'},
  AppDesStyle: {
    color: theme.palette.color.grey1,
    fontSize: '0.5rem',
    left: 0,
  },
  TabBorderstyle: {
    borderRight: `0.1rem solid ${theme.palette.color.grey2}`,
  },
  TabPanelStyle: {
    width: '100%',
  },
  UserProfileStyle: {
    width: '2.5rem',
    height: '2.5rem',
    marginTop: '0.4rem',
  },
  resize: {
    padding: '0.3rem 0.5rem',
    border: `0.1rem solid ${theme.palette.color.grey2}`,
    borderRadius: '0.3rem',
    width: '20rem',
  },
  logout: {
    width: '3rem',
    left: '2.5rem',
    textTransform: 'capitalize',
  },
  AppInfo: {
    border: `0.1rem solid ${theme.palette.color.AppInfoColore}`,
    padding: '1rem',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  SubmitButton: {
    margin: theme.spacing(1),
    textTransform: 'capitalize',
    color: theme.palette.color.white,
    padding: '0.2rem 1.5rem 0',
    marginRight: '1rem',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

const Setting = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [cookies, setCookie, removeCookie] = useCookies(['user']);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (!cookies.user) {
      navigate('/auth');
    }
  }, []);
  return (
    <React.Fragment>
      <div className={classes.PhoneViewDisable}>
        <Navbar />
        <div>
          <Container maxWidth="lg">
            <div className={classes.mainDev}>
              <Paper className={classes.PaperStyle}>
                <div className={classes.TabBorderstyle}>
                  <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    TabIndicatorProps={{
                      className: classes.TabIndicatorPropsStyle,
                    }}
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example">
                    <Tab
                      label={
                        <span className={value === 0 ? classes.activeTab : ''}>
                          Edit Profile
                        </span>
                      }
                      {...a11yProps(1)}
                      className={classes.textTransform}
                    />

                    <Tab
                      label={
                        <span className={value === 1 ? classes.activeTab : ''}>
                          Social media
                        </span>
                      }
                      {...a11yProps(1)}
                      className={classes.textTransform}
                    />

                    <Tab
                      label={
                        <span className={value === 2 ? classes.activeTab : ''}>
                          Sconti Earnings
                        </span>
                      }
                      {...a11yProps(2)}
                      className={classes.textTransform}
                    />
                    <Tab
                      label={
                        <span className={value === 3 ? classes.activeTab : ''}>
                          Help and Feedback
                        </span>
                      }
                      {...a11yProps(3)}
                      style={{
                        textTransform: 'capitalize',
                        marginBottom: '100%',
                      }}
                    />
                    <Button
                      // variant='contained'
                      className={classes.logout}
                      onClick={handleClickOpen}>
                      Logout
                    </Button>
                    <Dialog
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description">
                      <DialogTitle id="alert-dialog-title">Logout?</DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Do you really want to Logout?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          style={{textTransform: 'capitalize'}}
                          onClick={handleClose}>
                          Cancel
                        </Button>
                        <Button
                          style={{textTransform: 'capitalize'}}
                          onClick={() => {
                            removeCookie('user');
                            handleClose();

                            navigate('/auth', {replace: true});
                          }}
                          autoFocus>
                          Logout
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Tabs>

                  <div>
                    <Divider />
                    <div className={classes.AppInfoBoder}>
                      <img
                        src={AppLogo}
                        alt="App-Img"
                        className={classes.AppLogo}
                      />

                      <div className={classes.AppTextBorder}>
                        <Typography
                          variant="subtitle2"
                          className={classes.AppNameStyle}>
                          Sconti Technology
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          className={classes.AppDesStyle}>
                          Control settings for connected <br></br> experiences
                          across Platform<br></br>including post and news
                          sharing
                          <br></br>& logging in.
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>

                <TabPanel
                  value={value}
                  index={0}
                  className={classes.TabPanelStyle}>
                  <EditProfile />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Socialmedia />
                </TabPanel>

                <TabPanel value={value} index={2}>
                  <ScontiEarnings />
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <HelpAndFeedback />
                </TabPanel>
              </Paper>
            </div>
          </Container>
        </div>
      </div>
      <div className={classes.PhoneViewEnable}>
        <PhoneView />
      </div>
    </React.Fragment>
  );
};

export default Setting;
