import React, {useState, useEffect} from 'react';
import {Typography, Avatar, Button, Paper} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {useMutation} from '@apollo/client';
import FollowUser from '../../queries/FollowUser';
import UnFollowUser from '../../queries/UnFollowUser';
import {useCookies} from 'react-cookie';
import {useNavigate} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    width: '25vw',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '1rem',
    padding: '1rem',
  },
  UserName: {
    fontSize: 14,
    width: '12rem',
    textAlign: 'center',
    marginTop: '0.5rem',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  CollageName: {
    fontSize: 12,
  },
  UserProfileStyle: {width: '3rem', height: '3rem'},
  // marginLeft05: {marginLeft: ' 0.5rem'},
  FollowButton: {
    textTransform: 'capitalize',
    width: '5rem',
    height: '2rem',
    borderColor: '#000',
    borderWidth: '0.1rem',
  },
}));

const FolloweUserCard = props => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [followChange, setfollowChange] = useState(true);
  const [cookies, setCookie] = useCookies(['user']);
  const [followMutation] = useMutation(FollowUser);
  const [unfollowMutation] = useMutation(UnFollowUser);

  const followUser = () => {
    followMutation({
      variables: {
        userId: props.followers.userId,
        followedUserId: cookies.user.userId,
      },
    })
      .then(res => {
        console.log(res);
      })
      .catch(err => {
        console.log(err);
      });
  };
  const unfollowUser = () => {
    unfollowMutation({
      variables: {
        userId: props.followers.userId,
        followedUserId: cookies.user.userId,
      },
    })
      .then(res => {
        console.log(res);
      })
      .catch(err => {
        console.log(err);
      });
  };
  useEffect(() => {
    setfollowChange(props.followers.amIFollowing);
  }, []);
  return (
    <div className={classes.root}>
      <div
        style={{cursor: 'pointer'}}
        onClick={() => {
          navigate('/profile', {state: props.followers.userId});
          if (props.close) {
            props.close();
          }
        }}>
        <Avatar
          alt="Remy Sharp"
          src={props.followers ? props.followers.profileImageUrl : 'Remy-shep'}
          className={classes.UserProfileStyle}
        />
      </div>
      <div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-evenly"}}>
        <div
          className={classes.marginLeft05}
          style={{cursor: 'pointer'}}
          onClick={() => {
            navigate('/profile', {state: props.followers.userId});
            if (props.close) {
              props.close();
            }
          }}>
          <Typography variant="subtitle1" className={classes.UserName}>
            {props.followers ? props.followers.name : 'user'}
          </Typography>
          <Typography variant="subtitle2" className={classes.CollageName}>
            {props.followers ? props.followers.description : 'user'}
          </Typography>
        </div>
        <div
          className={classes.FollowButton}
          onClick={() => {
            console.log(props.followers);
            if (followChange === true) {
              unfollowUser();
              setfollowChange(followChange - 1);
              alert('you unfollowed ');
            } else {
              followUser();
              alert('you Followed ');
              setfollowChange(followChange + 1);
            }
            setfollowChange(!followChange);
          }}>
          {cookies.user.userId === props.followers.userId ? (
            <div />
          ) : followChange ? (
            <Button
              variant="contained"
              className={classes.FollowButton}
              style={{
                background: '#ff9d00',
                color: 'white',
              }}>
              unfollow
            </Button>
          ) : (
            <Button
              variant="contained"
              className={classes.FollowButton}
              style={{
                background: '#00A3FF',
                color: 'white',
              }}>
              follow
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default FolloweUserCard;
