import gql from 'graphql-tag';

export default gql`
  query getAllPostViewers(
    $limit: Int
    $nextToken: String
    $postId: ID!
  ) {
    getAllPostViewers(
      limit: $limit
      nextToken: $nextToken
      postId: $postId
    ) @connection(key: "UserProfileDisplayModel") {
      edges {
        node {
          name
          userId
          profileImageUrl
          userType
          college
          company
          
          amIFollowing
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
