import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Typography, Avatar, Paper, Button} from '@material-ui/core';
import reply from '../../assets/Icon_svg/reply.svg';
import moment from 'moment';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import DeleteIcon from '@material-ui/icons/Delete';
import ShowSubComments from '../../queries/ShowComments';
import DeleteComment from '../../queries/DeleteComment';
import LikeComment from '../../queries/LikeComment';
import UnLikeComment from '../../queries/UnLikeComment';
import {useQuery, useMutation} from '@apollo/client';
import {useCookies} from 'react-cookie';
import {useNavigate} from 'react-router-dom';
import ShowComments from '../../queries/ShowComments';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
  },
  UserProfileStyle: {
    width: '2rem',
    height: '2rem',
  },
  DisplayFlex: {display: 'flex', fontSize: 18},
  contNotSelect: {
    color: theme.palette.color.grey1,
  },
  ButtonStyle: {
    width: '2rem',
    height: '1rem',
    fontSize: 15,
    textTransform: 'capitalize',
    // marginbottom:"1rem",

    backgroundColor: theme.palette.color.white,
    '&:hover': {
      background: theme.palette.color.white,
    },
  },
  ButtonStyle: {
    width: '2rem',
    height: '1rem',
    fontSize: 15,
    textTransform: 'capitalize',
    // marginBottom: '0.1rem',

    backgroundColor: theme.palette.color.white,
    '&:hover': {
      background: theme.palette.color.white,
    },
  },
  liked: {color: '#FF0000', fontSize: 18},
  IcontNotSelect: {
    color: theme.palette.color.grey1,
    fontSize: 18,
  },
  Paper: {
    width: '20rem',
    overflow: 'break-word',
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    padding: '0.5rem',
    backgroundColor: '#F1EFEF',
  },
  contentImg: {
    maxHeight: '10rem',
    maxWidth: '10rem',
    margin: '0.5rem 3rem',
    borderRadius: '0.5rem',
  },
  time: {
    fontSize: 15,
    top: '0.1rem',
  },
  commentedByName: {
    color: '#000',
    display: 'flex',
    flexDirection: 'row',
  },
  reply: {
    // fontSize: 12,
    // fontWeight: 'bold',
    color: '#8B8B8B',
    height: '1.2rem',
  },
  sub: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
    marginLeft: '3rem',
    marginTop: '0.5rem',
    fontSize: 18,
  },
  smallDiv: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    gap: '0.2rem',
  },
  details: {display: 'flex', flexDirection: 'row', gap: '1rem'},
}));

const CommentCard = props => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [cookies, setCookie] = useCookies(['user']);
  const [iconChangeForLike, seticonChangeForLike] = useState(true);
  const [subcommentScreeData, setSubcommentScreeData] = useState([]);
  const [open, setOpen] = React.useState(false);
  // const [subCommentOpen, setSubCommentOpen] = useState(true);
  // const [openMore, setOpenMore] = useState(true);
  const [loading, setLoading] = useState(true);

  // const handleClick = () => {
  //   setOpenMore(!openMore);
  // };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    seticonChangeForLike(props.commentScreeData.likedByMe);
  }, []);
  useQuery(
    ShowSubComments,
    {
      variables: {
        commentId: props.commentScreeData.id,
      },
      onCompleted: res => {
        console.log('getCommentsForComment', res);
        setSubcommentScreeData(
          res.getCommentsForComment ? res.getCommentsForComment : [],
        );
        setLoading(false);
      },
      onError: err => {
        console.log(err);
      },
    },
    [],
  );
  const [deleteMyCommentMutation] = useMutation(DeleteComment);
  const [likeCommentMutation] = useMutation(LikeComment);
  const [unLikeCommentMutation] = useMutation(UnLikeComment);
  const updateCache = (client, {data: {deleteComment}}) => {
    const data = client.readQuery({
      query: ShowComments,
      variables: {
        postId: props.postId,
        userId: cookies.user.userId,
        limit: 10,
        nextToken: null,
      },
    });

    const newData = {
      // getCommentsForPost: [...data.getCommentsForPost, createComment],
      getCommentsForPost: data.getCommentsForPost.edges.filter(
        item => item.node.id !== props.commentScreeData.id,
      ),
    };
    client.writeQuery({
      query: ShowComments,
      variables: {
        postId: props.postId,
        userId: cookies.user.userId,
        limit: 10,
        nextToken: null,
      },
      data: newData,
    });
  };

  const updateCachelike = (client, {data: {createComment}}) => {
    const data = client.readQuery({
      query: ShowComments,
      variables: {
        postId: props.postId,
        userId: cookies.user.userId,
        limit: 10,
        nextToken: null,
      },
    });

    var newArray = [];
    data.getCommentsForPost.edges.map((item, index) => {
      if (item.node.id === props.commentScreeData.id) {
        newArray.push({
          node: {
            ...item.node,
            likedByMe: true,
          },
        });
      } else {
        newArray.push(item.node);
      }
    });
    const newData = {
      getCommentsForPost: newArray,
    };
    client.writeQuery({
      query: ShowComments,
      variables: {
        postId: props.postId,
        userId: cookies.user.userId,
        limit: 10,
        nextToken: null,
      },
      data: newData,
    });
  };

  const updateCacheunlike = (client, {data: {createComment}}) => {
    const data = client.readQuery({
      query: ShowComments,
      variables: {
        postId: props.postId,
        userId: cookies.user.userId,
        limit: 10,
        nextToken: null,
      },
    });

    var newArray = [];
    data.getCommentsForPost.edges.map((item, index) => {
      if (item.node.id === props.commentScreeData.id) {
        newArray.push({
          node: {
            ...item.node,
            likedByMe: false,
          },
        });
      } else {
        newArray.push(item.node);
      }
    });
    const newData = {
      getCommentsForPost: newArray,
    };
    client.writeQuery({
      query: ShowComments,
      variables: {
        postId: props.postId,
        userId: cookies.user.userId,
        limit: 10,
        nextToken: null,
      },
      data: newData,
    });
  };
  const handleLikeComment = () => {
    likeCommentMutation({
      variables: {
        commentId: props.commentScreeData.id,
        likedByUserId: cookies.user.userId,
      },
      update: updateCachelike,
    });
  };
  const handleUnLikeComment = () => {
    unLikeCommentMutation({
      variables: {
        commentId: props.commentScreeData.id,
        likedByUserId: cookies.user.userId,
      },
      update: updateCacheunlike,
    });
  };
  const deleteMainComment = () => {
    deleteMyCommentMutation({
      variables: {
        commentId: props.commentScreeData.id,
      },
      update: updateCache,
    })
      .then(res => {
        console.log(res);
        // alert('deleted successfully');
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <div>
      <div className={classes.root}>
        <div
          style={{cursor: 'pointer'}}
          onClick={() => {
            navigate('/profile', {state: props.commentScreeData.commentedById});
          }}>
          <Avatar
            alt={props.commentScreeData.name}
            src={props.commentScreeData.commentedByProfileImageUrl}
            className={classes.UserProfileStyle}
          />
        </div>
        <Paper elevation={2} className={classes.Paper}>
          <div className={classes.details}>
            <Typography
              variant="body2"
              color="textSecondary"
              className={classes.commentedByName}>
              <b>{props.commentScreeData.commentedByName}</b>
            </Typography>
            <Typography variant="body2" className={classes.time}>
              {moment
                .utc(props.commentScreeData.createdOn)
                .local()
                .startOf('seconds')
                .fromNow()}
            </Typography>
          </div>
          <Typography variant="body2">
            {props.commentScreeData.description}
          </Typography>
          {props.commentScreeData.media ? (
            <img
              src={props.commentScreeData.media[0].url}
              alt="UserImage"
              className={classes.contentImg}
            />
          ) : (
            <div></div>
          )}
        </Paper>
      </div>
      <div></div>
      {/* {props.data.commentScreeData ? props.data.commentScreeData.url : ''} */}
      {/**----------------------------------------------------------------------------------------------- */}
      <div className={classes.sub}>
        <div className={classes.smallDiv}>
          {/**<Typography variant="body2" >
            Reply
          </Typography> */}
          <img src={reply} alt='reply' className={classes.reply} />
        </div>

        {props.commentScreeData.commentedById === cookies.user.userId ? (
          <div>
            <DeleteIcon
              style={{
                fontSize: 18,
                cursor: 'pointer',
              }}
              onClick={handleClickOpen}
            />
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
              <DialogContent>
                <DialogTitle> Delete the Comment</DialogTitle>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to delete the comment?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    if (handleClose) {
                      handleClose();
                    }
                  }}
                  style={{textTransform: 'capitalize'}}>
                  Cancel{' '}
                </Button>
                <Button
                  onClick={() => {
                    if (deleteMainComment) {
                      deleteMainComment();
                      handleClose();
                    }
                  }}
                  style={{textTransform: 'capitalize'}}>
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        ) : (
          <div />
        )}

        <div
          className={classes.DisplayFlex}
          onClick={() => {
            seticonChangeForLike(!iconChangeForLike);
          }}>
          {!iconChangeForLike ? (
            <div className={classes.ButtonStyle} onClick={handleLikeComment}>
              <FavoriteBorderIcon className={classes.IcontNotSelect} />

              {props.commentScreeData.likeCount ||
              props.commentScreeData.likeCount !== 0
                ? props.commentScreeData.likeCount
                : ''}
            </div>
          ) : (
            <div
              className={classes.ButtonStyle1}
              style={{display: 'flex', gap: '0.2rem'}}
              disableRipple
              onClick={handleUnLikeComment}>
              <FavoriteIcon className={classes.liked} />{' '}
              <Typography style={{fontSize: 14}}>
                {props.commentScreeData.likeCount ||
                props.commentScreeData.likeCount !== 0
                  ? props.commentScreeData.likeCount
                  : ''}
              </Typography>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CommentCard;
