import {
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  Paper,
  Grid,
  Avatar,
  Divider,
} from '@material-ui/core';
import React, {useState, useRef, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import RuleIcon from '@mui/icons-material/Rule';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

const useStyles = makeStyles(theme => ({
  left: {
    width: '40vw',
    height: '100vh',
  },
  right: {
    width: '60vw',
    height: '100vh',
  },

  paper: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  main: {
    marginTop: '5rem',
    display: 'flex',
    flexDirection: 'row',
    gap: '2rem',
  },
  cardLeft: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
    justifyContent: 'flex-start',
    margin: '1rem',
  },
  next: {
    width: '10rem',
    color: 'white',
    left: '30rem',
    textTransform: 'capitalize',
    borderRadius: '1rem',
  },
  radio: {
    left: '90%',
  },
  formControl: {
    border: '0.1rem solid #00a3ff',
    borderRadius: '1rem',
    width:"70%"
  },
  mainGrid: {
    width: '20vw',
    display: 'grid',
    gridTemplateColumns: 'auto auto auto auto auto auto',
    gridGap: '2rem',
    margin: '1rem',
  },
  results: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: '0.5rem',
  },
  avg: {
    border: '0.1rem solid #00a3ff',
    borderRadius: '1rem',
    marginLeft: '1rem',
    marginRight: '1rem',
    top: '1rem',
    color: 'white',
    textAlign: 'center',
    height: '2rem',
    backgroundColor: '#00a3ff',
    padding: '0.3rem',
    fontSize: '1rem',
  },
  yours: {
    border: '0.1rem solid #ff9d00',
    borderRadius: '1rem',
    marginLeft: '1rem',
    marginRight: '1rem',
    top: '1rem',
    color: 'white',
    textAlign: 'center',
    backgroundColor: '#ff9d00',
    height: '2rem',
    padding: '0.3rem',
    fontSize: '1rem',
  },
  done: {
    borderRadius: '1rem',
    marginLeft: '1rem',
    marginRight: '1rem',
    top: '1rem',
    color: 'white',
    width: '30rem',
  },
  response: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: '0.5rem',
  },
}));

const Quiz = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [value, setValue] = React.useState('female');

  const handleChange = event => {
    setValue(event.target.value);
  };
  const Ref = useRef(null);
  const [timer, setTimer] = useState('00:15:00');

  const getTimeRemaining = e => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = e => {
    let {total, hours, minutes, seconds} = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (hours > 9 ? hours : '0' + hours) +
          ':' +
          (minutes > 9 ? minutes : '0' + minutes) +
          ':' +
          (seconds > 9 ? seconds : '0' + seconds),
      );
    }
  };

  const clearTimer = e => {
    setTimer('00:15:00');

    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();

    deadline.setSeconds(deadline.getSeconds() + 10);
    return deadline;
  };

  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);

  return (
    <React.Fragment>
      <div className={classes.main}>
        <Paper className={classes.left}>
          <div className={classes.cardLeft}>
            <Typography variant="h5">Name:</Typography>
            <Typography variant="h5" style={{color: 'gray'}}>
              {' '}
              Sconti Weekly placement test
            </Typography>
          </div>
          <div className={classes.cardLeft}>
            <Typography variant="h5">Name:</Typography>
            <Typography variant="h5" style={{color: 'gray'}}>
              {' '}
              15Minutes
            </Typography>
          </div>
          <div
            style={{
              marginLeft: '10rem',
              marginRight: '10rem',
            }}>
            <Typography variant="h5">Timer</Typography>
            <Typography variant="h5" style={{border: '1px dotted blue'}}>
              {timer}
            </Typography>
          </div>
          <div className={classes.root}>
            <Grid container spacing={2}>
              <Grid item className={classes.mainGrid}>
                <Avatar>1</Avatar>
                <Avatar>2</Avatar>
                <Avatar>3</Avatar>
                <Avatar>4</Avatar>
                <Avatar>5</Avatar>
                <Avatar>6</Avatar>
                <Avatar>7</Avatar>
                <Avatar>8</Avatar>
                <Avatar>9</Avatar>
                <Avatar>10</Avatar>
                <Avatar>11</Avatar>
                <Avatar>12</Avatar>
                <Avatar>13</Avatar>
                <Avatar>14</Avatar>
                <Avatar>15</Avatar>
              </Grid>
            </Grid>
          </div>
          <Typography style={{marginLeft: '1rem'}}>
            🟠-Skipped🔵-Attended⚫-Not attended
          </Typography>
          <Typography style={{marginLeft: '1rem'}}>
            Result will be updated soon after the quiz ends...
          </Typography>
          <div className={classes.results}>
            <RuleIcon />
            <Button>Click here to see response with answers</Button>
          </div>
          <div className={classes.response}>
            <AnalyticsIcon />
            <Button>Check your statistical results</Button>
          </div>
          <div>
            <Typography style={{marginLeft: '1rem', top: '1rem'}}>
              Average Score
            </Typography>
            <div className={classes.avg}>40%</div>
            <Typography style={{marginLeft: '1rem', top: '1rem'}}>
              Your Score
            </Typography>
            <div className={classes.yours}>40%</div>
          </div>
          <div>
            <Button
              variant="contained"
              color="primary"
              className={classes.done}>
              Done
            </Button>
          </div>
        </Paper>
        <Paper elevation={2} className={classes.right}>
          <div>
            <div>
              <Typography variant="h6" style={{margin: '2rem'}}>
                Q1) Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Dicta asperiores atque harum quod placeat excepturi reiciendis,
                nulla culpa tempore velit ipsa perferendis reprehenderit odit
                animi inventore laborum distinctio natus sint!
              </Typography>
            </div>
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={value}
              onChange={handleChange}
              style={{margin: '2rem', gap: '1rem'}}>
              <FormControlLabel
              
                value="female"
                label="Female"
                control={<Radio className={classes.radio} />}
                className={classes.formControl}
              />
              <FormControlLabel
                value="male"
                control={<Radio className={classes.radio} />}
                label="Male"
                className={classes.formControl}
              />
              <FormControlLabel
                value="male"
                control={<Radio className={classes.radio} />}
                label="Male"
                className={classes.formControl}
              />
              <FormControlLabel
                value="male"
                control={<Radio className={classes.radio} />}
                label="Male"
                className={classes.formControl}
              />
            </RadioGroup>
          </div>
          <Button color="primary" variant="contained" className={classes.next}>
            Next
          </Button>
          <Divider />
          <Button
            color="primary"
            variant="contained"
            onClick={handleClickOpen}
            className={classes.next}>
            Submit
          </Button>
          <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
              !! Submitted Scuccessfully!!
            </DialogTitle>
            <DialogContent dividers>
              <Typography gutterBottom>
                Congratulaions on attending the quiz!!You will be notified on
                the winners.
              </Typography>
              <Button variant='contained' style={{display:"block",margin:"auto",color:"white",textTransform:"capitalize"}} autoFocus onClick={handleClose} color="primary">
                Check Results
              </Button>
            </DialogContent>
          </Dialog>
        </Paper>
      </div>
    </React.Fragment>
  );
};

export default Quiz;
