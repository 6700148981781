import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Typography, Button} from '@material-ui/core';
// import AppleIcon from '@material-ui/icons/Apple';
import ShopIcon from '@material-ui/icons/Shop';
import AndroidIcon from '@mui/icons-material/Android';
import LanguageIcon from '@mui/icons-material/Language';

const useStyles = makeStyles(theme => ({
  button: {
    padding: '0.4rem 5rem',
    textTransform: 'capitalize',
    color: theme.palette.color.white,
    marginTop: '2rem',
  },
}));

const PhoneView = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Typography
        variant="h4"
        component="h6"
        style={{
          textAlign: 'center',
          padding: '5%',
          fontWeight: '600',
        }}>
        Sconti App
      </Typography>
      <Typography
        variant="h4"
        component="h6"
        style={{
          textAlign: 'center',
          padding: '5% ',
          fontWeight: '600',
        }}>
        Introducing SAMAI-AI Based Mocktest Platform 🤖
      </Typography>
      <Typography
        variant="h6"
        component="h6"
        style={{
          textAlign: 'center',
          padding: '5% ',
          fontWeight: '600',
        }}>
        About SAMAI:{' '}
      </Typography>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}>
        <img
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtZsmgvJ9e3oWHRaypKxpEz199Ns85kNCVDuc6tW-CtVFbzP4kTHFpq1gPIPfphFikAE8&usqp=CAU"
          width="50%"
          height="50%"
          alt="Testone"
        />
      </div>
      <div
        style={{
          textAlign: 'center',
          marginTop: '2rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Button
          onClick={() => {
            window.open(
              'https://play.google.com/store/apps/details?id=com.scontinent.sconti',
            );
          }}
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<AndroidIcon />}>
          download
        </Button>
        <Button
          onClick={() => {
            window.open('https://samai.scontiapp.com');
          }}
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<LanguageIcon />}>
          SAMAI🤖
        </Button>
      </div>
    </React.Fragment>
  );
};

export default PhoneView;
