import React,{useState,forwardRef, useImperativeHandle} from 'react'
import {makeStyles} from '@material-ui/core/styles';
import {Paper, Typography} from '@material-ui/core';
import {useQuery} from '@apollo/client';
import ShowInterests from '../../queries/ShowInterests';
import ChipChange from '../Auth/ChipChange';

const useStyles = makeStyles(theme => ({
    paper: {
      padding: '1rem',
      borderRadius: '1rem',
      width: '24rem',
      height:"12rem",
      display: 'flex',
      flexDirection:"column",
      margin: '2rem 3rem 1rem 0rem',
    },
      right: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    Box: {
      display: 'flex',
      justifyContent: 'center',
      alignItems:"flex-start",
      flexWrap: 'wrap',
      marginTop: '1rem',
    //height: '25rem',
    //width:"30rem",
      overflowY: 'auto',
    
      '& > *': {
        margin: theme.spacing(0.3),
      },
    },

    HeadingStyle: {
      marginBottom: '0.2rem',
      color: theme.palette.color.black,
      
    },
  }));

const Topics = forwardRef((props, ref) => {
    const classes = useStyles();
    const [interestData, setinterestData] = useState([]);   
    const [chipData, setChipData] = useState([]);
    useImperativeHandle(ref, () => ({
      sendData() {
        return chipData
      },
    }))

  console.log(interestData.map(item => item));
  useQuery(
    ShowInterests,
    {
      onCompleted: res => {
        console.log('fetchPrimaryInterests', res);
        setinterestData(res.fetchPrimaryInterests ? res.fetchPrimaryInterests : []);
      },
      onError: err => {
        console.log(err);
      },
    },
    [],
  );
  return (
    <React.Fragment>
      <div className={classes.right}>
        <Paper elevation={3} className={classes.paper}>
          <div>
            <Typography variant="h6" className={classes.HeadingStyle}>
              Choose Topics
            </Typography>
          </div>
       
          <div className={classes.Box}>
            {interestData.map((item, key) => {
              return (
                <div key={key}>
                  <ChipChange item={item} chipData={chipData} setChipData={setChipData}/>
                </div>
              );
            })}
          </div>
        </Paper>
      </div>
    </React.Fragment>
  )
});

export default Topics
