import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Button, Divider, Paper} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import {Link, useNavigate} from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(theme => ({
  scontilogo: {
    height: '4rem',
    width: '4rem',
    borderRadius: '2rem',
    position: 'sticky',
    margin: '0.5rem 0rem 0rem 5.5rem',
  },
  Paper: {
    width: '18rem',
    borderRadius: '1rem',
    margin: '1rem 0rem',
    padding: '1rem',
    background: 'linear-gradient(to right bottom, #FBAB7E, #F7CE68)',
  },
  bannerDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  banner: {
    height: '5rem',
    width: '5rem',
    borderTopRightRadius: '1rem',
    borderTopLeftRadius: '1rem',
  },
  UserName: {
    // margin: '2rem 0rem 0rem 0rem',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  CollageName: {
    fontSize: 12,
    textAlign: 'center',
  },
  Divider: {
    margin: '1rem 0rem 0rem 0rem',
  },
  PostsNumber: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
  Posts: {
    textAlign: 'center',
  },
  ProfilRoot: {
    flexDirection: 'row',
    display: 'flex',
    margin: '0.5rem 0rem 1rem 0rem',
  },
  flex1: {
    flex: 1,
    cursor: 'pointer',
  },
  button: {
    textTransform: 'capitalize',
    alignSelf: 'center',
    borderRadius: 15,
    margin: '-1rem 0rem 0.5rem 0rem',
  },
  ButtonBordered: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const ScontiAdsCard = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div>
        <Paper elevation={3} className={classes.Paper}>
          <div>
            <Typography
              variant="h6"
              component="h6"
              textAlign="center"
              color="black">
              SAMAI-AI Based-Mock-Test
            </Typography>
            <div className={classes.bannerDiv}>
              <img
                decode="async"
                alt="Login"
                longdesc="https://studentcontinent.com/"
                loading="eager"
                referrerPolicy="no-referrer"
                src="https://img.freepik.com/free-vector/graident-ai-robot-vectorart_78370-4114.jpg?t=st=1718374892~exp=1718378492~hmac=2d1043cf51da1b7a5d664b4d04c73502f5a4cebe5004c49b785875e883d4b856&w=740"
                className={classes.banner}
              />
            </div>
            <Link
              to="https://samai.scontiapp.com/upsc-kpsc-online-mocktest"
              style={{
                textDecoration: 'none',
              }}>
              <Button
                endIcon={<ArrowForwardIcon />}
                variant="outlined"
                style={{
                  color: 'black',
                  fontSize: 14,
                  textAlign: 'center',
                }}>
                Take AI Based Mock Test
              </Button>
            </Link>
          </div>
        </Paper>
      </div>
    </React.Fragment>
  );
};

export default ScontiAdsCard;
