import gql from 'graphql-tag';

export default gql`
  query homeSearchNews(
    $limit: Int
    $nextToken: String
    $inputFilter: SearchInputFilter!
  ) {
    homeSearchNews(
      limit: $limit
      nextToken: $nextToken
      inputFilter: $inputFilter
    ) @connection(key: "UserProfileDisplayModel") {
      edges {
        node {
          id
          heading
          summary
          mediaUrl
          hostUrl
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
