import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import {Typography, Avatar} from '@material-ui/core';
import FollowUser from '../../queries/FollowUser';
import UnFollowUser from '../../queries/UnFollowUser';
import {useCookies} from 'react-cookie';
import {useNavigate} from 'react-router-dom';
import {useMutation} from '@apollo/client';

const useStyles = makeStyles({
  root: {
    height: '15rem',
    width: '15rem',
    borderRadius:"1rem",
    backgroundColor:"#ededed"
  
  },
  UserName: {
    fontWeight: 'bold',
    fontSize: 12,
    width: '10rem',
    height:"1rem",
    textAlign: 'center',
  },

  CollageName: {
    fontSize: 10,
    width: '10rem',
    height:"1rem",
    textAlign: 'center',

  },
  UserProfileStyle: {
    width: '5rem',
    height: '5rem',
    // marginBottom: '1rem',
  },
  marginLeft05: {
    display:"flex",
    flexDirection:"column",justifyContent:"center",alignItems:"center",
    cursor: 'pointer',
    gap:"0.5rem"
  },

});

const Users = props => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [iconChange, seticonChange] = useState(false);
  const [cookies, setCookie] = useCookies(['user']);
  const [followMutation] = useMutation(FollowUser);
  const [unfollowMutation] = useMutation(UnFollowUser);

  const followUser = () => {
    followMutation({
      variables: {
        userId: props.data.userId,
        followedUserId: cookies.user.userId,
      },
    })
      .then(res => {
        console.log(res);
      })
      .catch(err => {
        console.log(err);
      });
  };
  const unfollowUser = () => {
    unfollowMutation({
      variables: {
        userId: props.data.userId,
        followedUserId: cookies.user.userId,
      },
    })
      .then(res => {
        console.log(res);
      })
      .catch(err => {
        console.log(err);
      });
  };
  useEffect(() => {
    seticonChange(props.data.amIFollowing);
  }, []);
  return (

   <Card className={classes.root}>
   <CardContent>
     <div
     style={{
       cursor: 'pointer',
       display: 'flex',
       justifyContent: 'center',
       alignItems: 'center',
     }}
       onClick={() => {
         navigate('/profile', {state: props.data.userId});
       }}>
       <Avatar
         alt="Remy Sharp"
         src={props.data.profileImageUrl}
         className={classes.UserProfileStyle}
       />
     </div>

     <div
       className={classes.marginLeft05}
       onClick={() => {
         navigate('/profile', {state: props.data.userId});
       }}>
       <Typography variant="subtitle1" className={classes.UserName}>
         {props.data.name}
       </Typography>

       <Typography variant="subtitle2" className={classes.CollageName}>
         {props.data.college}
       </Typography>
    
     </div>
   </CardContent>
   <CardActions>
     <div
     style={{
       cursor: 'pointer',
       display: 'flex',
       flexDirection:"row",
       justifyContent: 'center',
       alignItems: 'center',
     }}
       onClick={() => {
         
         if (!iconChange === true) {
           followUser();
          //  alert('you followed ');
         } else {
           unfollowUser();
          //  alert('you unFollowed ');
         }
         seticonChange(!iconChange);
       }}>
       {iconChange ? (
         <Button size="small" variant="contained" style={{left:"4.5rem", backgroundColor:"#ff9d00",color:"white" ,top:"1rem",}}>
           Unfollow
         </Button>
       ) : (
         <Button size="small" variant="contained" style={{left:"4.5rem", backgroundColor:"#00A3FF", color:"white",top:"1rem"}}>
           Follow{' '}
         </Button>
       )}
     </div>
   </CardActions>
 </Card>

  );
};

export default Users;
