import React, {useEffect, useRef, useState} from 'react';
import {
  Avatar,
  Badge,
  Typography,
  Button,
  Dialog,
  DialogContent,
  Modal,
  Paper,
  LinearProgress,
  IconButton,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import GitHubIcon from '@material-ui/icons/GitHub';
import CloseIcon from '@material-ui/icons/Close';
import YouTubeIcon from '@material-ui/icons/YouTube';
import FlagIcon from '@material-ui/icons/Flag';
import ShareIcon from '@material-ui/icons/Share';
import {MoreVert} from '@material-ui/icons';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import Hobbies from '../../assets/Icon_svg/Hobbies.svg';
import Degree from '../../assets/Icon_svg/Degree.svg';
import GraduationSymbol1 from '../../assets/Icon_svg/GraduationSymbol1.svg';
import AboutYourself from '../../assets/Icon_svg/AboutYourself.svg';
import scontimedia_bannerImage from '../../assets/Icon_png/scontimedia_bannerImage.jpeg';
import Company from '../../assets/Icon_svg/Company.svg';
import GraduationCap from '../../assets/Icon_svg/GraduationCap.svg';
import Location1 from '../../assets/Icon_svg/Location1.svg';
import {Cancel} from '@material-ui/icons';
import {useCookies} from 'react-cookie';
import {Link, useNavigate} from 'react-router-dom';
import {FirebaseDynamicLinks} from 'firebase-dynamic-links';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from 'react-share';
const DialogTitle = props => {
  const {children, classes, onClose, ...other} = props;
  return (
    <MuiDialogTitle
      disableTypography
      {...other}
      style={{
        display: 'flex',
        flexDirection: 'row',
        borderRadius: '1rem',
        gap: '22rem',
        marginBottom: '-1.5rem',
      }}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          style={{bottom: '0.4rem'}}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const useStyles = makeStyles(theme => ({
  Paper: {
    width: '45rem',
    marginTop: '1rem',
    marginLeft: '2rem',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.down('md')]: {
      width: '50rem',
      marginLeft: 0,
    },
  },
  flex: {
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  bannerImage: {
    width: '100%',
    height: '13rem',
  },
  UserProfileStyle: {
    width: '9rem',
    height: '9rem',
    margin: '-6.5rem 4% 0',
    border: '4.5px solid white',
  },
  FollowButton: {
    margin: theme.spacing(1),
    textTransform: 'capitalize',
    color: theme.palette.color.white,
    padding: '0.2rem 1.5rem 0',
    marginRight: '1rem',
  },
  FollowBorderStyle: {
    margin: '1% 4%',
    display: 'flex',
  },
  DisplayFlex: {
    display: 'flex',
  },
  flex1: {
    flex: '1',
  },
  EditeIconStyle: {
    color: theme.palette.color.grey,
    marginTop: '0.8rem',
    marginRight: '1rem',
  },
  fontWeightBold: {
    fontWeight: 'bold',
  },
  UserNameBorderStyle: {
    margin: '0% 4%',
    marginTop: '0.6rem',
  },
  Colorgrey: {
    color: theme.palette.color.grey,
  },
  LocationIconBorder: {
    margin: '1.2%  3.5%',
    display: 'flex',
    gap: '1%',
    Right: '1%',
  },

  FollowTextStyle: {
    fontSize: '10',
    margin: '0.2rem 0.5rem',
  },
  socialmediaBorderStyle: {
    margin: '0 3%',
    display: 'flex',
  },
  SocicalIconStyle: {margin: '0 1% 2%'},
  FollowNumberButton: {
    padding: 0,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  ModalDivStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  bar: {
    width: '50%',
    marginLeft: '2.5rem',
    height: 25,
    borderRadius: 10,
    backgroundColor: '#eee',
  },
}));

function CardProfile({userDetailsData}, props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  // const [userDetailsData, setuserDetailsData] = useState({});
  const [cookies, setCookie] = useCookies(['user']);
  const [followChange, setfollowChange] = useState(true);
  const [isFollower, setFollower] = React.useState(false);
  const [openForProfile, setOpenForProfile] = useState(false);
  const [openForReport, setOpenForReport] = useState(false);
  const [linkstoshare, setlinkstoshare] = useState('');
  const [openforview, setOpenforview] = React.useState(false);
  const [fullimage, setFullimage] = useState('');

  const handleOpenforView = url => {
    setOpenforview(true);
    setFullimage(url);
  };

  const handleCloseforView = () => {
    setOpenforview(false);
  };

  const handleClickOpenForReport = () => {
    setOpenForReport(true);
  };
  const handleCloseForReport = () => {
    setOpenForReport(false);
  };

  const handleOpenForProfile = () => {
    setOpenForProfile(true);
  };

  const handleCloseForProfile = () => {
    setOpenForProfile(false);
  };

  const changeModalVisibility = bool => {
    setOpen(bool);
  };
  const firebaseDynamicLinks = new FirebaseDynamicLinks(
    'AIzaSyAa3zif99kE5V0qgP_dYoLpwWOfwv12XbQ',
  );
  useEffect(() => {
    setfollowChange(!userDetailsData.amIFollowing);
  }, [userDetailsData]);

  const generateDynamicLink = () => {
    firebaseDynamicLinks
      .createLink({
        dynamicLinkInfo: {
          domainUriPrefix: 'https://studentcontinent.page.link',
          link:
            'https://play.google.com/store/apps/details?id=com.scontinent.sconti&userId=' +
            userDetailsData.userId,
          androidInfo: {
            androidPackageName: 'com.scontinent.sconti',
          },
        },
      })
      .then(res => {
        setlinkstoshare(res.shortLink);
      })
      .catch(err => {
        console.log(err);
      });

    // console.log('Generated Dynamic Link:', shortLink, previewLink);
    // Now you can share the generated link with users
  };
  const [openForShare, setOpenForShare] = React.useState(false);

  const handleClickOpenForShare = () => {
    generateDynamicLink();
    setOpenForShare(true);
  };
  const handleCloseForShare = () => {
    setOpenForShare(false);
  };
  const interestsstring = () => {
    var sting = '';

    userDetailsData.interests.map((item, index) => {
      if (index !== 0) {
        sting = sting + ' | ' + item;
      } else {
        sting = sting + item;
      }
    });
    return sting;
  };
  return (
    <div>
      <Paper elevation={1} className={classes.Paper}>
        <div className={classes.flex}>
          <div>
            <img
              src={
                userDetailsData.bannerImageUrl
                  ? userDetailsData.bannerImageUrl
                  : scontimedia_bannerImage
              }
              // src={code}
              className={classes.bannerImage}
              alt="download app"
            />
            <div className={classes.DisplayFlex}>
              <div
                className={classes.flex1}
                onClick={() => {
                  handleOpenforView(userDetailsData.profileImageUrl);
                }}>
                {userDetailsData.userType === 'Professional' ? (
                  <Badge
                    overlap="circle"
                    // variant="dot"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    badgeContent={
                      <img
                        src={GraduationSymbol1}
                        alt="cap"
                        style={{
                          width: 30,
                          height: 30,
                          marginBottom: '2rem',
                        }}
                      />
                    }>
                    <Avatar
                      // alt={userDetailsData.name.substring(1)}
                      alt="UserImage"
                      src={userDetailsData.profileImageUrl}
                      className={classes.UserProfileStyle}
                    />
                  </Badge>
                ) : (
                  <Avatar
                    // alt={userDetailsData.name.substring(1)}
                    alt="UserImage"
                    src={userDetailsData.profileImageUrl}
                    className={classes.UserProfileStyle}
                  />
                )}
              </div>
              <Modal
                open={openforview}
                onClose={handleCloseforView}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'start',
                    margin: '2rem',
                  }}>
                  {fullimage ? (
                    <img
                      style={{
                        borderRadius: '10px',
                        width: 300,
                        height: '100%',
                      }}
                      src={fullimage}
                      alt="media"
                    />
                  ) : (
                    <Typography variant="h4" style={{color: 'white'}}>
                      {' '}
                      No User Image To Show
                    </Typography>
                  )}
                  <IconButton
                    onClick={handleCloseforView}
                    style={{
                      width: '1rem ',
                      height: '1rem',
                      backgroundColor: 'white',
                      // right: '10rem',
                    }}>
                    <Cancel />
                  </IconButton>
                </div>
              </Modal>
              {userDetailsData.userId === cookies.user.userId ? (
                <div />
              ) : (
                <div
                  className={classes.FollowButton}
                  onClick={() => {
                    setfollowChange(!followChange);
                  }}>
                  {followChange ? (
                    <Button
                      variant="contained"
                      className={classes.FollowButton}
                      style={{
                        margin: '0.2rem  0rem 0rem 0.5rem',
                        background: '#00A3FF',
                      }}>
                      follow
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      className={classes.FollowButton}
                      style={{
                        margin: '0.2rem  0rem 0rem 0.5rem',
                        background: '#ff9d00',
                      }}>
                      unfollow
                    </Button>
                  )}
                </div>
              )}
              {userDetailsData.userId === cookies.user.userId ? (
                <Link style={{textDecoration: 'none'}} to="/setting">
                  <EditIcon className={classes.EditeIconStyle} />
                </Link>
              ) : (
                <div />
              )}
              <IconButton onClick={handleOpenForProfile}>
                <MoreVert />
              </IconButton>
              {userDetailsData.userId === cookies.user.userId ? (
                <Modal
                  open={openForProfile}
                  onClose={handleCloseForProfile}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description">
                  <Paper
                    elevation={3}
                    style={{
                      width: '15rem',
                      marginLeft: '41rem',
                      marginTop: '15rem',
                    }}>
                    <center
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      <Button
                        startIcon={<ShareIcon />}
                        onClick={handleClickOpenForShare}>
                        Share Profile
                      </Button>
                      <Dialog
                        onClose={handleCloseForShare}
                        aria-labelledby="customized-dialog-title"
                        open={openForShare}>
                        <DialogTitle
                          id="customized-dialog-title"
                          onClose={handleCloseForShare}>
                          Share To
                        </DialogTitle>
                        <DialogContent dividers>
                          <div
                            style={{
                              width: '30rem',
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'flex-start',
                              justifyContent: 'flex-start',
                              borderRadius: '2rem',
                            }}>
                            <div
                              style={{
                                gap: '1.5rem',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'flex-start',
                                justifyContent: 'flex-start',
                              }}>
                              <LinkedinShareButton url={linkstoshare}>
                                <LinkedinIcon size={32} round={true} />
                                <Typography variant="body2">
                                  LinkedIn
                                </Typography>
                              </LinkedinShareButton>

                              <WhatsappShareButton url={linkstoshare}>
                                <WhatsappIcon size={32} round={true} />
                                <Typography variant="body2">
                                  Whatsapp
                                </Typography>
                              </WhatsappShareButton>

                              <TelegramShareButton url={linkstoshare}>
                                <TelegramIcon size={32} round={true} />
                                <Typography variant="body2">
                                  Telegram
                                </Typography>
                              </TelegramShareButton>
                              <FacebookShareButton url={linkstoshare}>
                                <FacebookIcon size={32} round={true} />
                                <Typography variant="body2">
                                  FaceBook
                                </Typography>
                              </FacebookShareButton>
                              <TwitterShareButton url={linkstoshare}>
                                <TwitterIcon size={32} round={true} />
                                <Typography variant="body2">Twitter</Typography>
                              </TwitterShareButton>
                              <EmailShareButton url={linkstoshare}>
                                <EmailIcon size={32} round={true} />
                                <Typography variant="body2">Email</Typography>
                              </EmailShareButton>
                            </div>
                          </div>
                        </DialogContent>
                      </Dialog>

                      <Button
                        startIcon={<CloseIcon />}
                        onClick={handleCloseForProfile}>
                        {' '}
                        Cancel
                      </Button>
                    </center>
                  </Paper>
                </Modal>
              ) : (
                <Modal
                  open={openForProfile}
                  onClose={handleCloseForProfile}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description">
                  <Paper
                    elevation={3}
                    style={{
                      width: '15rem',
                      marginLeft: '41rem',
                      marginTop: '15rem',
                    }}>
                    <center
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      <Button
                        startIcon={<ShareIcon />}
                        onClick={handleClickOpenForShare}>
                        Share Profile
                      </Button>
                      <Dialog
                        onClose={handleCloseForShare}
                        aria-labelledby="customized-dialog-title"
                        open={openForShare}>
                        <DialogTitle
                          id="customized-dialog-title"
                          onClose={handleCloseForShare}>
                          Share To
                        </DialogTitle>
                        <DialogContent dividers>
                          <div
                            style={{
                              width: '30rem',
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'flex-start',
                              justifyContent: 'flex-start',
                              borderRadius: '2rem',
                            }}>
                            <div
                              style={{
                                gap: '1.5rem',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'flex-start',
                                justifyContent: 'flex-start',
                              }}>
                              <LinkedinShareButton url={linkstoshare}>
                                <LinkedinIcon size={32} round={true} />
                                <Typography variant="body2">
                                  LinkedIn
                                </Typography>
                              </LinkedinShareButton>

                              <WhatsappShareButton url={linkstoshare}>
                                <WhatsappIcon size={32} round={true} />
                                <Typography variant="body2">
                                  Whatsapp
                                </Typography>
                              </WhatsappShareButton>

                              <TelegramShareButton url={linkstoshare}>
                                <TelegramIcon size={32} round={true} />
                                <Typography variant="body2">
                                  Telegram
                                </Typography>
                              </TelegramShareButton>
                              <FacebookShareButton url={linkstoshare}>
                                <FacebookIcon size={32} round={true} />
                                <Typography variant="body2">
                                  FaceBook
                                </Typography>
                              </FacebookShareButton>
                              <TwitterShareButton url={linkstoshare}>
                                <TwitterIcon size={32} round={true} />
                                <Typography variant="body2">Twitter</Typography>
                              </TwitterShareButton>
                              <EmailShareButton url={linkstoshare}>
                                <EmailIcon size={32} round={true} />
                                <Typography variant="body2">Email</Typography>
                              </EmailShareButton>
                            </div>
                          </div>
                        </DialogContent>
                      </Dialog>
                      <Button
                        startIcon={<FlagIcon />}
                        onClick={handleClickOpenForReport}>
                        {' '}
                        Report Profile
                      </Button>
                      <Dialog
                        onClose={handleCloseForReport}
                        aria-labelledby="customized-dialog-title"
                        open={openForReport}>
                        <MuiDialogTitle
                          style={{marginBottom: '-1rem'}}
                          id="customized-dialog-title">
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'flex-start',
                              justifyContent: 'space-around',
                              gap: '3rem',
                            }}>
                            <Typography variant="h6">
                              Select a reporting reason
                            </Typography>
                            <IconButton
                              aria-label="close"
                              style={{bottom: '0.5rem'}}
                              onClick={handleCloseForReport}>
                              <CloseIcon />
                            </IconButton>
                          </div>
                        </MuiDialogTitle>
                        <DialogContent
                          dividers
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start',
                          }}>
                          <Button
                            onClick={() => {
                              alert('Thank you for your feed back');
                            }}>
                            Abusive Content
                          </Button>
                          <Button
                            onClick={() => {
                              alert('Thank you for your feed back');
                            }}>
                            Content not meeting the set of standards
                          </Button>
                          <Button
                            onClick={() => {
                              alert('Thank you for your feed back');
                            }}>
                            Content Creating unstability
                          </Button>
                          <Button
                            onClick={() => {
                              alert('Thank you for your feed back');
                            }}>
                            Irrelevent post identity
                          </Button>
                          <Button
                            onClick={() => {
                              alert('Thank you for your feed back');
                            }}>
                            Post Copied from other source
                          </Button>
                        </DialogContent>
                      </Dialog>
                      <Button
                        startIcon={<CloseIcon />}
                        onClick={handleCloseForProfile}>
                        {' '}
                        Cancel
                      </Button>
                    </center>
                  </Paper>
                </Modal>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            // gap: '1rem',
          }}>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{marginLeft: '3rem'}}>
            {userDetailsData.profileCompleteness}% Profile Completed
          </Typography>
          <LinearProgress
            variant="determinate"
            value={Number.parseInt(
              userDetailsData.profileCompleteness
                ? userDetailsData.profileCompleteness
                : 0,
            )}
            className={classes.bar}
          />
        </div>
        <div className={classes.UserNameBorderStyle}>
          <Typography variant="h6" className={classes.fontWeightBold}>
            {userDetailsData.name ? userDetailsData.name : 'user'}
          </Typography>
        </div>
        <div className={classes.LocationIconBorder}>
          <img
            src={AboutYourself}
            alt="AboutYourself"
            fontSize="small"
            className={classes.Colorgrey}
          />
          <Typography variant="subtitle1">
            {userDetailsData.description ? (
              userDetailsData.description
            ) : userDetailsData.userId === cookies.user.userId ? (
              <Typography style={{color: 'gray'}}>
                + Add about yourself
              </Typography>
            ) : (
              <Typography style={{color: 'gray'}}>
                No Description To Show
              </Typography>
            )}
          </Typography>
        </div>
        <div className={classes.LocationIconBorder}>
          <img
            src={GraduationCap}
            alt="GraduationCap"
            fontSize="small"
            className={classes.Colorgrey}
          />
          <Typography variant="subtitle1">
            {userDetailsData.college ? (
              userDetailsData.college
            ) : userDetailsData.userId === cookies.user.userId ? (
              <Typography style={{color: 'gray'}}>
                + Add Your College Name
              </Typography>
            ) : (
              <Typography style={{color: 'gray'}}>
                No College To Show
              </Typography>
            )}
          </Typography>
        </div>{' '}
        <div className={classes.LocationIconBorder}>
          <img
            src={Degree}
            alt="Degree"
            fontSize="small"
            className={classes.Colorgrey}
          />
          <Typography variant="subtitle1">
            {userDetailsData.degree ? (
              userDetailsData.degree
            ) : userDetailsData.userId === cookies.user.userId ? (
              <Typography style={{color: 'gray'}}>
                + Add Your Degree Name
              </Typography>
            ) : (
              <Typography style={{color: 'gray'}}>No Degree To Show</Typography>
            )}
          </Typography>
        </div>{' '}
        {userDetailsData.userType === 'Professional' ? (
          <div className={classes.LocationIconBorder}>
            <img
              src={Company}
              alt="company"
              fontSize="small"
              className={classes.Colorgrey}
            />
            <Typography variant="subtitle1">
              {userDetailsData.company ? (
                userDetailsData.company
              ) : userDetailsData.userId === cookies.user.userId ? (
                <Typography style={{color: 'gray'}}>
                  + Add Your Company
                </Typography>
              ) : (
                <Typography style={{color: 'gray'}}>
                  No Company To Show
                </Typography>
              )}
            </Typography>
          </div>
        ) : (
          <div />
        )}
        <div className={classes.LocationIconBorder}>
          <img
            src={Hobbies}
            alt="Hobbies"
            fontSize="small"
            className={classes.Colorgrey}
          />
          <Typography variant="subtitle2">
            {userDetailsData.interests ? (
              interestsstring()
            ) : userDetailsData.userId === cookies.user.userId ? (
              <Typography style={{color: 'gray'}}>
                + Add Your Interests
              </Typography>
            ) : (
              <Typography style={{color: 'gray'}}>
                No Interest To Show
              </Typography>
            )}
          </Typography>
        </div>
        <div className={classes.LocationIconBorder}>
          <img src={Location1} alt="Location1" className={classes.Colorgrey} />
          <Typography variant="subtitle1">
            Lives in &nbsp;
            {userDetailsData.state ? (
              userDetailsData.state
            ) : userDetailsData.userId === cookies.user.userId ? (
              <Typography style={{color: 'gray'}}>
                + Add Your State & City
              </Typography>
            ) : (
              <Typography style={{color: 'gray'}}>
                No location To Show
              </Typography>
            )}
          </Typography>
        </div>
        <div className={classes.FollowBorderStyle}>
          <Button
            onClick={() => {
              changeModalVisibility(true);
              setFollower(true);
              navigate('/followlist', {
                state: {userId: userDetailsData.userId, isfollower: true},
              });
            }}
            className={classes.FollowNumberButton}>
            <Typography variant="subtitle1" className={classes.fontWeightBold}>
              {userDetailsData.followersCount}
            </Typography>
            <Typography variant="subtitle2" className={classes.FollowTextStyle}>
              Followers
            </Typography>
          </Button>

          <Button
            onClick={() => {
              changeModalVisibility(true);
              setFollower(false);
              navigate('/followlist', {
                state: {userId: userDetailsData.userId, isfollower: false},
              });
            }}
            className={classes.FollowNumberButton}>
            <Typography variant="subtitle1" className={classes.fontWeightBold}>
              {userDetailsData.followingCount}
            </Typography>
            <Typography variant="subtitle2" className={classes.FollowTextStyle}>
              Following
            </Typography>
          </Button>
        </div>
        {userDetailsData.userId === cookies.user.userId ? (
          <div />
        ) : (
          <div className={classes.socialmediaBorderStyle}>
            <Button
              disableElevation
              disableRipple
              onClick={() => {
                if (userDetailsData.gitHubUrl) {
                  window.open(userDetailsData.gitHubUrl);
                } else {
                  alert('There is no link to navigate');
                }
              }}>
              <GitHubIcon className={classes.SocicalIconStyle} />
            </Button>
            {/**<Button
         disableElevation
         disableRipple
         onClick={() => window.open('https://www.facebook.com')}>
         <FacebookIcon className={classes.SocicalIconStyle}>
           {userDetailsData.faceBookUrl}
         </FacebookIcon>
       </Button> */}
            <Button
              disableElevation
              disableRipple
              onClick={() => {
                if (userDetailsData.linkedInUrl) {
                  window.open(userDetailsData.linkedInUrl);
                } else {
                  alert('There is no link to navigate');
                }
              }}>
              <LinkedInIcon className={classes.SocicalIconStyle} />
              {}
            </Button>
            <Button
              disableElevation
              disableRipple
              onClick={() => {
                if (userDetailsData.instagramUrl) {
                  window.open(userDetailsData.instagramUrl);
                } else {
                  alert('There is no link to navigate');
                }
              }}>
              <InstagramIcon className={classes.SocicalIconStyle} />
            </Button>
            <Button
              disableElevation
              disableRipple
              onClick={() => {
                if (userDetailsData.faceBookUrl) {
                  window.open(userDetailsData.faceBookUrl);
                } else {
                  alert('There is no link to navigate');
                }
              }}>
              <YouTubeIcon className={classes.SocicalIconStyle} />
            </Button>
          </div>
        )}
      </Paper>
      {/**<Dialog
      open={open}
      onClose={() => changeModalVisibility(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description">
      <div className={classes.ModalDivStyle}>
        <FolloweList
          user={userDetailsData.userId}
          changeModalVisibility={changeModalVisibility}
          isFollower={isFollower}
        />
      </div>
    </Dialog>
 */}
    </div>
  );
}

export default CardProfile;
