import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Typography, Avatar, Modal, LinearProgress} from '@material-ui/core';
import story from '../../assets/Icon_png/story.png';
import BackwardArrow from '../../assets/Icon_svg/Backward_Arrow.svg';
import ForwardArrow from '../../assets/Icon_svg/Forward_Arrow.svg';

const useStyles = makeStyles(theme => ({
  root: {
    alignSelf: 'center',
    margin: '0.8rem',
  },
  UserProfileStyle: {
    width: '3rem',
    height: '3rem',
    marginTop: '0.2rem',
  },
  UserNameStyle: {textAlign: 'center', marginTop: '0.5rem'},
  StoryImageStyle: {
    height: '25rem',
    borderRadius: '0.5rem',
    display: 'flex',
  },
  colorPrimary: {
    backgroundColor: theme.palette.color.grey3,
  },
  barColorPrimary: {
    backgroundColor: theme.palette.color.white,
  },
  storyProfileStyle: {
    width: '2rem',
    height: '2rem',
    marginTop: '1rem',
  },
  mainDev: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  BackwardArrow: {
    margin: '0 2rem 5rem',
    height: '1.5rem',
    width: '1.5rem',
  },
  ForwardArrow: {
    margin: '0 2rem 5rem 1.7rem',
    height: '1.5rem',
    width: '1.5rem',
  },
  storyDivBorder: {margin: '3.5rem 0 10rem'},
  margin1: {margin: '1rem'},
  displayflex: {display: 'flex'},
  userBorderStyle: {margin: '0.8rem 1rem 0'},
  userNameStyle: {color: '#fff', fontSize: 12, fontWeight: 'bold'},
  userCollageName: {color: '#fff', fontSize: 12},
}));

const Story = props => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress(oldProgress => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <React.Fragment>
      <div className={classes.root}>
        <div onClick={handleOpen} type="submit">
          <Avatar
            alt="Remy Sharp"
            src={
              props.userfetchstories
                ? props.userfetchstories.createdBy.profileImageUrl
                : ''
            }
            className={classes.UserProfileStyle}
          />
          <Typography variant="subtitle2" className={classes.UserNameStyle}>
            {props.userfetchstories
              ? props.userfetchstories.createdBy.name
              : ''}
          </Typography>
        </div>

        <Modal open={open} onClose={handleClose}>
          <div open={open} onClick={handleClose}>
            <div className={classes.mainDev}>
              <Avatar
                alt="Remy Sharp"
                src={BackwardArrow}
                className={classes.BackwardArrow}
              />
              <div className={classes.storyDivBorder}>
                <div className={classes.margin1}>
                  <LinearProgress
                    variant="determinate"
                    value={progress}
                    classes={{
                      colorPrimary: classes.colorPrimary,
                      barColorPrimary: classes.barColorPrimary,
                    }}
                  />
                </div>

                <img
                  src={story}
                  alt="UdayaRajurs"
                  className={classes.StoryImageStyle}
                />
                <div className={classes.displayflex}>
                  <Avatar
                    alt="Remy Sharp"
                    src={
                      props.userfetchstories
                        ? props.userfetchstories.createdBy.profileImageUrl
                        : ''
                    }
                    className={classes.storyProfileStyle}
                  />
                  <div className={classes.userBorderStyle}>
                    <Typography
                      variant="subtitle2"
                      className={classes.userNameStyle}>
                      {props.userfetchstories
                        ? props.userfetchstories.createdBy.name
                        : ''}{' '}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.userCollageName}>
                      {props.userfetchstories
                        ? props.userfetchstories.createdBy.collegeName
                        : ''}{' '}
                    </Typography>
                  </div>
                </div>
              </div>

              <Avatar
                alt="Remy Sharp"
                src={ForwardArrow}
                className={classes.ForwardArrow}
              />
            </div>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default Story;
