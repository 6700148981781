import React from 'react';
import {useState} from 'react';
import {Button, Divider, IconButton, Input} from '@material-ui/core';
import {Cancel} from '@material-ui/icons';
import {makeStyles} from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    width: '25rem',
    bottom: '0.3rem',
  },
  input: {
    display: 'none',
  },
  btn: {
    color: 'white',
    width: '10rem',
    height: '2rem',
    top: '1rem',
    marginLeft: '1rem',
    textTransform: 'capitalize',
  },
}));

const CrispyPost = props => {
  const classes = useStyles();
  // const [inputs, setInputs] = useState([{key: '', value: ''}]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [mediaType, setMediaType] = useState();

  const addHandler = () => {
    if (props.inputs.length === 6) {
      alert('Maximum Limit is 6');
    } else {
      const _inputs = [...props.inputs];
      _inputs.push({key: '', heading: '', content: '', focus: false});
      props.setInputs(_inputs);
    }
  };

  const deleteHandler = key => {
    const _inputs = props.inputs.filter((input, index) => index !== key);
    props.setInputs(_inputs);
  };

  return (
    <React.Fragment>
      <div>
        <div className={classes.root}>
          {props.inputs.map((input, key) => {
            return (
              <div>
                <IconButton onClick={() => deleteHandler(key)}>
                  <Cancel />
                </IconButton>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                        gap: '1rem',
                      }}>
                      <span>{key + 1}:</span>
                      <Input
                        className={classes.heading}
                        disableUnderline={true}
                        fullWidth
                        multiline={true}
                        variant="outlined"
                        placeholder="Enter Heading"
                        value={input.heading}
                        onChange={e => {
                          props.adddata(
                            e.target.value,
                            key,
                            input.content,
                            true,
                          );
                        }}
                      />
                    </div>
                  </AccordionSummary>
                  <Divider />
                  <AccordionDetails>
                    <Input
                      disableUnderline={true}
                      style={{border: 'none', width: '25rem'}}
                      placeholder="Enter Content"
                      multiline={true}
                      variant="outlined"
                      value={input.content}
                      onChange={e => {
                        props.adddata(input.heading, key, e.target.value, true);
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
              </div>
            );
          })}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '2rem',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        {props.inputs.length >= 6 ? (
          <div />
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={addHandler}
            className={classes.btn}>
            Add SubTopics
          </Button>
        )}
        {selectedImage && (
          <div style={{margin: '0 1rem'}}>
            <div style={{position: 'relative', margin: '1rem'}}>
              <Cancel
                style={{color: '#8B8B8B'}}
                onClick={() => {
                  setSelectedImage(null);
                  if (props.isedit) {
                    props.seteditmedia(true);
                  }
                }}
              />
            </div>
            <img
              alt="not found"
              width={'100%'}
              height={'100%'}
              style={{borderRadius: '0.5rem'}}
              src={URL.createObjectURL(selectedImage)}></img>
          </div>
        )}
        <input
          accept="image/*"
          className={classes.input}
          id="contained-button-file"
          multiple
          type="file"
          onChange={event => {
            props.setSelectedImage(event.target.files[0]);
            props.setMediaType('image');
            if (props.isedit) {
              props.seteditmedia(true);
            }
          }}
        />
        {props.mediaType && props.mediaType !== '' ? (
          <Button
            variant="contained"
            color="primary"
            component="span"
            disabled
            className={classes.btn}>
            Add Image
          </Button>
        ) : (
          // <label htmlFor="contained-button-file">

          <label htmlFor="contained-button-file">
            <Button
              variant="contained"
              color="primary"
              component="span"
              className={classes.btn}>
              Add Image
            </Button>
          </label>
          // </label>
        )}
      </div>
    </React.Fragment>
  );
};

export default CrispyPost;
