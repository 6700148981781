import React from 'react';
import {Typography, Button} from '@material-ui/core';

const NewsCard = ({News}) => {
  return (
    <React.Fragment>
      {News.map((item, index) => {
        return (
          <div
            key={index}
            style={{
              flexDirection: 'row',
              display: 'flex',
              marginBottom: '0.5rem',
            }}>
            <Typography
              variant="h4"
              style={{margin: '0rem 0.5rem 0rem', color: '#818181'}}>
              {'\u2022'}
            </Typography>
            <Button
              type="submit"
              onClick={() => window.open(item.node.hostUrl)}
              disableElevation
              disableRipple
              style={{
                textAlign: 'Left',
                display: 'flex',
                padding: '0 0.5rem 0 0',
                textTransform: 'capitalize',
                backgroundColor: 'transparent',
              }}>
              {item.node.heading.substring(0, 50)}...
            </Button>
          </div>
        );
      })}
    </React.Fragment>
  );
};

export default NewsCard;
