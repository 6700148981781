import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {useCookies} from 'react-cookie';
import {useQuery} from '@apollo/client';
import {makeStyles} from '@material-ui/core/styles';
import Navbar from '../navbar/Navbar';
import {Button, Container} from '@material-ui/core';
import ProfileCard from '../profile/ProfileCard';
import SuggestionCard from '../suggestion_Card/SuggestionCard';
import AddPost from '../addPost/AddPost';
import LatestNews from '../news/LatestNews';
import PostDesgin from '../postDesing/PostDesgin';
import PhoneView from '../phoneView/PhoneView';
import ShowPost from '../../queries/ShowPost';
import CreatePost from '../addPost/CreatePost';
import PollDisplay from '../postDesing/PollDisplay';
import {ThreeCircles} from 'react-loader-spinner';
import {ArrowUpward} from '@material-ui/icons';
import {useLocation} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import Story from '../story/StoryCard';
import ScontiAdsCard from '../../AdsCard/ScontiAdsCard';

const useStyles = makeStyles(theme => ({
  mainDiv: {
    display: 'flex',
    paddingTop: '4rem',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
    },
  },
  ProfilCard: {
    position: 'sticky',
    top: 0,
    marginLeft: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  PhoneViewDisable: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  PhoneViewEnable: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  News: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  Serach: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  PhoneViewDisable: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  PhoneViewEnable: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  scrbtn: {
    position: 'fixed',
    borderRadius: '10px',
    bottom: '1rem',
    margin: '0rem 10rem',
    color: 'white',
    textTransform: 'capitalize',
    zIndex: 1,
  },
}));

const Home = () => {
  const classes = useStyles();
  const pathname = useLocation();
  const navigate = useNavigate();
  // const navigate = useNavigate();
  const [data, setData] = React.useState([]);
  const [cookies, setCookie] = useCookies(['user']);
  const [getSubscriptionsData, setGetSubscriptionsData] = React.useState([]);
  const [loading, setLoading] = useState(true);
  const [showButton, setShowButton] = useState(false);
  const listInnerRef = useRef();
  const trackRef = useRef([]);

  const {fetchMore} = useQuery(ShowPost, {
    variables: {
      limit: 10,
      nextToken: null,
      userModelInputFilter: {
        userId: cookies.user ? cookies.user.userId : '',
      },
    },

    onCompleted: res => {
      console.log('posts', res);
      setData(res.getSubscriptions ? res.getSubscriptions.edges : []);
      setGetSubscriptionsData(res.getSubscriptions);
      setLoading(false);
    },
    onError: err => {
      console.log(err);
    },
  });

  console.log('get sub data : ', getSubscriptionsData);

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        nextToken: getSubscriptionsData.pageInfo.endCursor,
        limit: 10,
        userModelInputFilter: {
          userId: cookies.user ? cookies.user.userId : '',
        },
      },

      updateQuery: (prev, {fetchMoreResult}) => {
        if (!fetchMoreResult) return prev;

        setGetSubscriptionsData(fetchMoreResult.getSubscriptions);
        return {
          ...prev,
          getSubscriptions: {
            ...prev.getSubscriptions,
            edges: [
              ...prev.getSubscriptions.edges,
              ...fetchMoreResult.getSubscriptions.edges,
            ],
            pageInfo: fetchMoreResult.getSubscriptions.pageInfo,
          },
        };
      },
    });
  };

  useLayoutEffect(() => {
    const handleScroll = () => {
      window.innerHeight + window.scrollY >= document.body.offsetHeight - 500
        ? setShowButton(true)
        : setShowButton(false);

      // You can define your own conditions to trigger loading more data based on scroll
      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - 500
      ) {
        console.log('pagination if check');
        console.log(trackRef.current);
        if (getSubscriptionsData && getSubscriptionsData.pageInfo) {
          if (
            !trackRef.current.includes(
              getSubscriptionsData.pageInfo
                ? getSubscriptionsData.pageInfo.endCursor
                : '',
            )
          ) {
            // setGetSubscriptionsData(getSubscriptionsData.concat(handleLoadMore))

            handleLoadMore();
            trackRef.current = [
              ...trackRef.current,
              getSubscriptionsData
                ? getSubscriptionsData.pageInfo.endCursor
                : '',
            ];
          }
        }
      }
    };
    // const handleScrollButtonVisibility = () => {
    //   window.pageYOffset > 600 ? setShowButton(true) : setShowButton(false);
    // };
    // window.addEventListener('scroll', handleScrollButtonVisibility);
    // return () => {
    //   window.removeEventListener('scroll', handleScrollButtonVisibility);
    // };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [data]);
  // Ensure that this effect is executed whenever new data is fetched

  const [open, setOpen] = useState(false);
  const handleScrollToTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const onScroll = () => {
    if (listInnerRef.current) {
      const {scrollTop, scrollHeight, clientHeight} = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        console.log('finisehed');
      }
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  useEffect(() => {
    if (!cookies.user) {
      navigate('/auth');
    }
    // console.log('cookie user', cookies);
  }, []);

  const body = (
    <div>
      <CreatePost />
    </div>
  );
  return (
    <React.Fragment>
      <div className={classes.PhoneViewDisable}>
        <Navbar click={handleScrollToTop} />
        <div
          onScroll={() => {
            onScroll();
          }}
          ref={listInnerRef}>
          <Container maxWidth="xl">
            <div className={classes.mainDiv}>
              <div className={classes.ProfilCard}>
                <ProfileCard />
                <SuggestionCard />
              </div>
              <div>
                <AddPost handleClose={handleClose} handleOpen={handleOpen} />
                {/* <Story /> */}
                {showButton && (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.scrbtn}
                    onClick={handleScrollToTop}
                    startIcon={<ArrowUpward />}>
                    Back To Top
                  </Button>
                )}
                {loading ? (
                  <div style={{marginLeft: '13.5rem', marginTop: '5rem'}}>
                    <ThreeCircles
                      height="100"
                      width="100"
                      color="#4fa94d"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="three-circles-rotating"
                      outerCircleColor="#00a3ff"
                      innerCircleColor="#ff9d00"
                      middleCircleColor="#4088FF"
                    />
                  </div>
                ) : (
                  <div>
                    {data.map((item, key) => (
                      <div key={key}>
                        {item.node.poll ? (
                          <PollDisplay data={item.node} />
                        ) : (
                          <PostDesgin data={item.node} />
                        )}
                      </div>
                    ))}
                    {getSubscriptionsData.pageInfo.hasNextPage ? (
                      <div style={{marginLeft: '13.5rem', marginTop: '5rem'}}>
                        <ThreeCircles
                          height="100"
                          width="100"
                          color="#4fa94d"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                          ariaLabel="three-circles-rotating"
                          outerCircleColor="#00a3ff"
                          innerCircleColor="#ff9d00"
                          middleCircleColor="#4088FF"
                        />
                      </div>
                    ) : (
                      <div />
                    )}
                  </div>
                )}
              </div>
              <div className={classes.News}>
                <div style={{marginLeft:35}}>
                  <ScontiAdsCard />
                </div>
                <LatestNews />
              </div>
            </div>
          </Container>
        </div>
      </div>
      <div className={classes.PhoneViewEnable}>
        <PhoneView />
      </div>
    </React.Fragment>
  );
};

export default Home;
