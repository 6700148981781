import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useNavigate} from 'react-router-dom';
import {Typography, Divider, Avatar} from '@material-ui/core';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'stretch',
  },
  UserProfileStyle: {
    width: '3rem',
    height: '3rem',
    margin: '1rem 0.5rem',
  },
  UserNameStyle: {
    display: 'flex',
    alignItems: 'center',
    flex: '1',
  },
  userName: {
    fontWeight: 'bold',
  },
  NotificationDesText: {
    width: '29rem',
    overflow:"break-word",wordBreak:"break-word",wordWrap:"break-word"
  },

  TimeText: {
    marginTop: '0.5rem',
  },
}));
const NotificationCard = props => {
  const navigate = useNavigate();
  const [notificationData, setNotificationData] = useState({});
  useEffect(() => {
    setNotificationData(JSON.parse(props.userNotificationData.data));
  }, []);

  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.root}>
        <div
          style={{cursor: 'pointer'}}
          onClick={() => {
            if (JSON.parse(props.userNotificationData.data).newsId) {
              navigate('/news', {
                state: JSON.parse(props.userNotificationData.data).newsId,
              });
            }
            // else if(JSON.parse(props.userNotificationData.data).postId){
            //   navigate('/viewPost', {
            //     state: JSON.parse(props.userNotificationData.data).postId,
            //   });
            // }
            else {
              navigate('/profile', {
                state: JSON.parse(props.userNotificationData.data).actedByUserId
                  ? JSON.parse(props.userNotificationData.data).actedByUserId
                  : JSON.parse(props.userNotificationData.data).followingUserId
                  ? JSON.parse(props.userNotificationData.data).followingUserId
                  : '',
              });
            }
          }}>
          {JSON.parse(props.userNotificationData.data).newsId ? (
            <NewspaperIcon style={{fontSize: '4rem', color: 'gray'}} />
          ) : (
            <Avatar
              alt="Remy Sharp"
              src={
                JSON.parse(props.userNotificationData.data)
                  .actedByUserProfileImageUrl
              }
              className={classes.UserProfileStyle}
            />
          )}
        </div>

        <div
          style={{
            padding: '0.5rem',
            cursor: 'pointer',
          }}
          onClick={() => {
            if (JSON.parse(props.userNotificationData.data).postId) {
              navigate('/viewPost', {
                state: JSON.parse(props.userNotificationData.data).postId,
              });
            } else if (
              JSON.parse(props.userNotificationData.data).followingUserId
            ) {
              navigate('/profile', {
                state: JSON.parse(props.userNotificationData.data).actedByUserId
                  ? JSON.parse(props.userNotificationData.data).actedByUserId
                  : JSON.parse(props.userNotificationData.data).followingUserId
                  ? JSON.parse(props.userNotificationData.data).followingUserId
                  : '',
              });
            } else {
              navigate('/news', {
                state: JSON.parse(props.userNotificationData.data).newsId,
              });
            }
          }}>
          <Typography variant="subtitle2" className={classes.userName}>
            {JSON.parse(props.userNotificationData.data).title.substring(0, 50)}
          </Typography>
          <Typography
            variant="subtitle2"
            className={classes.NotificationDesText}>
            {JSON.parse(props.userNotificationData.data).message.includes(
              'sp@%12',
            )
              ? JSON.parse(props.userNotificationData.data).message.split(
                  'sp@%12',
                )[0]
              : JSON.parse(props.userNotificationData.data).message.length > 100
              ? JSON.parse(props.userNotificationData.data).message.substring(
                  0,
                  100,
                ) + '...'
              : JSON.parse(props.userNotificationData.data).message.substring(
                  0,
                  100,
                )}
          </Typography>

          <div className={classes.MoreOption}>
            <Typography variant="subtitle2" className={classes.TimeText}>
              {moment
                .utc(props.userNotificationData.createdOn)
                .local()
                .startOf('seconds')
                .fromNow()}
            </Typography>
          </div>
        </div>
      </div>
      <Divider />
    </React.Fragment>
  );
};

export default NotificationCard;
