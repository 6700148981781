import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';

import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

import {
  Typography,
  Card,
  IconButton,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Badge,
  LinearProgress,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import AnswerPoll from '../../queries/AnswerPoll';
import {useMutation} from '@apollo/client';
import {useCookies} from 'react-cookie';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ShowPost from '../../queries/ShowPost';
const DialogTitle = props => {
  const {children, classes, onClose, ...other} = props;
  return (
    <MuiDialogTitle
      disableTypography
      {...other}
      style={{
        display: 'flex',
        flexDirection: 'row',
        borderRadius: '1rem',
        gap: '22rem',
        marginBottom: '-1.5rem',
      }}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          style={{bottom: '0.4rem'}}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};
const useStyles = makeStyles(theme => ({
  Paper: {
    width: '34rem',
    borderRadius: '1rem',
    margin: '1rem 0rem',
    paddingBottom: '0.5rem',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.only('md')]: {
      marginRight: '2rem',
    },
  },
  userDetailsBorderStyle: {
    display: 'flex',
    justifyContent: 'spaceBetween',
    margin: '0rem 1rem',
    cursor: 'pointer',
  },
  UserProfileStyle: {
    width: '2.5rem',
    height: '2.5rem',
    marginTop: '1rem',
  },
  UserNameAndLocatoinBorderStyle: {
    margin: '1rem 1rem',

    width: '22rem',
    cursor: 'pointer',
  },
  LocationIconStyle: {
    width: '1rem',
    height: '1rem',
  },
  LocationTextStyle: {
    fontSize: 10,
    margin: '0rem 0.3rem',
    color: theme.palette.primary.main,
  },
  PostAddTimeTextStyle: {fontSize: 10, width: '5rem'},
  MoreIconStyle: {
    width: '1.5rem',
    height: '1.5rem',
    marginTop: '0.7rem',
    left: '2rem',
  },
  PostDesStyle: {
    margin: '0.5rem 0.5rem',
    width: '32rem',
    overflow: 'break-word',
    wordBreak: 'break-word',
    wordWrap: 'break-word',
  },
  BookMarkIconBorderStyle: {display: 'flex', marginLeft: '7rem'},
  LikeCommentShareBorderStyle: {
    margin: '0.5rem 1rem 0rem 1rem',
    display: 'flex',
  },
  DisplayFlex: {display: 'flex'},
  fontWeightBold: {fontWeight: 'bold'},
  PostImageStyle: {width: '100%'},
  IconSelect: {
    color: theme.palette.primary.main,
  },
  IcontNotSelect: {
    color: theme.palette.color.grey1,
  },
  CommentIconBorderStyle: {display: 'flex', margin: '0rem 1rem'},
  ButtonStyle: {
    padding: 0,
    textTransform: 'capitalize',
    backgroundColor: theme.palette.color.white,
    '&:hover': {
      background: theme.palette.color.white,
    },
  },
  ModalDivStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  IconWorng: {
    color: theme.palette.color.white,
  },
  badgeIcon: {
    marginLeft: '1.5rem',
  },
  badgeText: {
    marginLeft: '1.5rem',
  },
  liked: {color: '#FF0000'},
  group: {display: 'flex', margin: '1.5rem'},
}));

const PollDisplayForComment = props => {
  const classes = useStyles();
  const [cookies, setCookie] = useCookies(['user']);
  const [value, setValue] = React.useState('female');
  const [isReadMore, setIsReadMore] = useState(true);

  const handleChange = event => {
    handlePollAnswer(Number.parseInt(event.target.value));
  };
  const [answerPollMutation] = useMutation(AnswerPoll);
  const updateCacheforpoll = (client, {data: {answerPoll}}) => {
    console.log('entered');
    const data = client.readQuery({
      query: ShowPost,
      variables: {
        limit: 10,
        nextToken: null,
        userModelInputFilteruser: {userId: cookies.user.userId},
      },
    });

    // const newArray = data.getSubscriptions.edges.filter(
    //   item => item.node.postId !== props.data.postId,
    // );
    var newArray = [];
    data.getSubscriptions.edges.map((item, index) => {
      if (item.node.postId === props.data.postId) {
        newArray.push({
          node: {
            ...item.node,
            poll: answerPoll.poll,
          },
        });
      } else {
        newArray.push(item);
      }
    });
    // console.log('likechanges', newArray[0].node.postedBy.likedByMe);
    const newData = {
      getSubscriptions: {...data.getSubscriptions, edges: newArray},
    };
    // const newData = {getSubscriptions: newArray};
    client.writeQuery({
      query: ShowPost,
      variables: {
        limit: 10,
        nextToken: null,
        userModelInputFilteruser: {userId: cookies.user.userId},
      },
      data: newData,
    });
  };
  const handlePollAnswer = selected => {
    answerPollMutation({
      variables: {
        postId: props.data.postId,
        answeredByUserId: cookies.user.userId,
        selectedOption: selected,
      },
      update: updateCacheforpoll,
    });
  };

  return (
    <React.Fragment>
      <Card elevation={1} className={classes.Paper}>
        <FormControl component="fieldset" className={classes.group}>
          <Typography className={classes.PostDesStyle} input>
            {props.data.description}
          </Typography>

          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={value}
            onChange={handleChange}>
            {props.data.poll.options.map((item, index, key) => (
              <div>
                <div>
                  {props.data.poll.amIAnswered ? (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: 5,
                      }}>
                      <div style={{top: '1rem', width: 50}}>
                        <Typography
                          variant="body2"
                          // style={{bottom: '2rem'}}
                        >
                          {Number.parseInt(
                            props.data.poll.optionSelectionPercentage[index],
                          )}
                          %
                        </Typography>
                        {props.data.poll.myAnsIndex === index &&
                          props.data.poll.correctAnsIndex !== index && (
                            <CancelIcon style={{color: 'red'}} />
                          )}
                        {((props.data.poll.myAnsIndex === index &&
                          props.data.poll.correctAnsIndex === index) ||
                          props.data.poll.correctAnsIndex === index) && (
                          <CheckCircleIcon style={{color: 'green'}} />
                        )}
                      </div>
                      <div style={{width: '34rem'}}>
                        <Typography
                          variant="body2"
                          style={{paddingBottom: '1rem'}}>
                          {item}
                        </Typography>
                        {props.data.poll.amIAnswered ? (
                          props.data.poll.optionSelectionPercentage[index] >=
                          0 ? (
                            <LinearProgress
                              variant="determinate"
                              style={{
                                width: '50%',
                                height: '10px',
                                borderRadius: '10px',
                                bottom: '0.7rem',
                              }}
                              value={
                                props.data.poll.optionSelectionPercentage[index]
                              }
                            />
                          ) : (
                            <div />
                          )
                        ) : (
                          <div />
                        )}
                      </div>
                    </div>
                  ) : (
                    <FormControlLabel
                      // style={{top:"2rem"}}
                      value={index}
                      control={<Radio />}
                      label={item}></FormControlLabel>
                  )}
                </div>
              </div>
            ))}
          </RadioGroup>
        </FormControl>
        {props.data.poll.totalAnswerCount > 0 ? (
          <div>
            <Badge
              badgeContent={props.data.poll.totalAnswerCount}
              max={9999}
              overlap="rectangular"
              // color="primary"
              style={{
                color:
                  props.data.poll.totalAnswerCount >= 100
                    ? '#FF8B00'
                    : props.data.poll.totalAnswerCount >= 50
                    ? '#8F1D14'
                    : '#00A3FF',
              }}>
              <PeopleAltIcon
                color="primary"
                style={{
                  color:
                    props.data.poll.totalAnswerCount >= 100
                      ? '#FF8B00'
                      : props.data.poll.totalAnswerCount >= 50
                      ? '#8F1D14'
                      : '#00A3FF',
                }}
                className={classes.badgeIcon}
              />
            </Badge>
            <p className={classes.badgeText}>Answered</p>
          </div>
        ) : (
          <div />
        )}
       
        {/**props.data.poll.totalAnswerCount */}
      </Card>
    </React.Fragment>
  );
};

export default PollDisplayForComment;
