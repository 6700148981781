import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {Link} from 'react-router-dom';


const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});
const QuizRegCard = () => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <div>
        <img
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTw_HeSzHfBorKS4muw4IIeVvvRgnhyO8Gn8w&usqp=CAU"
          alt="CardName"
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '0.5rem',
            justifyContent: 'flex-start',
          }}>
          <Typography>QuizName :</Typography>
          <Typography style={{color: 'gray'}}>Name of the Quiz</Typography>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '0.5rem',
            justifyContent: 'flex-start',
          }}>
          <Typography>Description:</Typography>
          <Typography style={{color: 'gray'}}>Explore Your Skils</Typography>
        </div>{' '}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '0.5rem',
            justifyContent: 'flex-start',
          }}>
          <Typography>Event Date and Time:</Typography>
          <Typography style={{color: 'gray'}}>Duration</Typography>
        </div>{' '}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '0.5rem',
            justifyContent: 'flex-start',
          }}>
          <Typography>Number of people Registered:</Typography>
          <Typography style={{color: 'gray'}}>50+</Typography>
        </div>
      </div>
      <Link style={{textDecoration: 'none'}} to="/register">
      <Button
        size="small"
        color="primary"
        variant="contained"
        style={{
          marginRight: '1rem',
          marginLeft: '1rem',
          color: 'whitesmoke',
          textTransform: 'capitalize',
          width: '19rem',
        }}>
        Register Now
      </Button>
      </Link>

    </Card>
  );
};

export default QuizRegCard;
