import React from 'react';
import {Typography,  Button, Card} from '@material-ui/core';
import {Link} from 'react-router-dom';



const SubQuizSection = () => {
  return (
    <Card
      elevation={2}
      style={{width: '12rem', height: '15rem', borderRadius: '1rem'}}>
      <div>
        <div>
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTw_HeSzHfBorKS4muw4IIeVvvRgnhyO8Gn8w&usqp=CAU"
            alt='subQuiz'
            width={100}
            height={100}
            style={{display: 'block', margin: 'auto',objectFit:"contain"}}
          />
        </div>
        <div>
          <Typography variant="body1" style={{textAlign: 'center'}}>
            Test Name{' '}
          </Typography>
        </div>
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '0.5rem',
              justifyContent: 'flex-start',
              marginLeft: '0.5rem',
            }}>
            <Typography>Starts At:</Typography>
            <Typography style={{color: 'gray'}}>Time</Typography>
          </div>{' '}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '0.5rem',
              justifyContent: 'flex-start',
              marginLeft: '0.5rem',
            }}>
            <Typography>Ends At:</Typography>
            <Typography style={{color: 'gray'}}>Time</Typography>
          </div>{' '}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '0.5rem',
              justifyContent: 'flex-start',
              marginLeft: '0.5rem',
            }}>
            <Typography>Quiz Duration</Typography>
            <Typography style={{color: 'gray'}}>Time</Typography>
          </div>
        </div>
        <Link to='/instruction' style={{textDecoration:"none"}}>
        <Button
          size="small"
          color="primary"
          variant="contained"
          style={{
            color: 'whitesmoke',
            textTransform: 'capitalize',
            left: '3rem',
          }}>
          Start Now
        </Button>
        </Link>
      </div>
    </Card>
  );
};

export default SubQuizSection;
