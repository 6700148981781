import React, {useState, useEffect, useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
  Paper,
  Typography,
  Button,
  Divider,
  TextField,
  Avatar,
} from '@material-ui/core/';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CancelIcon from '@material-ui/icons/Cancel';
import ClearIcon from '@material-ui/icons/Clear';
import ImageIcon from '@material-ui/icons/Image';
import QueueIcon from '@material-ui/icons/Queue';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import CrispyPost from './CrispyPost';
import {uploadFile} from 'react-s3';
import {useCookies} from 'react-cookie';
import {useMutation} from '@apollo/client';
import ShowPost from '../../queries/ShowPost';
import {db} from '../Auth/firebase';
import EditPosts from '../../queries/EditPost';
import ShowPostOfUser from '../../queries/ShowPostOfUser';


const useStyles = makeStyles(theme => ({
  Paper: {
    backgroundColor: '#fff',
    width: '500px',
    minHeight: '21.5rem',
    maxHeight: '500px',
    overflowY: 'auto',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
  },

  CreateAPostTextStyle: {
    padding: '0.5rem',
  },
  avatar: {
    display: 'flex',
    padding: '0.7rem',
  },
  username: {
    display: 'flex',
    alignItems: 'center',

    fontWeight: 'bold',
  },
  textfield: {
    marginLeft: '1rem',
    marginBottom: '1rem',
  },
  btnicon: {
    display: 'flex',
    position: 'relative',
    padding: '0.5rem 0',
    marginLeft: '0.7rem',
  },

  button: {
    textTransform: 'capitalize',
    padding: 0,
    marginRight: '1rem',
    backgroundColor: '#00A3FF',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#00A3FF',
    },
    input: {
      display: 'none',
    },
  },

  RemovePollbutton: {
    textTransform: 'capitalize',
    marginRight: '1rem',
    padding: '0 0.5rem',
    backgroundColor: '#ff0000',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#ff0000',
    },
    input: {
      display: 'none',
    },
  },
  AfterColore: {color: '#8B8B8B', marginRight: '1rem', cursor: 'pointer'},
  BeforeColore: {color: '#00A3FF', marginRight: '1rem', cursor: 'pointer'},
}));

const EditPost = (props) => {
  const classes = useStyles();
  const [cookies, setCookie] = useCookies(['user']);
  const [mediaType, setMediaType] = useState();
  const [mediaName, setMediaName] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedDoc, setSelectedDoc] = useState();
  const [source, setSource] = useState();
  const [poll, setPoll] = useState(
    props.PollType === true ? true : false,
  );
  const [disablebtn, setDisablebtn] = useState(false);
  const [crispy, setCrispy] = useState(
    props.CrispyType === true ? true : false,
  );
  const [postCapture, setPostCapture] = useState('');
  const [mediaData, setmediaData] = useState([]);
  const [inputs, setinputs] = useState([
    {key: '', heading: '', content: '', focus: false},
  ]);
  const [awsdata, setawsdata] = useState({});
  const [config, setconfig] = useState();
  // aws confing
  const [selectedFile, setSelectedFile] = useState(null);
  const [updateMedia, setupdateMedia] = useState(false);

  useEffect(() => {
    db.collection('details')
      .doc('AWSconstants')
      .get()
      .then(snap => {
        setawsdata(snap.data());
        setconfig({
          bucketName: snap.data().bucket,
          region: snap.data().region,
          accessKeyId: snap.data().accessKey,
          secretAccessKey: snap.data().secretKey,
        });
      })
      .catch(err => {
        console.log(err);
      });
  }, []);
  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleFileInput = e => {
    setSelectedFile(e.target.files[0]);
  };
  const inputHandler = (text, key, checkstate, focus) => {
    const _inputs = [...inputs];
    _inputs[key].heading = text;
    _inputs[key].key = key;
    _inputs[key].content = checkstate;
    _inputs[key].focus = focus;
    setinputs(_inputs);
    // props.options(_inputs);
  };
  const handleUpload = async file => {
    uploadFile(file, config)
      .then(data => {
        var mediaarr = [];
        var obj = {
          url: data.location,
          type: mediaType,
          name: '',
        };
        mediaarr = [...mediaData, obj];
        setmediaData([...mediaData, obj]);
        if(crispy){
          crispyPostData(mediaarr)
          console.log(          mediaarr
          )
        }else{
        handleAddPost(mediaarr);
        }
      })
      .catch(err => console.error(err));
  };

  // aws config ends
  console.log(mediaType);
  const [addPostMutation] = useMutation(EditPosts);

  const updateCacheforedit = (client, {data: {editPost}}) => {
    const data = client.readQuery({
      query: ShowPost,
      variables: {
        limit: 10,
        nextToken: null,
        userModelInputFilter: {userId: cookies.user.userId},
      },
    });
    const data1 = client.readQuery({
      query: ShowPostOfUser,
      variables: {
        limit: 10,
        nextToken: null,
        userModelInputFilter: {
          userId: cookies.user.userId,
        },
        visitingUserId: cookies.user.userId,
      },
    });

    // console.log('wholenew', editPost);
    console.log(data);
    if (editPost) {
      var obj = {node: editPost};
      const index = data.getSubscriptions.edges.findIndex(
        index => index.node.postId === props.data.postId,
      );
      const index1 = data1.postsOfUser.edges.findIndex(
        index => index.node.postId === props.data.postId,
      );
      console.log('index', index);
      var newArray = [...data.getSubscriptions.edges];
      newArray[index] = obj;
      const newData = {
        getSubscriptions: {...data.getSubscriptions, edges: newArray},
      };
      var newArray1 = [...data1.postsOfUser.edges];
      newArray1[index] = obj;
      const newData1 = {
        postsOfUser: {...data.postsOfUser, edges: newArray},
      };
      // const newData = {getSubscriptions: newArray};
      client.writeQuery({
        query: ShowPost,
        variables: {
          limit: 10,
          nextToken: null,
          userModelInputFilter: {userId: cookies.user.userId},
        },
        data: newData,
      });
      client.writeQuery({
        query: ShowPostOfUser,
        variables: {
          limit: 10,
          nextToken: null,
          userModelInputFilter: {
            userId: cookies.user.userId,
          },
          visitingUserId: cookies.user.userId,
        },
        data: newData1,
      });
    }
  };
  const handleAddPost = mediaarr => {
    addPostMutation({
      variables: {
        postId: props.data ? props.data.postId : '',
        description: postCapture,
        // interests: childCompRef.current.sendData(),

        mediaData: mediaarr,
        updateMedia: updateMedia,
      },
      update: updateCacheforedit,
    });
  };

  useEffect(() => {
    if (props.mediaLink !== undefined && props.mediaType === '0') {
      //  const url = URL.createObjectURL(props.mediaLink);
      setMediaType('image');
      // 0 indicates image
      setSelectedImage(props.mediaLink);
    }
    if (props.mediaLink !== undefined && props.mediaType === '1') {
      setMediaType('video');
      // const url = URL.createObjectURL(props.mediaLink);
      setSource(props.mediaLink);
      // 1 indicates Video
    }
    if (props.mediaLink !== undefined && props.mediaType === '2') {
      setMediaType('doc');
      // const urlfordoc = URL.createObjectURL(props.mediaLink);
      setSelectedDoc(props.mediaLink);
      // 2 indicates doc
    }

    if (props.data) {
      setPostCapture(props.data.description);
      if (props.data.media && props.data.media.length > 0) {
        props.data.media.map((item, index) => {
          if (item.type === 'image') {
            setSelectedImage(item.url);
            setMediaType('image');
          }
          if (item.type === 'video') {
            setSource(item.url);
            setMediaType('video');
          }
          if (
            item.type === 'word' ||
            item.type === 'pdf' ||
            item.type === 'excel' ||
            item.type === 'ppt'
          ) {
            setSelectedDoc(item.url);
            setMediaType('doc');
          }
        });
        setinputs(inputs);
      }
      if (
        props.data.description &&
        props.data.description !== '' &&
        props.data.description.includes('sp@%12')
      ) {
        setCrispy(true);
        setinputs(
          JSON.parse(
            props.data.description.substring(
              props.data.description.indexOf('sp@%12') + 6,
              props.data.description.length + 1,
            ),
          ),
        );
        setPostCapture(
          props.data.description &&
            props.data.description !== '' &&
            props.data.description.includes('sp@%12')
            ? props.data.description.substring(
                0,
                props.data.description.indexOf('sp@%12'),
              )
            : props.data.description,
        );
      }
    }
  }, []);

  const handleVideoChange = event => {
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);
    if (event.target.files[0].size>(10e6)*3) {
      alert("Video limit is 30mb")
    }
    else{
      setSource(file);
      setMediaType('video');
    }
    event.target.value = '';
  };

  const handleDocChange = event => {
    // setMediaType(2);
    event.preventDefault();
    console.log('documenttype', event.target.files[0].type);
    const doctype = event.target.files[0].type;
    const filefordoc = event.target.files[0];
    setSelectedDoc(urlfordoc);
    event.target.value = '';
    if (filefordoc) {
      const fileExtension = filefordoc.type.split('.').pop();
      console.log(`File extension: ${fileExtension}`);
      setMediaType(filefordoc);
      console.log(filefordoc);
    }
    const urlfordoc = `https://drive.google.com/viewerng/viewer?embedded=true&url='+${filefordoc}`;
    setSelectedDoc(urlfordoc);
    if (doctype === 'application/pdf') {
      setMediaType('pdf');        setMediaName(filefordoc.name);

      setSelectedDoc(filefordoc);
    } else if (
      doctype === 'application/ppt' ||
      doctype === 'application/pptx'
    ) {
      setMediaType('ppt');
      setSelectedDoc(filefordoc);
      setMediaName(filefordoc.name);

    } else if (
      doctype === 'application/xlsx' ||
      doctype === 'application/xls'
    ) {
      setMediaType('excel');
      setSelectedDoc(filefordoc);
      setMediaName(filefordoc.name);

    } else if (
      doctype === 'application/doc' ||
      doctype === 'application/docx' ||
      'application/word'
    ) {
      setMediaType('word');
      setSelectedDoc(filefordoc);
      setMediaName(filefordoc.name);

    }
  };
  const childCompRef = useRef();


  const crispyPostData = (media) => {
    console.log('predata',{
      description: postCapture.trim() + 'sp@%12' + JSON.stringify(inputs),
        postId: props.data.postId,
        mediaData: media,
        updateMedia: updateMedia,
    })
    addPostMutation({
      variables: {
        description: postCapture.trim() + 'sp@%12' + JSON.stringify(inputs),
        postId: props.data.postId,
        mediaData: media,
        updateMedia: updateMedia,
        // interests: childCompRef.current.sendData(),

      },
      // update: updateCache,
    })
      .then(res => {
        console.log(res);
      })
      .catch(err => {
        console.log(err);
      });
  };
  return (
    <div style={{overflowY: 'hidden'}}>
      <Paper elevation={5} className={classes.Paper}>
        <div style={{minHeight: '25rem'}}>
          <div style={{display: 'flex'}}>
            <Typography
              variant="body1"
              gutterBottom
              className={classes.CreateAPostTextStyle}>
              Edit Post
            </Typography>
            <div
              style={{
                flex: 1,
                alignSelf: 'center',
                textAlign: 'right',
                marginRight: '1rem',
              }}>
              <ClearIcon
                style={{cursor: 'pointer'}}
                onClick={() => {
                  //  setExitPost(true);
                  props.close();
                  setSelectedImage(null);
                  setMediaType(undefined);
                  setSource(undefined);
                  setSelectedDoc(undefined);
                  setPoll(false);
                  setCrispy(false);
                  // props.close;
                }}
              />
            </div>
          </div>
          <Divider />
          <div className={classes.avatar}>
            <Avatar alt="UserImage" src={cookies.user.profileImageUrl} />
            <div style={{marginLeft: '0.6rem', flex: 2}}>
              <Typography variant="subtitle2" className={classes.username}>
                {cookies.user.name}
              </Typography>
              <Typography variant="subtitle2" style={{fontSize: '0.7rem'}}>
                {cookies.user.college}
              </Typography>
            </div>
            <div>
              {crispy === true ? (
                <Button
                  onClick={() => {
                    setMediaType(undefined);
                    setCrispy(false);
                  }}
                  className={classes.RemovePollbutton}>
                  Remove Crispy
                </Button>
              ) : (
                ''
              )}
            </div>
            <div>
              {source !== undefined ? (
                <Button
                  onClick={() => {
                    setMediaType(undefined);
                    setSource(undefined);
                  }}
                  className={classes.RemovePollbutton}>
                  remove Video
                </Button>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className={classes.textfield}>
            <TextField
              noValidate
              autoComplete="off"
              multiline
              placeholder={crispy === true ? 'Enter Topic' : 'Type Something..'}
              variant="standard"
              style={{width: '100%', paddingRight: '1rem'}}
              InputProps={{
                disableUnderline: true,
                paddingeft: '2rem',
                maxLength: 1000,
              }}
              onChange={e => {
                setPostCapture(e.target.value);
              }}
              value={postCapture}
            />
          </div>

          <div>
            {selectedImage && (
              <div style={{margin: '0 1rem'}}>
                <div style={{position: 'relative', margin: '1rem'}}>
                  <CancelIcon
                    style={{color: '#8B8B8B'}}
                    onClick={() => {
                      setMediaType(undefined);
                      setSelectedImage(null);
                      setupdateMedia(true);
                      setmediaData([]);
                    }}
                  />
                </div>
                <img
                  alt="not found"
                  width={'100%'}
                  height={'100%'}
                  style={{borderRadius: '0.5rem'}}
                  src={(selectedImage && selectedImage.toString().substring(0,5)==='https')?selectedImage:URL.createObjectURL(selectedImage)}
                />
              </div>
            )}
            <input
              accept="image/*"
              id="ImageAddCreate"
              type="file"
              style={{display: 'none'}}
              onChange={event => {
                setSelectedImage(event.target.files[0]);
                setMediaType('image');
                event.target.value = '';

              
              }}
            />
          </div>

          <div className="VideoInput">
            {source && (
              <div
                style={{
                  margin: '0 1rem',
                  maxWidth: '33rem',
                }}>
                <video
                  className="VideoInput_video"
                  width={'100%'}
                  height={'100%'}
                  controls
                  src={(source && source.toString().substring(0,5)==='https')?source:URL.createObjectURL(source)}

                
                />
              </div>
            )}
            <input
              type="file"
              onChange={handleVideoChange}
              accept=".mov,.mp4"
              style={{display: 'none'}}
              id="VideoAdd"
            />
          </div>
          <div>
            <div>
              {selectedDoc && (
                <div
                  style={{
                    margin: '0 1rem',
                    maxWidth: '33rem',
                  }}>
                  <div style={{position: 'absolute', margin: '1rem'}}>
                    <CancelIcon
                      style={{color: '#8B8B8B'}}
                      onClick={() => {
                        setMediaType(undefined);
                        setSelectedDoc(null);
                      }}
                    />
                  </div>
                  <iframe
                    title="doctype"
                    height="100%"
                    width="100%"
                    controls
                    src={selectedDoc}
                  />
                </div>
              )}
            </div>
            <form action="">
              <input
                style={{display: 'none'}}
                type="file"
                onChange={handleDocChange}
                accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                //  text/plain, application/pdf,"
                id="DocumentAdd"
                src={selectedDoc}
              />
            </form>
          </div>

          <div>
            {crispy === true ? (
              <CrispyPost
                adddata={inputHandler}
                inputs={inputs}
                setInputs={setinputs}
                setSelectedImage={setSelectedImage}
                setMediaType={setMediaType}
                mediaType={mediaType}
                isedit={true}
                seteditmedia={setupdateMedia}
                
              />
            ) : (
              ''
            )}
          </div>
      
        </div>
        <div className={classes.btnicon}>
          {mediaType === undefined ? (
            <label htmlFor="ImageAddCreate">
              <ImageIcon className={classes.BeforeColore} />
            </label>
          ) : (
            <ImageIcon className={classes.AfterColore} />
          )}

          {mediaType === undefined ? (
            <label htmlFor="VideoAdd">
              <VideoLibraryIcon className={classes.BeforeColore} />
            </label>
          ) : (
            <VideoLibraryIcon className={classes.AfterColore} />
          )}

          {mediaType === undefined ? (
            <label htmlFor="DocumentAdd">
              <AttachFileIcon className={classes.BeforeColore} />
            </label>
          ) : (
            <AttachFileIcon className={classes.AfterColore} />
          )}

          {mediaType === undefined ? (
            <label htmlFor="CrispyPostAdd">
              <QueueIcon
                className={classes.BeforeColore}
                onClick={() => {
                  // setMediaType(4);
                  setCrispy(true);
                }}
              />
            </label>
          ) : (
            <QueueIcon className={classes.AfterColore} />
          )}

          <div style={{flex: 1, textAlign: 'right'}}>
            <Button
              variant="contained"
              className={classes.button}
              disabled={disablebtn}
              onClick={() => {
                if (crispy) {
                  if(selectedImage && selectedImage!==""){
                    handleUpload(selectedImage)
                  }else{
                  crispyPostData([]);
                  }
                  

                } else {
                  if (mediaType === 'image') {
                    handleUpload(selectedImage);
                  } else if (mediaType === 'video') {
                    handleUpload(source);
                  } else if (
                    mediaType === 'word' ||
                    mediaType === 'excel' ||
                    mediaType === 'pdf' ||
                    mediaType === 'ppt'
                  ) {
                    handleUpload(selectedDoc);
                  } else {
                    handleAddPost([]);
                    setDisablebtn(true);
                  }
                }
                
                  // alert('Post Updated  Succuessfully');
                  props.close();
                  props.closed();
                  
              }}>
              Update
            </Button>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default EditPost;
