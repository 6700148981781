
import gql from 'graphql-tag';

export default gql`
  mutation likeComment(
    $commentId: ID!,
    $likedByUserId: String!,
  ) {
    likeComment(
      commentId: $commentId
      likedByUserId: $likedByUserId
    )
  }
`;
