
import gql from 'graphql-tag';

export default gql`
query getAllStates {
  getAllStates {
    stateName
  }
}
`;
