import * as React from 'react';
import {createRoot} from 'react-dom/client';
import {CssBaseline, ThemeProvider} from '@material-ui/core';
import App from './App';
import theme from './theme';
import reportWebVitals from './reportWebVitals';
import {CookiesProvider} from 'react-cookie';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
} from '@apollo/client';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
const cache = new InMemoryCache();

const client = new ApolloClient({
  cache: cache,
  uri: 'https://api.scontiapp.com/graphql',
  // uri: "http://scontiapi.ap-south-1.elasticbeanstalk.com/graphql",
 
  name: 'sconti-app-client',
  version: '1.3',
  queryDeduplication: false,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-first',
      nextFetchPolicy: 'cache-and-network',
    },
  },
});
root.render(
  <ThemeProvider theme={theme}>
    <CookiesProvider>
      <ApolloProvider client={client}>
        <CssBaseline />
        <App />
      </ApolloProvider>
    </CookiesProvider>
  </ThemeProvider>,
);
reportWebVitals();
