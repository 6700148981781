import gql from 'graphql-tag';

export default gql`
  query fetchNotifications($limit: Int, $nextToken: String, $userId: String) {
    fetchNotifications(limit: $limit, nextToken: $nextToken, userId: $userId)
      @connection(key: "NotificationDisplayModelConnection") {
      edges {
        node {
          id
          title
          message
          notifiedUserId
          data
          createdOn
          seen
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
