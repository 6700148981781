import gql from 'graphql-tag';

export default gql`
  query viewPost($postId: ID!, $userId: String) {
    viewPost(postId: $postId, userId: $userId) {
      postId
      description
      media {
        name
        url
        type
        thumbnailUrl
        audioPlayCount
      }
      poll {
        description
        options
        optionSelectionPercentage
        correctAnsIndex
        myAnsIndex
        amIAnswered
        totalAnswerCount
      }
      postedBy {
        name
        userId
        profileImageUrl
        collegeName
        amIFollowing
        userType
        companyName
      }
      interests {
        name
      }
      sharedByUserId
      sharedByUserName
      sharedByUserProfileImageUrl
      likedByMe
      commentCount
      likeCount
      shareCount
      createdOn
      updatedOn
      viewCount
      markedByCount
    }
  }
`;
