import {Button, Paper, Typography} from '@material-ui/core';
import React from 'react';
import TimerIcon from '@material-ui/icons/Timer';
import DateRangeIcon from '@material-ui/icons/DateRange';
import EventNoteIcon from '@material-ui/icons/EventNote';
import {Link} from 'react-router-dom';

const Instruction = () => {
  return (
    <Paper elevation={3}>
      <div style={{position: 'relative'}}>
        <img
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTjTg7qF7Haj4Wi2_8cZxD8Uake4V07FTSrQ&usqp=CAU"
          alt="Theme"
          style={{left: '35rem', position: 'absolute', width: 150}}
        />
        <div>
          <Typography variant="h6" style={{textAlign: 'center'}}>
            Test Name{' '}
          </Typography>
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '0.5rem',
                justifyContent: 'flex-start',
              }}>
              <TimerIcon />
              <Typography>Duration:</Typography>
              <Typography>Time</Typography>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '0.5rem',
                justifyContent: 'flex-start',
              }}>
              <DateRangeIcon style={{color: 'green'}} />
              <Typography>Starts at:</Typography>
              <Typography>Time</Typography>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '0.5rem',
                justifyContent: 'flex-start',
              }}>
              <DateRangeIcon style={{color: 'red'}} />
              <Typography>Ends at:</Typography>
              <Typography>Time</Typography>
            </div>
          </div>
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '0.5rem',
                justifyContent: 'center',
              }}>
              <EventNoteIcon />
              <Typography>Points to remember</Typography>
            </div>
            <ol>
              <li> Total duration of the quiz is 10 mins</li>
              <li>
                Quiz can be attended only once if you wish to exit it would be
                submitted automatically and there is no provision to attended
                again
              </li>
              <li>
                {' '}
                <span role="img">🟠-Skipped</span>
                <span role="img">🔵-Attend</span>
                <span role="img">⚫-Attended</span>
              </li>
            </ol>
          </div>
        </div>
        <Link to="/quiz" style={{textDecoration: 'none'}}>
          <Button
            variant="contained"
            color="primary"
            style={{
              color: 'white',
              textTransform: 'capitalize',
              left: '35rem',
              borderRadius: '1.5rem',
            }}>
            Start Quiz
          </Button>
        </Link>
        <Button
          variant="contained"
          color="primary"
          style={{
            color: 'white',
            textTransform: 'capitalize',
            left: '35rem',
            borderRadius: '1.5rem',
          }}>
          View Results
        </Button>
      </div>
    </Paper>
  );
};

export default Instruction;
