import React from 'react';
import './App.css';
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import Auth from './components/Auth/Auth';
import Home from './components/home/Home';
import News from './components/news/News';
import Notification from './components/notification/Notification';
import Setting from './components/setting/Setting';
import Profile from './components/profile/Profile';
import SearchDisplay from './components/search/Search';
import HashtagDisplay from './components/home/HashtagDisplay';
import MainQuizSection from './components/quiz/MainQuizSection';
import Instruction from './components/quiz/Instruction';
import Quiz from './components/quiz/Quiz';
import QuizRegCard from './components/quiz/QuizRegCard';
import QuizRegister from './components/quiz/QuizRegister';
import ViewPosts from './components/postDesing/ViewPost';
import FolloweList from './components/profile/FolloweList';
import Suggestion from './components/suggestion_Card/Suggestion';
import PageNotFound from './components/PageNotFound';
// import Login from './components/Auth/Login';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/auth" exact element={<Auth />} />
        {/* <Route path="/login" exact element={<Login />} /> */}

        <Route path="/home" element={<Home />} />
        <Route path="/" element={<Navigate replace to="/Auth" />} />
        <Route path="/news" exact element={<News />} />
        <Route path="/notification" exact element={<Notification />} />
        <Route path="/SearchDisplay" exact element={<SearchDisplay />} />
        <Route path="/setting" exact element={<Setting />} />
        <Route path="/profile" exact element={<Profile />} />
        <Route path="/mainquiz" exact element={<MainQuizSection />} />
        <Route path="/hashtagDisplay" exact element={<HashtagDisplay />} />
        <Route path="/viewPost" exact element={<ViewPosts />} />
        <Route path="/followList" exact element={<FolloweList />} />
        <Route path="/suggestion" element={<Suggestion />} />
        <Route path="/404" exact element={<PageNotFound />} />
        <Route path="*" exact element={<Navigate to="/404" />} />
      </Routes>

     
      <Routes>
        <Route path="/instruction" element={<Instruction />} />
        <Route path="/quiz" element={<Quiz />} />
        <Route path="/quizreg" element={<QuizRegCard />} />
        <Route path="/register" element={<QuizRegister />} />
        <Route path="/suggestion" element={<Suggestion />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
